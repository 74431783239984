export const Conditions = {
    conditions: {
        bg: [
            { condition: "Ново", value: "new"},
            //{ condition: "Използвано", value: "USED" },
        ],
        en: [
            { condition: "New", value: "NEW"},
            //{ condition: "Used", value: "USED" },
        ],
    }
}

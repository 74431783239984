import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private getDocumentsBaseURL : string = environment.api + "api/partners";
  private getDocsBaseURL : string = environment.api + "api/docs";


  constructor(private httpClient: HttpClient) {
  }

  getPP(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), "privacy-policy-terms.pdf"
    ])
    return this.getDocumentsBaseURL + params;
  }

  getWaranty(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), "warranty.pdf"
    ])
    return this.getDocumentsBaseURL + params;
  }

  getCC(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), "commission-contract.pdf"
    ])
    return this.getDocumentsBaseURL + params;
  }

  getC(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), "coefficient-polices.pdf"
    ])
    return this.getDocumentsBaseURL + params;
  }

  getDPP(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), "data-protection-policy.pdf"
    ])
    return this.getDocumentsBaseURL + params;
  }

  getGPP() {
    const params = RequestHelper.addRESTParams([
      "general-privacy-policy.pdf"
    ])
    return this.getDocsBaseURL + params;
  }

  getGT() {
    const params = RequestHelper.addRESTParams([
      "general-terms.pdf"
    ])
    return this.getDocsBaseURL + params;
  }
  
  getDocument(url) {
    return this.httpClient.get(url, {responseType: 'blob'});
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { ProductLocation } from 'src/models/DTO/productLocation/productLocation';
import { CreateProductLocationRequestModel } from 'src/models/requestModels/productLocation/createProductLocationRequestModel'


@Injectable({
  providedIn: 'root'
})
export class ProductlocationService {
  
  private createProductLocationEntryUrl: string = environment.api + 'api/products';
  private getProductLocationEntryUrl: string = environment.api + 'api/products';
  private changeProductLocationEntryUrl: string = environment.api + 'api/products';
  private deleteProductLocationEntryUrl: string = environment.api + 'api/products';


  constructor(private httpClient: HttpClient) { }

  createProductLocation(productLocation: CreateProductLocationRequestModel) {
    const params = RequestHelper.addRESTParams([
      productLocation.productId.toString(), 'partner-location-products'
    ]);
    return this.httpClient.post<ProductLocation>(this.createProductLocationEntryUrl + params, productLocation);
  }

  getProductLocationByProductAndLocation(productId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), 'partner-location-products'
    ]);
    return this.httpClient.get<ProductLocation[]>(this.getProductLocationEntryUrl + params);
  }

  updateProductLocation(productLocation: CreateProductLocationRequestModel) {
    const params = RequestHelper.addRESTParams([
      productLocation.productId.toString(), 'partner-location-products', productLocation.id.toString()
    ]);
    return this.httpClient.put(this.changeProductLocationEntryUrl + params, productLocation);
  }

  deleteProductLocation(productId: number, locationId: number) {
    const params = RequestHelper.addRESTParams([
      productId.toString(), 'partner-location-products', locationId.toString()
    ]);
    return this.httpClient.delete(this.changeProductLocationEntryUrl + params);
  }
}

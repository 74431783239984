export const RequestHelper = {
    addURLParam: function(key: string, value: string, isInitial: boolean = false) : string {
        if (!isInitial) {
            return key + '=' + value;
        }
        return '?' + key + '=' + value;
    },

    addMultipleParams: function(params: any[]) : string {
        let parameters = '';
        for (let i = 0; i < params.length; i++) {
            if (i == 0) {
                parameters += '?' + params[i].key + '=' + params[i].value;
            }
            else {
                parameters += '&' + params[i].key + '=' + params[i].value;
            }
        }
        return parameters
    },

    addRESTParams: function(params: string[]) : string {
        let parameters = '';
        params.forEach(param => {
            parameters += "/" + param;
        })
        return parameters
    },

}

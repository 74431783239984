import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserRegistration } from '../../../models/DTO/user/UserRegistrationModel';

@Injectable({
  providedIn: 'root'
})
export class UserinfostoreService {

  constructor() { }

  private defaultUser : UserRegistration = { } as any;

  private _userInfo = new BehaviorSubject<UserRegistration>(this.defaultUser);

  public readonly userInfo$ = this._userInfo.asObservable();

  get userInfo() {
    return this._userInfo.getValue();
  }

  set userInfo(value: UserRegistration) {
    this._userInfo.next(value);
  }

  addNewUser(value: UserRegistration) {
    this.userInfo = {...value};
  } 

  removeUser() {
    this.userInfo = this.defaultUser;
  }
}
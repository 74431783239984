import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PartnerRegistrationRequestModel } from 'src/models/requestModels/partner/PartnerRegistrationRequestModel';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { Partner } from 'src/models/DTO/partner/partner';
import { LocalstorageserviceService } from '../../infrastructureServices/localstorageservice/localstorageservice.service';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { Totals } from 'src/models/DTO/V2/orders/Totals';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  private partnerRegistrationURL : string = environment.api + "api/partners";
  private partnerInfoURL : string = environment.api + "api/partners";
  private partnerUpdateURL : string = environment.api + "api/partners";
  private orderTotalsURL : string = environment.api + "order/totals";


  
  constructor(private httpClient: HttpClient,
    protected localStorageService : LocalstorageserviceService) {

  }

  private preparePartner(user: UserRegistration, createdUserId: number) {
    let partnerRequestModel : PartnerRegistrationRequestModel = {
      email: user.email,
      phone: user.compTelephone,
      code: user.compPostcode,
      legalOrgLocationName: user.compAddress,
      tradingName: user.tradingName,
      name: user.legalName,
      legalOrgName: user.legalName,
      bulstat: user.bulstat,
      vatId: (user.vatNumber) ?  + user.vatNumber : undefined,
      vatRegistered: (user.vatNumber) ?  true : false,
      ownerId: createdUserId,
      ownerLogin: user.userName,
      legalOrgType: user.type,
      partnerLocationName: user.address,
      speedyPassword: "",
      speedyUsername: ""
    }
    return partnerRequestModel;
  }

  registerPartner(user: UserRegistration, createdUserId: number) {
    const partnerRequestModel = this.preparePartner(user, createdUserId);
    return this.httpClient.post<Partner>(this.partnerRegistrationURL, partnerRequestModel);
  }

  getPartner(userId: number) {
    const params = '?ownerId.equals=' +  userId
    return this.httpClient.get<Partner[]>(this.partnerInfoURL+params);
  }

  updatePartner(partner: Partner) {
    let partnerRequestModel: PartnerRegistrationRequestModel = {} as any;
    AutoMapper.map(partner, partnerRequestModel);
    return this.httpClient.put<Partner>(this.partnerUpdateURL, partnerRequestModel);
  }

  getTotals() {
    return this.httpClient.get<Totals>(this.orderTotalsURL).toPromise();
  }
}

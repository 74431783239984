import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Packaging } from 'src/models/DTO/V2/packaging/Packaging';
import { ProductPackaging } from 'src/models/DTO/V2/packaging/ProductPackaging';
import { CreatePackagingRequestModel } from 'src/models/requestModels/V2/packaging/CreatePackagingRequestModel';

@Injectable({
  providedIn: 'root'
})
export class PackagingService {

  private packagingURL : string = environment.api + "product/packaging";


  constructor(private httpClient: HttpClient) { }

  createPackaging(packagingRequestModel: CreatePackagingRequestModel) {
    return this.httpClient.post<Packaging>(this.packagingURL, packagingRequestModel);
  }

  updatePackaging(packagingRequestModel: CreatePackagingRequestModel) {
    const params = '/' + packagingRequestModel.uuid;
    return this.httpClient.put<Packaging>(this.packagingURL + params, packagingRequestModel);
  }

  getPackaging(productUUID: string) {
    return this.httpClient.get<ProductPackaging>(this.packagingURL + '/' + productUUID);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './layouts/registration/registration.component';
import { REGISTRATION_ROUTES } from './../infrastructure/routes/registration/routes'
import { DASHBOARD_ROUTES } from './../infrastructure/routes/dashboard/routes';
import { STRIPE_ROUTES } from './../infrastructure/routes/stripe/routes';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { AuthGuardService } from 'src/services/guards/authentication/authguard.service';
import { ResourcenotfoundComponent } from './errors/resourcenotfound/resourcenotfound.component';

const routes: Routes = [
  {
    // IMPORTANT: Change this to dashboard later
    path: '', redirectTo: '/dashboard/products', pathMatch: 'full'
  },
  { 
    path: 'registration', 
    component: RegistrationComponent, 
    data: { title: 'Registartion Views' }, 
    children: REGISTRATION_ROUTES 
  },
  { 
    path: 'dashboard', 
    component: DashboardComponent,
    data: { title: 'Dashboard Views' }, 
    children: DASHBOARD_ROUTES,
    canActivate: [AuthGuardService]
  },
  {
    path: 'error/resource/notfound', component: ResourcenotfoundComponent
  },
  ...STRIPE_ROUTES
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

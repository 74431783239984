import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductPricing } from 'src/models/DTO/V2/pricing/ProductPricing';
import { CreatePricingRequestModel } from 'src/models/requestModels/V2/pricing/CreatePricingRequestModel';
import { CreateBasePricingRequestModel } from 'src/models/requestModels/V2/pricing/CreateBasePricingRequestModel';


@Injectable({
  providedIn: 'root'
})
export class PricingService {

  private pricingUrl : string = environment.api + "product/pricing";

  private basePricingUrl : string = environment.api + "product/pricing/base";


  constructor(private httpClient: HttpClient) { }

  createBasePricing(createBasePricing: CreateBasePricingRequestModel) {
    return this.httpClient.post<any>(this.basePricingUrl, createBasePricing);
  }

  patchBasePricing(createBasePricing: CreateBasePricingRequestModel) {
    return this.httpClient.patch<any>(this.basePricingUrl, createBasePricing);
  }

  createCustomPricing(createPricing: CreatePricingRequestModel) {
    return this.httpClient.post<any>(this.pricingUrl, createPricing);
  }

  getProductPricing(productUIID: string) {
    return this.httpClient.get<ProductPricing>(this.pricingUrl + '/' + productUIID);
  }

  deleteProductPricing(productPricingUUID: string) {
    return this.httpClient.delete<any>(this.pricingUrl + '/' + productPricingUUID);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Manufacturer } from 'src/models/DTO/V2/manufacturer/Manufacturer';
import { Manufacturers } from 'src/models/DTO/V2/manufacturer/Manufacturers';
import { CreateNewManufacturerRequestModel } from 'src/models/requestModels/V2/manufacturer/CreateNewManufacturerRequestModel';


@Injectable({
  providedIn: 'root'
})
export class ManifacturerService {

  private manufacturerURL : string = environment.api + "manufacturer";

  constructor(private httpClient: HttpClient) { }

  createNewManufacturer(newManifacturer: CreateNewManufacturerRequestModel) {
    return this.httpClient.post<Manufacturer>(this.manufacturerURL, newManifacturer);
  }

  getManufacturers() {
    return this.httpClient.get<Manufacturers>(this.manufacturerURL);
  }
}

import { Routes } from "@angular/router";
import { AddproductComponent } from "src/app/dashboard/addproduct/addproduct.component";
import { AddserviceComponent } from "src/app/dashboard/addservice/addservice.component";
import { CalendarComponent } from "src/app/dashboard/calendar/calendar.component";
import { CarriersComponent } from "src/app/dashboard/carriers/carriers.component";
import { CreateStoreComponent } from "src/app/dashboard/create-store/create-store.component";
import { DeliveryComponent } from "src/app/dashboard/delivery/delivery.component";
import { EditproductComponent } from "src/app/dashboard/editproduct/editproduct.component";
import { EditserviceComponent } from "src/app/dashboard/editservice/editservice/editservice.component";
import { EditstoreComponent } from "src/app/dashboard/editstore/editstore.component";
import { InvoicesComponent } from "src/app/dashboard/invoices/invoices.component";
import { OrderComponent } from "src/app/dashboard/order/order.component";
import { OrdersComponent } from "src/app/dashboard/orders/orders.component";
import { SettingsComponent } from "src/app/dashboard/settings/settings.component";
import { StoresComponent } from "src/app/dashboard/stores/stores.component";
import { ProductsComponent } from './../../../app/dashboard/products/products.component';

export const DASHBOARD_ROUTES: Routes = [
      {
        path: '', redirectTo: 'products', pathMatch: 'full'
      },
      {  
        path: 'products', component: ProductsComponent 
      },
      {  
        path: 'products/add', component: AddproductComponent 
      },
      {  
        path: 'services/add', component: AddserviceComponent 
      },
      {  
        path: 'services/edit/:id', component: EditserviceComponent 
      },
      {  
        path: 'products/edit/:id', component: EditproductComponent 
      },
      {  
        path: 'products/add/:id', component: AddproductComponent 
      },
      {  
        path: 'stores', component: StoresComponent 
      },
      {  
        path: 'store/create', component: CreateStoreComponent 
      },
      {  
        path: 'store/edit/:id', component: EditstoreComponent 
      },
      {  
        path: 'settings', component: SettingsComponent 
      },
      {  
        path: 'delivery', component: DeliveryComponent 
      },
      {  
        path: 'invoices', component: InvoicesComponent 
      },
      {  
        path: 'logistics', component: CarriersComponent 
      },
      {
        path: 'orders/:defaultpage', component: OrdersComponent 
      },
      {
        path: 'order/:id/:currentorderpage', component: OrderComponent 
      },
      {  
        path: 'calendar/:id', component: CalendarComponent 
      },
];
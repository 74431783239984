import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DuplicateOrganisationCheckResponse } from 'src/models/requestModels/V2/checking/DuplicateOrganisationCheckResponse';
import { DuplicateOrganisationCheckRequest } from 'src/models/DTO/V2/checking/DuplicateOrganisationCheckRequest';

@Injectable({
  providedIn: 'root'
})
export class CheckerService {

  private checkURL : string = environment.api + "organisation/check-presence";

  constructor(private httpClient: HttpClient) { }

  duplicatePartnerCheck(duplicateOrganisationCheckRequest: DuplicateOrganisationCheckRequest) {
    return this.httpClient.post<DuplicateOrganisationCheckResponse>(this.checkURL, duplicateOrganisationCheckRequest);
  }
}

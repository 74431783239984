import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { Country } from 'src/models/DTO/countries/country';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  private getAllCountriesURL : string = environment.api + "api/countries";

  constructor(private httpClient: HttpClient) { }

  getCountries(ln: string = 'bg') {
    const params = RequestHelper.addMultipleParams([
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      }
    ]);
    return this.httpClient.get<Country[]>(this.getAllCountriesURL + params);
  }
}

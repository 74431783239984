import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoggedUser } from '../../../models/DTO/user/LoggedUser';


@Injectable({
  providedIn: 'root'
})
export class LoggeduserinfoService {


  constructor() { }

  private defaultUser : LoggedUser = { } as any;

  private _userInfo = new BehaviorSubject<LoggedUser>(this.defaultUser);

  public readonly userInfo$ = this._userInfo.asObservable();

  get userInfo() {
    return this._userInfo.getValue();
  }

  set userInfo(value: LoggedUser) {
    this._userInfo.next(value);
  }

  addNewUser(value: LoggedUser) {
    this.userInfo = {...value};
  } 

  removeUser() {
    this.userInfo = this.defaultUser;
  }
}

<div class="container-xxl">
    <div class="row">
        <h5>{{ this.organisation?.name_in_latin }}</h5>
        <a href="/details/b305d6b4-9017-40fe-8f7e-c7223f9b7d02" type="button" class="btn btn-outline-primary">{{ this.lables.firmDetails }}</a>
    </div>
    <div class="row">
        <div class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">  
            <table mat-table  (scroll)="onTableScroll($event)" multiTemplateDataRows  [dataSource]="productsDataSource" matSort  class="mat-elevation-z8">
                <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef > {{ this.lables.product }} </th>
                <td mat-cell *matCellDef="let element" style="width: 75px;"> <img *ngIf="this.productImages.images.length" class="product-image-field" [src]="this.getItemImageURL(element.product.uuid)" alt=""> </td>
                </ng-container>
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ this.lables.name }} </th>
                <td mat-cell *matCellDef="let element" class="product-name-field"> {{ element.product.name }} </td>
                </ng-container>
                <ng-container matColumnDef="SKU">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ this.lables.sku }} </th>
                <td mat-cell *matCellDef="let element"> {{element.SKU}} </td>
                </ng-container>
                <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef> {{ this.lables.quantity }}</th>
                <td mat-cell *matCellDef="let element"> {{element.product.total_available_quantity}} </td>
                </ng-container>
                <ng-container matColumnDef="ordered">
                    <th mat-header-cell *matHeaderCellDef> Test </th>
                </ng-container>

                <ng-container matColumnDef="meduimPrice">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ this.lables.price }} </th>
                    <td mat-cell *matCellDef="let element"> {{this.getPriceRange(element)}} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ this.lables.date }} </th>
                    <td mat-cell *matCellDef="let element"> {{this.convertDate(element)}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element" style="width: 50px; text-align: center !important;"> <button *ngIf="element.product.skus.length" [ngbTooltip]="this.lables.variations" (click)="expandedElement = expandedElement === element ? null : element" class="btn btn-outline-primary variations-btn"><span class="variations"></span></button> </td>
                </ng-container>


                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsForProductTable.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <table class="table">
                                <tbody>
                                <tr *ngFor="let variation of element.product.skus">
                                    <td style="width: 75px;"> <img *ngIf="this.productImages.images.length" class="product-image-field" [src]="this.getItemImageURL(variation.uuid)" alt=""> </td>                                                            
                                    <td>{{variation.sku}}</td>
                                    <td class="product-name-field"> <span *ngFor="let option of variation.options" style="display: block;">{{ option.option.display_name  + ': ' + option.value }}</span> </td>
                                    <td > {{variation.sku}} </td>
                                    <td > {{element.product.total_available_quantity}} </td>
                                    <td > {{this.getPriceRange(element)}} </td>
                                    <td > {{this.convertDate(element)}} </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForProductTable"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumnsForProductTable;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>
    </div>
</div>


<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).lable}}</h3>
                <div class="form-group-wrapper">
                    <div class="form-group">
                        <label for="email">{{this.lables(this.getPageName()).emailLabel}}</label>
                        <input (input)="this.emailChange($event.target.value)" type="email" class="form-control" id="email" aria-describedby="emailHelp">
                    </div>
                    <div class="terms">С потвърждаване на имейла, Вие се съгласявате с <a href="https://shareme.co/terms" target="_blank">Общите условия</a>
                        и сте се запознали с <a href="https://shareme.co/privacy" target="_blank">Политиката за лични дании.</a>
                    </div>
                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                        {{this.lables(this.getPageName()).error}}
                    </div>
                    <!-- <button *ngIf="!this.userId()" (click)="this.register()" type="button" class="btn btn-primary registerBtn">{{this.lables(this.getPageName()).registerBtn}}</button> -->
                    <button (click)="this.moveForward()" type="button" class="btn btn-primary continueBtn">{{this.lables(this.getPageName()).continueBtn}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
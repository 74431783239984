<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body">
    <div class="row">
        <div class="col-sm-12">
            <app-storeform></app-storeform>
            <div style="text-align: center;">
                <button style="margin: 5px;" class="btn btn-primary" (click)="this.save()">{{this.dashboardLables(this.getPageName()).save}}</button>
            </div>            
        </div>
    </div>
</div>
<h1 mat-dialog-title>{{ dialogs.saveChangesLabel }}</h1>
<div mat-dialog-content>
  <p class="modal-title">{{ dialogs.givePassword }}</p>
  <div>
    <div style="display: flex;">
        <input style="margin-top: 20px;" class="form-control" type="password" [placeholder]="dialogs.password" [type]="hideConfirm ? 'text': 'password'" matInput (change)="password = $event.target.value">
        <i class="material-icons" matSuffix (click) = "hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility':'visibility_off'}}</i>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-transparent margin" mat-button (click)="onNoClick()">{{ dialogs.cancel }}</button>
  <button class="btn btn-primary margin" style="float: right; width: 100px;" mat-button [mat-dialog-close]="password" cdkFocusInitial>{{ dialogs.OK }}</button>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Labels } from 'src/config/labels/labels';
import { PartnerPositions } from 'src/config/labels/partnerPositions';
import { Successes } from 'src/config/labels/successes';
import { InitialPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/InitialPartnerModelRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-emailconfirmation',
  templateUrl: './emailconfirmation.component.html',
  styleUrls: ['./emailconfirmation.component.css']
})
export class EmailconfirmationComponent extends BaseComponent implements OnInit {

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private toastr: ToastrService) {
      super(userinfostoreService, localStorageService, router)
      this.nextPage = 'registration/password/change';
      this.pageName = 'emailConfirmationPage';
     }

  ngOnInit(): void {
    let partnerType = '';
    if (this.user.shareholder && this.user.owner) {
      partnerType = PartnerPositions.positions.both;
    }
    else if (this.user.shareholder) {
      partnerType = PartnerPositions.positions.shareholder
    }
    else {
      partnerType = PartnerPositions.positions.owner
    }
    const beginRegistrationRequestModel: InitialPartnerModelRequestModel = {
      email: this.user.email,
      partner_type: partnerType
    }
    this.registrationService.initiateRegistration(beginRegistrationRequestModel).subscribe(response => {
      if (response.data.verified) {
        this.moveForward();
      }
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { TableVariation } from 'src/models/DTO/variations/tableVariation';
import { Variation } from 'src/models/DTO/variations/variation';
import { CreateVariantImageRequestModel } from 'src/models/requestModels/images/CreateVariantImageRequestModel';
import { NewProduct } from 'src/models/requestModels/product/newProductRequestModel';
import { CreateVariationRequestModel } from 'src/models/requestModels/variations/createVariationRequestModel';
import { ImagesService } from '../images/images.service';


@Injectable({
  providedIn: 'root'
})
export class VariationsService {

  private createVarationsForProductURL = environment.api + "api/products";
  private getVariationsForProductURL = environment.api + 'api/products';
  private getVariationsForMultipleProductsURL = environment.api + 'api/product-variants';
  private updateVariationsForProductURL = environment.api + 'api/products';
  private deleteVariationForProductURL = environment.api + 'api/products';


  constructor(private httpClient: HttpClient,
    private imageService: ImagesService) { }

  createVariations(variations : TableVariation[], product: NewProduct) : Promise<Variation>[] {
    let postRequestsToPromises : Promise<any>[] = [];
    variations.forEach(variation => {
      const newVariation = this.prepareVariation(variation, product.id);
      newVariation.id = undefined;
      const params = RequestHelper.addRESTParams([
        product.id.toString(), 'product-variants' 
      ])
      postRequestsToPromises.push(
        this.httpClient.post<Variation>(this.createVarationsForProductURL + params, newVariation).toPromise(),
      )
    });
    return postRequestsToPromises;
  } 

  updateVariations(variations : TableVariation[], product: NewProduct) : Promise<Variation>[] {
    let postRequestsToPromises : Promise<any>[] = [];
    variations.forEach(variation => {
      const newVariation = this.prepareVariation(variation, product.id);
      const params = RequestHelper.addRESTParams([
        product.id.toString(), 'product-variants', variation.id.toString()
      ])
      postRequestsToPromises.push(
        this.httpClient.put<Variation>(this.updateVariationsForProductURL + params, newVariation).toPromise(),
      )
    });
    return postRequestsToPromises;
  } 

  public updateImageVariations(variation: TableVariation) {
    let variantModel: CreateVariantImageRequestModel = {} as any;
    variantModel.content = variation.file.imageContent.substring(variation.file.imageContent.indexOf(',') + 1); 
    variantModel.contentContentType = variation.file.file.contentContentType;
    variantModel.productVariantId = variation.id;
    if (variation.active) {
      variantModel.id = variation.file.id;
      variantModel.code = variation.file.file.code;
      return this.imageService.updateVariantImagesForProduct(variantModel).toPromise();
    }
    else {
      variantModel.code = Core.getRandomString(24);
      variantModel.name = Core.getRandomString(24);
      return this.imageService.createVariantImagesForProduct(variantModel).toPromise();
    }
  }

  private prepareVariation(variation: TableVariation, productId: number) {
    let newVariationModel: CreateVariationRequestModel = { } as any;
    AutoMapper.map(variation, newVariationModel, 
      [
        {
          from: 'SKU',
          to: 'sku'
        },
        {
          from: 'quantity',
          to: 'qtyAvailableValue'
        }
      ],
      [
        {
          key: 'variantOfId',
          value: productId
        }
      ]);
      newVariationModel['file'] = undefined;
      return newVariationModel;
  }

  getVariation(productId: number) {
    let params = RequestHelper.addRESTParams([
      productId.toString(), 'product-variants' 
    ])
    params += RequestHelper.addMultipleParams([
      {
        key: "sku.notEquals",
        value: environment.autoCode
      }
    ])
    return this.httpClient.get<Variation[]>(this.getVariationsForProductURL + params);
  }
  
  
  getMultipleVariation(productIds: number[]) {
    const params = RequestHelper.addMultipleParams([
      {
        key: "variantOfId.in",
        value: productIds
      },
      {
        key: "sku.notEquals",
        value: environment.autoCode
      }
    ])
    return this.httpClient.get<Variation[]>(this.getVariationsForMultipleProductsURL + params);
  }

  deletePricings(variationIds: number[]) {
    return this.httpClient.request('DELETE', this.deleteVariationForProductURL, { body: { ids: variationIds } })
  }
}

<label>{{this.dashboardLables(this.getPageName()).stores}}</label>
<mat-form-field *ngIf="this.storesAsStrings.length" [formGroup]="this.infoFormGroup" class="chip-list stores">
    <mat-label></mat-label>
    <mat-chip-list #chipList aria-label="Store selection">
    <mat-chip *ngFor="let store of selectedStores" [selectable]="selectable"
            [removable]="removable" (removed)="remove(store)">
        {{store}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input [placeholder]="this.dashboardLables(this.getPageName()).newStore"
            [matChipInputFor]="chipList"
            #storeInput
            formControlName = "stores"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let store of filteredStores | async" [value]="store">
          {{store}}
        </mat-option>
      </mat-autocomplete>
</mat-form-field>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';


import { BaseComponent } from '../../base/base.component';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { WorkingtimeService } from 'src/services/requestServices/workingtime/workingtime.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { Labels } from 'src/config/labels/labels';
import { ContactsService } from 'src/services/requestServices/V2/contacts/contacts.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent extends BaseComponent implements OnInit {

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router: Router,
    private userRequestService: UsersService,
    private partnersRequestService: PartnersService,
    private locationService: LocationsService,
    private workingTimeService: WorkingtimeService,
    private userRegistrationV2: RegistrationService,
    private contactService: ContactsService) {
    super(userinfostoreService, localStorageService, router);
    this.pageName = "finish";
    this.nextPage = "dashboard/products";
  }

  ngOnInit(): void {
  }

  registerUser() {
    // Check Confirmed Account
    this.userRegistrationV2.registerPartner(this.user).subscribe(partner => {
      this.user.id = partner.data.uuid;
      this.localStorageService.setLocalStorageItem(Labels.lsTokenKey, partner.data.token);
      this.userRegistrationV2.registerOrganisation(this.user).subscribe((organization) => {
        const orginasationId = organization.data.uuid;
        this.contactService.registerPartnerContact(this.user).subscribe(() => {
          this.contactService.registerOrganisationContact(this.user, orginasationId).subscribe(() => {
            super.moveForward();
          })
        })
      })
    })
  }


  moveForward() {
    this.registerUser();
  }

}

<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body">
    <div class="row">
        <div class="col-sm-12">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" href="#return" role="tab" aria-controls="pills-profile" aria-selected="false">{{this.dashboardLables(this.getPageName()).return}}<small *ngIf="!this.loggedPartner.data?.organisations[0].product_max_return_period_in_days" class="return-req-fields red"></small></a>
                </li>
                <!-- <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" href="#payments" role="tab" aria-controls="pills-profile" aria-selected="false">{{this.dashboardLables(this.getPageName()).payments}}</a>
                </li> -->
                <li class="nav-item" role="presentation" style="flex: 1; ">
                <a class="nav-link documents"  id="pills-contact-tab" data-bs-toggle="pill" href="#documents" role="tab" aria-controls="pills-contact" aria-selected="false">{{this.dashboardLables(this.getPageName()).documents}}<small *ngIf="this.loggedPartner.data?.documents && !this.areDocumentsFilled()" class="return-req-fields red"></small></a>
                </li>
            </ul>
            <div class="tab-content pills-tabContent" id="pills-tabContent">
                
                <div class="tab-pane fade show active" id="return" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <ngb-alert *ngIf="!this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days" [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
                        <small class="alertIcon"></small> {{this.dashboardLables(this.getPageName()).deliveryAlert}}
                    </ngb-alert>
                    <div class="returnTab">
                        <form *ngIf="this.return" [formGroup]="this.return" class="form-group">

                            <h4 class="return-header">{{this.dashboardLables(this.getPageName()).returnPeriod}}<span class="return-req-fields">*</span></h4>
                            <div class="form-check form-check-inline">
                                <input  formControlName="returnPeriod" name="returnPeriod" class="return-radio" type="radio"  id="inlineRadio1" [value]="14" checked>
                                <label class="form-check-label" style="font-size: 14px; width: 108px;" for="inlineRadio1">{{this.dashboardLables(this.getPageName()).feendays}}</label>
                            </div>
                            <div  class="form-check form-check-inline">
                                <input formControlName="returnPeriod" name="returnPeriod" class="return-radio" type="radio"  id="inlineRadio2" [value]="30">
                                <label class="form-check-label" style="font-size: 14px; width: 108px;" for="inlineRadio2">{{this.dashboardLables(this.getPageName()).tyndays}}</label>
                            </div>
                            <small style="font-size: 14px; width: 60%; display: inline-flex; align-items: center; margin-left: 20px; top: -13px !important; position: relative;" id="emailHelp" class="text-muted returnInfo">{{this.dashboardLables(this.getPageName()).returnPeriodInfo}}</small>

                            <!-- <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).freeReturnPeriodLabel}}</h4>

                            <label >{{this.dashboardLables(this.getPageName()).freeReturnPeriod}}</label>
                            <select style="width: 30%; display: inline-block;" formControlName="freeReturnPeriod" id="resInterval" class="form-control">
                                <option *ngFor="let returnPeriod of this.returnPeriods; index as i" [value]="returnPeriod.value" [selected] = "i == 0">{{returnPeriod.period}}</option>
                            </select>
                            <div style="width: 60%; display: inline-flex; position: relative; top: -10px;" id="emailHelp" class="text-muted">{{this.dashboardLables(this.getPageName()).freeReturnPeriodInfo}}</div> -->
                        </form>
                        <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                            {{this.errorMessage}}
                        </div>
                        <div style="margin-left: 50px;">
                            <button style="margin: 5px; width: 148px; height: 46px;" [disabled]="this.return?.get('returnPeriod').dirty ? false : true" class="btn btn-primary" (click)="this.update(this.return)">{{this.dashboardLables(this.getPageName()).upload}}</button>
                        </div>
                    </div>
                    <div class="accordion" id="accordionExample" style="margin-top: 30px; margin-bottom: 20px; box-shadow: 0px 4px 20px rgb(153 163 191 / 10%);">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne" style="border-radius: 10px;">
                            <button style="border-radius: 10px; font-weight: 600;" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"  aria-controls="collapseOne">
                                {{this.dashboardLables(this.getPageName()).returnInstructions.header}}
                            </button>
                          </h2>
                          <div style="border-radius: 10px;" id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p>{{this.dashboardLables(this.getPageName()).returnInstructions.listHeader}}</p>
                                <br>
                                <p style="font-weight: 600;">{{this.dashboardLables(this.getPageName()).returnInstructions.p}}</p>
                                <ol>
                                    <li>{{this.dashboardLables(this.getPageName()).returnInstructions.li1}}</li>
                                    <li>{{this.dashboardLables(this.getPageName()).returnInstructions.li2}}</li>
                                    <li>{{this.dashboardLables(this.getPageName()).returnInstructions.li3}}
                                        <ul style="list-style-type:disc;">
                                            <li>{{this.dashboardLables(this.getPageName()).returnInstructions.lili1}}</li>
                                            <li>{{this.dashboardLables(this.getPageName()).returnInstructions.lili2}}</li>
                                        </ul>
                                    </li>
                                  </ol>
                                </div>
                          </div>
                        </div>
                      </div>

                    <div style="background-color: white; border-radius: 10px; margin-top: 10px; padding: 20px; box-shadow: 0px 4px 20px rgb(153 163 191 / 10%);">
                        <h4 class="return-header">Адрес за връщане на продукти<span class="return-req-fields">*</span></h4>
                        <form *ngIf="this.returmAddress" [formGroup]="this.returmAddress">
                            <div class="returnAddress" style="width: 100%;">
                                <div class="row">
                                    <div class="col-6">
                                        <label for="name">Област:<span class="return-req-fields">*</span></label>
                                        <input formControlName="state" type="text" [class]="(this.returmAddress?.get('state').invalid && this.returmAddress?.get('state').touched) ? 'form-control is-invalid' : 'form-control'"id="name" placeholder="Въведи">
                                    </div>
                                    <div class="col-6">
                                        <label for="name">Квартал:</label>
                                        <input formControlName="residence" type="text" class="form-control" id="name" placeholder="Въведи">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label for="name">гр./с.:<span class="return-req-fields">*</span></label>
                                        <input formControlName="city" type="text" [class]="(this.returmAddress?.get('city').invalid && this.returmAddress?.get('city').touched) ? 'form-control is-invalid' : 'form-control'" id="name" placeholder="Въведи">
                                    </div>
                                    <div class="col-6">
                                        <label for="name">Телефон за контакт:<span class="return-req-fields">*</span></label>
                                        <input formControlName="telephone" type="text"  [class]="(this.returmAddress?.get('telephone').invalid && this.returmAddress?.get('telephone').touched) ? 'form-control is-invalid' : 'form-control'" id="name" placeholder="Въведи">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <label for="name">ул./бул.:<span class="return-req-fields">*</span></label>
                                        <input formControlName="street" type="text" [class]="(this.returmAddress?.get('street').invalid && this.returmAddress?.get('street').touched) ? 'form-control is-invalid' : 'form-control'" id="name" placeholder="Въведи">
                                    </div>
                                    <div class="col-6">
                                        <label for="name">Пояснение:</label>
                                        <input formControlName="details" type="text" class="form-control" id="name" placeholder="Въведи">
                                    </div>
                                </div>
                                <div class="row" style="margin-bottom: 10px;">
                                    <div class="col-6">
                                        <label for="name">№:<span class="return-req-fields">*</span></label>
                                        <input formControlName="number" type="number" [class]="(this.returmAddress?.get('number').invalid && this.returmAddress?.get('number').touched) ? 'form-control is-invalid' : 'form-control'" id="name" placeholder="Въведи">
                                    </div>
                                </div>
                                <small style="margin-top: 20px; font-size: 14px; width: 60%; display: inline-flex; align-items: center; margin-left: 20px; top: -13px !important; position: relative;" id="emailHelp" class="text-muted returnInfo">Всички продукти, които клиентът иска да върне, ще бъдат изпращани до този адрес.</small>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                        {{this.errorMessage}}
                                    </div>
                                    <div style="text-align: center;">
                                        <button style="margin: 15px; width: 148px; height: 46px;" [disabled]="!this.returmAddress?.dirty" class="btn btn-primary" (click)="this.saveReturnAddress()">{{this.dashboardLables(this.getPageName()).upload}}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <!-- <div class="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div *ngIf="!this.stripeStatus" class="payments-intro">
                        <div class="alert alert-warning" role="alert">
                            {{this.dashboardLables(this.getPageName()).stripeCardInfo}}
                        </div>
                        <img src="/assets/images/dashboard/payments/stripe.svg" alt="ShareMe company logo">
                        <div style="text-align: center;">
                            <button style="margin: 5px;" class="btn btn-primary return-save-btn" (click)="this.toStripe()">{{this.dashboardLables(this.getPageName()).toStripe}}</button>
                        </div>
                    </div>
                    <div *ngIf="this.stripeStatus?.data.length && this.stripeStatus?.data[0].state == this.stripeLabels.statuses.pending.status" class="payments-onboarding">
                        <div class="alert alert-warning" role="alert">
                            {{this.dashboardLables(this.getPageName()).stripeCardInfo}}
                        </div>
                        <img style="margin: 5px;" src="/assets/images/dashboard/payments/stripe.svg" alt="ShareMe company logo">
                        <div style="text-align: center;">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{{this.stripeLabels.accountId}}</th>
                                        <th>{{this.stripeLabels.status}}</th>
                                        <th>{{this.stripeLabels.reason}}</th>
                                        <th>{{this.stripeLabels.portal}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let stripe of this.stripeStatus?.data">
                                        <td><h6>{{stripe.provider_account_id}}</h6></td>
                                        <td><h6>{{this.stripeLabels.statuses[stripe.state].label}}</h6></td>
                                        <td><h6>{{stripe.provider_account_last_error_message}}</h6></td>
                                        <td>
                                            <a style="margin: 5px;" class="btn btn-primary" [href]="this.stripeLinkInfo?.data.redirect_url">{{this.dashboardLables(this.getPageName()).stripeActivate}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="margin: 3px; padding: 3px; text-align: center; color: #75809C">
                                {{this.dashboardLables(this.getPageName()).stripeHelp}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.stripeStatus?.data.length && this.stripeStatus?.data[0].state != this.stripeLabels.statuses.pending.status" class="payments-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>{{this.stripeLabels.accountId}}</th>
                                    <th>{{this.stripeLabels.status}}</th>
                                    <th>{{this.stripeLabels.reason}}</th>
                                    <th>{{this.stripeLabels.portal}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let stripe of this.stripeStatus?.data">
                                    <td><h6>{{stripe.provider_account_id}}</h6></td>
                                    <td><h6>{{this.stripeLabels[stripe.state].label}}</h6></td>
                                    <td><h6>{{stripe.provider_account_last_error_message}}</h6></td>
                                    <td>
                                        <a style="margin: 5px;" class="btn btn-primary" [href]="this.stripeLinkInfo?.data.redirect_url">{{this.dashboardLables(this.getPageName()).stripePortal}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin: 3px; padding: 3px; text-align: center; color: #75809C">
                            {{this.dashboardLables(this.getPageName()).stripeHelp}}
                        </div>
                    </div>
                </div> -->


                <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <ngb-alert *ngIf="this.areDocumentsInvalid" [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
                        <small class="alertIcon"></small>  {{this.dashboardLables(this.getPageName()).documentsAlert}} 
                    </ngb-alert>
                    <table multiTemplateDataRows *ngIf="this.contracts.data.length" mat-table [dataSource]="contractsTableDataSource" matSort  class="mat-elevation-z8">
        
                        <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef style="width: 55px;">#</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex">{{ i + 1 }}</td>
                        </ng-container>
                        
                        <ng-container matColumnDef="document">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).document}}</th>
                            <td mat-cell *matCellDef="let element" class="document-field"> {{this.documentEnumName(element.code)}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="file">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).file}}</th>
                            <td mat-cell *matCellDef="let element"><button class="btn btn-link file-field" (click)="this.open(element.download.uuid)">PDF</button></td>
                        </ng-container>
        
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).date}}</th>
                            <td mat-cell *matCellDef="let element" class="calendar-icon"> {{ this.convertDate(element.download?.updated_at) }} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).status}}</th>
                            <td mat-cell *matCellDef="let element"><span [class]="(element.download?.is_accepted) ? 'confirmed' : 'unconfirmed'" >{{(element.download?.is_accepted) ? this.dashboardLables(this.getPageName()).confirmed : this.dashboardLables(this.getPageName()).notconfirmed}}</span></td>
                        </ng-container>
        
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width: 140px !important;">{{this.dashboardLables(this.getPageName()).action}}</th>
                            <td mat-cell *matCellDef="let element"><button *ngIf="!element.download?.is_accepted" class="btn btn-outline-success btn-sm" [disabled]="element.documentConfirmed">{{this.dashboardLables(this.getPageName()).sign}}</button></td>
                        </ng-container>
                    
                         <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumnsForContractsTable.length" style="padding-left: 15px; padding-top: 8px; padding-bottom: 8px;">
                                <div class="example-element-detail"
                                    [@detailExpand]="element == expandedElement  ? 'expanded' : 'collapsed'">
                                    <div class="form-check" style="padding-left: 1.8em;">
                                        <input (click)="this.confirmTermsAndConditionsAccepted(expandedElement);" name="flexCheckDefault"  class="form-check-input" type="checkbox" [class]="(expandedElement?.termsAndConditionsAcceptedIsValid) ? 'form-check-input' : 'form-check-input is-invalid'" [checked]="expandedElement?.termsAndConditionsAccepted" id="flexCheckDefault">
                                        <label  class="form-check-label expanded-detail-label" for="flexCheckDefault">
                                            {{this.dashboardLables(this.getPageName()).terms}}
                                        </label>
                                    </div>
                                    <div class="form-check" style="padding-left: 1.8em;">
                                        <input (click)="this.confirmOfficialRepresentative(expandedElement);" name="flexCheckChecked"  [class]="(expandedElement?.officialRepresentativeConfirmedIsValid) ? 'form-check-input' : 'form-check-input is-invalid'" type="checkbox" [checked]="expandedElement?.officialRepresentativeConfirmed" id="flexCheckChecked" >
                                        <label class="form-check-label expanded-detail-label" for="flexCheckChecked">
                                            {{this.dashboardLables(this.getPageName()).declaration}} <span style="color: #000; font-weight: bold;">"{{this.loggedPartner.data.organisations[0].native_name}}" {{this.loggedPartner.data.organisations[0].legal_form.label}}</span>
                                        </label>
                                    </div>
                                    <button style="margin: 5px; width: 148px; height: 46px;" class="btn btn-success btn-sm confirm-button" (click)="this.updateContract(expandedElement)">{{this.dashboardLables(this.getPageName()).save}}</button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsForContractsTable"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsForContractsTable;"
                            class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element  || element.download?.is_accepted ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                        {{this.errorMessage}}
                    </div>
                    <div class="docked-text">{{this.dashboardLables(this.getPageName()).documentFooter}}</div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #returns let-modal>
    <div style="text-align: center; padding: 20px;">
        <div class="modal-body" style="text-align: center; font-weight: 700;">
            {{this.dashboardLables(this.getPageName()).modalBody}}
        </div>
        <button style="width: 150px;" type="button" class="btn btn-primary" (click)="modal.close()">{{this.sharedLabels.close}}</button>
    </div>
</ng-template>
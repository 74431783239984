
<div class="container" style="height: 100%; overflow-y: scroll;">
    <div class="row" style="height: 100%;">
        <div class="col-sm-7 column registration-panel" style="height: 100vh;">     
            <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
        </div>
        <div class="col-sm-5 column" style="background-color: #0A69EB; position: fixed; right: 0px; height: 100vh; overflow-y: scroll;">
            <!-- <div class="languages">
                <button type="button" (click)="this.changeLang('en')" class="btn"><div class="flag-icon-us"></div></button>
                <button type="button" (click)="this.changeLang('bg')" class="btn"><div class="flag-icon-bg"></div></button>      
            </div> -->
            <div class="registration-btn">
                <a *ngIf="!this.changeLoginToRegistrationBtn" style="margin-top: 3px; float: right;" href="/registration/login" class="btn btn-primary">{{this.lables(this.getPageName()).logIn}}</a>
                <a *ngIf="this.changeLoginToRegistrationBtn" style="margin-top: 3px; float: right;" href="/registration/position" class="btn btn-primary">{{this.lables(this.getPageName()).signUp}}</a>
            </div>
            <div class="comp-info">
                <div class="logo">
                    <img src="/assets/images/share.svg" alt="QPay company logo">
                    <img src="/assets/images/me.svg" style="margin: 8px 0px 0px 3px;" alt="QPay company logo">
                </div>
                <span class="feature">{{this.lables(this.getPageName()).moto}}</span>
                <div class="features">
                    <div>
                        <img class="check" src="/assets/images/check.svg" alt="QPay company logo"> <span class="feature">{{' ' + this.lables(this.getPageName()).lineOne}}</span>
                    </div>
                    <div>
                        <img class="check" src="/assets/images/check.svg" alt="QPay company logo"> <span class="feature">{{' ' + this.lables(this.getPageName()).lineTwo}}</span>  
                    </div>
                    <div>
                        <img class="check" src="/assets/images/check.svg" alt="QPay company logo"> <span class="feature">{{' ' + this.lables(this.getPageName()).lineThree}}</span>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

export const CompanyTypes = {
    compTypes: {
        bg: [
            { type: "АД", value: "AD"},
            { type: "ЕАД", value: "EAD" },
            { type: "ЕООД", value: "EOOD" },
            { type: "ООД", value: "OOD" },
            { type: "ЕТ", value: "ET" },
            { type: "КДА", value: "KDA" },
            { type: "КД", value: "KD" },
            { type: "СД", value: "SD" }
        ],
        en: [
            { type: "AD", value: "AD"},
            { type: "EAD", value: "EAD" },
            { type: "EOOD", value: "EOOD" },
            { type: "OOD", value: "OOD" },
            { type: "ET", value: "ET" },
            { type: "KDA", value: "KDA" },
            { type: "KD", value: "KD" },
            { type: "SD", value: "SD" }
        ],
    }
}

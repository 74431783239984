import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Labels } from 'src/config/labels/labels';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private localStorageService: LocalstorageserviceService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let request = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.getLocalStorageItem(Labels.lsTokenKey)}`
      }
    });
    
    return next.handle(request);
  }
}
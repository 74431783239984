import { Injectable } from '@angular/core';
import { AttachDetachTagRequestModel } from 'src/models/requestModels/V2/tag/AttachDetachTagRequestModel'
import { Tags } from 'src/models/DTO/V2/tag/Tags'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private tagURL : string = environment.api + "tag";


  constructor(private httpClient: HttpClient) { }

  createTags(tagsRequestModel: AttachDetachTagRequestModel) {
    const params = '/attach'
    return this.httpClient.post<any>(this.tagURL + params, tagsRequestModel);
  }

  detachTags(tagsRequestModel: AttachDetachTagRequestModel) {
    const params = '/detach'
    return this.httpClient.post<any>(this.tagURL + params, tagsRequestModel);
  }

  getTags(productUUID: string) {
    const params = '/of/product/' + productUUID
    return this.httpClient.get<Tags>(this.tagURL + params);
  }
}


<div class="container">
    <div class="row">
        <div *ngIf="!this.hideMenu" class=" column fixed-top" style="display: flex; flex-direction: column; padding: inherit; box-shadow: 0px 4px 20px rgba(117, 128, 156, 0.1); width: 270px; height: 100vh;">
            <div class="company" style="text-align: center; text-align: center; border-bottom: 1px solid #E5E5E5; width: 230px; margin: 20px; padding: 20px;">
                <img style="width: 80px; height: 80px; cursor: pointer; border-radius: 100px;" (click)="this.goToSettings()" [src]="this.loggedPartner?.data?.images?.data[0]?.original?.url ? this.loggedPartner?.data?.images?.data[0]?.original?.url : '/assets/images/dashboard/icons/avatar.png'" alt="Logo">
            </div> 
            <!-- <div class="user">
                <span *ngIf="this.loggedUser" style="margin: 5px;">Здравей, {{this.loggedUser.firstName + " " + this.loggedUser.lastName}}</span>
            </div>  -->
            <div class="list-group navigation" style="stroke: rgba(117, 128, 156, 0.1);">
                <a type="button" (click)="this.onMenuClick($event, 'orders');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.disabled"><span class="orders">{{this.dashboardLables(this.getPageName()).orders}}</span></a>
                <a type="button" (click)="this.onMenuClick($event, 'products');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.disabled"><span class="products">{{this.dashboardLables(this.getPageName()).products}}</span></a>
                <a type="button" (click)="this.onMenuClick($event, 'delivery');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.normal"><span class="delivery">{{this.dashboardLables(this.getPageName()).tools}} <small *ngIf="this.areReturnFieldsNotRight()" class="return-req-fields red"></small></span></a>
                <a type="button" (click)="this.onMenuClick($event, 'logistics');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.disabled"><span class="logistics">{{this.dashboardLables(this.getPageName()).logistics}}<small *ngIf="!this.areReturnFieldsNotRight() && !this.loggedPartner?.data?.carrier?.data[0]" class="return-req-fields red"></small></span></a>
                <a type="button" (click)="this.onMenuClick($event, 'invoices');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.disabled"><span class="invoices">{{this.dashboardLables(this.getPageName()).invoices}}</span></a>
                <a type="button" (click)="this.onMenuClick($event, 'settings');" [class]="((this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days && this.loggedPartner?.data?.documents && this.areDocumentsFilled())) ? this.linkClasses.normal : this.linkClasses.disabled"><span class="settings">{{this.dashboardLables(this.getPageName()).settings}}</span></a>
                <a type="button" (click)="this.logout();" class="list-group-item list-group-item-action"><span class="logout">{{this.dashboardLables(this.getPageName()).logout}}</span></a>
              </div>  
              <div class="logo">
                <img src="/assets/images/dashboard/navigation/shareme.svg" alt="ShareMe company logo">
            </div>   
        </div>
        <div [class]="this.hideMenu ? 'col-sm-12 align-self-center column wholescreen' : 'col-sm-10 column left'" >
           <router-outlet></router-outlet>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Errors } from 'src/config/labels/errors'
import { Labels } from 'src/config/labels/labels';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';

@Component({
  selector: 'app-resourcenotfound',
  templateUrl: './resourcenotfound.component.html',
  styleUrls: ['./resourcenotfound.component.css']
})
export class ResourcenotfoundComponent implements OnInit {

  public labels;

  constructor(
    private localstorageserviceService: LocalstorageserviceService,
    private router: Router
    ) { 
    const language = this.localstorageserviceService.getLocalStorageItem(Labels.lsKey);
    this.labels = Errors.errrorPages.resourceNotFound[language];
   }

  ngOnInit(): void {
  }

  goToHomePage() {
    this.router.navigate(['dashboard/products'])
  }
}

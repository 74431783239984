import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';

@Injectable({
  providedIn: 'root'
})
export class LoggedinfoService {

  constructor() { }

  private defaultUser : PartnerInfo = { } as any;

  private _userInfo = new BehaviorSubject<PartnerInfo>(this.defaultUser);

  public readonly userInfo$ = this._userInfo.asObservable();

  get userInfo() {
    return this._userInfo.getValue();
  }

  set userInfo(value: PartnerInfo) {
    this._userInfo.next(value);
  }

  addNewUser(value: PartnerInfo) {
    this.userInfo = {...value};
  } 

  removeUser() {
    this.userInfo = this.defaultUser;
  }
}

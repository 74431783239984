import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';
import { ValidationHelper } from '../../../infrastructure/validationHelpers/validationHelper';
import { TelPrefixes } from '../../../config/labels/telPrefixes';
import { Convertor } from '../../../infrastructure/convertorHelpers/convertor';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { PhoneNumberUtil } from 'google-libphonenumber';



@Component({
  selector: 'app-personaldetails',
  templateUrl: './personaldetails.component.html',
  styleUrls: ['./personaldetails.component.css']
})
export class PersonaldetailsComponent extends BaseComponent implements OnInit {

  public personalDetails : FormGroup
  private errorMsg: string;
  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private fb: FormBuilder,
    protected router: Router) {
      super(userinfostoreService, localStorageService, router);
      this.backPage = 'registration/password/change';
      this.nextPage = 'registration/address/details';
      this.pageName = 'personalDetails';
      this.personalDetails = fb.group({
        'FN': ['', [Validators.required, Validators.maxLength(255), Validators.pattern(ValidationConfig.regexes.cyrylic)]],
        'LN': ['', [Validators.required, Validators.maxLength(255), Validators.pattern(ValidationConfig.regexes.cyrylic)]],
        'date': ['', [Validators.required]],
        'tel': ['', [Validators.required, Validators.maxLength(10), this.validateTell.bind(this)]],
        'TelPrefix': [TelPrefixes.prefixes[0]],
      })

     }

  ngOnInit(): void {
  }

  telPrefixes() {
    return TelPrefixes.prefixes;
  }

  arePersonalDetailsValid() {
    let fieldName = ValidationHelper.formGroup.validate(this.personalDetails, this.lables(this.pageName));
    if (!this.validateFullTel()) {
      fieldName = this.lables(this.pageName).tel;
    } 
    if (this.checkForFullYear() < 18) {
      fieldName = this.lables(this.pageName).date;
    }
    if (fieldName != '') {
      this.error = true;
      this.errorMsg = fieldName + this.lables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  validateTell (control: AbstractControl): {[key : string]: boolean} | null {
    const value = control.value.toString();
    const phoneUtils = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtils.parse(value, this.personalDetails.get('TelPrefix').value.country);
      if (phoneUtils.isValidNumberForRegion(number, this.personalDetails.get('TelPrefix').value.country)) {
        return null;
      }
      return {'validateTell': false};
    }
    catch(error) {
      return {'validateTell': false};
    }
  };

  checkForFullYear() {
    let ageDifMs = Date.now() - (new Date(this.personalDetails.get('date').value) as any);
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getSelectedPrefix() {
    return this.personalDetails.get('TelPrefix').value.prefix;
  }

  getFullTel() {
    return this.getSelectedPrefix() + this.personalDetails.get('tel').value;
  }

  validateFullTel() {
    const tel = this.getFullTel();
    const country = this.personalDetails.get('TelPrefix').value.country;
    return ValidationHelper.telephone.validate(tel, country);  
  }

  errorMessage() {
    return this.errorMsg;
  }

  moveForward() {
    if (!this.arePersonalDetailsValid()) {
      return;
    }
    var delimiter = "/";
    this.user.birthday = Convertor.convertDate.convertDateWithDelimiter(this.personalDetails.get('date').value, delimiter);
    const phoneUtils = PhoneNumberUtil.getInstance();
    const number = phoneUtils.parse(this.personalDetails.get('tel').value, this.personalDetails.get('TelPrefix').value.country);
    this.user.telephone = number.getNationalNumber().toString();
    this.user.firstName = this.personalDetails.get('FN').value;
    this.user.lastName = this.personalDetails.get('LN').value;
    super.moveForward();
  }
}

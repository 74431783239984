import { Component, OnInit } from '@angular/core';
import { UserinfostoreService } from '../../../services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { EmailConfirmationService } from '../../../services/requestServices/emailConfirmationService/email-confirmation.service';
import { BaseComponent } from '../../base/base.component';
import { Router } from '@angular/router';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { Labels } from 'src/config/labels/labels';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-emailpage',
  templateUrl: './emailpage.component.html',
  styleUrls: ['./emailpage.component.css']
})
export class EmailpageComponent extends BaseComponent implements OnInit {

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private emailConfiramtionService : EmailConfirmationService,
    private userLoginService: UsersService,
    protected router: Router,
    private toast: ToastrService) {
      super(userinfostoreService, localStorageService, router);
      this.nextPage = 'registration/email/confirmation';
      this.backPage = 'registration/position';
      this.pageName = 'emailPage';
     }

  ngOnInit(): void {
  }

  emailChange(value) {
    this.user.email = value;
    this.user.password = value;
    this.userinfostoreService.addNewUser(this.user);
  }

  register() {
    if (this.user.email && 
      ValidationHelper.email.validate(this.user.email)) {
        this.emailConfiramtionService.confirmEmail(this.user.email).subscribe(createdUserInfo => {
          this.toast.success(this.lables(this.getPageName()).successfulEmailSending);
          this.user.id = createdUserInfo.id;
        })
    }
    else {
      this.error = true;
    }
  }

  userId() {
    return this.user.id;
  }

  moveForward() {
    if (this.user.email && 
      ValidationHelper.email.validate(this.user.email)) {
        super.moveForward();
    }
    else {
      this.error = true;
    }
  }
}

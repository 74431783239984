import { Component, OnInit } from '@angular/core';
import { UserinfostoreService } from '../services/stateServices/userinfoservice/userregistrationstateservice.service';
import { UserRegistration } from '../models/DTO/user/UserRegistrationModel';
import { LocalstorageserviceService } from '../services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Labels } from './../config/labels/labels';
import { Router } from '@angular/router';
import { BaseComponent } from './base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor() {}

  ngOnInit(): void {
  }

  title = 'ShareMe';
}

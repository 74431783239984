export const ReturnPeriods = {
    periods: {
        bg: [
            { period: "Същия ден", value: "SAME_DAY"},
            { period: "Един ден", value: "DAYS_1"},
            { period: "Три дена", value: "DAYS_3"},
            { period: "Пет дена", value: "DAYS_5"},
            { period: "Осем дена", value: "DAYS_8"},
            { period: "12 дена", value: "DAYS_12"},
            { period: "Без", value: "NO_FREE_RETURN "}

            //{ condition: "Използвано", value: "USED" },
        ],
        en: [
            { condition: "New", value: "NEW"},
            //{ condition: "Used", value: "USED" },
        ],
    }
}

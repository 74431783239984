export const PackagingCorurierTypes = {
    options: {
        bg: [
            {
                name: 'Стандарт 24',
                value: 505
            },
            {
                name: 'Палет',
                value: 413
            },
            {
                name: 'Гуми',
                value: 704
            }
        ]
    }
}
export const Genders = {
    genders: {
        bg: [
            { gender: "Мъжки", value: "MALE"},
            { gender: "Женски", value: "FEMALE"},
            { gender: "Унисекс", value: "UNISEX"}
        ],
        en: [
            { gender: "Male", value: "MALE"},
            { gender: "Female", value: "FEMALE"},
            { gender: "Unisex", value: "UNISEX"}
        ]
    }
}
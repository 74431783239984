<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body" style="box-shadow: 20p 20p 20px rgba(117, 128, 156, 0.1);">
    <div class="row">
        <div class="offset-sm-1 col-sm-10 column">
            <ul class="nav nav-tabs" id="myTab" role="tablist" style="font-size: 19px; height: 60px; z-index: 100000; margin-bottom: 30px; background-color: white; width: 100%; position: fixed; left: 0px; top: 0px;">
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <img src="/assets/images/dashboard/navigation/shareme.svg" alt="ShareMe company logo">
                </li>   
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <a [class]="(this.completedTabs.info.passed) ? 'nav-link  completed' : 'nav-link active'" (click)="this.handleTabRedirect($event, this.completedTabs.info, this)"  aria-selected="true"  role="tab" href="#info" aria-controls="info"><span class="info">{{this.dashboardLables(this.getPageName()).infoLabel}}</span></a>
                </li>
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <a [class]="(this.completedTabs.specs.passed) ? 'nav-link  completed' : 'nav-link '"  (click)="this.handleTabRedirect($event, this.completedTabs.specs, this)" aria-selected="false"  role="tab" href="#specs" aria-controls="specs"><span class="specs">{{this.dashboardLables(this.getPageName()).specs}}</span></a>
                </li>
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item" *ngIf="this.variations.length">
                    <a [class]="(this.completedTabs.variations.passed) ? 'nav-link  completed' : 'nav-link '"  (click)="this.handleTabRedirect($event, this.completedTabs.variations, this)" aria-selected="false"  role="tab" href="#variations" aria-controls="specs"><span class="variations">{{this.dashboardLables(this.getPageName()).variations}}</span></a>
                </li>
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <a [class]="(this.completedTabs.price.passed) ? 'nav-link  completed' : 'nav-link '"  (click)="this.handleTabRedirect($event, this.completedTabs.price, this)"  aria-selected="false"  role="tab" href="#price" aria-controls="price"><span class="price">{{this.dashboardLables(this.getPageName()).price}}</span></a>
                </li>
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <a [class]="(this.completedTabs.delivery.passed) ? 'nav-link  completed' : 'nav-link '" (click)="this.handleTabRedirect($event, this.completedTabs.delivery, this)"  aria-selected="false"  role="tab" href="#ship" aria-controls="ship"><span class="ship">{{this.dashboardLables(this.getPageName()).ship}}</span></a>
                </li>
                <li [style]="this.variations.length ? 'width: 14.2%' : 'width: 16.6%'" class="nav-item">
                    <a class="nav-link" style="cursor: pointer; background-color: #F3F6FB; border-radius: 5px; height: 37px; width: fit-content; margin: auto; margin-top: 5px;" (click)="this.handleCancelRedirect()" aria-selected="false"  role="tab"  aria-controls="ship"><span class="cancel">{{this.dashboardLables(this.getPageName()).cancel}}</span></a>
                </li>
            </ul>
            <mat-progress-bar color="primary" style="z-index: 100000; position: fixed; top: 60px; left: 0px; width: 100vw;;" mode="determinate" [value]="this.value"></mat-progress-bar>

            <div class="tab-content pInfo" id="myTabContent" style="margin-bottom: 150px; background-color: white; position: relative; top: 100px; padding: 30px; box-shadow: 0px 4px 20px rgba(153, 163, 191, 0.1); border-radius: 12px;">

                <!-- ----------------------
                    Product info
                ---------------------- -->
    
                <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row ">
                            <div class="col-sm-12" style="text-align: center;">
                                <h4 style="color: #0A69EB; font-size: 16px">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2 style="font-size: 32px;">{{this.dashboardLables(this.getPageName()).infoLabel}}</h2>
                            </div>
                        </div>
                        <div class="row justify-content-center" style="margin-top: 30px;">
                            <div class="col-lg-6">
                                <div [formGroup]="this.productInfo" class="form-group info-form">
                                    <label for="category">{{this.dashboardLables(this.getPageName()).category}}:</label><span class="req-fields">*</span><br>
                                    <select (change)="this.categoryChange($event)"  formControlName="category"  id="category"  [class]="(this.productInfo.get('category').invalid && this.productInfo.get('category').touched) ? 'form-control is-invalid' : 'form-control'">
                                        <option value="" selected>{{this.dashboardLables(this.getPageName()).placeholders.choose}}</option>
                                        <option *ngFor="let category of this.categories; index as i" [value]="category.uuid" >{{category.name}}</option>
                                    </select> 

                                    <br><label for="subcategory">{{this.dashboardLables(this.getPageName()).subcategory}}:</label><span class="req-fields">*</span><br>
                                    <select (change)="this.subCategoryChange($event)" formControlName="subcategory" id="subcategory"  [class]="(this.productInfo.get('subcategory').invalid && this.productInfo.get('subcategory').touched) ? 'form-control is-invalid' : 'form-control'">
                                        <option value="" selected>{{this.dashboardLables(this.getPageName()).placeholders.choose}}</option>
                                        <option *ngFor="let subcategory of this.subCategories" [value]="subcategory.uuid">{{subcategory.name}}</option>
                                    </select>

                                    <br *ngIf="this.subSubCategories.length"><span *ngIf="this.subSubCategories.length"><label for="subcategory">{{this.dashboardLables(this.getPageName()).subSubCategory}}</label><span class="req-fields">*</span><br></span>
                                    <select (change)="this.subSubCategoryChange($event)" *ngIf="this.subSubCategories.length" style="width: 100%; display: inline-block;"  formControlName="subSubCategory" id="brand"  [class]="(this.productInfo.get('subSubCategory').invalid && this.productInfo.get('subSubCategory').touched) ? 'form-control is-invalid' : 'form-control'">
                                        <option value="" selected>{{this.dashboardLables(this.getPageName()).placeholders.choose}}</option>
                                        <option *ngFor="let subSubCategory of this.subSubCategories" [value]="subSubCategory.uuid">{{subSubCategory.name}}</option>
                                    </select>

                                    <br><label for="name">{{this.dashboardLables(this.getPageName()).name}}:</label><span class="req-fields">*</span><br>
                                    <input formControlName="name" type="text" [class]="(this.productInfo.get('name').invalid && this.productInfo.get('name').touched) ? 'form-control is-invalid' : 'form-control'" id="name" [placeholder]="this.dashboardLables(this.getPageName()).placeholders.name">

                                    <br><label style="display: inline-block;" for="brand">{{this.dashboardLables(this.getPageName()).brand}}:</label><span class="req-fields">*</span><br>
                                    <select [style]="(this.productInfo.get('brand').touched) ? 'width: 100%; display: inline-block; color: #495057 ;' : 'width: 100%; display: inline-block; color: #6C757D;'" (change)="this.changeBrand($event)" formControlName="brand" id="brand"  [class]="(this.productInfo.get('brand').invalid && this.productInfo.get('brand').touched) ? 'form-control is-invalid' : 'form-control'">
                                        <option value="" disabled selected hidden>{{this.dashboardLables(this.getPageName()).placeholders.choose}}</option>
                                        <option s *ngFor="let brand of this.brands" [value]="brand.uuid">{{brand.name}}</option>
                                    </select>

                                    <input style="display: inline-block; width: 40%; margin-left: 10px;" *ngIf="this.productInfo.get('brand').value == this.getCustomBrandTag()" formControlName="customBrand" type="text" class="form-control" id="manufacturer" [placeholder]="this.dashboardLables(this.getPageName()).customBrandPlaceholder">


                                    <br><label for="manufacturer">{{this.dashboardLables(this.getPageName()).manufacturer}}:</label>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).manufacturerInfo"></small><br>
                                    <input formControlName="manufacturer" type="text" id="manufacturer" [class]="(this.productInfo.get('manufacturer').invalid && this.productInfo.get('manufacturer').touched) ? 'form-control is-invalid' : 'form-control'" [placeholder]="this.dashboardLables(this.getPageName()).placeholders.manufacturer">


                                    <br><label for="condition">{{this.dashboardLables(this.getPageName()).condition}}:</label>&nbsp;<small id="emailHelp" class="text-muted"  data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).conditionInfo"></small><br>
                                    <select formControlName="condition" id="condition" class="form-control">
                                        <option *ngFor="let condition of this.condtions;" [value]="condition.value">{{condition.condition}}</option>
                                    </select>

                                    <!-- <br><label for="quantity">{{this.dashboardLables(this.getPageName()).quantity}}</label><br>
                                    <input formControlName="quantity" type="number" class="form-control" id="quantity" [placeholder]="this.dashboardLables(this.getPageName()).quantity"> -->

                                    <br><label for="barcode">{{this.dashboardLables(this.getPageName()).barcode}}:</label>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).barcodeInfo"></small><br>
                                    <input formControlName="barcode" type="number" [class]="(this.productInfo.get('barcode').invalid && this.productInfo.get('barcode').touched) ? 'form-control is-invalid' : 'form-control'" id="barcode" [placeholder]="this.dashboardLables(this.getPageName()).placeholders.ean">

                                    <br><label for="sku">{{this.dashboardLables(this.getPageName()).SKU}}:</label><span class="req-fields"> * </span> <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).SKUInfo"></small><br>
                                    <input formControlName="SKU" type="text" [class]="(this.productInfo.get('SKU').invalid && this.productInfo.get('SKU').touched) ? 'form-control is-invalid' : 'form-control'" id="sku" [placeholder]="this.dashboardLables(this.getPageName()).placeholders.sku">

                                    <br><label for="quantity">Количество:</label><span class="req-fields"> * </span> <br>
                                    <input formControlName="quantity" (change)="this.onlyPositiveNumber(this.productInfo.get('quantity'))" type="number" [class]="(this.productInfo.get('quantity').invalid && this.productInfo.get('quantity').touched) ? 'form-control is-invalid' : 'form-control'" id="quantity" placeholder="Пример: 12бр.">


                                    <div *ngIf="this.loggedPartner?.data?.organisations[0]?.vat_number">
                                        <label for="vat">{{this.dashboardLables(this.getPageName()).VAT}}:</label><span class="req-fields"> * </span><small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).VATInfo"></small><br>
                                        <select formControlName="VAT" id="vat" class="form-control">
                                            <option value="20">20%</option>
                                            <option value="9">9%</option>
                                            <option value="0">0%</option>
                                        </select>
                                    </div>

                                    <br *ngIf="!this.loggedPartner?.data?.organisations[0]?.vat_number"><label for="warranty" style="display: inline-block;">{{this.dashboardLables(this.getPageName()).warranty}}:</label>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" [title]="this.dashboardLables(this.getPageName()).warratyInfo"></small><br>
                                    <input min="0" formControlName="warranty" (change)="this.onlyPositiveNumber(this.productInfo.get('warranty'))" type="number" [class]="(this.productInfo.get('warranty').invalid && this.productInfo.get('warranty').touched) ? 'form-control is-invalid' : 'form-control'" id="warranty" [placeholder]="this.dashboardLables(this.getPageName()).warranty">
                                    <span style="font-weight: bold; color: #75809C !important;">{{this.dashboardLables(this.getPageName()).months}}</span>

                                    <br>
                                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                        {{this.errorMessage('info')}}
                                    </div>
                                    <div style="text-align: center; margin-top: 30px;">
                                        <button style="margin: 5px; width: 148px; height: 45px;" class="btn btn-primary" (click)="this.nextTab('specs', 'info')">{{this.dashboardLables(this.getPageName()).next}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ----------------------
                    Specs
                ---------------------- -->
    
                <div class="tab-pane fade show " id="specs" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <h4 style="color: #0A69EB; font-size: 16px">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2 style="font-size: 32px; margin-bottom: 30px;">{{this.dashboardLables(this.getPageName()).specsLabel}}</h2>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-sm-12" style="text-align: center; margin-bottom: 10px;">
                                <div [formGroup]="this.specs" class="form-group info-form">
                                    <label for="description"  style="display: block; text-align: left;">{{this.dashboardLables(this.getPageName()).description}}:</label>
                                    <textarea cdkTextareaAutosize [style]="this.specs.dirty ? 'color: black; display: block;' : ''" formControlName="description" type="text" [class]="(this.specs.get('description').invalid && this.specs.get('description').touched) ? 'form-control is-invalid' : 'form-control'" id="description" placeholder="Пример: Последно поколение геймърски лаптоп Acer...."></textarea>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div [formGroup]="this.specs" class="form-group info-form" style="width: 70%; display: inline-block;">
                                    <label for="description"  style="display: block;">{{this.dashboardLables(this.getPageName()).photos}}:</label>
                                    <div style="margin: 5px;" class="center">
                                        <app-imageupload placement="right"></app-imageupload>
                                    </div>
                                </div>
                                <div style="background-color: #F3F6FB; border-radius: 10px; width: 30%; margin: auto; display: inline-grid; padding: 10px;  top: 40px; float: right; right: -40px; position: relative;">
                                    <h2 style="color: #75809C; font-size: 16px; padding: 10px;">{{this.dashboardLables(this.getPageName()).imageInfo.heading}}</h2>
                                    <ul>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li1}}   
                                         </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li2}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li3}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li4}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li5}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li6}}
                                        </li>
                                    </ul>
                                    <span style="color: #75809C; font-size: 14px; margin-bottom: 9px;">{{this.dashboardLables(this.getPageName()).imageInfo.btnLabel}}: <a href="/assets/images/dashboard/addProduct/example.png" target="_blank">{{this.dashboardLables(this.getPageName()).imageInfo.button}}</a></span>
                                    <h2 style="color: #75809C; font-size: 16px;">{{this.dashboardLables(this.getPageName()).imageInfo.heading2}}</h2>
                                    <ul>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li7}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li8}}
                                        </li>
                                        <li style="color: #99A3BF; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.li9}}
                                        </li>
                                    </ul> 
                                    <div style="width: 90%; margin: auto; background-color: white; padding: 5px; border-radius: 10px;">
                                        <span style="color: #75809C; padding: 14px; border-radius: 10px; font-size: 14px;">
                                            {{this.dashboardLables(this.getPageName()).imageInfo.info}} <a style="color: #0A69EB;" href="tel:+359 898 983 228">+359 898 983 228</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 30px;">
                            <div class="col-sm-6 offset-sm-3">
                                <div [formGroup]="this.specs" class="form-group info-form">
                                    <div *ngFor="let option of this.options;" style="margin-bottom: 5px;">
                                        <label [for]="option.display_name" style="width: fit-content">{{option.display_name}}:</label>
                                        <input *ngIf="option.type.label != 'boolean' && option.can_accept_free_form_values && !option.values.length" [style]="this.specs.dirty ? 'color: black; display: block;' : ''"  [formControlName]="option.display_name.replace('.' , '')" [type]="option.type.label == 'string' ? 'text' : option.type.label" [class]="(this.specs.get(option.display_name.replace('.' , '')).invalid && this.specs.get(option.display_name.replace('.' , '')).touched) ? 'form-control is-invalid' : 'form-control'" [id]="option.display_name" [placeholder]="this.dashboardLables(this.getPageName()).choose"><span *ngIf="option.type.label == 'boolean'" class="req-fields">*</span>
                                        <select *ngIf="option.type.label == 'boolean' && option.can_accept_free_form_values" [style]="this.specs.get(option.display_name.replace('.' , '')).dirty ? 'color: black; display: block' : 'color: #6C757D;'" [formControlName]="option.display_name.replace('.' , '')"  [class]="(this.specs.get(option.display_name.replace('.' , '')).invalid && this.specs.touched) ? 'form-control is-invalid' : 'form-control'" [id]="option.display_name" [id]="option.display_name">
                                            <option style="color: #6C757D;" value="" disabled selected hidden>Избери</option>
                                            <option *ngFor="let yesNo of this.yesAndNo;" [value]="yesNo.value">{{yesNo.option}}</option>
                                        </select>
                                        <select *ngIf="!option.can_accept_free_form_values || option.values.length" [style]="this.specs.get(option.display_name).dirty ? 'color: black; display: block' : 'color: #6C757D;'" [formControlName]="option.display_name"  [class]="(this.specs.get(option.display_name).invalid && this.specs.touched) ? 'form-control is-invalid' : 'form-control'" [id]="option.display_name" [id]="option.display_name">
                                            <option style="color: #6C757D;" value="" disabled selected hidden>Избери</option>
                                            <option *ngFor="let pvalue of option.values" [value]="pvalue.uuid">{{pvalue.value}}</option>
                                        </select>
                                    </div>
                                
                                    <div>
                                        <label for="tags">{{this.dashboardLables(this.getPageName()).tags}}</label><small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Това са ключови думи, чрез които по-лесно ще намерят продукти ви. Накрая на всяка ключова дума, натиснете 'Tab' или 'enter' , за да задействате ключовата дума."></small>
                                        <mat-form-field class="example-chip-list" appearance="fill" style="width: 100%; margin: 10px;">
                                            <mat-chip-list #chipList aria-label="Fruit selection">
                                              <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                                {{tag}}
                                                <button matChipRemove>
                                                  <mat-icon>cancel</mat-icon>
                                                </button>
                                              </mat-chip>
                                              <input placeholder="Пример: лаптоп; Samsung; мъжки обувки;"
                                                     [matChipInputFor]="chipList"
                                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                     [matChipInputAddOnBlur]="addOnBlur"
                                                     (matChipInputTokenEnd)="addTag($event)"
                                                     (selectionChange)="this.specs.get('tags').setValue($event.value)"
                                                     [class]="(this.specs.get('tags')?.invalid && this.specs.get('tags')?.touched) ? 'form-control is-invalid' : 'form-control'">
                                            </mat-chip-list>
                                          </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-sm-12 variations" *ngIf="this.hasInstanceAttribute()">
                                <table mat-table [dataSource]="variationDataSource"  class="mat-elevation-z8">

                                    <ng-container matColumnDef="image">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).logo}} </th>
                                      <td mat-cell *matCellDef="let element">
                                          <img *ngIf="element.file" style="margin-bottom: 5px; width: 80px; height: 100px; display: block;" [src]="element.file.imageContent" alt="">
                                          <button class="btn table-btn" (click)="this.openImageSelection(element.id)">{{this.dashboardLables(this.getPageName()).select}}</button> 
                                      </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="size">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).size}} </th>
                                      <td mat-cell *matCellDef="let element">
                                        <input (change)="element.size = $event.target.value" class="form-control" id="size" [placeholder]="this.dashboardLables(this.getPageName()).size">
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="color">
                                        <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).color}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <input (change)="element.color = $event.target.value" class="form-control" id="color" [placeholder]="this.dashboardLables(this.getPageName()).color">
                                        </td>                                      
                                    </ng-container>
                                    
                                  
                                    <ng-container matColumnDef="SKU">
                                      <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).SKU}}</th>
                                      <td mat-cell *matCellDef="let element">
                                        <input (change)="element.SKU = $event.target.value" class="form-control" id="SKU" [placeholder]="this.dashboardLables(this.getPageName()).SKU">
                                      </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).quantity}} </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input (change)="element.quantity = $event.target.value" type="number" class="form-control" id="Quantity" [placeholder]="this.dashboardLables(this.getPageName()).quantity">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).delete}}  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="btn table-btn" (click)="this.removeVariation(element.id)">{{this.dashboardLables(this.getPageName()).delete}} </button>
                                        </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForProductTable"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsForProductTable;"></tr>
                                </table>
                                <div style="text-align: center;">
                                    <button style="margin: 5px;" class="btn btn-primary" (click)="this.createNewTableRow()">{{this.dashboardLables(this.getPageName()).addVariation}} </button>
                                </div>
                            </div> -->
                            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                {{this.errorMessage('specs')}}
                            </div>
                            <div style="text-align: center; width: 100%;">
                                <button style="margin: 5px; width: 148px; height: 45px;"  class="btn btn-primary" (click)="this.nextTab('price', 'specs')">{{this.dashboardLables(this.getPageName()).next}}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- ----------------------
                    Variations
                ---------------------- -->


                <div class="tab-pane fade show" id="variations" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-sm-12" style="text-align: center;">
                                <h4 style="color: #0A69EB; font-size: 16px">{{this.dashboardLables(this.getPageName()).addVariations}}</h4>
                                <h2 style="font-size: 32px;">{{this.dashboardLables(this.getPageName()).variations}}</h2>
                            </div>
                        </div>
                        <div *ngIf="this.variationSlide == this.variationSlides[0]" style="margin: auto; width: 80%; margin-bottom: 30px;">
                            <span (click)="userVariationOptionSelect(true)">
                                <input style="transform: scale(2);"  type="radio" id="yesvariations" name="variations" [checked]="this.userSelectedWhetherToShowVariations" value="Yes">
                                <label style="margin-left: 10px; font-weight: unset; color: black;" for="yesvariations">{{this.dashboardLables(this.getPageName()).yesVariations}}</label>
                            </span>
                            <span style="float: right;" (click)="userVariationOptionSelect(false)">
                                <input style="transform: scale(2);" id="novariations" type="radio" name="variations" [checked]="!this.userSelectedWhetherToShowVariations" value="No">
                                <label style="margin-left: 10px; font-weight: unset; color: black;" for="novariations">{{this.dashboardLables(this.getPageName()).noVariations}}</label>
                            </span>
                        </div>
                        <div *ngIf="userSelectedWhetherToShowVariations && this.variations.length">
                            <div class="col-sm-12 variationstable">
                                <label  style="width: fit-content">Тип вариация:</label><br>
                                <button *ngIf="this.variations.length == 2" style="margin: 5px; width: 148px; height: 45px;"  [class]="this.variationType == 'all' ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="this.loadVariations('all')">Размер и цвят</button>
                                <button *ngFor="let variation of this.variations" style="margin: 5px; width: 148px; height: 45px;"  [class]="this.variationType == variation.display_name ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="this.loadVariations(variation.display_name)">{{variation.display_name}}</button>

                                <table mat-table [dataSource]="variationsDataSource"  class="mat-elevation-z8">

                                    <ng-container matColumnDef="image">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).photos}} </th>
                                      <td mat-cell *matCellDef="let element" style="padding-left: 0px !important;">
                                            <div *ngFor="let image of this.imageCollectionPerVariation[element.uuid]?.data; index as i" style="display: inline-block;">
                                                <img  *ngIf="i < 2" [src]="(image && image.original) ? image.original.url : ''" alt="" width="45" height="45" style="margin-right: 5px; border-radius: 10px"  (click)="this.openImageVariationUpload(element)">
                                                <div *ngIf="i == 2" class="imageOverlay" style="position: relative" (click)="this.openImageVariationUpload(element)">
                                                    <img [src]="(image && image.original) ? image.original.url : ''" alt="" width="45" height="45" style="margin-right: 5px; border-radius: 10px; opacity: 50%;">
                                                    <div *ngIf="(this.imageCollectionPerVariation[element.uuid].data.length - 3) > 0" class="centered">{{ '+' + (this.imageCollectionPerVariation[element.uuid].data.length - 3)}}</div>

                                                </div>
                                            </div>
                                          <button *ngIf="this.imageCollectionPerVariation[element.uuid]?.data.length < 1" class="btn table-btn" [style]="element.uuid ? 'color: #0A69EB;' : 'color: #8E99B9;'" (click)="this.openImageVariationUpload(element)">{{this.dashboardLables(this.getPageName()).variationImageUpload}}</button> 
                                      </td>
                                    </ng-container>

                                    <ng-container *ngFor="let variation of this.variations" [matColumnDef]="variation.display_name">
                                        <th style="min-width: 200px;" mat-header-cell *matHeaderCellDef> {{variation.display_name}} </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input *ngIf="!variation.values.length" (change)="element[variation.display_name] = $event.target.value; element.changed = true;" [value]="element[variation.display_name] ? element[variation.display_name] : ''" [class]="(!element[variation.display_name]) ? 'form-control is-invalid' : 'form-control'" [id]="variation.display_name" [placeholder]="this.dashboardLables(this.getPageName()).choose + ' ' + variation?.display_name.toLowerCase()">
                                             
                                            <!-- <input  type="color" id="head" name="head" value="#e66465" placeholder="Избери"> -->
                                            <mat-select style="padding-left: 0px !important; padding-right: 0px !important;"  [compareWith]="this.compareColors" matNativeControl  *ngIf="variation.field_name == 'color' && variation.values.length" (selectionChange)="element[variation.display_name] = $event.value; element.changed = true;" [value]="element[variation.display_name] ? element[variation.display_name] : ''" [class]="(!element[variation.display_name]) ? 'form-control is-invalid' : 'form-control'" [id]="variation.display_name">
                                                <mat-select-trigger *ngIf="element[variation.display_name]">
                                                    {{element[variation.display_name][1]}} <img *ngIf="element[variation.display_name][1].includes('Многоцветен')" style="display: inline-block;height: 20px;width: 20px;float: right;position: relative;"  src="/assets/images/dashboard/products/colorful.svg" alt=""><span *ngIf="!element[variation.display_name][1].includes('Многоцветен')" [style]="(!element[variation.display_name][1].includes('Бял')) ? 'display: inline-block;height: 20px;width: 20px;float: right;position: relative;background-color: '+ this.getColor(element[variation.display_name][1]) : 'display: inline-block;height: 20px;width: 20px;float: right;position: relative; border: 1px solid black'"></span>
                                                </mat-select-trigger>
                                                <mat-option style="color: #6C757D;" value="" disabled selected hidden>Избери</mat-option>
                                                <mat-option *ngFor="let color of this.vcolors" [value]="[color.uuid, color.value]">
                                                    {{color.value}} <img *ngIf="color.value.includes('Многоцветен')" style="display: inline-block;height: 20px;width: 20px;float: right;position: relative; top: 10px;"  src="/assets/images/dashboard/products/colorful.svg" alt=""><span *ngIf="!color.value.includes('Многоцветен')" [style]="(!color.value.includes('Бял')) ? 'display: inline-block;height: 20px;width: 20px;float: right;position: relative; top: 10px; background-color: '+ this.getColor(color.value) : 'display: inline-block;height: 20px;width: 20px;float: right;position: relative; top: 10px; border: 1px solid black'"></span>
                                                </mat-option>
                                              </mat-select>
                                            
                                            <select style="min-width: 150px;"  *ngIf="variation.field_name == 'size' && variation.values.length" (change)="element[variation.display_name] = $event.target.value; element.changed = true;" [value]="element[variation.display_name] ? element[variation.display_name] : ''" [class]="(!element[variation.display_name]) ? 'form-control is-invalid' : 'form-control'" [id]="variation.display_name">
                                                <option style="color: #6C757D;" value="" disabled selected hidden>Избери</option>
                                                <option *ngFor="let size of this.vsizes" [value]="size.uuid">{{size.value}}</option>
                                            </select>
                                        </td>
                                      </ng-container>

                                    <ng-container matColumnDef="sku">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).SKU}} </th>
                                      <td mat-cell *matCellDef="let element">
                                        <input (change)="element.sku = $event.target.value; element.changed = true;" [value]="element.sku" [class]="(!element.sku) ? 'form-control is-invalid' : 'form-control'" id="sku" [placeholder]="this.dashboardLables(this.getPageName()).choose + ' ' + this.dashboardLables(this.getPageName()).variationsSKU">
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).quantity}} </th>
                                        <td mat-cell *matCellDef="let element">
                                          <input (change)="element.quantity = $event.target.value; element.changed = true;" [value]="element.quantity" [class]="(!element.quantity) ? 'form-control is-invalid' : 'form-control'" id="quantity" [placeholder]="this.dashboardLables(this.getPageName()).choose + ' ' +this.dashboardLables(this.getPageName()).variationsQuantity">
                                        </td>
                                      </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).action}}  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="btn btn-primary" *ngIf="element.changed || !element.uuid" (click)="(!element.uuid) ? this.saveVariation(element) : this.updateVariation(element)">{{this.dashboardLables(this.getPageName()).uploadVariation}} </button>
                                            <button style="margin-left: 10px;" *ngIf="element.uuid" class="btn table-btn btn-danger" (click)="this.deleteVariation(element)">{{this.dashboardLables(this.getPageName()).delete}} </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForVariations"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsForVariations;"></tr>
                                </table>
                                <div style="margin-top: 10px;">
                                    <button class="btn" style="margin: 5px; color: #0A69EB; padding-left: 0px; margin-left: 0px;" (click)="this.createNewVariation()"> <img style="position: relative; top: -2px; height: 27px;"  src="/assets/images/dashboard/addProduct/addPricing.png"> {{this.dashboardLables(this.getPageName()).addVariation}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div style="text-align: center; width: 100%;">
                                <button style="margin: 5px; width: 148px; height: 45px; margin-top: 40px;"  class="btn btn-primary" (click)="this.nextTab('price', 'variations')">{{this.dashboardLables(this.getPageName()).next}}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- ----------------------
                    Price
                ---------------------- -->
    
                <div class="tab-pane fade show " id="price" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <h4 style="color: #0A69EB; font-size: 16px">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2 style="font-size: 32px;">{{this.dashboardLables(this.getPageName()).pricing}}</h2>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <div [formGroup]="this.pricing" class="form-group info-form">
                                    <div *ngFor="let attribute of [].constructor(this.priceRangeCount); index as i" [style]="(i == this.priceRangeCount - 1) ? 'left: 30px; position: relative;' : ''">
                                        <div class="pricing">
                                            <label [for]="'unitsFrom'+i" style="display: block; float: left;" >{{i < 4 ? this.dashboardLables(this.getPageName()).units : this.dashboardLables(this.getPageName()).uFrom }} <span *ngIf="i < 4" style="color: red !important"> * </span></label>
                                            <input [style]="i < 4 ? 'color: #6C757D;' : ''" pattern="[0-9]" [disabled]="i < 4" [formControlName]="'unitsFrom'+i" type="number" [class]="(this.pricing.get('unitsFrom'+i).invalid) ? 'form-control is-invalid' : 'form-control'" [value]="i" [id]="'unitsFrom'+i" [placeholder]="i">
                                        </div>
                                        <img *ngIf="(i >= 4)" style="margin: 10px;" src="/assets/images/dashboard/addProduct/dash.png">
                                        <div *ngIf="i > 3" class="pricing">
                                            <label [for]="'unitsTo'+i" style="display: block; float: left;" >{{this.dashboardLables(this.getPageName()).uTo}}</label>
                                            <input pattern="[0-9]" [formControlName]="'unitsTo'+i" type="number" [class]="(this.pricing.get('unitsTo'+i).invalid) ? 'form-control is-invalid' : 'form-control'" [id]="'unitsTo'+i"  [placeholder]="this.dashboardLables(this.getPageName()).uTo">
                                        </div>
                                        <div *ngIf="i < 4" class="pricing">
                                        </div>
                                        <img *ngIf="(i >= 4)" style="margin: 10px;" src="/assets/images/dashboard/addProduct/dash.png">
                                        <div class="pricing" [style]="(i < 4) ? 'margin-left: 65px;' : ''">
                                            <label [for]="'pricing'+i" style="display: block;float: left;">{{this.dashboardLables(this.getPageName()).price}}:</label>
                                            <input style="display: inline; width: 85%" [formControlName]="'pricing'+i" [value]="''" (change)="this.onInputChangeMakeOnlyPositive(this.pricing.get('pricing'+i))" type="number" [class]="(this.pricing.get('pricing'+i).invalid) ? 'form-control is-invalid' : 'form-control'" [id]="'pricing'+i" [placeholder]="'Пример: ' + (50 - i * 2).toFixed(2)"><span class="currency">{{this.dashboardLables(this.getPageName()).lv}}</span>
                                        </div>
                                        <button class="btn" *ngIf="(i == this.priceRangeCount - 1)"  style="margin: 5px;"  (click)="this.decreaseRange()"><img  src="/assets/images/dashboard/addProduct/deletePricing.png"></button>

                                    </div>
                                    <button class="btn" style="width: 25%; position: relative; left: 30px; display: block; float: left; margin: 5px; color: #0A69EB; margin: 0px; padding: 0px;margin-bottom: 10px; margin-top: 10px;" (click)="this.increaseRange()"> <img style="position: relative; top: -2px;"  src="/assets/images/dashboard/addProduct/addPricing.png"> {{this.dashboardLables(this.getPageName()).add}}</button>
                                    <div style="color: #99A3BF; font-size: 14px; float: left;"> {{this.dashboardLables(this.getPageName()).pricingInfo}}</div>
                                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                        {{this.errorMessage('price')}}
                                    </div>
                                    <div style="text-align: center;">
                                        <button style="margin: 5px; width: 148px; height: 45px; margin-top: 15px;"  class="btn btn-primary" (click)="this.nextTab('ship', 'price')">{{this.dashboardLables(this.getPageName()).next}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ----------------------
                    Ship
                ---------------------- -->
    
                <div class="tab-pane fade show " id="ship" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center; margin-bottom: 20px;">
                                <h4 style="color: #0A69EB; font-size: 16px">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2 style="font-size: 32px;">{{this.dashboardLables(this.getPageName()).ship}}</h2>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div [formGroup]="this.ship" class="form-group info-form">

                                    <!-- <label for="packaging">{{this.dashboardLables(this.getPageName()).packaging}}</label>
                                    <select formControlName="packaging"  class="form-control" id="packaging">
                                        <option *ngFor="let packaging of this.packagingOptions;" [value]="packaging.value">{{packaging.option}}</option>
                                    </select> -->


                                    <!-- <app-storestabs *ngIf="this.ship.controls.pickUp.value == 'true'"></app-storestabs> -->

                                    <label for="shippWithin" style="font-size: 18px;">{{this.dashboardLables(this.getPageName()).shippWithin}}: <span style="color: red !important"> * </span></label>
                                    <input formControlName="shippWithin"  (change)="this.onInputChangeMakeOnlyPositiveAndWholeNumbers(this.ship.get('shippWithin'))" type="number" inputmode="numeric"  [class]="(this.ship.get('shippWithin').invalid && this.ship.get('shippWithin').touched) ? 'form-control is-invalid' : 'form-control'" id="shippWithin" placeholder="Пример: 2">
                                    
                                    <label style="width: 199%; font-size: 18px; margin-top: 50px;">{{this.dashboardLables(this.getPageName()).sizeLabel}}:</label>

                                    <div class="shipItems" style="margin-right: 40px !important;">
                                        <label style="font-size: 14px;" class="weight" for="weight">{{this.dashboardLables(this.getPageName()).weight}}: <span style="color: red !important"> * </span></label>
                                        <div class="input-group">
                                            <input formControlName="weight" (change)="this.onInputChangeMakeOnlyPositive(this.ship.get('weight'))" type="number" [class]="(this.ship.get('weight').invalid && this.ship.get('weight').touched) ? 'form-control is-invalid' : 'form-control'" id="weight" placeholder="Пример: 2.50">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).kg}}</span>
                                            </div> 
                                        </div>   
                                    </div>

                                    <div class="shipItems">
                                        <label style="font-size: 14px;"  class="length" for="length">{{this.dashboardLables(this.getPageName()).length}}:</label>
                                        <div class="input-group">
                                            <input formControlName="length" (change)="this.onInputChangeMakeOnlyPositive(this.ship.get('length'))" type="number" [class]="(this.ship.get('length').invalid && this.ship.get('length').touched) ? 'form-control is-invalid' : 'form-control'" id="length" placeholder="Пример: 35.5">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="shipItems" style="margin-right: 40px !important;">
                                        <label style="font-size: 14px;" class="depth" for="depth">{{this.dashboardLables(this.getPageName()).depth}}:</label>
                                        <div class="input-group">
                                        <input formControlName="depth" (change)="this.onInputChangeMakeOnlyPositive(this.ship.get('depth'))" type="number" [class]="(this.ship.get('depth').invalid && this.ship.get('depth').touched) ? 'form-control is-invalid' : 'form-control'" id="depth" placeholder="Пример: 6.00">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>  
                                        </div>
                                    </div>

                                    <div class="shipItems">
                                        <label style="font-size: 14px;"  class="height" for="height">{{this.dashboardLables(this.getPageName()).height}}:</label>
                                        <div class="input-group">
                                            <input formControlName="height" (change)="this.onInputChangeMakeOnlyPositive(this.ship.get('height'))" type="number" [class]="(this.ship.get('height').invalid && this.ship.get('height').touched) ? 'form-control is-invalid' : 'form-control'" id="height" placeholder="Пример: 35.5">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <label style="width: 100%; font-size: 18px; margin-top: 50px;">{{this.dashboardLables(this.getPageName()).fragile}} <span style="color: red !important"> * </span>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Може да бъдете таксувани допълнително от Спиди за тази услуга."></small></label>

                                    <span style="position: relative; left: 7px;">
                                        <input (change)="toggleFragile($event, true)" style="transform: scale(2);"  type="checkbox" id="yesfragile" name="fragile" [checked]="this.ship.get('fragile').value" value="true">
                                        <label style="width: fit-content; margin-left: 10px; font-weight: unset; color: black;" for="yesfragile">{{this.dashboardLables(this.getPageName()).yes}}</label>
                                    </span>
                                    <span style="margin: 100px" >
                                        <input (click)="toggleFragile($event, false)" style="transform: scale(2);" id="nofragile" type="checkbox" name="fragile" [checked]="!this.ship.get('fragile').value" value="false">
                                        <label style="width: fit-content;  margin-left: 10px; font-weight: unset; color: black;" for="nofragile">{{this.dashboardLables(this.getPageName()).no}}</label>
                                    </span>

                                    <label style="width: 100%; font-size: 18px; margin-top: 50px;">{{this.dashboardLables(this.getPageName()).courrierType}}  <span style="color: red !important"> * </span>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Това ще е вида куриерска услуга за доставка на продукта."></small></label>

                                    <span  style="position: relative; left: 7px;">
                                        <input style="transform: scale(2);" (change)="toggleCourrierType($event, this.dashboardLables(this.getPageName()).standart)"  type="checkbox" id="standart" name="courrierType" [checked]="this.ship.get('courierType').value == this.getCourrierTypes(this.dashboardLables(this.getPageName()).standart).value" [value]="this.getCourrierTypes(this.dashboardLables(this.getPageName()).standart).value">
                                        <label style="width: fit-content; margin-left: 10px; font-weight: unset; color: black;" for="courrierType">{{this.dashboardLables(this.getPageName()).standart}}</label>
                                    </span>
                                    <span style="margin: 30px; margin-left: 45px;" >
                                        <input style="transform: scale(2);" (change)="toggleCourrierType($event, this.dashboardLables(this.getPageName()).pallete)"  type="checkbox" id="standart" name="courrierType" [checked]="this.ship.get('courierType').value == this.getCourrierTypes(this.dashboardLables(this.getPageName()).pallete).value" [value]="this.getCourrierTypes(this.dashboardLables(this.getPageName()).pallete).value">
                                        <label style="width: fit-content;  margin-left: 10px; font-weight: unset; color: black;" for="courrierType">{{this.dashboardLables(this.getPageName()).pallete}}</label>
                                    </span>
                                    <span style="margin: 30px; margin-left: 5px;">
                                        <input style="transform: scale(2);"   (change)="toggleCourrierType($event, dashboardLables(this.getPageName()).tyres)" type="checkbox" id="standart" name="courrierType" [checked]="this.ship.get('courierType').value == this.getCourrierTypes(this.dashboardLables(this.getPageName()).tyres).value" [value]="this.getCourrierTypes(this.dashboardLables(this.getPageName()).tyres).value">
                                        <label style="width: fit-content;  margin-left: 10px; font-weight: unset; color: black;" for="courrierType">{{this.dashboardLables(this.getPageName()).tyres}}</label>
                                    </span>

                                    <label style="width: 100%; font-size: 18px; margin-top: 50px;">{{this.dashboardLables(this.getPageName()).payOptions}}</label>
                                    <label style="font-size: 14px; width: 100%;">{{this.dashboardLables(this.getPageName()).extras}}:  <span style="color: red !important"> * </span></label>
                                    <select [value]="this.testExtrasValue" style="width: 100%; display: inline-block;" (change)="this.selectExtras($event)"  id="brand" class="form-control">
                                        <!-- <option value="" selected>{{this.dashboardLables(this.getPageName()).placeholders.choose}}</option> -->
                                        <option  value="check">{{this.dashboardLables(this.getPageName()).check}}</option>
                                        <option  value="test">{{this.dashboardLables(this.getPageName()).test}}</option>
                                        <option  value="both">{{this.dashboardLables(this.getPageName()).extras}}</option>
                                        <option selected value="none">{{this.dashboardLables(this.getPageName()).noCheckAndTest}}</option>

                                    </select>

                                </div>
                                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                    {{this.errorMessage("ship")}}
                                </div>
                                <div style="text-align: center;">
                                    <button style="margin: 5px; width: 148px; height: 45px; margin-top: 15px;"  class="btn btn-primary" (click)="this.nextTab('', 'ship')">{{this.dashboardLables(this.getPageName()).upload}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #chooseColor let-modal>
    <div style="text-align: center; padding: 20px;">
        <h4>Избери цвят</h4>
        <mat-form-field appearance="fill">
            <mat-label>Choose an option</mat-label>
        </mat-form-field>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="background-color: white; border: 1px solid #B6BFD6; color: black; float: left;" mat-button (click)="modal.close()">Затвори</button>
            <button class="btn btn-primary" style="float: right;" mat-button (click)="this.selectColor('white')" cdkFocusInitial>Обнови парола</button>
          </div>
    </div>
</ng-template>
export const YesAndNo = {
    options: {
        bg: [
            { option: "Да", value: true},
            { option: "Не", value: false},
        ],
        en: [
            { option: "Yes", value: true},
            { option: "No", value: false}
        ]
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UpdateDeliveryRequestModel } from 'src/models/requestModels/V2/delivery/UpdateDeliveryRequestModel';


@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  private deliveryURL : string = environment.api + "organisation/";



  constructor(private httpClient: HttpClient) { }

  updateDelivery(updateDelivery: UpdateDeliveryRequestModel, organisationUUID: string) {
    const params = organisationUUID + '/settings'
    return this.httpClient.patch<any>(this.deliveryURL + params, updateDelivery);
  }
}

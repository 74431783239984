<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body" style="height: 100%;">
    <div class="row" style="height: 100%;">
        <div class="col-sm-12 column" style="height: 100%;">
            <ul class="nav nav-tabs" id="myTab" role="tablist" style="margin-bottom: 15px;">
                <li class="nav-item">
                    <a class="nav-link active" (click)="this.handleTabRedirect($event)"  aria-selected="true"  role="tab" href="#info" aria-controls="info"><span class="info">{{this.dashboardLables(this.getPageName()).infoLabel}}</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" onclick="return false;" (click)="this.handleTabRedirect($event)"  aria-selected="false"  role="tab" href="#specs" aria-controls="specs"><span class="specs">{{this.dashboardLables(this.getPageName()).specs}}</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  (click)="this.handleTabRedirect($event)"  aria-selected="false"  role="tab" href="#price" aria-controls="price"><span class="price">{{this.dashboardLables(this.getPageName()).price}}</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="this.handleTabRedirect($event)"  aria-selected="false"  role="tab" href="#ship" aria-controls="ship"><span class="ship">{{this.dashboardLables(this.getPageName()).ship}}</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  (click)="this.handleCancelRedirect()" aria-selected="false"  role="tab"  aria-controls="ship"><span class="cancel">{{this.dashboardLables(this.getPageName()).cancel}}</span></a>
                </li>
            </ul>

            <div class="tab-content pInfo" id="myTabContent">

                <!-- ----------------------
                    Product info
                ---------------------- -->
    
                <div class="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <h4 style="color: #0A69EB;">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2>{{this.dashboardLables(this.getPageName()).infoLabel}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 offset-sm-3">
                                <div *ngIf="this.productInfo"  [formGroup]="this.productInfo" class="form-group info-form">
                                    <label for="category">{{this.dashboardLables(this.getPageName()).category}}</label>
                                    <select (change)="this.categoryChange($event)"  formControlName="category" id="category" class="form-control">
                                        <option *ngFor="let category of this.categories; index as i" [value]="category.id" [selected] = "i == 0">{{category.name}}</option>
                                    </select>

                                    <label for="subcategory">{{this.dashboardLables(this.getPageName()).subcategory}}</label>
                                    <select (change)="this.subCategoryChange($event)" formControlName="subcategory" id="subcategory" class="form-control">
                                        <option *ngFor="let subcategory of this.subCategories" [value]="subcategory.id">{{subcategory.name}}</option>
                                    </select>

                                    <label for="name">{{this.dashboardLables(this.getPageName()).name}}</label>
                                    <input formControlName="name" type="text" class="form-control" id="name" [placeholder]="this.dashboardLables(this.getPageName()).name">

                                    <label style="display: block;" for="brand">{{this.dashboardLables(this.getPageName()).brand}}</label>
                                    <select style="width: 100%; display: inline-block;" (change)="this.changeBrand($event)" formControlName="brand" id="brand" class="form-control">
                                        <option *ngFor="let brand of this.brands" [value]="brand.id">{{brand.name}}</option>
                                    </select>

                                    <input style="display: inline-block; width: 40%; margin-left: 5px;" *ngIf="this.productInfo.get('brand').value == this.customBrandId" formControlName="customBrand" type="text" class="form-control" id="manufacturer" [placeholder]="this.dashboardLables(this.getPageName()).customBrand">


                                    <label for="manufacturer">{{this.dashboardLables(this.getPageName()).manufacturer}}</label>
                                    <input formControlName="manufacturer" type="text" class="form-control" id="manufacturer" [placeholder]="this.dashboardLables(this.getPageName()).manufacturer">
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).manufacturerInfo}}</small>


                                    <label for="condition">{{this.dashboardLables(this.getPageName()).condition}}</label>
                                    <select formControlName="condition" id="condition" class="form-control">
                                        <option *ngFor="let condition of this.condtions;" [value]="condition.value">{{condition.condition}}</option>
                                    </select>
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).conditionInfo}}</small>

                                    <label for="barcode">{{this.dashboardLables(this.getPageName()).barcode}}</label>
                                    <input formControlName="barcode" type="number" class="form-control" id="barcode" [placeholder]="this.dashboardLables(this.getPageName()).barcode">
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).barcodeInfo}}</small>

                                    <label for="sku">{{this.dashboardLables(this.getPageName()).SKU}}</label>
                                    <input formControlName="SKU" type="text" class="form-control" id="sku" [placeholder]="this.dashboardLables(this.getPageName()).SKU">
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).SKUInfo}}</small>

                                    <label for="vat">{{this.dashboardLables(this.getPageName()).VAT}}</label>
                                    <select formControlName="VAT" id="vat" class="form-control">
                                        <option value="FULL">20%</option>
                                        <option value="REDUCED">9%</option>
                                        <option value="ZERO">0%</option>
                                    </select>
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).VATInfo}}</small>

                                    <label for="warranty" style="display: block;">{{this.dashboardLables(this.getPageName()).warranty}}</label>
                                    <input formControlName="warranty" type="number" class="form-control" id="warranty" [placeholder]="this.dashboardLables(this.getPageName()).warranty">
                                    {{this.dashboardLables(this.getPageName()).months}}
                                    <small id="emailHelp" class="text-muted"> {{this.dashboardLables(this.getPageName()).manufacturerInfo}}</small>


                                    <br>
                                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                        {{this.errorMessage('info')}}
                                    </div>
                                    <div style="text-align: center;">
                                        <button style="margin: 5px;" class="btn btn-primary" (click)="this.nextTab('specs', 'info')">{{this.dashboardLables(this.getPageName()).next}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ----------------------
                    Specs
                ---------------------- -->
    
                <div class="tab-pane fade show " id="specs" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <button style="margin: 5px; float: left; width: auto;" class="btn backTab" (click)="this.nextTab('info')">{{this.dashboardLables(this.getPageName()).back}}</button>
                                <h4 style="color: #0A69EB;">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2>{{this.dashboardLables(this.getPageName()).specsLabel}}</h2>
                            </div>
                        </div>                        
                    
                        <div class="row">
                            <div class="col-sm-12">
                                <div *ngIf="this.specs"  [formGroup]="this.specs" class="form-group info-form">

                                    <div *ngIf="!this.hasInstanceAttribute()">
                                        <label for="quantity" style="display: block;">{{this.dashboardLables(this.getPageName()).quantity}}</label>
                                        <input [formControl]="this.specs.get('quantity')" type="number" class="form-control" id="quantity" [placeholder]="this.dashboardLables(this.getPageName()).quantity">
                                    </div>
                                    <div *ngIf="this.hasInstanceAttribute()">
                                        <label for="gender" style="display: block;">{{this.dashboardLables(this.getPageName()).gender}}</label>
                                        <select [formControl]="this.specs.get('gender')"  class="form-control" id="gender">
                                            <option *ngFor="let gender of this.genders;" [value]="gender.value">{{gender.gender}}</option>
                                        </select>
                                    </div>
                                    
                                    <div style="margin: 5px;" class="center">
                                        <app-imageupload placement="right" [ngbTooltip]="this.dashboardLables(this.getPageName()).uploadImageInfo"
                                        [openDelay]="300" [closeDelay]="500"></app-imageupload>                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 offset-sm-3">
                                <div *ngIf="this.specs" [formGroup]="this.specs" class="form-group info-form">
                                    <div *ngFor="let attribute of this.attributes;">
                                        <label [for]="attribute.name" style="display: block;">{{attribute.attributeName}}</label>
                                        <input [formControlName]="attribute.name" [type]="attribute.javaScriptType" class="form-control" [id]="attribute.name" [placeholder]="attribute.attributeName">
                                    </div>
                                </div>                           
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 variations" *ngIf="this.hasInstanceAttribute()">
                                <table mat-table [dataSource]="variationDataSource"  class="mat-elevation-z8">

                                    <ng-container matColumnDef="image">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).logo}} </th>
                                      <td mat-cell *matCellDef="let element">
                                          <img *ngIf="element.file" style="margin-bottom: 5px; width: 80px; height: 100px; display: block;" [src]="(element.file.sanitizedContent) ? element.file.sanitizedContent : element.file.imageContent" alt="">
                                          <button class="btn table-btn" (click)="this.openImageSelection(element.id)">{{this.dashboardLables(this.getPageName()).select}}</button> 
                                      </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="size">
                                      <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).size}} </th>
                                      <td mat-cell *matCellDef="let element">
                                        <input [value]="element.size" (change)="element.size = $event.target.value" class="form-control" id="size" [placeholder]="this.dashboardLables(this.getPageName()).size">
                                      </td>
                                    </ng-container>

                                    <ng-container matColumnDef="color">
                                        <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).color}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <input [value]="element.color"  (change)="element.color = $event.target.value" class="form-control" id="color" [placeholder]="this.dashboardLables(this.getPageName()).color">
                                        </td>                                      
                                    </ng-container>
                                    
                                  
                                    <ng-container matColumnDef="SKU">
                                      <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).SKU}}</th>
                                      <td mat-cell *matCellDef="let element">
                                        <input [value]="element.SKU"  (change)="element.SKU = $event.target.value" class="form-control" id="SKU" [placeholder]="this.dashboardLables(this.getPageName()).SKU">
                                      </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).quantity}} </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input [value]="element.resQuantity" type="number" (change)="element.quantity = $event.target.value" class="form-control" id="Quantity" [placeholder]="this.dashboardLables(this.getPageName()).quantity">
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).delete}}  </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button class="btn table-btn" (click)="this.removeVariation(element.id)">{{this.dashboardLables(this.getPageName()).delete}} </button>
                                        </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForProductTable"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsForProductTable;"></tr>
                                </table>
                                <div style="text-align: center;">
                                    <button style="margin: 5px;" class="btn btn-primary" (click)="this.createNewTableRow()">{{this.dashboardLables(this.getPageName()).addVariation}} </button>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div *ngIf="this.specs"  [formGroup]="this.specs" class="form-group info-form">
                                    <label for="description" style="display: block;">{{this.dashboardLables(this.getPageName()).description}}</label>
                                    <textarea formControlName="description" type="text" class="form-control" id="description" [placeholder]="this.dashboardLables(this.getPageName()).description"></textarea>
                                </div>
                            </div>
                            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                {{this.errorMessage('specs')}}
                            </div>
                            <div style="text-align: center;">
                                <button style="margin: 5px;" class="btn btn-primary" (click)="this.nextTab('price', 'specs')">{{this.dashboardLables(this.getPageName()).next}}</button>
                            </div> 
                        </div>
                    </div>
                </div>

                <!-- ----------------------
                    Price
                ---------------------- -->
    
                <div class="tab-pane fade show " id="price" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <button style="margin: 5px; float: left; width: auto;" class="btn backTab" (click)="this.nextTab('specs')">{{this.dashboardLables(this.getPageName()).back}}</button>
                                <h4 style="color: #0A69EB;">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2>{{this.dashboardLables(this.getPageName()).pricing}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div *ngIf="this.pricing && this.isPricingFormValid"  [formGroup]="this.pricing" class="form-group info-form">
                                    <div *ngFor="let attribute of this.pricings; index as i">
                                        <div class="pricing">
                                            <label [for]="'unitsFrom'+i" style="display: block;" >{{this.dashboardLables(this.getPageName()).uFrom}}</label>
                                            <input [disabled]="i < 4"  [formControlName]="'unitsFrom'+i" type="number" class="form-control" [value]="i" [id]="'unitsFrom'+i" [placeholder]="i">
                                        </div>
                                        <div *ngIf="i > 3" class="pricing">
                                            <label [for]="'unitsTo'+i" style="display: block;" >{{this.dashboardLables(this.getPageName()).uTo}}</label>
                                            <input [formControlName]="'unitsTo'+i"  type="number" class="form-control" [id]="'unitsTo'+i"  [placeholder]="this.dashboardLables(this.getPageName()).uTo">
                                        </div>
                                        <div *ngIf="i < 4" class="pricing">
                                        </div>
                                        <div class="pricing">
                                            <label [for]="'pricing'+i" style="display: block;">{{this.dashboardLables(this.getPageName()).price}}</label>
                                            <input [formControlName]="'pricing'+i"  type="number" class="form-control" [id]="'pricing'+i" [placeholder]="this.dashboardLables(this.getPageName()).price">
                                        </div>
                                    </div>
                                    <br>
                                    <button style="margin: 5px;" class="btn btn-primary" (click)="this.increaseRange()">{{this.dashboardLables(this.getPageName()).add}}</button>
                                    <button style="margin: 5px;" class="btn btn-primary" (click)="this.decreaseRange()">{{this.dashboardLables(this.getPageName()).removeLast}}</button>
                                    <br>
                                    <small> {{this.dashboardLables(this.getPageName()).pricingInfo}}</small>
                                    <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                        {{this.errorMessage('price')}}
                                    </div>
                                    <div style="text-align: center;">
                                        <button style="margin: 5px;" class="btn btn-primary" (click)="this.nextTab('ship', 'price')">{{this.dashboardLables(this.getPageName()).next}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ----------------------
                    Ship
                ---------------------- -->
    
                <div class="tab-pane fade show " id="ship" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12" style="text-align: center;">
                                <button style="margin: 5px; float: left; width: auto;" class="btn backTab" (click)="this.nextTab('price')">{{this.dashboardLables(this.getPageName()).back}}</button>
                                <h4 style="color: #0A69EB;">{{this.dashboardLables(this.getPageName()).addLabel}}</h4>
                                <h2>{{this.dashboardLables(this.getPageName()).ship}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div *ngIf="this.ship"  [formGroup]="this.ship" class="form-group info-form">

                                    <label for="pickUp">{{this.dashboardLables(this.getPageName()).pickUp}}</label>
                                    <select formControlName="pickUp"  class="form-control" id="pickUp">
                                        <option *ngFor="let yesNo of this.yesAndNo;" [value]="yesNo.value">{{yesNo.option}}</option>
                                    </select>


                                    <app-storestabs *ngIf="this.ship.controls.pickUp.value == 'true' || this.ship.controls.pickUp.value === true"></app-storestabs>

                                    <label for="shippWithin">{{this.dashboardLables(this.getPageName()).shippWithin}}</label>
                                    <input formControlName="shippWithin" type="number" class="form-control" id="shippWithin" [placeholder]="this.dashboardLables(this.getPageName()).days">

                                    <div class="shipItems">
                                        <label class="height" for="height">{{this.dashboardLables(this.getPageName()).height}}</label>
                                        <div class="input-group">
                                            <input formControlName="height" type="number" class="form-control" id="height" [placeholder]="this.dashboardLables(this.getPageName()).height">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="shipItems">
                                        <label  class="length" for="length">{{this.dashboardLables(this.getPageName()).length}}</label>
                                        <div class="input-group">
                                            <input formControlName="length" type="number" class="form-control" id="length" [placeholder]="this.dashboardLables(this.getPageName()).length">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="shipItems">
                                        <label class="depth" for="depth">{{this.dashboardLables(this.getPageName()).depth}}</label>
                                        <div class="input-group">
                                        <input formControlName="depth" type="number" class="form-control" id="depth" [placeholder]="this.dashboardLables(this.getPageName()).depth">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).cm}}</span>
                                            </div>  
                                        </div>
                                    </div>

                                    <div class="shipItems">
                                        <label class="weight" for="weight">{{this.dashboardLables(this.getPageName()).weight}}</label>
                                        <div class="input-group">
                                            <input formControlName="weight" type="number" class="form-control" id="weight" [placeholder]="this.dashboardLables(this.getPageName()).weight">
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="basic-addon2">{{this.dashboardLables(this.getPageName()).kg}}</span>
                                            </div> 
                                        </div>   
                                    </div>
                                </div>
                                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                    {{this.errorMessage("ship")}}
                                </div>
                                <div style="text-align: center;">
                                    <button style="margin: 5px;" class="btn btn-primary" (click)="this.upload('ship')">{{this.dashboardLables(this.getPageName()).upload}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
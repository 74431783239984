import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { ContractActionRequestModel } from 'src/models/requestModels/contracts/contractActionRequestModel';
import { Contract } from 'src/models/DTO/contracts/contract';


@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private createCancelationPolicyUrl: string = environment.api + 'api/partners';
  private getCancelationPolicyUrl: string = environment.api + 'api/partners';
  private changeCancelationPolicyUrl: string = environment.api + 'api/partners';

  private createCoefficientPolicyUrl: string = environment.api + 'api/partners';
  private getCoefficientPolicyUrl: string = environment.api + 'api/partners';
  private changeCoefficientPolicyUrl: string = environment.api + 'api/partners';

  private createDataPolicyUrl: string = environment.api + 'api/partners';
  private getDataPolicyUrl: string = environment.api + 'api/partners';
  private changeDataPolicyUrl: string = environment.api + 'api/partners';

  private createGeneralPolicyUrl: string = environment.api + 'api/partners';
  private getGeneralPolicyUrl: string = environment.api + 'api/partners';
  private changeGeneralPolicyUrl: string = environment.api + 'api/partners';

  private createCommisionPolicyUrl: string = environment.api + 'api/partners';
  private getCommisionPolicyUrl: string = environment.api + 'api/partners';
  private changeCommisionPolicyUrl: string = environment.api + 'api/partners';

  constructor(private httpClient: HttpClient) { }

  // Cancelation
  createCancelationPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'cancelation-policies']
    );
    return this.httpClient.post<Contract>(this.createCancelationPolicyUrl + params, contract);
  }

  getCancelationPolicy(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'cancelation-policies']
    );
    return this.httpClient.get<Contract[]>(this.getCancelationPolicyUrl + params);
  }

  updateCancelationPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'cancelation-policies', contract.id.toString()]
    );
    return this.httpClient.put<Contract>(this.changeCancelationPolicyUrl + params, contract);
  }

  // Coeficcient
  createCoefficientsPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'coefficient-policies']
    );
    return this.httpClient.post<Contract>(this.createCoefficientPolicyUrl + params, contract);
  }

  getCoefficientsPolicy(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'coefficient-policies']
    );
    return this.httpClient.get<Contract[]>(this.getCoefficientPolicyUrl + params);
  }

  updateCoefficientsPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'coefficient-policies', contract.id.toString()]
    );
    return this.httpClient.put<Contract>(this.changeCoefficientPolicyUrl + params, contract);
  }

  // Data
  createDataPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'data-protection-policies']
    );
    return this.httpClient.post<Contract>(this.createDataPolicyUrl + params, contract);
  }

  getDataPolicy(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'data-protection-policies']
    );
    return this.httpClient.get<Contract[]>(this.getDataPolicyUrl + params);
  }

  updateDataPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'data-protection-policies', contract.id.toString()]
    );
    return this.httpClient.put<Contract>(this.changeDataPolicyUrl + params, contract);
  }


  // General
  createGeneralPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'general-terms-and-conditions']
    );
    return this.httpClient.post<Contract>(this.createGeneralPolicyUrl + params, contract);
  }

  getGeneralPolicy(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'general-terms-and-conditions']
    );
    return this.httpClient.get<Contract[]>(this.getGeneralPolicyUrl + params);
  }

  updateGeneralPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'general-terms-and-conditions', contract.id.toString()]
    );
    return this.httpClient.put<Contract>(this.changeGeneralPolicyUrl + params, contract);
  }


  // Commission
  createCommissionPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'commission-contracts']
    );
    return this.httpClient.post<Contract>(this.createCommisionPolicyUrl + params, contract);
  }

  getCommissionPolicy(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'commission-contracts']
    );
    return this.httpClient.get<Contract[]>(this.getCommisionPolicyUrl + params);
  }

  updateCommissionPolicy(contract: ContractActionRequestModel) {
    const params = RequestHelper.addRESTParams(
      [contract.partnerId.toString(), 'commission-contracts', contract.id.toString()]
    );
    return this.httpClient.put<Contract>(this.changeCommisionPolicyUrl + params, contract);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { Labels } from 'src/config/labels/labels';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { LoggedUser } from 'src/models/DTO/user/LoggedUser';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoginService } from 'src/services/requestServices/V2/login/login.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  public userLogin: FormGroup;
  private errorMsg: string;
  constructor(protected userInfoService : UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected router: Router,
    private partnersService: PartnersService,
    protected loggedinfoservice: LoggedinfoService,
    private imagesService: ImagesService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private loginService: LoginService,
    private infoService: InfoService) {
    super(userInfoService, localStorageService, router);
    this.nextPage = "dashboard";
      this.pageName = 'login';
      this.userLogin = fb.group({
        'userName': ['', [Validators.required]],
        'password': ['', [Validators.required]],
        'rememberMe': [false, [Validators.required]],
      })
      this.loginService.logOut();
   }

  ngOnInit(): void {
  }

  errorMessage() {
    return this.errorMsg;
  }

  moveBack() {
    return;
  }

  validateFields() {
      let fieldName = "";
      Object.keys(this.userLogin.controls).forEach(control => {
        if (!this.userLogin.get(control).valid) {
          fieldName = this.lables(this.pageName)[control];
        }
      });
      if (fieldName != '') {
        this.error = true;
        this.errorMsg = fieldName + this.lables(this.pageName).invalidField;
        return false;
      }
      return true;
  }

  rememberMeChanged(event) {
    if (event.target.checked) {
      this.user.rememberMe = true;
    }
    else {
      this.user.rememberMe = false;
    }
  }

  login() {
    this.user.userName = this.userLogin.get("userName").value;
    this.user.email = this.userLogin.get("userName").value;
    this.user.password = this.userLogin.get("password").value;
    this.user.rememberMe = this.userLogin.get("rememberMe").value;
    this.loginService.loginPartner(this.user).subscribe(tokenInfo => {
      this.localStorageService.setLocalStorageItem(Labels.lsTokenKey, tokenInfo.data.token);
      this.infoService.info().subscribe(partner => {
        this.loggedinfoservice.addNewUser(partner);
        super.moveForward();
      })
    })
  }

  moveToMainSite() {
    window.open('https://shareme.co/', '_blank')
  }


}

export const Successes = {
    imageUpload: {
        bg: "Успешно качихте снимката",
        en: "The image was uploaded successfully"
    },

    imageReorder: {
        bg: "Успешно променихте реда на снимките",
        en: "The images was reordered successfully"
    },

    imageDelete: {
        bg: "Успешно изтрихте снимката",
        en: "The image was deleted successfully"
    },

    productCreation: {
        bg: "Успешно качихте продукт",
        en: "You have created the product successfully" 
    },

    productFinalization: {
        bg: "Успешно качихте продукт",
        en: "You have finalized the creation of the product successfully" 
    },

    variationCreation: {
        bg: "Успешно запазихте вашата вариация",
        en: "You have saved the variation successfully" 
    },

    variationDeletion: {
        bg: "Успешно изтрихте вашата вариация",
        en: "You have deleted the variation successfully" 
    },

    serviceCreation: {
        bg: "Успешно качихте вашата услуга",
        en: "You have created the service successfully" 
    },

    itemStateChange: {
        bg: "Успешно сменихте статуса на вашия артикул",
        en: "You have changed the state of the item successfully" 
    },

    itemArchive: {
        bg: "Успешно архивирахте вашия артикул",
        en: "You have archived the item successfully" 
    },

    itemReturn: {
        bg: "Успешно активирахте вашия артикул",
        en: "You have activated the item successfully" 
    },

    itemDelete: {
        bg: "Успешно изтрихте вашия артикул",
        en: "You have deleted the item successfully" 
    },

    storeCreation: {
        bg: "Успешно качихте вашия магазин",
        en: "You have created the store successfully" 
    },

    storeDelete: {
        bg: "Успешно изтрихте вашия магазин",
        en: "You have deleted the store successfully" 
    },

    updatePartner: {
        bg: "Успешно обновихте информацията за компанията",
        en: "You have updated your company info successfully" 
    },

    updatePartnerInfo: {
        bg: "Успешно обновихте информацията за партньора",
        en: "You have updated your partner info successfully" 
    },

    updateLogo: {
        bg: "Успешно обновихте логото си",
        en: "You have updated your logo successfully" 
    },

    changePassword: {
        bg: "Успешно променихте паролата си",
        en: "You have changed your password successfully" 
    },

    updateTerms: {
        bg: "Успешно подписахте споразуманието",
        en: "You have sign the contract successfully" 
    },

    updateCalendarEntry: {
        bg: "Успешно обновихте календара",
        en: "You have updated the calendar successfully" 
    },

    updateCarrierAddressEntry: {
        bg: "Успешно обновихте мястото за доставка",
        en: "You have updated the carrier address successfuly" 
    },

    confirmedAccount: {
        bg: "Успешно потвърдихте вашата регистрация",
        en: "You have confirmed your registration successfuly" 
    },

    confirmedOrder: {
        bg: "Успешно потвърдихте вашата поръчка",
        en: "You have confirmed your order successfuly" 
    },

    cancledOrder: {
        bg: "Успешно отказахте вашата поръчка",
        en: "You have cancled your order successfuly" 
    },

}
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';
import { TelPrefixes } from '../../../config/labels/telPrefixes';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { CountriesService } from 'src/services/requestServices/countries/countries.service';
import { Country } from 'src/models/DTO/countries/country';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WorkingtimesComponent } from 'src/app/shared/workingtimes/workingtimes.component';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { PhoneNumberUtil } from 'google-libphonenumber';



@Component({
  selector: 'app-companydetailed',
  templateUrl: './companydetailed.component.html',
  styleUrls: ['./companydetailed.component.css']
})
export class CompanydetailedComponent extends BaseComponent implements OnInit {


  public companyDetails: FormGroup;
  public customWorkTimeView : boolean = false;
  private _errorMsg: string;
  private _countries: Country[] = [{name: "България"}] as any;
  filteredOptions: Observable<Country[]>;

  @ViewChild(WorkingtimesComponent)
  private _workingtimesComponent: WorkingtimesComponent;
  
  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private countryService: CountriesService,
    private fb: FormBuilder,
    protected router: Router) {
    super(userinfostoreService, localStorageService, router);
    this.pageName = "companyDetails";
    this.backPage = 'registration/company';
    this.nextPage = 'registration/finish';
    this.companyDetails = fb.group({
      'country': [{value: '', disabled: true}, [Validators.required]],
      'city': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      'address': ['', [Validators.required]],
      'postcode': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.alpha_dash)]],
      'TelPrefix': [TelPrefixes.prefixes[0]],
      'tel': ['', [Validators.required, Validators.maxLength(10), this.validateTell.bind(this)]]
    });
    this.companyDetails.controls.country.setValue("България");


  }

  ngOnInit(): void {
    this.filteredOptions = this.companyDetails.get('country').valueChanges
      .pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }


  
  onChangedCountry(event) {
    const selectedCountry = event.source.value;
    this._countries.forEach(country => {
      if (country.name == selectedCountry) {
        this.user.partnerLocation.countryId = country.id;
      }
    })
  }

  private _filter(value: string): Country[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this._countries.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;
  }

  countries() {
    return this._countries;
  }

  telPrefixes() {
    return TelPrefixes.prefixes;
  }

  getFullTel() {
    return this.getSelectedPrefix() + this.companyDetails.get('tel').value;
  }

  getSelectedPrefix() {
    return this.companyDetails.get('TelPrefix').value.prefix;
  }

  validateFullTel() {
    const tel = this.getFullTel();
    const country = this.companyDetails.get('TelPrefix').value.country;
    return ValidationHelper.telephone.validate(tel, country);  
  }

  validateTell (control: AbstractControl): {[key : string]: boolean} | null {
    const value = control.value.toString();
    const phoneUtils = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtils.parse(value, this.companyDetails.get('TelPrefix').value.country);
      if (phoneUtils.isValidNumberForRegion(number, this.companyDetails.get('TelPrefix').value.country)) {
        return null;
      }
      return {'validateTell': false};
    }
    catch(error) {
      return {'validateTell': false};
    }
  };

  errorMessage() {
    return this._errorMsg;
  }
  
  areCompanyDetailsValid() {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this.companyDetails, this.dashboardLables(this.getPageName()));
    if (!this.validateFullTel()) {
      fieldName = this.lables(this.pageName).tel;
    }
    fieldName = this._workingtimesComponent.validate()
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.lables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  private getCountryIdFromName(name: string) {
    let country = this._countries.find((country) => country.name == name);
    return country.id;
  }

  moveForward() {
    if (!this.areCompanyDetailsValid()) {
      return;
    }

    this.user.partnerLocation.countryId = this.getCountryIdFromName(this.companyDetails.get('country').value);
    this.user.partnerLocation.postalCode = this.companyDetails.get('postcode').value;
    this.user.partnerLocation.cityName = this.companyDetails.get('city').value;
    this.user.partnerLocation.address1 = this.companyDetails.get('address').value;

    // May need to remove them later
    this.user.compCountry = this.companyDetails.get('country').value;
    this.user.comCity = this.companyDetails.get('city').value;
    this.user.compAddress = this.companyDetails.get('address').value;
    this.user.compPostcode = this.companyDetails.get('postcode').value;
    const phoneUtils = PhoneNumberUtil.getInstance();
    const number = phoneUtils.parse(this.companyDetails.get('tel').value, this.companyDetails.get('TelPrefix').value.country);
    this.user.compTelephone = number.getNationalNumber().toString();
    this.user.wordays = this._workingtimesComponent.prepareWorkingTime();
    super.moveForward();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Image } from 'src/models/DTO/V2/image/Image';
import { DeleteImage } from 'src/models/requestModels/V2/image/DeleteImage';
import { ImageCollection } from 'src/models/DTO/V2/image/ImageCollection';
import { CreateNewBase64Image } from 'src/models/requestModels/V2/image/Image';
import { ImageReorderRequestModel } from 'src/models/requestModels/V2/image/ImageReorderRequestModel';



@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private imageURL : string = environment.api + "media";
  private _imageReorderURL : string = environment.api + 'media/reorder'

  constructor(private httpClient: HttpClient) { }

  createNewImage(newImageModel: CreateNewBase64Image) {
    return this.httpClient.post<Image>(this.imageURL, newImageModel);
  }

  getImagesPerProduct(type: string, itemUUID: string, collection: string = 'images') {
    const queryParam = '/' + [type, itemUUID, collection].join('/');
    return this.httpClient.get<ImageCollection>(this.imageURL + queryParam);
  }

  deleteImage(imageToDelete: DeleteImage) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: imageToDelete
    }
    return this.httpClient.delete<any>(this.imageURL, options)
  }

  reorderImages(imageReorderRequestModel: ImageReorderRequestModel) {
    return this.httpClient.post<any>(this._imageReorderURL, imageReorderRequestModel)
  }
}

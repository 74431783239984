import { Routes } from "@angular/router";
import { OverviewComponent } from './../../../app/stripe/overview/overview.component';
import { DetailsComponent } from './../../../app/stripe/details/details.component';


export const STRIPE_ROUTES: Routes = [
      {
        path: 'overview/:uuid', component: OverviewComponent
      },
      {  
        path: 'details/:uuid', component: DetailsComponent
      }
];
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DASHBOARD_LABLES } from 'src/config/labels/dashboard';
import { Labels } from 'src/config/labels/labels';
import { Country } from 'src/models/DTO/countries/country';
import { City } from 'src/models/DTO/cities/city';
import { CreateStoreRequestModel } from 'src/models/requestModels/stores/createStoreRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { CountriesService } from 'src/services/requestServices/countries/countries.service';
import { CitiesService } from 'src/services/requestServices/cities/cities.service';
import { Store } from 'src/models/DTO/stores/store';
import { LoggedUser } from 'src/models/DTO/user/LoggedUser';
import { environment } from 'src/environments/environment';
import { YesAndNo } from 'src/config/labels/yesNoOptions';
import { CreateWorkingTimeRequestModel } from 'src/models/requestModels/workingTime/workingTime';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { WorkingtimesComponent } from '../workingtimes/workingtimes.component';
import { WorkingTime } from 'src/models/DTO/workingTime/workingTime';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';

@Component({
  selector: 'app-storeform',
  templateUrl: './storeform.component.html',
  styleUrls: ['./storeform.component.css']
})
export class StoreformComponent implements OnInit {

  store: FormGroup;
  private error: boolean = false;
  private _errorMsgs: string = "";
  public pagename: string = "storeForm";
  private _language: string;
  public customWorkTimeView : boolean = false;
  private _countries: Country[] = [] as any;
  filteredOptions: Observable<Country[]>;
  private _cities: City[] = [] as any;
  filteredCityOptions: Observable<City[]>;
  private _currentStore: Store;
  private _loggedUser: LoggedUser;

  @ViewChild(WorkingtimesComponent)
  private _workingtimesComponent: WorkingtimesComponent;

  constructor(
    private FB: FormBuilder,
    private router: Router,
    private localStorageService: LocalstorageserviceService,
    private countryService: CountriesService,
    private cityService: CitiesService
  ) { 
    this.store = FB.group({
      name: ['', [Validators.required]],
      //code: ['', [Validators.required]],
      country: [{value: '', disabled: true}, [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      post: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      pickUp: [true, [Validators.required]],
    });
    this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);  
    this.countryService.getCountries().subscribe(countries => {
      this._countries = countries;
      this.store.controls.country.setValue("България");
    });
    this.cityService.getAllCities().subscribe((cities) => {
      this._cities = cities;
    })
  }

  ngOnInit(): void {
    this.filteredOptions = this.store.get('country').valueChanges
      .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.filteredCityOptions = this.store.get('city').valueChanges
      .pipe(
      startWith(''),
      map(value => this._filterCity(value))
    );
  }

  private _filter(value: string): Country[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this._countries.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;
  }

  private _filterCity(value: string): City[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this._cities.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;
  }

  backToStores() {
    this.router.navigate(['dashboard/stores']);
  }

  set loggedUser(loggedUser: LoggedUser){
    this._loggedUser = loggedUser;
  }

  get yesAndNo() {
    return YesAndNo.options[this._language];
  }

  shouldShowError() {
    return this.error;
  }

  errorMessage() {
    return this._errorMsgs;
  }

  get dashboardLables() {
    return DASHBOARD_LABLES[this._language][this.pagename];
  }

  public validation() {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this.store, this.dashboardLables);
    fieldName = this._workingtimesComponent.validate();
    if (fieldName != '') {
      this.error = true;
      this._errorMsgs = fieldName + this.dashboardLables.invalidField;
      return false;
    }
    return true;
  }

  onCountryChange(event) {
    const country = event.option.value;
    const countryId = this.getCountryIdFromName(country);
    this.cityService.getCities(countryId).subscribe((cities) => {
      this.store.controls.city.setValue('');
      this._cities = cities;
    })
  }

  private getCountryIdFromName(name: string) {
    let country = this._countries.find((country) => country.name == name);
    return country.id;
  }

  setStore(currentStore: Store, workingTime: WorkingTime) {
    this._currentStore = currentStore;
    this.store.controls.name.setValue(this._currentStore.name);
    //this.store.controls.code.setValue(this._currentStore.code);
    //this.store.controls.country.setValue(this._currentStore.countryId);
    this.store.controls.city.setValue(this._currentStore.cityName);
    this.store.controls.address.setValue(this._currentStore.address1);
    this.store.controls.post.setValue(this._currentStore.postalCode);
    this.store.controls.phone.setValue(this._currentStore.phone);
    this.store.controls.pickUp.setValue(this._currentStore.pickupLocation);
    //this._workingtimesComponent.setValues(workingTime);
  }

  prepareRequestModel() {
    let requestModel: CreateStoreRequestModel = {} as any;
    requestModel.name = this.store.controls.name.value;
    requestModel.address1 = this.store.controls.address.value;
    requestModel.countryId = this.getCountryIdFromName(this.store.controls.country.value);
    requestModel.cityName = this.store.controls.city.value;
    requestModel.phone = this.store.controls.phone.value;
    requestModel.postalCode = this.store.controls.post.value;
    requestModel.name = this.store.controls.name.value;
    requestModel.partnerId = this._loggedUser.partner.id;
    requestModel.locationRole = environment.storeRole;
    requestModel.id = (this._currentStore) ? this._currentStore.id : undefined;
    requestModel.code = (this._currentStore) ? this._currentStore.code : Core.getRandomString(24);
    requestModel.pickupLocation = this.store.controls.pickUp.value;
    return requestModel;
    //requestModel.code = this.store.controls.name.value;
    //requestModel.pickUp = this.store.controls.name.value;
  }

  returnWorkingTime() : CreateWorkingTimeRequestModel {
    return {} as any;
    //return this._workingtimesComponent.prepareWorkingTime();
  }
}

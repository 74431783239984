import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { CreateLocationRequestModel } from 'src/models/requestModels/locations/CreateLocationRequestModel'
import { CreateStoreRequestModel } from 'src/models/requestModels/stores/createStoreRequestModel';
import { Store } from 'src/models/DTO/stores/store';
import { Location } from 'src/models/DTO/locations/location';


@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  private createPartnerLocationUrl : string = environment.api + "api/partners";
  private createUserLocationUrl : string = environment.api + "api/users";
  private getUserLocationURL: string = environment.api + "api/users"
  private getPartnerLocationURL: string = environment.api + "api/partners"
  private getStoresUrl : string = environment.api + "api/partners";
  private createStoresUrl : string = environment.api + "api/partners";
  private getStoreByIdUrl: string = environment.api + "api/partner-locations";
  private updateStoreUrl: string = environment.api + "api/partners";
  private deleteStoreUrl: string = environment.api + "api/partners";


  constructor(private httpClient: HttpClient) { }

  createPartnerLocation(location: CreateLocationRequestModel) {
    const params = RequestHelper.addRESTParams(
      [location.partnerId.toString(), 'locations']
    );
    return this.httpClient.post<Location>(this.createPartnerLocationUrl + params, location);
  }

  createUserLocation(location: CreateLocationRequestModel) {
    const params = RequestHelper.addRESTParams(
      [location.userId.toString(), 'locations']
    );
    return this.httpClient.post<Location>(this.createUserLocationUrl + params, location);
  }

  createStore(store: CreateStoreRequestModel) {
    const params = RequestHelper.addRESTParams(
      [store.partnerId.toString(), 'locations']
    );
    return this.httpClient.post<Store>(this.createStoresUrl + params, store);
  }

  getLocationForUserByUserId(userId: number) {
    const params = RequestHelper.addRESTParams(
      [userId.toString(), 'locations']
    );
    return this.httpClient.get<Location>(this.getUserLocationURL + params);
  }

  getLocationForPartnerByPartnerId(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'locations']
    );
    return this.httpClient.get<Location>(this.getPartnerLocationURL + params);
  }

  getStoresForPartner(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'locations']
    ) + 
    RequestHelper.addMultipleParams(
     [
      {
        key: 'locationRole.equals',
        value: environment.storeRole
      }
     ]
    );
    return this.httpClient.get<Store[]>(this.getStoresUrl + params);
  }

  getStoreById(storeId: number) {
    const params = RequestHelper.addRESTParams(
      [storeId.toString()]
    ) 
    return this.httpClient.get<Store>(this.getStoreByIdUrl + params);
  }

  updateStore(store: CreateStoreRequestModel) {
    const params = RequestHelper.addRESTParams(
      [store.partnerId.toString(), 'locations', store.id.toString()]
    );
    return this.httpClient.put(this.updateStoreUrl + params, store);
  }

  deleteStore(storeId: number, partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'locations', storeId.toString()]
    );
    return this.httpClient.delete(this.deleteStoreUrl + params);
  }
}

<app-loading *ngIf="this.isLoading"></app-loading>
<button type="button" class="btn btn-outline-light cancel-gray cancel-edit-order" (click)="this.goToOrders()">Назад</button>
<div class="dashboard-body">
    <div class="top-white container" style="margin-top: 70px;">
        <div class="row topfeatures">
            <div [class]="this.order?.data?.invoice_address ? 'col-sm-3 column' :  'col-sm-6 column'" [style]="this.order?.data?.invoice_address ? 'font-size: 10pt' :  ''">
                <h5 style="font-weight: bold;">Поръчка</h5>
                <div class="row">
                    <div class="col-sm-4 column labels">
                        Дата:
                    </div>
                    <div class="col-sm-8 column">
                        {{ this.convertDate(this.order?.data?.created_at).date + ' '  +  this.convertDate(this.order?.data?.created_at).time }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Поръчка:
                    </div>
                    <div class="col-sm-8 column">
                        {{ this.order?.data?.uuid }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Тип:
                    </div>
                    <div class="col-sm-8 column">
                        {{ this.checkGroupType(this.order?.data?.group) }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Статус:
                    </div>
                    <div class="col-sm-8 column" [style]="'color: ' + this.orderStatusColor + ';'">
                        {{ this.convertOrderStatus(order?.data?.state) }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Товар. №:
                    </div>
                    <div class="col-sm-8 column">
                        {{ (this.order?.data?.shipment[0]?.id && this.order?.data?.shipment[0].carrier_shipment_identifier) ? this.order?.data?.shipment[0].carrier_shipment_identifier : '-' }}
                    </div>
                    <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-4 column labels">
                        Такса отказ: 
                    </div>
                    <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-8 column">
                        {{ this.cancellationFee }}
                    </div>
                    <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-4 column labels">
                        Дата на отказ:
                    </div>
                    <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-8 column">
                        {{  this.convertDate(this.order?.data?.updated_at).date + ' '  +  this.convertDate(this.order?.data?.updated_at).time }}
                    </div>
                    <div *ngIf="this.order?.data?.state == 'returned'" class="col-sm-4 column labels">
                        Такса 5%:
                    </div>
                    <div *ngIf="this.order?.data?.state == 'returned'" class="col-sm-8 column">
                        {{ this.order?.data?.total_tax_amount }}
                    </div>
                    <div *ngIf="this.order?.data?.state == 'returned'" class="col-sm-4 column labels">
                        Причина:
                    </div>
                    <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-8 column">
                        {{ this.order?.data?.refund_reason }}
                    </div>
                    <div *ngIf="this.order?.data?.state == 'returned'" class="col-sm-4 column labels">
                        Възс. такса:
                    </div>
                    <!-- <div *ngIf="this.order?.data?.state == 'cancelled'" class="col-sm-8 column">
                        {{ this.order?.data?.total_tax_amount }}
                    </div> -->
                </div>
            </div>
            <div class="col-sm-3 column" style="font-size: 10pt;" *ngIf="this.order?.data?.invoice_address">
                <h5 style="font-weight: bold;">Данни за фактура</h5>
                <div class="row" *ngIf="this.order?.data?.invoice_address?.type.label == 'USER_INVOICE_ADDRESS' 
                                        || this.order?.data?.invoice_address?.type.label ==  'USER_SHIPPING_TYPE_CONTACT'
                                        || this.order?.data?.invoice_address?.type.label ==  'USER_INVOICE_TYPE_CONTACT'
                                        || this.order?.data?.invoice_address?.type.label == 'USER_SHIPPING_OFFICE_TYPE_CONTACT'">
                    <div class="col-sm-4 column labels">
                        Име:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.name }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Телефон:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.mobile_phone_1_e164 }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Адрес:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.buildAddressDetails(this.order?.data?.invoice_address) }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Град:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.city }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Пощенски код:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.post_code != '0000' ? this.order?.data?.invoice_address?.post_code : '-' }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Платежен метод:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                         {{ this.paymentType(this.order?.data?.paymentMethod.code) }}
                    </div>
                </div>
                <div class="row" *ngIf="this.order?.data?.invoice_address?.type.label == 'COMPANY_INVOICE_ADDRESS' 
                                        || this.order?.data?.invoice_address?.type.label == 'USER_COMPANY_INVOICE_TYPE_CONTACT'">
                    <div class="col-sm-4 column labels">
                        Фирма:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.company_name }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        ЕИК:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.company_number }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        ДДС №:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.company_vat_number }}
    
                    </div>

                    <div class="col-sm-4 column labels">
                        Адрес:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.address_line_2 + ' '
                            + this.order?.data?.invoice_address?.city + ' '
                            + this.order?.data?.invoice_address?.post_code }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        МОЛ:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.company_owner }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Тел.:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.invoice_address?.mobile_phone_1_e164 }}
    
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Платежен метод:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.paymentType(this.order?.data?.paymentMethod.code) }}
                    </div>
                </div>
            </div>
            <div class="col-sm-3 column" style="font-size: 10pt;" *ngIf="this.order?.data?.delivery_address">
                <h5 style="font-weight: bold;">Данни за доставка</h5>
                <div class="row">
                    <div class="col-sm-4 column labels">
                        Име :
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.delivery_address?.name }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Телефон:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.delivery_address?.mobile_phone_1_e164 }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Метод на доставка:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.convertShipmentMethod(this.order?.data?.delivery_method) }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Адрес:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.buildAddressDetails(this.order?.data?.delivery_address) }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Град:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.delivery_address?.city }}
                    </div>
    
                    <div class="col-sm-4 column labels">
                        Пощенски код:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.delivery_address?.post_code != '0000' ? this.order?.data?.delivery_address?.post_code : '-' }}
                    </div>
                </div>
            </div>
            <div [class]="this.order?.data?.invoice_address ? 'col-sm-3 column' :  'col-sm-6 column'" [style]="this.order?.data?.invoice_address ? 'font-size: 10pt' :  ''">
                <h5 style="font-weight: bold;">&nbsp;</h5>
                <div *ngIf="this.order?.data?.state == 'returned'" class="row">
                    <div class="col-sm-4 column labels">
                        Сума за възстановяване:
                    </div>
                    <div [class]="this.classForSensitiveInfo"  data-bs-toggle="tooltip" [ngbTooltip]="(this.classForSensitiveInfo.includes('blurry')) ? 'Данните ще бъдат видими след потвърждаване на поръчката.' : ''">
                        {{ this.order?.data?.amount_refunded + ' лв' }}
                    </div>
                    <div class="col-sm-6 column bolded" style="font-weight: bold">
                        Възстановена сума:
                    </div>
                    <div class="col-sm-6 column bolded" style="font-weight: bold">
                        {{ this.order?.data?.amount_refunded + ' лв' }}
                    </div>
                    <button style="margin: 5px; width: 148px; height: 45px; display: block; margin-bottom: 10px;" class="btn btn-primary" (click)="this.refund()">Възстанови чрез ShareMe</button>
                    <p style="color: #75809C">*Ако поръчката е платена с Наложен платеж, трябва да възстановите сумата на клиента, чрез вашата банкова сметка.</p>
                </div>
                <div *ngIf="this.order?.data?.state != 'returned'" class="row">
                    <div class="col-sm-6 column labels marginBottom">
                        Ваучер:
                    </div>
                    <div class="col-sm-6 column marginBottom">
                        {{ this.priceConvertor(this.order?.data?.voucher_amount || 0) + ' лв' }}
                    </div>
    
                    <div class="col-sm-6 column labels marginBottom">
                        Междинна сума:
                    </div>
                    <div class="col-sm-6 column marginBottom">
                        {{ this.priceConvertor(this.order?.data?.total_gross) + ' лв' }}
                    </div>
    
                    <div class="col-sm-6 column labels marginBottom">
                        Доставка:
                    </div>
                    <div class="col-sm-6 column marginBottom">
                        {{ this.priceConvertor(this.order?.data?.shipment_cost) + ' лв' }}
                    </div>
                    <hr style=" margin: auto; color: #99A3BF; width: 100%; margin: auto; margin-top: 10px; margin-bottom: 20px;">
                    <div class="col-sm-6 column bolded"  >
                        Общо:
                    </div>
                    <div class="col-sm-6 column bolded">
                        {{ this.priceConvertor(this.orderTotal) + ' лв' }}
                    </div>
    
                    <div style="margin-top: 10px;" class="col-sm-12 column" *ngIf="this.orderRightPanel == 'to_confirm'">
                        <button style="margin: 5px; width: 148px; height: 45px; display: block;  margin: auto;" class="btn btn-primary" (click)="this.confirmOrder()">Потвърди</button>
                    </div>
    
                    <div style="margin-top: 10px;"  class="col-sm-12 column" *ngIf="this.orderRightPanel == 'normal'">
                        <div *ngIf="!this.order?.data?.shipment?.uuid" style="margin-bottom: 10px;">
                            <p style="display: inline-block; width: fit-content; margin-right: 10px;">Брой пакети<span style="color: red;">*</span>:</p> 
                            <input  type="number" class="form-control" [value]="this.packegeNumber" (change)="this.onPackageChange($event)">
                            <small id="packageInfo" class="text-muted" data-bs-toggle="tooltip" title="Въведи стойност между 1 и 10"></small>
                        </div>
                        <button style="margin: 5px; width: 100%; font-size: 9pt; height: 45px; display: block; margin: auto; margin-bottom: 10px; margin-top: 10px;" class="btn btn-primary" (click)="this.generateShipment()">Генерирай товарителница</button>
                        <button style="margin: 5px; width: 100%; font-size: 9pt; height: 45px; display: block; margin: auto; margin-bottom: 10px; margin-top: 10px;" type="button" class="btn btn-outline-dark" (click)="this.printStockReciept()">Отпечатай стокова разписка</button>
                    </div>
    
                    <div style="margin-top: 10px;"  class="col-sm-12 column" *ngIf="this.orderRightPanel == 'completed'">
                        <div class="row">
                            <div class="col-sm-12" style="padding-left: 5px; padding-right: 5px;">
                                <button style="width: 100%;  font-size: 9pt; height: 45px; display: block;  margin: auto;" type="button" class="btn btn-outline-dark" (click)="this.printWayBill()">Товарителница</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="padding-left: 5px; padding-right: 5px;">
                                <button style="margin: 5px; width: 100%; font-size: 9pt; height: 45px; display: block; margin: auto; margin-bottom: 10px; margin-top: 10px;" type="button" class="btn btn-outline-dark" (click)="this.printStockReciept()">Отпечатай стокова разписка</button>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 10px;"  class="col-sm-12 column" *ngIf="this.orderRightPanel == 'with_shipment'">
                        <div class="row" *ngIf="this.shouldShowPickUpBtn(this.order)">
                            <div class="col-sm-6" style="padding-left: 5px; padding-right: 5px;">
                                <button style="width: 100%;  font-size: 9pt;  height: 45px; display: block; margin: auto;" class="btn btn-primary" (click)="this.generateShipment()">Заяви за вземане</button>
                            </div>
                            <div class="col-sm-6" style="padding-left: 5px; padding-right: 5px;">
                                <button style="width: 100%;  font-size: 9pt; height: 45px; display: block; margin: auto;" type="button" class="btn btn-outline-dark" (click)="this.printWayBill()">Товарителница</button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!this.shouldShowPickUpBtn(this.order)">
                            <div class="col-sm-12" style="padding-left: 5px; padding-right: 5px;">
                                <button style="width: 100%;  font-size: 9pt; height: 45px; display: block; margin: auto;" type="button" class="btn btn-primary" (click)="this.printWayBill()">Товарителница</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="padding-left: 5px; padding-right: 5px;">
                                <button style="margin: 5px; width: 100%; font-size: 9pt; height: 45px; display: block; margin: auto; margin-bottom: 10px; margin-top: 10px;" type="button" class="btn btn-outline-dark" (click)="this.printStockReciept()">Отпечатай стокова разписка</button>
                            </div>
                        </div>
                    </div>
    
                    <div style="margin-top: 10px;"  class="col-sm-12 column" *ngIf="this.orderRightPanel == 'cancelled'">
                        <div class="row">
                            <div class="col-sm-12" style="padding-left: 5px; padding-right: 5px;">
                                <button style="margin: 5px; width: 100%; font-size: 9pt; height: 45px; display: block; margin: auto; margin-bottom: 10px; margin-top: 10px;" type="button" class="btn btn-outline-dark" (click)="this.printStockReciept()">Отпечатай стокова разписка</button>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
        <div *ngIf="ordersDataSource.length" class="row topfeatures" style="margin-top: 10px;">
            <table #productTable mat-table  multiTemplateDataRows  [dataSource]="ordersDataSource" matSort  class="mat-elevation-z8 products" style="text-align: center; width: -webkit-fill-available;">
                <ng-container matColumnDef="Product">
                  <th mat-header-cell *matHeaderCellDef style="width: 450px;"> {{this.dashboardLables(this.getPageName()).image}} </th>
                  <td mat-cell *matCellDef="let element" style="vertical-align: middle; width: 450px;">
                    <span *ngIf="element.editable">
                        <img [src]="element.imageSource" alt="" style="width: 170px !important; border-top: unset; vertical-align: top;" class="product-image-field">
                        <div class="p-display-block" style="display: inline-block; margin-left: 7px">
                            <p style="font-weight: bold;  font-size: 18px; text-wrap: wrap; width: 230px;">
                                {{ element.sku.product.name }}
                            </p>
                            <p style="font-size: 16px;">
                                <span style="color: #75809C">SKU:</span> {{ element.sku.sku }} <br> <span style="color: #75809C">EAN:</span> {{ element.sku.product.organisation.number }}
                            </p>
                            <p style="font-size: 16px;" *ngIf="this.orderVariationsIfAny(element)">
                                <span *ngFor="let orderOption of this.orderVariationsIfAny(element)">
                                    <span style="color: #75809C">{{ orderOption.name }}: </span> {{ orderOption.value }} <br>
                                </span>
                            </p>
                        </div>
                    </span>
                    <p *ngIf="!element.editable" style="font-weight: bold; text-align: left;">
                        <span style="position: relative; left: 170px"> {{ element.sku.product.name }} </span>
                    </p>
                 </td>
                </ng-container>
                <ng-container matColumnDef="Quantity">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 70px;">{{this.dashboardLables(this.getPageName()).qty}}</th>
                  <td mat-cell *matCellDef="let element" class="product-name-field"> {{ (element.editable) ? element.quantity + ' бр.' : '-'}} </td>
                </ng-container>
                <ng-container matColumnDef="PricePerPiece">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header><span>{{this.dashboardLables(this.getPageName()).qtypp}}</span>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Цената за 1бр. от продукта"></small></th>
                  <td mat-cell *matCellDef="let element" class="product-name-field"> {{ (element.editable) ?  this.priceConvertor(this.getPriceForFirstRange(element)) + ' лв.' : '-'}} </td>
    
                </ng-container>
                <ng-container matColumnDef="Net">
                  <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).pricenet}} </th>
                  <td mat-cell *matCellDef="let element" class="product-name-field"> {{this.priceConvertor(element.single_net) + ' лв.'}} </td>
                </ng-container>
                <ng-container matColumnDef="Vat">
                    <th mat-header-cell *matHeaderCellDef style="width: 70px;"> {{this.dashboardLables(this.getPageName()).vat}} </th>
                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{ this.convertVatPercentage(element) + '%' }} </td>
                </ng-container>
    
                <ng-container matColumnDef="Price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 70px;"> {{this.dashboardLables(this.getPageName()).pricegross}} </th>
                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{ this.priceConvertor(element.single_gross) + ' лв.'}} </td>
                </ng-container>
    
                <ng-container matColumnDef="Total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: right !important; width: 130px; "> {{this.dashboardLables(this.getPageName()).sum}} </th>
                    <td mat-cell *matCellDef="let element" class="product-name-field" style="text-align: right !important;"> <span>{{ this.priceConvertor(this.getLineTotalAmount(element)) + ' лв.' }}</span> </td>
                </ng-container>
    
                <!-- <ng-container  *ngIf="this.order?.data?.state == 'pending'"  matColumnDef="action"> -->
                <ng-container    matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element" class="product-name-field" style="max-width: 160px; width: 150px">
                        <button *ngIf="element.editable && this.order?.data?.state != 'cancelled' && this.order?.data?.state != 'confirmed'" style="margin: 5px; width: 148px; height: 45px; display: block; margin: auto; margin-bottom: 10px; color: #E53E3E;" type="button" class="btn btn-outline-light cancel" (click)="this.cancelOrderLine(element)">Откажи</button>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForProductTable"></tr>
                <tr  mat-row *matRowDef="let element; columns: displayedColumnsForProductTable;">
            </tr>
            </table>
    
            <div class="col-sm-6 column p-display-block" style="margin-top: 30px;">
                <p>Междина сума:</p>
                <p>Доставка (с ДДС):</p>
                <p style="font-weight: bold;">ОБЩО:</p>
            </div>
            <div class="col-sm-6 column p-display-block" style="text-align: center; margin-top: 30px;">
                <div style="width: fit-content; position: relative; right: 144px; float: right;">
                    <p style="width: fit-content; ">{{ this.priceConvertor(this.order?.data?.total_gross) + ' лв'}} </p>
                    <p style="width: fit-content; ">{{ this.priceConvertor(this.order?.data?.shipment_cost) + ' лв'}} </p>
                    <p style="font-weight: bold; width: fit-content;">{{ this.priceConvertor(this.orderTotal) + ' лв'}} </p>
                </div>
            </div>
    
        </div>
    </div>

    <div style="margin-bottom: 10px;" class="bottom-white container" *ngIf="this.returnedOrdersDataSource.length">
        <h3 style="color: #E53E3E; font-weight: 500;">Отказани продукти</h3>
        <div *ngIf="this.returnedOrdersDataSource.length" class="row topfeatures">

            <table #productTable mat-table  multiTemplateDataRows  [dataSource]="returnedOrdersDataSource" matSort  class="mat-elevation-z8 products" style="text-align: center; width: -webkit-fill-available;">
                <ng-container matColumnDef="Product">
                    <th mat-header-cell *matHeaderCellDef  style="width: 450px;"> {{this.dashboardLables(this.getPageName()).image}} </th>
                    <td mat-cell *matCellDef="let element" style="vertical-align: middle; width: 450px;">
                      <span *ngIf="element.editable">
                          <img [src]="element.imageSource" alt="" style="width: 170px !important; border-top: unset; vertical-align: top;" class="product-image-field">
                          <div class="p-display-block" style="display: inline-block; margin-left: 7px">
                              <p style="font-weight: bold;  font-size: 18px; text-wrap: wrap; width: 230px;">
                                  {{ element.sku.product.name }}
                              </p>
                              <p style="font-size: 16px;">
                                  <span style="color: #75809C">SKU:</span> {{ element.sku.sku }} <br> <span style="color: #75809C">EAN:</span> {{ element.sku.product.organisation.number }}
                              </p>
                              <p style="font-size: 16px;" *ngIf="this.orderVariationsIfAny(element)">
                                  <span *ngFor="let orderOption of this.orderVariationsIfAny(element)">
                                      <span style="color: #75809C">{{ orderOption.name }}: </span> {{ orderOption.value }} <br>
                                  </span>
                              </p>
                          </div>
                      </span>
                      <p *ngIf="!element.editable" style="font-weight: bold; text-align: left;">
                          <span style="position: relative; left: 170px"> {{ element.sku.product.name }} </span>
                      </p>
                   </td>
                  </ng-container>
                  <ng-container matColumnDef="Quantity">
                    <th mat-header-cell *matHeaderCellDef style="width: 70px;" mat-sort-header>{{this.dashboardLables(this.getPageName()).qty}}</th>
                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{ (element.editable) ? element.quantity + ' бр.' : '-'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="PricePerPiece">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 130px;"><span>{{this.dashboardLables(this.getPageName()).qtypp}}</span>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Цената за 1бр. от продукта"></small></th>
                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{ (element.editable) ?  this.priceConvertor(this.getPriceForFirstRange(element)) + ' лв.' : '-' }} </td>
      
                  </ng-container>
                  <ng-container matColumnDef="Net">
                    <th mat-header-cell *matHeaderCellDef style="width: 130px;"> {{this.dashboardLables(this.getPageName()).pricenet}} </th>
                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{this.priceConvertor(element.single_net) + ' лв.'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Vat">
                      <th mat-header-cell *matHeaderCellDef style="width: 70px;"> {{this.dashboardLables(this.getPageName()).vat}} </th>
                      <td mat-cell *matCellDef="let element" class="product-name-field"> {{ this.convertVatPercentage(element) + '%' }} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="Price">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 130px;"> {{this.dashboardLables(this.getPageName()).pricegross}} </th>
                      <td mat-cell *matCellDef="let element" class="product-name-field"> {{this.priceConvertor(element.single_gross) + ' лв.'}} </td>
                  </ng-container>
      
                  <ng-container matColumnDef="Total">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 70px;"> {{this.dashboardLables(this.getPageName()).sum}} </th>
                      <td mat-cell *matCellDef="let element" class="product-name-field"> {{(element.editable) ? this.priceConvertor(this.getReturnedTotalAmount(element)) + ' лв.' : '-'}} </td>
                  </ng-container>
      
                  <!-- <ng-container  *ngIf="this.order?.data?.state == 'pending'"  matColumnDef="action"> -->
                  <ng-container    matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 150px; width: 150px"> </th>
                      <td mat-cell *matCellDef="let element" class="product-name-field">
                          <!-- <button *ngIf="element.editable" style="margin: 5px; width: 148px; height: 45px; display: block; margin-bottom: 10px; color: #0A69EB;" type="button" class="btn btn-outline-dark cancel" (click)="this.cancelOrderLine(element)">Откажи</button> -->
                      </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsForCancledProductTable"></tr>
                  <tr  mat-row *matRowDef="let element; columns: displayedColumnsForCancledProductTable;">
              </tr>
            </table>
        </div>
    </div>
</div>


<ng-template #cancelWindow let-modal>
    <div style="text-align: center; padding: 30px;">
        <h4>Желаете ли да откажете продукта?</h4>
        <h6 style="color: #99A3BF; margin-top: 30px; margin-bottom: 30px;">При отказването на продукт {{this.selectedOrderLine?.sku.product.name }} няма да бъде начислена такса "Отказ". Ще бъде отказан само 1бр. от продукта.</h6>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="border-radius: 10px; color: #E53E3E; background-color: white; border: 1px solid #B6BFD6;  width: 45%; margin-right: 10px;" mat-button (click)="this.cancel()">Откажи продукт </button>
            <button class="btn btn-primary" style="border-radius: 10px; width: 45%;" mat-button (click)="modal.close()" cdkFocusInitial>Не сега</button>
          </div>
    </div>
</ng-template>

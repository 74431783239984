<app-loading *ngIf="this.isLoading"></app-loading>
<div class="mask" (click)="this.clicked = true" *ngIf="this.totalProducts == 0 && !this.isLoading && !this.clicked"></div>

<div *ngIf="!this.isLoading" class="container dashboard-body" style="padding-top: 40px;">
    <div class="row topfeatures">
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).revenue + ' (' + this.loggedPartner?.data?.totals.data.newOrdersNumber + ')'}}</h5>
                    <div>
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.newOrdersTotalAmount).lev.toLocaleString()    }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.newOrdersTotalAmount).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;" href="#" class="btn revenue"></a>
                    </div>
                </div>
              </div>
        </div>
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).balance}}</h5>
                    <div >
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.monthRevenue).lev.toLocaleString()  }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.monthRevenue).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;" href="#" class="btn balance"></a>  
                    </div>
                </div>
              </div>
        </div>
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).bankSave}}</h5>
                    <div >
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.yearRevenue).lev.toLocaleString()  }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.yearRevenue).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;" href="#" class="btn wallet"></a>  
                    </div>
                </div>
              </div>
        </div>
    </div>
    <ngb-alert  [dismissible]="false" style="margin: 15px; background-color: #FDF5DB !important; color: #20213D !important; margin-top: 10px;">
        <small class="alertIcon"></small> {{this.dashboardLables(this.getPageName()).productAlertInfo}}
    </ngb-alert>
    <div class="row" style="margin: 0 !important; padding: 15px;">
        <div class='perPagePanel' style="float: right;">
            <label for="optionPerPage" style="color: #75809C;">Покажи: </label>
            <mat-form-field style="margin: 5px; height: 30px; width: 140px;">
                <mat-select [(value)]="this.numberPerPageValue" (selectionChange)="this.chooseNumberPerPage($event)" disableOptionCentering id="optionPerPage"  panelClass="selection-panel" class="multy-select-search">
                    <mat-option  [value]="5"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 5" >  <span style="margin: 20px;">{{5 + " " + this.dashboardLables(this.getPageName()).productsLabel}}</span></mat-option>
                    <mat-option [value]="10"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 10" > <span style="margin: 20px;">{{10 + " " + this.dashboardLables(this.getPageName()).productsLabel}}</span></mat-option>
                    <mat-option [value]="20"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 20" > <span style="margin: 20px;">{{20 + " " + this.dashboardLables(this.getPageName()).productsLabel}}</span></mat-option>
                    <mat-option [value]="-1"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == -1" > <span style="margin: 20px;">{{this.dashboardLables(this.getPageName()).all}}</span></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="home-tab" (click)="this.resetPage()" data-bs-toggle="tab" href="#products" role="tab" aria-controls="products" aria-selected="true">{{this.dashboardLables(this.getPageName()).products}}</a>
            </li>
            <!-- <li class="nav-item" role="presentation" style="display: none;">
              <a class="nav-link" id="profile-tab" (click)="this.resetPage()" data-bs-toggle="tab" href="#services" role="tab" aria-controls="services" aria-selected="false">{{this.dashboardLables(this.getPageName()).services}}</a>
            </li> -->
        </ul>
        <div class="tab-content" id="myTabContent" style="padding: 0; width: 100%;">

            <!-- ----------------------
                PRODUCTS
            ---------------------- -->

            <div class="tab-pane fade show active" id="products" style="padding: 0;" role="tabpanel" aria-labelledby="home-tab">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12" style="padding: 0;">
                            <ul style="float: left;" class="nav nav-tabs products-nav" id="myTab2" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <a [class]=" (this.state == 'active') ? 'nav-link active' : 'nav-link'" id="home-tab2" (click)="this.changeStateFilter('active')" data-bs-toggle="tab" href="#inventory" role="tab" aria-controls="inventory" aria-selected="true">{{this.dashboardLables(this.getPageName()).inventory + this.totalActive}}</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a style="border-left: 1.5px solid #0A69EB; border-right: 1.5px solid #0A69EB;" [class]="(this.state == 'draft') ? 'nav-link active' : 'nav-link'" id="drafts-tab" (click)="this.changeStateFilter('draft')" data-bs-toggle="tab" href="#draft" role="tab" aria-controls="inventory" aria-selected="false">{{this.dashboardLables(this.getPageName()).draft + this.totalDrafts}}</a>
                                  </li>
                                <li class="nav-item" role="presentation">
                                  <a [class]="(this.state == 'archived') ? 'nav-link active' : 'nav-link'" id="profile-tab2" (click)="this.changeStateFilter('archived')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">{{this.dashboardLables(this.getPageName()).archive + this.totalArchived}}</a>
                                </li>
                            </ul>
                            <div style="float: right; position: relative; top: 12px;">
                                <div class="input-group flex-nowrap" style="margin-right: 10px; width: auto; display: inline-flex;">
                                    <div class="search-field-wrapper">
                                        <div class="input-group mb-2 mr-sm-2">
                                            <input (input)="this.setSearchedText($event)" type="text" class="form-control" style="font-size: unset; height: unset; border: 2px solid #F3F6FB !important; border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;" [value]="this.searchString" [placeholder]="this.dashboardLables(this.getPageName()).search" aria-label="Username" aria-describedby="addon-wrapping">
                                            <button *ngIf="this.searchString" (click)="this.clearSearchString()" type="button" class="btn bg-transparent" style="margin-left: -45px; z-index: 100;">
                                                <i class="fa fa-times" style="content: url('/assets/images/dashboard/products/cross.svg');"></i>
                                            </button>
                                            <div (click)="this.search()" class="input-group-append" style="cursor: pointer;">
                                                <div class="input-group-text"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [style]="(this.totalProducts == 0) ? 'background-color: white; padding: 10px; display: inline-block;' : 'display: inline-block;'" [class]="(this.totalProducts == 0) ? 'showBtn' : ''"><a type="button" style="height: 38px; font-size: unset; top: -2px; position: relative;" (click)="this.moveTo('/dashboard/products/add')" class="btn btn-primary" >{{this.dashboardLables(this.getPageName()).add}}</a></div>
                            </div>
                            <div class="tab-content" id="nav-tabContent2">

                                <!-- ----------------------
                                        Inventory
                                ---------------------- -->
                                <div *ngIf="this.totalProducts == 0" style="text-align: center; margin-bottom: 50px; z-index: 1001;" class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <img src="/assets/images/dashboard/products/noproducts.svg" style="margin: auto; margin-top: 40px; z-index: 1001;" width="200" height="200" alt="">
                                    <h3 [style]="this.clicked ? 'color: black; z-index: 1001; font-size: 19px;' : 'color: white; z-index: 1001; font-size: 19px;'">{{this.dashboardLables(this.getPageName()).firstProduct}} <br>
                                        <span [style]="this.clicked ? 'color: #75809C; z-index: 100000; font-size: 14px;' : 'color: white; z-index: 1001; font-size: 14px;'">{{this.dashboardLables(this.getPageName()).addProduct}}</span> <br> <br>
                                        <button *ngIf="!this.clicked" style="margin: 5px; width: 148px; height: 45px;"  class="btn btn-primary" (click)="this.clicked = true">{{this.dashboardLables(this.getPageName()).close}}</button>
                                    </h3>
                                </div>
                                <div *ngIf="this.totalProducts > 0" class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <table #productTable mat-table (scroll)="onTableScroll($event)" multiTemplateDataRows  [dataSource]="productsDataSource" matSort  class="mat-elevation-z8 products" style="text-align: center;">
                                        <ng-container matColumnDef="image">
                                          <th mat-header-cell *matHeaderCellDef > {{this.dashboardLables(this.getPageName()).image}} </th>
                                          <td mat-cell *matCellDef="let element" > <img *ngIf="this.productImages.images.length" class="product-image-field" [src]="this.getItemImageURL(element.product.uuid)" alt=""> </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                          <td mat-cell *matCellDef="let element" class="product-name-field"> {{element.product.name}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="SKU">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header><span>{{this.dashboardLables(this.getPageName()).SKU}}</span>&nbsp;<small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Код на продукта"></small></th>
                                          <td mat-cell *matCellDef="let element"> 
                                              <span *ngIf="element.product.skus.length <= 1"> {{element.product.skus[0].sku}} </span>
                                              <span class="var_span" *ngIf="element.product.skus.length > 1"> {{(element.product.skus.length - 1) + ' ' + this.dashboardLables(this.getPageName()).variationsLabel}} </span>
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="quantity">
                                          <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).quantity}} </th>
                                          <td mat-cell *matCellDef="let element"> {{this.getQuantity(element.product.total_available_quantity)}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="ordered">
                                            <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).ordered}} </th>
                                            <td mat-cell *matCellDef="let element"> {{this.getQuantity(0)}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="meduimPrice">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).price}} </th>
                                            <td mat-cell *matCellDef="let element" style="white-space: pre-wrap">{{this.getPriceRange(element).from + ' -' }} <br>{{this.getPriceRange(element).to}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).date}} </th>
                                            <td mat-cell *matCellDef="let element">{{this.convertDate(element).date.trim()}} <br><span style='color: #75809C'>{{this.convertDate(element).time}}</span> </td>
                                        </ng-container>

                                        <ng-container matColumnDef="state">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).status}}</th>
                                            <td mat-cell *matCellDef="let element"><span [style]="this.convertStatus(element).style">{{this.convertStatus(element).status}} </span></td>
                                        </ng-container>

                                        <ng-container matColumnDef="edit">
                                            <th mat-header-cell *matHeaderCellDef  style="width: 50px !important; text-align: center !important;"></th>
                                            <td  mat-cell *matCellDef="let element" style="width: 50px !important; text-align: center !important;">
                                                <span *ngIf="element.product.skus.length > 1">
                                                    <span *ngIf="this.state == 'active' || this.state == 'draft'" [ngbTooltip]="this.dashboardLables(this.getPageName()).edit" [openDelay]="300" [closeDelay]="500" class="edit" (click)="this.edit(element)"></span>
                                                    <span *ngIf="this.state == 'archived'" [ngbTooltip]="this.dashboardLables(this.getPageName()).restore" [openDelay]="300" [closeDelay]="500" class="activate" (click)="this.changeState(element, 'active')"></span>
                                                </span>
                                                <span *ngIf="element.product.skus.length <= 1">
                                                    <span></span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="change">
                                            <th mat-header-cell *matHeaderCellDef  style="width: 50px !important; text-align: center !important;"></th>
                                            <td  mat-cell *matCellDef="let element" style="width: 50px !important; text-align: center !important;">
                                                <span *ngIf="element.product.skus.length > 1">
                                                    <span *ngIf="this.state == 'active'" [ngbTooltip]="this.dashboardLables(this.getPageName()).toArchive" [openDelay]="300" [closeDelay]="500" class="archive" (click)="this.changeState(element, 'archived') "></span>
                                                    <span *ngIf="this.state == 'archived' || this.state == 'draft'" [ngbTooltip]="this.dashboardLables(this.getPageName()).delete" [openDelay]="300" [closeDelay]="500" class="delete" (click)="this.deleteProduct(element.product.uuid)"></span>
                                                </span>
                                                <span *ngIf="element.product.skus.length <= 1">
                                                    <span *ngIf="this.state == 'active' || this.state == 'draft'" [ngbTooltip]="this.dashboardLables(this.getPageName()).edit" [openDelay]="300" [closeDelay]="500" class="edit" (click)="this.edit(element)"></span>
                                                    <span *ngIf="this.state == 'archived'" [ngbTooltip]="this.dashboardLables(this.getPageName()).restore" [openDelay]="300" [closeDelay]="500" class="activate" (click)="this.changeState(element, 'active')"></span>
                                                </span>
                                            </td>

                                        </ng-container>

                                        <ng-container matColumnDef="status" >
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50px !important; text-align: center !important;"></th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px !important; text-align: center !important;"> 
                                                <button *ngIf="element.product.skus.length > 1" [ngbTooltip]="this.dashboardLables(this.getPageName()).variations" (click)="expandedElement = expandedElement === element ? null : element" class="btn btn-outline-primary variations-btn"><span [class]="expandedElement === element ? 'variations-opened' : 'variations'"></span></button> 
                                                <span *ngIf="element.product.skus.length <= 1">
                                                    <span *ngIf="this.state == 'active'" [ngbTooltip]="this.dashboardLables(this.getPageName()).toArchive" [openDelay]="300" [closeDelay]="500" class="archive" (click)="this.changeState(element, 'archived') "></span>
                                                    <span *ngIf="this.state == 'archived' || this.state == 'draft'" [ngbTooltip]="this.dashboardLables(this.getPageName()).delete" [openDelay]="300" [closeDelay]="500" class="delete" (click)="this.deleteProduct(element.product.uuid)"></span>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="expandedDetail">
                                            <td  *matCellDef="let element" [attr.colspan]="displayedColumnsForProductTable.length">
                                                <div class="example-element-detail"
                                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                                    <hr [style]="(element == expandedElement) ? 'width: 90%; margin: auto; height: 2px; background-color: #EEF1F6; border: 0px; top: 20px; position: relative;' : 'width: 90%; margin: auto; height: 2px; background-color: #EEF1F6; border: 0px'">
                                                    <table  class="table variation fixed">
                                                        <tbody>
                                                          <tr  *ngFor="let variation of element.product.skus" style="height: 95px;">
                                                            <td style="width: 90px !important; border-top: unset; vertical-align: middle;"> <img *ngIf="this.productImages.images.length" class="product-image-field" [src]="this.getItemImageURL(variation.uuid)" alt=""> </td>                                                            
                                                            <td style="width: 150px !important; border-top: unset;  vertical-align: middle;" class="product-name-field"> <span *ngFor="let option of variation.options" style="display: block;"><span style="color: #75809C;">{{ option.option.display_name }}</span>{{ ': ' + option.value }}</span> </td>
                                                            <td style="width: 108px !important; border-top: unset; vertical-align: middle;"> {{variation.sku}} </td>
                                                            <td style="width: 85px !important; border-top: unset; vertical-align: middle;"> {{this.getQuantity(variation.available_quantity)}}</td>
                                                            <td style="width: 85px  !important; border-top: unset; vertical-align: middle;"> {{this.getQuantity(variation.reserved_quantity)}} </td>
                                                            <td style="width: 85px  !important; white-space: pre-wrap; border-top: unset; vertical-align: middle;">{{this.getPriceRange(element).from + ' -' }} <br>{{this.getPriceRange(element).to}} </td>
                                                            <td style="width: 90px !important; border-top: unset; vertical-align: middle;">{{this.convertDate(element).date}} <br> <span style='color: #75809C'>{{this.convertDate(element).time}}</span> </td>
                                                            <td style="width: 71px !important; border-top: unset; vertical-align: middle;"> </td>
                                                            <td style="width: 50px !important; border-top: unset; vertical-align: middle;"> </td>
                                                            <td style="width: 50px !important; border-top: unset; vertical-align: middle;"> </td>
                                                            <td style="width: 50px !important; border-top: unset; vertical-align: middle;"> </td>

                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsForProductTable"></tr>
                                        <tr mat-row *matRowDef="let element; columns: displayedColumnsForProductTable;"
                                            class="example-element-row"
                                            [class.example-expanded-row]="expandedElement === element"
                                            [style]="(element == expandedElement) ? '' : 'box-shadow: 0px -5px 20px rgb(153 163 191 / 20%)'">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; let element;" class="example-detail-row" [style]="element == expandedElement ? '' : 'top: 0px; box-shadow: none;'"></tr>
                                    </table>

                                    <nav *ngIf="this.numberPerPageValue > -1 && this.productsDataSource.length" style="margin-top: 10px;" aria-label="Page navigation example">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item" *ngIf="this.page > 1">
                                                <a style="cursor: pointer;" class="page-link previous" (click)="this.goToPreviousPage()" tabindex="-1">{{this.dashboardLables(this.getPageName()).previousPage}}</a>
                                            </li>
                                            <li class="page-item">
                                                <input type="number" class="form-control current-page" style="width: 50px !important;" [value]="this.page" (change)="this.loadSpecificPage($event.target.value)">
                                            </li>
                                            <li class="page-item" *ngIf="this.page < this.lastPage">
                                                <a style="cursor: pointer;" class="page-link next" (click)="this.goToNextPage()">{{this.dashboardLables(this.getPageName()).nextPage}}</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
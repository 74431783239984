export const AutoMapper = {
    map: function(objectFrom: any, objectTo: any, defaults: DefaultFromTo[] = [], defaultValues: DefaultValue[] = [])  {
        Object.keys(objectFrom).forEach(key => {
            objectTo[key] = objectFrom[key]
        })

        if (defaults.length) {
            defaults.forEach(fromTo => {
                objectTo[fromTo.to] = objectFrom[fromTo.from]

            })
        }

        if (defaultValues.length) {
            defaultValues.forEach(defaultValue => {
                objectTo[defaultValue.key] = defaultValue.value

            })
        }
        return objectTo;
    }

}

interface DefaultFromTo {
    from: string,
    to: string
}


interface DefaultValue {
    key: string,
    value: any
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { subDays, startOfDay, addDays, addMinutes } from 'date-fns';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ReservationIntevals } from 'src/config/labels/reservationInterval';
import { Item } from 'src/models/DTO/base/item';
import { WorkingTime } from 'src/models/DTO/workingTime/workingTime';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { WorkingtimeService } from 'src/services/requestServices/workingtime/workingtime.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { CalendarService } from 'src/services/requestServices/calendar/calendar.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { TimeInterval } from 'src/models/DTO/timeIntervals/timeInterval';
import { Calendar } from 'src/models/DTO/calendar/calendar';
import { CreateCalendarRequestModel } from 'src/models/requestModels/calendar/createCalendarRequestModel';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { CalendarEntry } from 'src/models/DTO/calendar/calendarEntry';
import { ProductlocationService } from 'src/services/requestServices/productlocation/productlocation.service';
import { ProductLocation } from 'src/models/DTO/productLocation/productLocation';
import { Subject } from 'rxjs';
import { Successes } from 'src/config/labels/successes';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';


declare var $:any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [NgbDropdownConfig]
})
export class CalendarComponent extends BaseDashboardComponent implements OnInit {

  viewDate = new Date();
  events: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  color: ThemePalette = 'primary';
  checked = false;
  disabled = false;
  timeIntervals : TimeInterval[] = [];
  productLocations: ProductLocation[];
  selectedProductLocation: ProductLocation;
  refresh: Subject<any> = new Subject();

  protected _params: Params;

  private _partnerWorkingTime: WorkingTime;
  private _interval = 30;
  private _service: Item;
  private _calendar: Calendar;
  private _calendarEvents: CalendarEntry[];

  
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    private workingTimeService: WorkingtimeService,
    private itemService: ItemService,
    private calendarService: CalendarService,
    private productLocationService: ProductlocationService,
    private toastr: ToastrService,
    private ngbDropdownConfig: NgbDropdownConfig,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,


  ) { 
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);;
    this.pageName = "calendar";
    this.loadData();
    this.ngbDropdownConfig.placement = 'bottom-right'
  }

  async loadData() {
    await this.onDirectNavigation();
    this.getUrlParams().subscribe((params) => {
      this._params = params.params;
        this.itemService.getItem(this.loggedUser.partner.id, this._params.id).subscribe((service) => {
          this._service = service;
          this._interval = ReservationIntevals.values[this._service.reservationInterval];
          this.workingTimeService.getWorkingTimeByLocationId(this.loggedUser.partner.partnerLocationId)
            .subscribe((workingTime) => {
              this._partnerWorkingTime = workingTime[0];
              this.addTimeIntervals(this._interval, this._partnerWorkingTime).forEach((interval) => {
                this.timeIntervals.push({
                  time: interval,
                  reserved: false,
                  closed: false,
                  wholeDate: new Date().toString()
                });
              })
              this.productLocationService.getProductLocationByProductAndLocation(this._service.id).subscribe((locations) => {
                this.productLocations = locations;
                this.selectedProductLocation = locations[0];
                this.getCalendarEntries(this.selectedProductLocation).then(() => {
                  this.updateTimeIntervals();
                  this.showEvents();
                })
              })
          })

      })
    });
  }

  changeLocation(location) { 
    this.selectedProductLocation = location;
    this.getCalendarEntries(this.selectedProductLocation).then(() => {
      this.updateTimeIntervals();
      this.showEvents();
    })
  } 

  onReserve(interval: TimeInterval, event) {
    const date = moment(moment(interval.wholeDate).format('LL') + ' ' + interval.time); 
    const entryExists = this._calendarEvents.find((event) => new Date(event.startTime).toString() == new Date(date.toString()).toString())
    let calEntry: CalendarEntry = {
      code: Core.getRandomString(24),
      startTime: date,
      reserved: event.checked,
      reservationCalendarId: this._calendar.id,
      id: (entryExists) ? entryExists.id : null
    } as any;
    if (calEntry.id) {
      this.calendarService.updateCalendarEntry(calEntry).subscribe(() => {
        this.getCalendarEntries(this.selectedProductLocation).then(() => {
          this.updateTimeIntervals();
          this.showEvents();
          this.toastr.success(Successes.updateCalendarEntry[this._language]);
        })
      });
    }
    else {
      this.calendarService.createCalendarEntry(calEntry).subscribe(() => {
        this.getCalendarEntries(this.selectedProductLocation).then(() => {
          this.updateTimeIntervals();
          this.showEvents();
          this.toastr.success(Successes.updateCalendarEntry[this._language]);
        })
      });
    }
  }

  private showEvents() {
    this.events = [];
    this._calendarEvents.forEach((event) => {
      if (event.reserved) {
        this.events.push({
          start: new Date(event.startTime),
          end: addMinutes(new Date(event.startTime), this._interval),
          title: this.serviceName,
          color: { primary: "#0D6EFD", secondary: "#0D6EFD" },
          //actions: this.actions,
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          draggable: true,
        })
      }
    })
    this.refresh.next();
  }

  private updateTimeIntervals() {
    this._calendarEvents.forEach((event) => {
      this.timeIntervals.forEach((interval) => {
        if (new Date(moment(moment(interval.wholeDate).format('LL') + ' ' + interval.time).toString()).toString() == new Date(event.startTime).toString()) {
          interval.reserved = event.reserved;
        }
      })
    })
  }

  private getCalendarEntries(location) {
    return new Promise<void>((resolve, reject) => {
      this.getCalendar(location).then(() => {
        this.calendarService.getCalendarEntries(this._calendar.id).subscribe((entries) => {
          this._calendarEvents = entries;
          resolve();
        })
      })
    })
  }

  private async getCalendar(location: ProductLocation) {
    return new Promise<void>((resolve, reject,) => {
      this.calendarService.getCalendarByProductAndLocation(this._params.id, location.partnerLocationId).subscribe((calendars) => {
        if (!calendars.length) {
          let calendar: CreateCalendarRequestModel = {} as any;
          calendar.code = Core.getRandomString(24);
          calendar.name = Core.getRandomString(24);
          calendar.partnerLocationId = location.partnerLocationId;
          calendar.productId = this._params.id;
          this.calendarService.createCalendar(calendar).subscribe((calendar) => {
            this._calendar = calendar;
            resolve();
          })
        }
        else {
          this._calendar = calendars[0];
          resolve();
        }
      })
    })
  }

  ngOnInit(): void {
  }

  get serviceName() {
    return (this._service) ? this._service.name : '';
  }

  get isAllDayNotFree() {
    return this.timeIntervals.find(interval => interval.closed || interval.reserved);
  }

  get hasEmptySlot() {
    return this.timeIntervals.find(interval => !interval.closed && !interval.reserved);
  }

  markDayAsFree() {
    this.timeIntervals.forEach(interval => {
      interval.reserved = false;
      interval.closed = false;
      this.onReserve(interval, {checked: false});
    });
  }

  markDayAsTaken() {
    this.timeIntervals.forEach(interval => {
      interval.closed = true;
      this.onReserve(interval, {checked: true});
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
    this.timeIntervals = [];
    this.addTimeIntervals(this._interval, this._partnerWorkingTime, date).forEach((interval) => {
      this.timeIntervals.push({
        time: interval,
        reserved: false,
        closed: false,
        wholeDate: new Date(date).toString()
      });
    })
    this.updateTimeIntervals();
  }

  addTimeIntervals(interval, workingTime : WorkingTime, date = new Date()) {
    const currentDay = date.toLocaleString('en-us', {  weekday: 'long' }).toLowerCase();
    let start = workingTime[currentDay+'Start'].split(':');
    let end = workingTime[currentDay+'End'].split(':');
    const startTime = moment({hour : start[0], minute : start[1]});
    const endTime = moment({hour : end[0], minute :end[1]});
    const result = [startTime.format('LT').toString()];
    let time = startTime.add(interval,'m');
    while(time.isBetween(startTime,endTime,undefined, '[]')){
        result.push(time.format('LT').toString());
        time = time.add(interval,'m');
    }
    return result;
  }

  filter(type) {
    this.timeIntervals = [];
    this.addTimeIntervals(this._interval, this._partnerWorkingTime, this.viewDate).forEach((interval) => {
      this.timeIntervals.push({
        time: interval,
        reserved: false,
        closed: false,
        wholeDate: new Date(this.viewDate).toString()
      });
    })
    this.updateTimeIntervals();
    switch(type) {
      case 'free': {
        this.timeIntervals = this.timeIntervals.filter(interval => !interval.reserved);
        break;
      }
      case 'reserved': {
        this.timeIntervals = this.timeIntervals.filter(interval => interval.reserved);
        break;
      } 
      default: {
        return;
      }
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfirmOrder } from 'src/models/requestModels/V2/orders/confirmOrder';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  
  private shipmentURL : string = environment.api + "shipment";




  constructor(private httpClient: HttpClient) { }

  createShipment(shipmentUUID: string) {
    const params = '/' + shipmentUUID + '/pickup';
    return this.httpClient.post(this.shipmentURL + params, null);
  }

  printWayBill(shipmentUUID: string) {
    const params = '/' + shipmentUUID + '/print-waybill';
    return this.httpClient.post(this.shipmentURL + params, null, {headers: {'content-type': 'application/download'}, responseType: 'blob'});
  }

  updateParcels(confirmOrder: ConfirmOrder, shipmentUUID: string) {
    return this.httpClient.put(this.shipmentURL + '/' + shipmentUUID, confirmOrder);
  }
}


<div class="col column">
    <div class="card" style="width: 100%; height: 100%; margin: auto;">
        <div class="card-body">
            <button (click)="this.moveToMainSite()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                <form class="form-group" [formGroup]="this.userLogin">
                    <label class="credential-labels" for="userName">{{this.lables(this.getPageName()).userName}}</label>
                    <input formControlName="userName" type="text" class="form-control" id="userName" aria-describedby="emailHelp">
                    <label class="credential-labels" for="password">{{this.lables(this.getPageName()).password}}</label>
                    <div style="display: flex;">
                        <input matInput type="password" [type]="hide ? 'text': 'password'" formControlName="password" type="password" class="form-control" id="password" aria-describedby="emailHelp">
                        <i class="material-icons" matSuffix (click) = "hide = !hide">{{hide ? 'visibility':'visibility_off'}}</i>
                    </div>
                    <div class="form-check">
                        <input formControlName="rememberMe" name="rememberMe" (change)="this.rememberMeChanged($event)" class="form-check-input" type="checkbox"  id="exampleRadios1" value="option1">
                        <label class="form-check-label" for="exampleRadios1">
                            {{this.lables(this.getPageName()).rememberMe}}
                        </label>
                        <a style="color: #0A77CC; float: right;" href="registration/password/reset">{{this.lables(this.getPageName()).forgottenPassowrd}}</a>
                    </div>
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button style="margin-top: 3px;" (click)="this.login()" type="button" class="btn btn-primary loginBtn">{{this.lables(this.getPageName()).logIn}}</button>
                <!-- <a style="margin-top: 3px; float: right;" href="/registration/position" class="btn btn-primary registration-btn">{{this.lables(this.getPageName()).signUp}}</a> -->
            </div>
            
        </div>
    </div>
</div>
<router-outlet></router-outlet>
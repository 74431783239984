
<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                <form class="form-group" [formGroup]="this.personalDetails">
                    <label for="firstName">{{this.lables(this.getPageName()).FN}}</label>
                    <input formControlName="FN" type="text" [class]="(this.personalDetails.get('FN').invalid && this.personalDetails.get('FN').touched) ? 'form-control is-invalid' : 'form-control'" id="firstName" aria-describedby="emailHelp">
                    <label for="lastName">{{this.lables(this.getPageName()).LN}}</label>
                    <input formControlName="LN" type="text" [class]="(this.personalDetails.get('LN').invalid && this.personalDetails.get('LN').touched) ? 'form-control is-invalid' : 'form-control'" id="lastName" aria-describedby="emailHelp">
                    <label for="dateField">{{this.lables(this.getPageName()).date}}</label>
                    <input formControlName="date" type="date" [class]="(this.personalDetails.get('date').invalid && this.personalDetails.get('date').touched) ? 'form-control is-invalid' : 'form-control'" id="dateField" aria-describedby="emailHelp">
                    <div>
                        <label for="telPrefix">{{this.lables(this.getPageName()).tel}}</label>
                    </div>
                    <div class="input-group prefix">
                        <select style="width: fit-content;" formControlName="TelPrefix" class="custom-select" id="telPrefix">
                            <option *ngFor="let prefix of this.telPrefixes(); index as i;" [selected]="i == 0"  [ngValue]='prefix'>{{prefix.prefix}}</option>
                        </select>
                        <input formControlName="tel" type="string" [class]="(this.personalDetails.get('tel').invalid && this.personalDetails.get('tel').touched) ? 'form-control number is-invalid' : 'form-control number'" id="number" aria-describedby="emailHelp">

                    </div>
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button (click)="this.moveForward()" type="button" class="btn btn-primary nextBtn">{{this.lables(this.getPageName()).nextBtn}}</button>
            </div>
        </div>
    </div>
</div>

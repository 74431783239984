import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AttachOptionRequestModel } from 'src/models/requestModels/V2/option/AttachOptionRequestModel'
import { ProductOption } from 'src/models/DTO/V2/productOptions/ProductOption'
import { UpdateOptionRequestModel } from 'src/models/requestModels/V2/option/UpdateOptionRequestModel';
import { DetachOptionRequestModel } from 'src/models/requestModels/V2/option/DetachOptionRequestModel';

@Injectable({
  providedIn: 'root'
})
export class ProductoptionService {

  private optionURL : string = environment.api + "product/option";
  private optionBaseURL : string = environment.api + "product/option/";
  private optionAttachURL : string = this.optionBaseURL + 'attach';
  private optionDetachURL : string = this.optionBaseURL + 'detach';



  constructor(private httpClient: HttpClient) { }

  attachOptionToProduct(attachOptionRequestModel: AttachOptionRequestModel) {
    return this.httpClient.post(this.optionAttachURL, attachOptionRequestModel);
  }

  detachOptionToProduct(detachOptionRequesModel: DetachOptionRequestModel) {
    return this.httpClient.post(this.optionDetachURL, detachOptionRequesModel);
  }

  updateProductOption(updatedOption: UpdateOptionRequestModel) {
    return this.httpClient.put<ProductOption>(this.optionURL, updatedOption);
  }

  getOptionsForProduct(productUUID: string) {
    return this.httpClient.get<ProductOption>(this.optionBaseURL + productUUID + '?include=option,value');
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ContractService } from 'src/services/requestServices/contracts/contract.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DeliveryService } from 'src/services/requestServices/V2/delivery/delivery.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LogisticsService } from 'src/services/requestServices/V2/logistics/logistics.service';
import { StripeService } from 'src/services/requestServices/V2/stripe/stripe.service';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';

declare var bootstrap: any;


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent extends BaseDashboardComponent implements OnInit {


  public showBody: boolean = false;
  public invoices: any[] = []
  public displayedColumnsForAddressesTable = ['Period', 'FOrders', 'ShareMe', 'Taxes', 'Returned', 'ToRecieve', 'Invoice'];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private contractService: ContractService,
    private documentService: DocumentsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    private deliveryService: DeliveryService,
    private matDialog: MatDialog,
    protected stripeService: StripeService,
    protected carrierService: CarrierService,
    private logisticsService: LogisticsService,
    private modalService: NgbModal,
    private loadingState: LoadingstateService
    
  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentService);
    this.pageName = "carriers";
    this.loadData();
    this.loadingState.loading$.subscribe(result => {
      this.isLoading = result;
      if (this.isLoading) {
        setTimeout( () => { this.showBody = true }, 1500);
      }
      else {
        this.showBody = false;
      }
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadTooltips();
  }

  private async loadData() {
    await this.getUserInfo();
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { Labels } from 'src/config/labels/labels';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent extends BaseComponent implements OnInit {

  public changeLoginToRegistrationBtn = false;

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router: Router) {
    super(userinfostoreService, localStorageService, router);
    this.pageName = "home";
  }

  ngOnInit(): void {
    this.user = { } as any;
    this.user.userLocation = {} as any;
    this.user.partnerLocation = {} as any;
    this.userinfostoreService.addNewUser(
      this.user
    );
    if (!this.localStorageService.getLocalStorageItem(Labels.lsKey)) {
      this.localStorageService.setLocalStorageItem(Labels.lsKey, 'bg');
    }
  }

  changeLang(lang: string) {
    this.localStorageService.setLocalStorageItem(Labels.lsKey, lang);
    window.location.reload()
  }

  public onRouterOutletActivate(event : any) {
    console.log(event);
    if (event.pageName == "login") {
      this.changeLoginToRegistrationBtn = true;
    }
    else {
      this.changeLoginToRegistrationBtn = false;
    }
  }
}


<div class="col">
    <div class="card" style="width: 100%; margin-top: 8%;">
        <div class="card-body">
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                <h5 class="card-sub-title">{{this.lables(this.getPageName()).subtitle}}</h5>
                <form class="form-group" [formGroup]="this.passwordGroup">
                    <label for="password">{{this.lables(this.getPageName()).password}}</label>
                    <div style="display: flex;">
                        <input matInput type="password" [type]="hide ? 'text': 'password'" formControlName="password" [class]="(this.passwordGroup.get('password').invalid && this.passwordGroup.get('password').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmai2" aria-describedby="emailHelp">
                        <i class="material-icons" matSuffix (click) = "hide = !hide">{{hide ? 'visibility':'visibility_off'}}</i>
                    </div>
                    <label for="confirmPassword">{{this.lables(this.getPageName()).confirmPassword}}</label>
                    <div style="display: flex;">
                        <input matInput type="password" [type]="hideConfirm ? 'text': 'password'" formControlName="confirmPassword" [class]="(this.passwordGroup.get('confirmPassword').invalid && this.passwordGroup.get('confirmPassword').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmai2" aria-describedby="emailHelp">
                        <i class="material-icons" matSuffix (click) = "hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility':'visibility_off'}}</i>
                    </div>
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button style="margin-top: 60px;" (click)="this.changePassword()" type="button" class="btn btn-primary continueBtn">{{this.lables(this.getPageName()).continueBtn}}</button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
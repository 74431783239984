import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { TableProduct } from 'src/models/DTO/products/tableProduct';
import { TableService } from 'src/models/DTO/services/tableService';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private getPartnerProductsURL: string = environment.api + "api/partners";
  private getPartnerServiceURL: string = environment.api + "api/partners";

  constructor(private httpClient: HttpClient) { }

  getSearchedActiveProductsForPartner(partnerId: number, pageNumber: number, search: string, type: string) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.item
      },
      {
        key: 'active.equals',
        value: true
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      },
      {
        key: type,
        value: search
      }
    ]);
    return this.httpClient.get<TableProduct[]>(this.getPartnerProductsURL + params);
  }
  
  getSearchedArchivedProductsForPartner(partnerId: number, pageNumber: number, search: string, type: string) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.item
      },
      {
        key: 'active.equals',
        value: false
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      },
      {
        key: type,
        value: search
      }
    ]);
    return this.httpClient.get<TableProduct[]>(this.getPartnerProductsURL + params);
  } 

  getSearchedActiveServicesForPartner(partnerId: number, pageNumber: number, search: string, type: string) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.service
      },
      {
        key: 'active.equals',
        value: true
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      },
      {
        key: type,
        value: search
      }
    ]);
    return this.httpClient.get<TableService[]>(this.getPartnerServiceURL + params);
  } 

  getSearchedArchivedServicesForPartner(partnerId: number, pageNumber: number, search: string, type: string) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.service
      },
      {
        key: 'active.equals',
        value: false
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      },
      {
        key: type,
        value: search
      }
    ]);
    return this.httpClient.get<TableService[]>(this.getPartnerServiceURL + params);
  } 


}

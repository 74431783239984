import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Category } from 'src/models/DTO/V2/categories/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private getRootCategoryURL : string = environment.api + "category/?perPage=130";
  private getSubCategoryURL : string = environment.api + "category/";
  // http://shareme-be-api.test/v1/category/84099c12-16c4-413d-a39e-b380ca0a6cd4?perPage=130&depth=1&reverse=1

  constructor(private httpClient: HttpClient) { }

  getSubcategories(rootCategory: string) {
    const urlQuery = rootCategory + "?perPage=130&depth=1";
    return this.httpClient.get<Category>(this.getSubCategoryURL + urlQuery);
  }

  getSubSubcategories(rootCategory: string) {
    const urlQuery = rootCategory + "?perPage=130&depth=2";
    return this.httpClient.get<Category>(this.getSubCategoryURL + urlQuery);
  }

  getRootCategories() {
    return this.httpClient.get<Category>(this.getRootCategoryURL);
  }

  getParentCategory(childCategoryUUID: string) {
    const params = childCategoryUUID + '?perPage=130&depth=1&reverse=1';
    return this.httpClient.get<Category>(this.getSubCategoryURL + params);
  }
}

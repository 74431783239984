import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/models/DTO/stores/store';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';

@Component({
  selector: 'app-base-item',
  templateUrl: './base-item.component.html',
  styleUrls: ['./base-item.component.css']
})
export class BaseItemComponent extends BaseDashboardComponent implements OnInit {

  protected stores: Store[];

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected usersService: UsersService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected locationService: LocationsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService
    ) {
      super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
        partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    }

  ngOnInit(): void {
  }

  protected async checkForAddedStores()  {
   this.stores = await this.locationService.getStoresForPartner(this.loggedUser.partner.id).toPromise();
  }

  protected showMissingStoreAlert() {
    if (!this.stores || !this.stores.length) {
      return true
    }
    return false;
  }
}

<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0 5%;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                <form class="form-group" [formGroup]="this.companyDetails">
                    <div class="container">
                        <div class="row">
                            <div class="col-6 column">  
                                <form class="form-group" [formGroup]="this.companyDetails">
                                    <label  for="inputGroupSelect01">{{this.lables(this.getPageName()).country}}</label>
                                    <mat-form-field class="country-full-width" >
                                        <input type="text"
                                            disabled="true"
                                            matInput
                                            formControlName="country"
                                            [matAutocomplete]="auto"
                                            id="inputGroupSelect01"/>
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option (onSelectionChange)="this.onChangedCountry($event)" *ngFor="let c of this.filteredOptions | async;"  [value]="c.name">
                                                {{c.name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <label style="margin: 0;" for="exampleInputEmail1">{{this.lables(this.getPageName()).city}}</label>
                                    <input formControlName="city" type="text" [class]="(this.companyDetails.get('city').invalid && this.companyDetails.get('city').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                    <label for="exampleInputEmail1">{{this.lables(this.getPageName()).address}}</label>
                                    <input formControlName="address" type="text" [class]="(this.companyDetails.get('address').invalid && this.companyDetails.get('address').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                </form>
                            </div>
                            <div class="col-6 column">  
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).postcode}}</label>
                                <input formControlName="postcode" type="text" [class]="(this.companyDetails.get('postcode').invalid && this.companyDetails.get('postcode').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <div>
                                    <label for="inputGroupSelect01">{{this.lables(this.getPageName()).tel}}</label>
                                </div>
                                <div class="input-group prefix">
                                    <select style="width: fit-content;" formControlName="TelPrefix" class="custom-select" id="inputGroupSelect01">
                                        <option *ngFor="let prefix of this.telPrefixes(); index as i;" [selected]="i == 0"  [ngValue]='prefix'>{{prefix.prefix}}</option>
                                    </select>
                                    <input formControlName="tel" type="string" [class]="(this.companyDetails.get('tel').invalid && this.companyDetails.get('tel').touched) ? 'form-control number is-invalid' : 'form-control number'" id="inputGroupSelect02" aria-describedby="emailHelp">
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 50px;">
                            <div class="col-12 column">  
                                <app-workingtimes style="width: 100%;"></app-workingtimes>
                            </div>
                        </div>
                    </div>            
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button (click)="this.moveForward()" type="button" class="btn btn-primary nextBtn">{{this.lables(this.getPageName()).nextBtn}}</button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
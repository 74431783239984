export const ProductStatuses = {
    statuses: {
        draft: {
            bg: "Чернова",
            en: "DRAFT",
            color: 'black'
        },
        active: {
            bg: "Активен",
            en: "ACTIVE",
            color: '#007bff'

        },
        archived: {
            bg: "Архивиран",
            en: "ARCHIVED",
            csolor: 'black'

        }
    }
}
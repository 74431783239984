import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from 'src/app/shared/loading/loading.component';
import { ImageuploadComponent } from './images/imageupload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { StorestabsComponent } from './storestabs/storestabs.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { StoreformComponent } from './storeform/storeform.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { WorkingtimesComponent } from './workingtimes/workingtimes.component';
import { VariationimagesComponent } from './variationimage/variationimages/variationimages.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';




@NgModule({
  declarations: [
    LoadingComponent,
    ImageuploadComponent,
    StorestabsComponent,
    StoreformComponent,
    WorkingtimesComponent,
    VariationimagesComponent
  ],
  imports: [
    CommonModule,
    NgxFileDropModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    NgbCarouselModule,
    DragDropModule 
  ],
  exports: [
    LoadingComponent,
    ImageuploadComponent,
    StorestabsComponent,
    StoreformComponent,
    WorkingtimesComponent
  ]
})
export class SharedModule { }


<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                <form class="form-group" [formGroup]="this.personalAddress">
                    <label  for="inputGroupSelect01">{{this.lables(this.getPageName()).country}}</label>
                    <mat-form-field class="country-full-width">
                        <input 
                            disabled="true"
                            style='font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;                            '
                            type="text"
                            matInput
                            formControlName="country"
                            [matAutocomplete]="auto"
                            id="inputGroupSelect01"/>
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option (onSelectionChange)="this.onChangedCountry($event)" *ngFor="let c of this.filteredOptions | async;"  [value]="c.name">
                                {{c.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>                
                    <label >{{this.lables(this.getPageName()).city}}</label>
                    <input formControlName="city" type="text" [class]="(this.personalAddress.get('city').invalid && this.personalAddress.get('city').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                    <label for="exampleInputEmail1">{{this.lables(this.getPageName()).address}}</label>
                    <input formControlName="address" type="text" [class]="(this.personalAddress.get('address').invalid && this.personalAddress.get('address').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                    <label for="exampleInputEmail1">{{this.lables(this.getPageName()).postcode}}</label>
                    <input formControlName="postcode" type="text" [class]="(this.personalAddress.get('postcode').invalid && this.personalAddress.get('postcode').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button (click)="this.moveForward()" type="button" class="btn btn-primary nextBtn">{{this.lables(this.getPageName()).nextBtn}}</button>
            </div>
        </div>
    </div>
</div>

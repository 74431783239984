export const Convertor = {
    convertDate: {
        convertDateWithDelimiter: function(date, delimiter) {
            var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [day, month, year].join(delimiter);
        },
        convertDateTimeWithDelimiter: function(date, delimiter) {
            var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear(),
                    hour = d.getHours(),
                    minutes = d.getMinutes()
            let minutesAsString = '';
            let hoursAsString = '';
            if (minutes < 10) {
                minutesAsString = "0" + minutes;
            }
            else {
                minutesAsString = minutes.toString();
            }
            if (hour < 10) {
                hoursAsString = "0" + hour;
            }
            else {
                hoursAsString = hour.toString();
            }
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return {
                date: [day, month, year].join(delimiter),
                time: [hoursAsString, minutesAsString].join(':')
            };
        }
    },
    convertAttributeName: {
        convertFromAPI: function(attrName: string) {
            let converted = attrName.replace("Attr", '');
            return converted[0].toUpperCase() + converted.substr(1);
        }
    },
    convertImages: {
        plainToFullBase64: function(plainBase64: string, type: string) {
            return 'data:' + type + ';base64, ' + plainBase64;
        }
    },
    convertNumbers: {
        addToFixedSuffix: function(number: any, toSuffix: number) {
            return Number(number).toFixed(toSuffix);
        }
    },
    convertPrice: {
        splitPriceToFloat: function(number: any) {
            let numberToWorkWith = number || '0.00'
            let priceAsString = numberToWorkWith.toString();
            let splittetPrice = priceAsString.split('.');
            return {
                lev: splittetPrice[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                coins: splittetPrice[1] || '00'
            };
        }
    }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageuploadComponent } from 'src/app/shared/images/imageupload.component';
import { StorestabsComponent } from 'src/app/shared/storestabs/storestabs.component';
import { ReservationIntevals } from 'src/config/labels/reservationInterval';
import { Successes } from 'src/config/labels/successes';
import { environment } from 'src/environments/environment';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { Item } from 'src/models/DTO/base/item';
import { Category } from 'src/models/DTO/categories/category';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { Service } from 'src/models/DTO/services/Service';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { CategoriesService } from 'src/services/requestServices/categories/categories.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { ServiceService } from 'src/services/requestServices/services/service.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../../base-dashboard/base-dashboard.component';
import { BaseItemComponent } from '../../base-item/base-item.component';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';

@Component({
  selector: 'app-editservice',
  templateUrl: './editservice.component.html',
  styleUrls: ['./editservice.component.css']
})
export class EditserviceComponent extends BaseItemComponent implements OnInit {

  public serviceInfo: FormGroup;

  public base64Files: Base64Image[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild(ImageuploadComponent)
  private _imageUploadComponent: ImageuploadComponent;
  @ViewChild(StorestabsComponent)
  private _storeTabsComponent: StorestabsComponent;

  private _currentItem: Item;
  private _categories : Category[];
  private _subCategories: Category[];
  private _errorMsg: string;
  private _createdService: Service;

  constructor(   
    protected activatedRoute: ActivatedRoute, 
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected usersService: UsersService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected locationService: LocationsService,
    private categoryService: CategoriesService,
    private serviceService: ServiceService,
    private toastrService: ToastrService,
    private FB: FormBuilder,
    private itemService: ItemService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService, 
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService
    ) {
      super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
        partnersService, imagesService, sanitizer, router, locationService, loggedInfoService, infoService, imageService, carrierService, documentsService);
      this.onDirectNavigation();
      this.getCurrentProduct();
      this.pageName = "editService";
  }

  ngOnInit(): void {

  }

  async getCurrentProduct() {
    if (!this.router.navigated) {
      await this.getUserInfo();
    }
    this.getUrlParams().subscribe((params) => {
      this._params = params.params;
      this.itemService.getItem(this.loggedUser.partner.id, this._params.id).subscribe((service) => {
        this._currentItem = service;
        if (service.type != environment.service) {
          this.router.navigate(['error/resource/notfound']);
          return;
        }
        this._imageUploadComponent.setCreated(this._currentItem);
        this.initForm();

      })
    });
  }

  async getStores() {
    await this.onDirectNavigation();
    await this.checkForAddedStores();
    this._storeTabsComponent.setStores(this.stores);
    if (this.showMissingStoreAlert()) {
      this.toastrService.warning('You will not be able to add product without stores', 'Adding a product', { timeOut: 9500 })
    }
    this._storeTabsComponent.setProductId(this._currentItem.id);
  }

  private initForm() {
    this.serviceInfo = this.FB.group({
      category: [this._currentItem.category01Id, [Validators.required]],
      subcategory: [this._currentItem.category02Id, [Validators.required]],
      name: [this._currentItem.name, [Validators.required]],
      description2: [this._currentItem.description2, [Validators.required]],
      description: [this._currentItem.description, [Validators.required]],
      terms: ['', [Validators.required]],
      pricing: [this._currentItem.unitPriceAmt, [Validators.required]],
      resInterval: [this._currentItem.reservationInterval, [Validators.required]],
      stores: ['', [Validators.required]],
    });

    this._storeTabsComponent.setFormGroup(this.serviceInfo);
    this.getStores();

    this.getAllRootCategories(environment.serviceKey).then(
      (rootCategories) => {
        this._categories = rootCategories;
        this.serviceInfo.get('category').setValue(this.categories[0].id);
        this.executePromisesOnCategoryChange(this.categories[0].id);
    });
    this.getImagesForItem();
  }

  private getImagesForItem() {
    this.imagesService.getImagesForItem(this._currentItem.id).subscribe((images) => {
      images.forEach(image => {
        let base64Image: Base64Image = {} as any;
        let imageContent = Convertor.convertImages.plainToFullBase64(image.content, image.contentContentType);
        let sanitizedImageContent = this.sanitizer.bypassSecurityTrustResourceUrl(imageContent)

        AutoMapper.map(image, base64Image, [],
          [
            {
              key: 'file',
              value: image
            },
            {
              key: 'sanitizedContent',
              value: sanitizedImageContent
            }
          ]);
        this.base64Files.push(base64Image);

      });
    })
  }
  
  get categories() : Category[] {
    return this._categories;
  }

  get subcategories() : Category[] {
    return this._subCategories;
  }

  get errorMessage() : string {
    return this._errorMsg;
  }

  get intevals() {
    return ReservationIntevals.intevals[this._language];
  }

  ngAfterViewInit() {
    this.base64Files = this._imageUploadComponent.base64Files;
  }

  getAllRootCategories(type: string) : Promise<Category[]> {
    return this.categoryService.getAllRootCategories(type).toPromise();
  }

  getAllSubCategories(id) {
    return this.categoryService.getAllSubcategories(id).toPromise();
  }

  executePromisesOnCategoryChange(id) {
    this.getAllSubCategories(id).then(
      subCategories => {
        this._subCategories = subCategories;
        this.serviceInfo.get('subcategory').setValue(this.subcategories[0].id);
      }
    )
  }

  categoryChange(event) {
    this.executePromisesOnCategoryChange(event.target.value);
  }

  prepareServiceForUpload() {
    this._createdService.category01Id = this.serviceInfo.get('category').value;
    this._createdService.category02Id = this.serviceInfo.get('subcategory').value;
    this._createdService.unitPriceAmt = this.serviceInfo.get('pricing').value;
    this._createdService.description2 = this.serviceInfo.get('description2').value;
    this._createdService.description = this.serviceInfo.get('description').value;
    this._createdService.name = this.serviceInfo.get('name').value;
    this._createdService.reservationInterval = 'MINUTES_120';
    this._createdService.type = environment.service;
  }

  upload() {
    if (this.validateBeforeUpload()) {
      this.prepareServiceForUpload();
      this.itemService.updateItem(this._createdService)
        .subscribe(() => {
          this.toastrService.success(
            Successes.serviceCreation[this._language]
          )
          this.goToDashboard();
        });
    }
  }

  validateBeforeUpload() {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this.serviceInfo, this.dashboardLables(this.pageName))
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.dashboardLables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  handleCancelRedirect() {
    this.router.navigate(['dashboard/products']);
  }

}

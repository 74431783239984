<div mat-dialog-content>
    <div *ngIf="!this.data.length" class="alert alert-primary" role="alert">
        {{this.primaryAlert}}
      </div>
    <button class="btn margin" *ngFor="let image of this.data" (click)="this.selectImage(image)">
        <img style="margin: 5px; width: 80px; height: 100px; display: block;" [src]="image.imageContent" alt="">
    </button>
</div>
<div mat-dialog-actions>
  <button class="btn btn-primary margin" mat-button (click)="onNoClick()">{{this.primaries.Cancel[this.language]}}</button>
  <button class="btn btn-primary margin" mat-button [mat-dialog-close]="this.selectedImage" cdkFocusInitial>{{this.primaries.OK[this.language]}}</button>
</div>
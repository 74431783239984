export const Core = {
    getRandomString: function(length: number) : string {
        const keys : string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        let randomString : string = '';
        for (let i = 0; i < length; i++) {
            randomString += keys[Math.floor(Math.random() * keys.length-1)]; 
        }
        return randomString;
    },

    replaceLastCharactersOfString(originialString: string, charWithWichToReplace: string, amountOfLastCharactersToReplace: number) {
        let string = originialString.substring(0, originialString.length - amountOfLastCharactersToReplace);
        for (let index = 0; index < amountOfLastCharactersToReplace; index++) {
            string += charWithWichToReplace;
            
        }
        return string;
    }

}
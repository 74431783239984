<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body" style="height: 100%;">
    <div class="row">
        <div class="col-sm-1">
            <h2>{{this.dashboardLables(this.getPageName()).title}}</h2>
        </div>
        <div class="col-sm-3 offset-sm-6">
        </div>
        <div class="col-sm-2">
            <button class="btn btn-primary" (click)="this.createStore();">{{this.dashboardLables(this.getPageName()).add}}</button>
        </div>
    </div>
    <div class="row" style="height: 100%;">
        <div class="col-sm-12 column" style="height: 100%;">
            <table *ngIf="this.tableStores.length" mat-table [dataSource]="storesTableDataSource" matSort  class="mat-elevation-z8">

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).name}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
              
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).address}} </th>
                  <td mat-cell *matCellDef="let element"><a [href]="'https://maps.google.com/?q=' + element.address" target="_blank"> {{element.address}} </a></td>
                </ng-container>
              
                <ng-container matColumnDef="phone">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).phone}} </th>
                  <td mat-cell *matCellDef="let element"><a [href]="'tel:+' + element.phone"> {{element.phone}} </a></td>
                </ng-container>

                <ng-container matColumnDef="pickUp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).pickUp}} </th>
                    <td mat-cell *matCellDef="let element"> {{this.getPickUpInfo(element.pickupLocation)}} </td>
                  </ng-container>

                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"><span ngbTooltip="Промени" [openDelay]="300" [closeDelay]="500" class="edit" (click)="this.edit(element.id)"></span></td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element"><span ngbTooltip="Изтрий" [openDelay]="300" [closeDelay]="500" class="delete" (click)="this.delete(element)"></span></td>
                </ng-container>
            
              
                <tr mat-header-row *matHeaderRowDef="displayedColumnsForStoresTable"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsForStoresTable;"></tr>
            </table>

            <div *ngIf="!this.tableStores.length" class="alert alert-warning" role="alert">
                <p>{{this.dashboardLables(this.getPageName()).noStores}}</p>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, RouterModule, Router } from '@angular/router';
import { UsersService } from 'src/services/requestServices/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private userService: UsersService, private routing : Router) { }

  canActivate(): boolean {
    if (this.userService.isUserLoggedIn()) {
      return true;
    }
    this.routing.navigate(["/registration/login"])
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { Primaries } from 'src/config/labels/primaries';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Labels } from 'src/config/labels/labels';


@Component({
  selector: 'app-imageselection',
  templateUrl: './imageselection.component.html',
  styleUrls: ['./imageselection.component.css']
})
export class ImageselectionComponent {

  public selectedImage : Base64Image = {} as any;
  public primaryAlert : string;
  public language: string;
  public primaries = Primaries;

  constructor(
    public dialogRef: MatDialogRef<ImageselectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Base64Image[],
    private ls: LocalstorageserviceService) {
      this.language = this.ls.getLocalStorageItem(Labels.lsKey);
      this.primaryAlert = Primaries.noImages[this.language];
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectImage(image: Base64Image) {
    this.selectedImage = image;
  }

}

export const Sizes = [
    {
        size: 'XS',
        name: 'XS'
    },
    {
        size: 'S',
        name: 'S'
    },
    {
        size: 'M',
        name: 'M'
    },
    {
        size: 'L',
        name: 'L'
    },
    {
        size: 'XL',
        name: 'XL'
    },
    {
        size: 'XXL',
        name: 'XXL'
    },
]
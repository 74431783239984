import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperatorFunction, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Dialogs } from 'src/config/labels/dialogs';
import { CarrierAddressEntry } from 'src/models/DTO/V2/carrier/CarrierAddressEntry';
import { CarrierAddressItem } from 'src/models/DTO/V2/carrier/CarrierAddressItem';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';

@Component({
  selector: 'app-carrier-address',
  templateUrl: './carrier-address.component.html',
  styleUrls: ['./carrier-address.component.css']
})
export class CarrierAddressComponent implements OnInit {

  dialogs = Dialogs.bg;
  public addressDataToAdd = {
    country: -1,
    city: -1,
    street: -1
  };

  constructor(
    public dialogRef: MatDialogRef<CarrierAddressComponent>,
    private carrierAddress: CarrierService) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  formatter = (cAddress: CarrierAddressEntry) => cAddress.value;

  searchCountry: OperatorFunction<string, readonly CarrierAddressEntry[]> = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.carrierAddress.searchCarrierCountries(term).pipe(
          map(response => response.data.results)
        )
      )
    );

  searchCity: OperatorFunction<string, readonly CarrierAddressEntry[]> = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.carrierAddress.searchCarrierCity(this.addressDataToAdd.country, term).pipe(
          map(response => response.data.results)
        )
      )
    );

  searchStreet: OperatorFunction<string, readonly CarrierAddressEntry[]> = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.carrierAddress.searchCarrierStreet(this.addressDataToAdd.city,  term).pipe(
          map(response => response.data.results)
        )
      )
    );
    
}

<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body" style="height: 100%;">
    <div class="row">
        <div class="col-sm-12 column" style="height: 100%;">
        <div class="col-sm-12" style="text-align: center;">
            <button style="margin: 5px; float: left; width: auto;" class="btn backTab" (click)="this.handleCancelRedirect()">{{this.dashboardLables(this.getPageName()).back}}</button>
            <h4 style="color: #0A69EB;">{{this.dashboardLables(this.getPageName()).addServiceLabel}}</h4>
            <h2>{{this.dashboardLables(this.getPageName()).serviceInfoLabel}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div *ngIf="this.serviceInfo" [formGroup]="this.serviceInfo" class="form-group info-form">
                <label for="category">{{this.dashboardLables(this.getPageName()).category}}</label>
                <select (change)="this.categoryChange($event)"  formControlName="category" id="category" class="form-control">
                    <option *ngFor="let category of this.categories; index as i" [value]="category.id" [selected] = "i == 0">{{category.name}}</option>
                </select>

                <label for="name">{{this.dashboardLables(this.getPageName()).name}}</label>
                <input formControlName="name" type="text" class="form-control" id="name" [placeholder]="this.dashboardLables(this.getPageName()).name">

                <label for="description">{{this.dashboardLables(this.getPageName()).description}}</label>
                <input formControlName="description" type="text" class="form-control" id="description" [placeholder]="this.dashboardLables(this.getPageName()).description">
            </div>
        </div>
        <div class="col-sm-6">
            <div *ngIf="this.serviceInfo" [formGroup]="this.serviceInfo" class="form-group info-form">
                <label for="subcategory">{{this.dashboardLables(this.getPageName()).subcategory}}</label>
                <select formControlName="subcategory" id="subcategory" class="form-control">
                    <option *ngFor="let subcategory of this.subcategories" [value]="subcategory.id">{{subcategory.name}}</option>
                </select>

                <label for="description2">{{this.dashboardLables(this.getPageName()).description2}}</label>
                <input formControlName="description2" type="text" class="form-control" id="description2" [placeholder]="this.dashboardLables(this.getPageName()).description2">

                <label for="terms">{{this.dashboardLables(this.getPageName()).terms}}</label>
                <input formControlName="terms" type="text" class="form-control" id="terms" [placeholder]="this.dashboardLables(this.getPageName()).terms">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">

            <div style="margin: 5px;" class="center">
                <app-imageupload></app-imageupload>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <div *ngIf="this.serviceInfo" [formGroup]="this.serviceInfo" class="form-group info-form">
                <label for="pricing" style="text-align: center; display: grid;">{{this.dashboardLables(this.getPageName()).pricing}}</label>
                <input formControlName="pricing" type="number" class="form-control" id="pricing" [placeholder]="this.dashboardLables(this.getPageName()).pricing">
            </div>
            <h4>{{this.dashboardLables(this.getPageName()).reservation}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div *ngIf="this.serviceInfo" [formGroup]="this.serviceInfo" class="form-group info-form">
                <label for="resInterval">{{this.dashboardLables(this.getPageName()).resInterval}}</label>
                <select formControlName="resInterval" id="resInterval" class="form-control">
                    <option *ngFor="let inteval of this.intevals; index as i" [value]="inteval.value" [selected] = "i == 0">{{inteval.inteval}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-6">
            <app-storestabs></app-storestabs>
        </div>
    </div>



    <div class="row">
        <div class="col-sm-12">
            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                {{this.errorMessage}}
            </div>
            <div style="text-align: center;">
                <button style="margin: 5px;" class="btn btn-primary" (click)="this.upload()">{{this.dashboardLables(this.getPageName()).upload}}</button>
            </div>
        </div>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StripeOrganisationStatus } from 'src/models/DTO/V2/stripe/StripeOrganisationStatus';
import { StripeLinkRequestModel } from 'src/models/requestModels/V2/stripe/StripeLinkRequestModel';
import { StripeLinkInfo } from 'src/models/DTO/V2/stripe/StripeLinkInfo'



@Injectable({
  providedIn: 'root'
})
export class StripeService {


  private stripeURL: string = environment.api + "payment/account/link";
  private stripeOrgasanationURL: string = environment.api + "payment/account/for-organisation/";


  constructor(private httpClient: HttpClient) { }

  public getCurrentStripeStatus(organisationUUID: string) : Observable<StripeOrganisationStatus> {
    return this.httpClient.get<StripeOrganisationStatus>(this.stripeOrgasanationURL + organisationUUID);
  }

  public createLink(stripeLinkModel: StripeLinkRequestModel) : Observable<any> {
    return this.httpClient.post<StripeLinkInfo>(this.stripeURL , stripeLinkModel);
  }

}

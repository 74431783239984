<form [formGroup]="this.workDays">
    <div class="form-group">
        <label [style]="this.customLabelStyle" for="everyDay">{{this.dashboardLables.workDays}}:</label>
    </div>
    <input formControlName="everyDayFrom" type="time" class="form-control everyDayFrom" id="everyDay" aria-describedby="emailHelp">
    <input formControlName="everyDayTo" type="time" class="form-control everyDayTo" id="everyDay" aria-describedby="emailHelp">
    <button (click)="this.applyToAllDays()" type="button" class="btn btn-primary applyToAllDays">{{this.dashboardLables.apply}}</button>
    <input (change)="this.toggleCustomView($event)" class="form-check-input customWorkDay" type="checkbox" name="exampleRadios" id="exampleRadios1" value="option1">
    <label class="form-check-label" for="exampleRadios1">{{this.dashboardLables.customWorkDayViewLabel}}</label>

    <div *ngIf="this.customWorkTimeView">
        <br>
        <label style='display: block; margin-top: 10px;' for="monFrom">{{this.dashboardLables.mon}}</label>
        <input formControlName="monFrom" type="time" class="form-control from" id="monFrom" aria-describedby="emailHelp">
        <input formControlName="monTo" type="time" class="form-control to" id="monFrom" aria-describedby="emailHelp">
        <input formControlName="monRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="mon" value="option1">
        <label class="form-check-label" for="mon">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="tueFrom">{{this.dashboardLables.tue}}</label>
        <input formControlName="tueFrom" type="time" class="form-control from" id="tueFrom" aria-describedby="emailHelp">
        <input formControlName="tueTo" type="time" class="form-control to" id="tueFrom" aria-describedby="emailHelp">
        <input formControlName="tueRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="tue" value="option1">
        <label class="form-check-label" for="tue">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="wedFrom">{{this.dashboardLables.wed}}</label>
        <input formControlName="wedFrom" type="time" class="form-control from" id="wedFrom" aria-describedby="emailHelp">
        <input formControlName="wedTo" type="time" class="form-control to" id="wedFrom" aria-describedby="emailHelp">
        <input formControlName="wedRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="wed" value="option1">
        <label class="form-check-label" for="wed">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="thuFrom">{{this.dashboardLables.thu}}</label>
        <input formControlName="thuFrom" type="time" class="form-control from" id="thuFrom" aria-describedby="emailHelp">
        <input formControlName="thuTo" type="time" class="form-control to" id="thuFrom" aria-describedby="emailHelp">
        <input formControlName="thuRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="thu" value="option1">
        <label class="form-check-label" for="thu">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="friFrom">{{this.dashboardLables.fri}}</label>
        <input formControlName="friFrom" type="time" class="form-control from" id="friFrom" aria-describedby="emailHelp">
        <input formControlName="friTo" type="time" class="form-control to" id="friFrom" aria-describedby="emailHelp">
        <input formControlName="friRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="fri" value="option1">
        <label class="form-check-label" for="fri">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="satFrom">{{this.dashboardLables.sat}}</label>
        <input formControlName="satFrom" type="time" class="form-control from" id="satFrom" aria-describedby="emailHelp">
        <input formControlName="satTo" type="time" class="form-control to" id="satFrom" aria-describedby="emailHelp">
        <input formControlName="satRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="sat" value="option1">
        <label class="form-check-label" for="sat">
            {{this.dashboardLables.rest}}
        </label>

        <label style='display: block; margin-top: 10px;' for="sunFrom">{{this.dashboardLables.sun}}</label>
        <input formControlName="sunFrom" type="time" class="form-control from" id="sunFrom" aria-describedby="emailHelp">
        <input formControlName="sunTo" type="time" class="form-control to" id="sunFrom" aria-describedby="emailHelp">
        <input formControlName="sunRest"  class="form-check-input checkbox" type="checkbox" name="exampleRadios" id="sun" value="option1">
        <label class="form-check-label" for="sun">
            {{this.dashboardLables.rest}}
        </label>
    </div>
</form>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { UserLoginRequestModel } from 'src/models/requestModels/V2/user/UserLoginRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Token } from 'src/models/DTO/V2/token/Token';
import { Labels } from 'src/config/labels/labels';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userLoginURL : string = environment.api + "partner/auth/login";

  constructor(private httpClient: HttpClient,
    protected localStorageService : LocalstorageserviceService) {

  }

  loginPartner(user: UserRegistration) {
    const userRequestModel : UserLoginRequestModel = {
      password: user.password,
      email: user.email,
      remember: user.rememberMe
    }
    return this.httpClient.post<Token>(this.userLoginURL, userRequestModel);
  }

  logOut() {
    this.localStorageService.removeLocalStorageItem(Labels.lsTokenKey)
  }
}

export const Labels = {
    lsKey: 'ln',
    lsTokenKey: 'API-Token',
    lsNumberOfProductsPerPage: 'productsPerPage',
    ordersPerPage: 'ordersPerPage',
    en: {
        home: {
            moto: "Share more. Save more.",
            lineOne: "Free registration",
            lineTwo: "Unlimited products and service uploads",
            lineThree: "Group buying for more sales",
            signUp: "Sign up"
        },
        positionPage: {
            lable: 'Choose your position',
            owner: 'I am the owner/one of the owners',
            shareholder: 'I am the director',
            continueBtn: 'Continue',
            error: "You have not selected a position."
        },
        emailPage: {
            lable: 'Start selling more!',
            subLable: 'Let`s start with an email.',
            registration: 'Now you can set up your registration',
            emailLabel: 'Email',
            terms: 'By confirming your email you agree on our Terms and Conditions and that you have read and understood our privacy policy',
            successfulEmailSending: "Confirmation email have been sent successfully",
            registerBtn: 'Register',
            continueBtn: 'Continue',
            error: "You have not added a valid email."
        },
        emailConfirmationPage: {
            lable: 'A verification link has been sent to your email.',
            doneBtn: 'Done'
        },
        passwordConfirmation: {
            title: 'Create your password.',
            subtitle: 'Your password must contain at least 8 characters (one number digit, letter, symbol)',
            password: 'Password',
            confirmPassword: 'Confirm your password',
            invalidPassword: 'Your password is invalid',
            notSamePassword: 'Passwords are not the same',
            continueBtn: 'Continue'
        },
        personalDetails: {
            title: 'Personal details',
            subtitle: 'As the ones on your Id',
            FN: 'First Name',
            LN: 'Last Name',
            tel: 'Telephone',
            date: 'Date of birth',
            invalidField: ' is invalid',
            nextBtn: 'Continue'
        },
        personalAddress: {
            title: 'Personal address',
            subtitle: 'As the ones on your Id',
            country: 'Country',
            city: 'City',
            address: 'Address',
            postcode: 'Postcode',
            invalidField: ' is invalid',
            nextBtn: 'Continue'
        },
        companyAddress: {
            title: 'Company Details',
            legalName: 'Legal Bussiness Name',
            namePlaceholder: "QPay",
            tradingName: 'Trading name',
            inEnglish: "Company name in English",
            type: 'Type',
            bulstat: 'Bulstat/EIK/UIC',
            vat: 'VAT',
            vatNumber: 'VAT Number',
            invalidField: ' is invalid',
            nextBtn: 'Continue'
        },
        companyDetails: {
            title: 'Company Details',
            country: 'Country',
            city: 'City',
            address: 'Address',
            postcode: 'Postcode',
            tel: 'Phone Number',
            workDays: 'Working days',
            everyDay: "Apply to every day",
            apply: "Apply",
            customWorkDayViewLabel: "Custom",
            rest: "Rest",
            mon: 'Monday',
            tue: 'Tuesday',
            wed: 'Wednesday',
            thu: 'Thursday',
            fri: 'Friday',
            sat: 'Saturday',
            sun: 'Sunday',
            invalidField: ' is invalid',
            nextBtn: 'Continue'
        },
        finish: {
            title: "Your registration has been completed",
            finsihBtn: "Finish"
        },
        login: {
            title: "Welcome to ShareMe",
            subtitle: "Enter your details, to proceed further",
            userName: "Email",
            password: "Password",
            rememberMe: "Remember me",
            forgottenPassowrd: "Forgotten password",
            logIn: "Log in",
            signUp: "Sign up",
            invalidField: ' cannot be empty',
        },
        forgottenPassword: {
            lable: 'Forgot your password?',
            subLableFirstRow: 'Enter the email of your account and we’ll send',
            subLableSecondRow: 'and email with instructions to reset your password.',
            emailLabel: 'Email',
            resetPassword: 'Reset password',
            success: "We have successfully sent you an email to reset your password.",
            error: "You have not given a valid email."
        },
        emailActivation: {
            lable: "You have successfully activated your account and you can proceed with your registration."
        },
        errors: {
            apiError: "There is a problem with the system. Contact the support."
        }
    },
    bg: {
        home: {
            moto: "Споделяй повече. Спести повече.",
            lineOne: "Безплатна регистрация",
            lineTwo: "Неограничено качване на продукти",
            lineThree: "Групово пазаруване за повече продажби",
            signUp: "Регистрирай се",
            logIn: "Вход"
        },
        positionPage: {
            lable: 'Изберете роля във фирмата',
            owner: 'Аз съм управител/ един от управителите',
            shareholder: 'Аз съм собственик/ един от собствениците',
            continueBtn: 'Продължи',
            error: "Не сте задали позиция."
        },
        emailPage: {
            lable: 'Започнете да продавате повече',
            subLable: 'Да започнем с имейл.',
            registration: 'Сега можете да направите регистрацията',
            emailLabel: 'Имейл',
            terms: 'С потвърждението на имейла Вие се съгласявате на условията за ползване и декларирате, че сте прочели и разбрали политиката за поверитеност.',
            successfulEmailSending: 'Имейл за потвърждение ви беше изпратен успешно',
            registerBtn: 'Регистрирай се',
            continueBtn: 'Продължи',
            error: "Не сте въвели валиден имейл."
        },
        emailConfirmationPage: {
            lable: 'Верификационен линк беше изпратен на пощата Ви',
            doneBtn: 'Затвори'
        },
        resetpassconfirm: {
            lable: 'Изпратихме имейл с инструкции за промяна на паролата Ви',
            doneBtn: 'Затвори'
        },
        passwordConfirmation: {
            title: 'Създайте своята парола',
            subtitle: 'Паролата трябва да е минимум 8 символа (поне по една буква, една цифра и един знак)',
            password: 'Парола',
            confirmPassword: 'Потвърдете своята парола',
            invalidPassword: 'Паролата е невалидна',
            notSamePassword: 'Паролите не съвпадат',
            continueBtn: 'Продължи'
        },
        personalDetails: {
            title: 'Данни за представителя',
            subtitle: 'По лична карта',
            FN: 'Име',
            LN: 'Фамилия',
            tel: 'Мобилен телефон',
            date: 'Дата на раждане',
            invalidField: ' е невалиден/невалидна/невалидно',
            nextBtn: 'Напред'
        },
        personalAddress: {
            title: 'Данни за представителя',
            subtitle: 'По лична карта',
            country: 'Държава',
            city: 'Град',
            address: 'Адрес',
            postcode: 'Пощенски код',
            invalidField: ' е неваледен/невалидна',
            nextBtn: 'Продължи'
        },
        companyAddress: {
            title: 'Данни за фирмата',
            legalName: 'Наименование на фирмата',
            fillAllFields: "* Моля попълнете всички полета",
            namePlaceholder: "ШеърМи",
            tradingName: 'Наименование на латиница',
            type: 'Правна форма',
            nameInEnglish: "Tърговска марка",
            bulstat: 'ЕИК / Булстат',
            vat: 'ДДС регистрация',
            vatNumber: 'Номер по ДДС',
            iban: 'IBAN (Номер на банкова сметка) ',
            ibanInfo: 'Въведете банкова сметка на дружеството, по която ще се превеждат сумите от продажби',
            bank: 'Банка',
            bic: 'BIC/Swift на банката',
            description: 'Описание дейност на фирмата',
            invalidField: ' е невалиден/невалидна/невалидно',
            nextBtn: 'Продължи'
        },
        companyDetails: {
            title: 'Данни за фирмата',
            country: 'Държава',
            city: 'Град',
            address: 'Адрес',
            postcode: 'Пощенски код',
            tel: 'Телефон за контакт',
            workDays: 'Работно време',
            everyDay: "Задайте за всеки ден",
            apply: "Задай",
            customWorkDayViewLabel: "Специфично",
            rest: "Почивен",
            mon: 'Понеделник',
            tue: 'Вторник',
            wed: 'Сряда',
            thu: 'Четвъртък',
            fri: 'Петък',
            sat: 'Събота',
            sun: 'Неделя',
            invalidField: ' е невалиден/невалидна/невалидно',
            nextBtn: 'Напред'
        },
        finish: {
            title: "Благодарим Ви, за регистрацията!",
            finsihBtn: "Продължи"
        },
        login: {
            title: "Добре дошли в shareme.",
            subtitle: "Въведете данните, за да продължите",
            userName: "Имейл",
            password: "Парола",
            rememberMe: "Запомни ме",
            forgottenPassowrd: "Забравена парола?",
            logIn: "Вход",
            signUp: "Регистрирай се",
            invalidField: ' не може да бъде празен/на',

        },
        forgottenPassword: {
            lable: 'Забравихте паролата си?',
            subLableFirstRow: 'Въведете имейла си и ще изпратим имейл с инструкции',
            subLableSecondRow: ' за възстановяване на паролата.',
            emailLabel: 'Имейл',
            resetPassword: 'Смени паролата',
            success: "Успешно ви изпратихме мейл, да смените паролата си.",
            error: "Не сте дали валиден имейл."
        },
        resetPassword: {
            title: 'Смени парола',
            subtitle: 'Паролата трябва да е минимум 8 символа (поне по една буква, една цифра и един знак)',
            password: 'Парола',
            confirmPassword: 'Потвърдете паролата',
            invalidPassword: 'Паролата е невалидна',
            notSamePassword: 'Паролите не съвпадат',
            continueBtn: 'Потвърди'
        },
        emailActivation: {
            lable: "Успешно активирахте акаунта си и можете да продължите регистрацията си"
        },
        errors: {
            apiError: "Има грешка със системата. Свържете се с екипа за поддръжка."
        },
        orders: {
            ordersLabel: 'Поръчки',
            processing: 'В процес',
            completed: 'Завършени',
            canceled: 'Отказани',
            returned: 'Върнати',
            all: 'Всички',
            search: 'Търси',
            id: 'Поръчка №',
            SKU: 'СКУ',
            quantity: 'К-во',
            price: 'Сума',
            date: 'Дата',
            status: 'Статус',
            edit: 'Промени',
            cancel: 'Откажи',
            previousPage: 'Предишна',
            nextPage: 'Следваща',
            type: 'Тип',
            client: 'Клиент',
            tax: 'Такса отказ',
            net: 'Нето Продажба',
            method: 'Метод',
            enddate: 'Крайна дата',
            delivery: 'Метод на доставка',
            sum: 'Сума',
            returnedsum: 'Върната Сума',
            confirm: 'Нето Продажба',
            pickup: 'Нето Продажба',
            generate: 'Нето Продажба',
            print: 'Нето Продажба',
            return: 'Нето Продажба',
            cancelreturn: 'Нето Продажба',

            
        }
    }
  };
  
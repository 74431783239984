import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/base/base.component';
import { Labels } from 'src/config/labels/labels';
import { environment } from 'src/environments/environment';
import { LoggedUser } from 'src/models/DTO/user/LoggedUser';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';

declare var bootstrap: any;


@Component({
  selector: 'app-base-dashboard',
  templateUrl: './base-dashboard.component.html',
  styleUrls: ['./base-dashboard.component.css']
})
export class BaseDashboardComponent extends BaseComponent implements OnInit {

  public isLoading : boolean = true;

  protected loggedUser : LoggedUser = {} as any;
  protected _language;
  protected _params;
  public loggedPartner : PartnerInfo;

  
  private _tabToPage = {
    products: '/dashboard/products',
    stores: '/dashboard/logistics',
    settings: '/dashboard/settings',
    delivery: '/dashboard/delivery',
    orders: '/dashboard/orders/1'
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected usersService: UsersService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService
  ) { 
    super(userStateService, localStorageService, router);
    this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);  
    this.getUserInfo();
    if (!this.loggedPartner || !Object.keys(this.loggedPartner).length) {
      this.loggedInfoService.userInfo$.subscribe(partner => {
        this.loggedPartner = partner;
      })
    }
  }

  async getUserInfo() {
    let userInfo = await this.infoService.info().toPromise();
    userInfo.data.images = await this.imageService.getImagesPerProduct("ORGANISATION", userInfo.data.organisations[0].uuid, 'logo').toPromise().catch(e => {
      return {
        data: []
      }
    });
    userInfo.data.totals = await this.partnersService.getTotals();
    userInfo.data.carrier = await this.carrierService.getCarriearAccount().toPromise();
    userInfo.data.documents = await this.documentsService.getDocuments().toPromise();
    this.loggedInfoService.addNewUser(userInfo);
    if (!userInfo.data.organisations[0]?.product_max_return_period_in_days || !this.areDocumentsFilled()) {
      this.updateClasses('delivery');
      this.router.navigate(['dashboard/delivery']);
    }
  }

  private updateClasses(activeClass: string) {
    document.querySelectorAll('.navigation .active').forEach(
      el => {
        el.className = el.className.replace(' active', '');
    });
    document.querySelector('.' + activeClass).parentElement.className += ' active';
  }

  getUrlParams() : Observable<Params> {
    return this.activatedRoute.paramMap;
  }

  moveTo(page: string) {
    this.router.navigate([page]);
  }

  async onDirectNavigation() {
    if (!this.router.navigated  || !Object.keys(this.loggedPartner).length) {
      await this.getUserInfo();
    }
  }

  goToDashboard() {
    this.router.navigate(['dashboard/products'])
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    Object.keys(this._tabToPage).forEach(key => {
      if (this._tabToPage[key] == this.router.url) {
        this.updateClasses(key.toString());
      }
    })
  }

  isUserActive() : boolean {
    return this.loggedPartner?.data?.organisations[0]?.state != environment.onboarding;
  }

  areDocumentsFilled() : boolean {
    if (!this.loggedPartner.data.documents){
      return false;
    }
    let allFilled =true;
    this.loggedPartner.data.documents?.data.forEach(document => {
      if (environment.documentTemplates.includes(document.uuid)) {
        if (!document.download?.is_accepted){
          allFilled = false;
        } 
      }
    });
    return allFilled;
  }

  loadTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { CategoryAttribute } from 'src/models/DTO/attributes/attribute';
import { Category } from 'src/models/DTO/categories/category'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private getAllCategoriesURL : string = environment.api + "api/product-categories";
  private getAllAttributesForCategoryURL =  environment.api + "api/product-categories";

  constructor(private httpClient: HttpClient) { }

  getAllCategories() {
    return this.httpClient.get<Category[]>(this.getAllCategoriesURL);
  }

  getAllRootCategories(type: string) {
    const params = RequestHelper.addMultipleParams([
      {
        key: 'code.contains',
        value: type
      },
      {
        key: 'parentCategoryId.specified',
        value: 'false'
      },
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      }
    ]);
    return this.httpClient.get<Category[]>(this.getAllCategoriesURL + params);
  }

  getAllSubcategories(categoryId: number) {
    const params = RequestHelper.addMultipleParams([
      {
        key: 'parentCategoryId.equals',
        value: categoryId
      },
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      }
    ]);
    return this.httpClient.get<Category[]>(this.getAllCategoriesURL + params);
  }

  getAllAttributesForCategory(categoryId: number) {

    const params = RequestHelper.addRESTParams([
      categoryId.toString(), "attributes"
    ]);
    return this.httpClient.get<CategoryAttribute[]>(this.getAllAttributesForCategoryURL + params);
  }

  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { WorkingTime } from 'src/models/DTO/workingTime/workingTime';
import { CreateWorkingTimeRequestModel } from 'src/models/requestModels/workingTime/workingTime'

@Injectable({
  providedIn: 'root'
})
export class WorkingtimeService {


  private createWorkingTimeUrl : string = environment.api + "api/partner-locations";
  private updateWorkingTimeUrl : string = environment.api + "api/partner-locations";
  private getWorkingTimeByLocationIdUrl : string = environment.api + "api/partner-locations";


  constructor(private httpClient: HttpClient) { }

  createWorrkingTime(workingTime: CreateWorkingTimeRequestModel) {
    const params = RequestHelper.addRESTParams([
      workingTime.partnerLocationId.toString(), "working-times"
    ])
    return this.httpClient.post(this.createWorkingTimeUrl + params, workingTime);
  }

  updateWorrkingTime(workingTime: CreateWorkingTimeRequestModel) {
    const params = RequestHelper.addRESTParams([
      workingTime.partnerLocationId.toString(), "working-times", workingTime.id.toString()
    ])
    return this.httpClient.put(this.updateWorkingTimeUrl  + params, workingTime);
  }

  getWorkingTimeByLocationId(locationId: number) {
    const params = RequestHelper.addRESTParams([
      locationId.toString(), "working-times"
    ])
    return this.httpClient.get<WorkingTime[]>(this.getWorkingTimeByLocationIdUrl + params);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { TableStore } from 'src/models/DTO/stores/tableStore';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { ToastrService } from 'ngx-toastr';
import { Successes } from 'src/config/labels/successes';
import { YesAndNo } from 'src/config/labels/yesNoOptions';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent extends BaseDashboardComponent implements OnInit {


  displayedColumnsForStoresTable: string[];
  storesTableDataSource: MatTableDataSource<TableStore>;
  tableStores: TableStore[] = [];
  @ViewChild(MatSort) sortService: MatSort;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected productService: ProductService,
    protected usersService: UsersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    private locationService: LocationsService,
    private toastrService: ToastrService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService

    ) { 
      super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
        partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.loadData();
    this.pageName = "store";
  }

  ngOnInit(): void {
  }

  async loadData() {
    await this.onDirectNavigation();
    this.locationService.getStoresForPartner(this.loggedUser.partner.id).subscribe((stores) => {
      stores.forEach((store) => {
        let tableStore: TableStore = {} as any;
        AutoMapper.map(store, tableStore, [], [
          {
            key: 'address',
            value: store.cityName + ', ' + store.address1
          }
        ]);
        this.tableStores.push(tableStore);
      })
      this.storesTableDataSource = new MatTableDataSource(this.tableStores);
      this.displayedColumnsForStoresTable = ['name', 'address', 'phone', 'pickUp', 'edit', 'delete'];
      this.storesTableDataSource.sort = this.sortService;
    })
  }

  edit(id: number) {
    const route = RequestHelper.addRESTParams([
      'dashboard', 'store', 'edit' , id.toString()
    ])
    this.router.navigate([route]);
  }

  delete(store: TableStore) {
    this.locationService.deleteStore(store.id, this.loggedUser.partner.id).subscribe(() => {
      this.storesTableDataSource.data = this.storesTableDataSource.data.filter((tableStore) => tableStore.id != store.id);
      this.toastrService.success(Successes.storeDelete[this._language]);
    })
  }

  createStore() {
    this.router.navigate(['dashboard/store/create']);
  }

  getPickUpInfo(isPickUpValid) {
    let option = '';
    const yesAndNoOptions = YesAndNo.options[this._language];
    for (let i = 0; i < yesAndNoOptions.length; i++) {
      if (yesAndNoOptions[i].value == isPickUpValid) {
        option = yesAndNoOptions[i].option;
        break;
      }
    }
    return option;
  }
}

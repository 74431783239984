import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NewProduct } from 'src/models/requestModels/product/newProductRequestModel';
import { Product } from 'src/models/DTO/products/product'
import { TableProduct } from 'src/models/DTO/products/tableProduct';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private createProductURL : string = environment.api + "api/partners";
  private deleteProductURL : string = environment.api + "api/partners"
  private getPartnerProductsURL: string = environment.api + "api/partners";
  private updateProductURL : string = environment.api + 'api/partners';

  constructor(private httpClient: HttpClient) { }

  createProduct(product: NewProduct) {
    const params = RequestHelper.addRESTParams(
      [product.partnerId.toString(), 'products']
    );
    return this.httpClient.post<Product>(this.createProductURL + params, product);
  }

  deleteProduct(product: NewProduct) {
    const params = RequestHelper.addRESTParams(
      [product.partnerId.toString(), 'products', product.id.toString()]
    );
    return this.httpClient.delete(this.deleteProductURL + params);
  }

  getActiveProductsForPartner(partnerId: number, pageNumber: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.item
      },
      {
        key: 'active.equals',
        value: true
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      }
    ]);
    return this.httpClient.get<TableProduct[]>(this.getPartnerProductsURL + params);
  }
  
  getArchivedProductsForPartner(partnerId: number, pageNumber: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.item
      },
      {
        key: 'active.equals',
        value: false
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      }
    ]);
    return this.httpClient.get<TableProduct[]>(this.getPartnerProductsURL + params);
  } 
}

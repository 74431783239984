<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <div class="wrapper">
                <img class="card-img-top" src="/assets/images/confirmation.svg" alt="Card image cap">
                <h5 class="card-title">{{this.lables(this.getPageName()).title}}</h5>
                <button (click)="this.moveForward()" class="btn btn-primary finsihBtn">{{this.lables(this.getPageName()).finsihBtn}}</button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
export const PackagingOptions = {
    options: {
        bg: [
            { option: "Кутия", value: 'box'},
        ],
        en: [
            { option: "Yes", value: true},
            { option: "No", value: false}
        ]
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { PartnerRegistrationRequestModel } from 'src/models/requestModels/V2/partner/PartnerRegistrationRequestModel';
import { InitialPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/InitialPartnerModelRequestModel';
import { VerifyPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/VerifyPartnerModelRequestModel';
import { ActivateAccountRequestModel } from 'src/models/requestModels/V2/user/ActivateAccountRequestModel';
import { UserRegistrationRequestModel } from 'src/models/requestModels/V2/user/UserRegistrationRequestModel';
import { UserUpdateRequestModel } from 'src/models/requestModels/V2/user/UserUpdateRequestModel';
import { PasswordResetRequestModel } from 'src/models/requestModels/V2/password/PasswordResetRequestModel';
import { ResetPasswordRequestModel } from 'src/models/requestModels/V2/password/ResetPasswordRequestModel';
import { ChangePasswordRequestModel } from 'src/models/requestModels/V2/password/ChangePasswordRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { NewPartner } from 'src/models/DTO/V2/partner/NewPartner';
import { PartnerVerifyState } from 'src/models/DTO/V2/partner/PartnerVerifyState';
import { NewOrganisation } from 'src/models/DTO/V2/organisation/NewOrganisation';
import { PartnerPositions } from 'src/config/labels/partnerPositions';



@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private partnerCompleteRegistrationURL : string = environment.api + "partner/auth/complete_registration";
  private partnerUpdateURL : string = environment.api + "partner/me";
  private userRegistrationURL : string = environment.api + "partner/auth/register";
  private organisationRegistrationURL : string = environment.api + "organisation";
  private organisationInitiateResetPasswordURL : string = environment.api + "partner/auth/password_reset";
  private organisationResetPasswordURL : string = environment.api + "partner/auth/password_reset_complete";
  private partnerBeginRegistrationURL : string = environment.api + "partner/auth/begin_registration";
  private partnerVerifyHashTagURL : string = environment.api + "partner/auth/verify_reg_hash";

  private partnerChangePasswordURL : string = environment.api + "partner/change_password";


  constructor(private httpClient: HttpClient,
    protected localStorageService : LocalstorageserviceService) {

  }

  registerPartner(user: UserRegistration) {
    const userRequestModel : UserRegistrationRequestModel = {
      first_name: user.firstName,
      last_name: user.lastName,
      password: user.password,
      password_confirmation: user.password,
      date_of_birth: user.birthday,
      email: user.email
    }
    return this.httpClient.post<NewPartner>(this.userRegistrationURL, userRequestModel);
  }

  registerOrganisation(user: UserRegistration) {
    const partnerRequestModel : PartnerRegistrationRequestModel = {
      partner_uuid : user.id,
      native_name : user.legalName,
      name_in_latin : user.compNameInEnglish,
      legal_form : user.type,
      number : user.bulstat,
      vat_registered : user.vatNumber ? true : false,
      vat_number : user.vatNumber,
      description : { bg: user.compDescription },
      bank_name : user.compBank,
      iban : user.compIban,
      bic_swift_code : user.compBic,
      opening_hours : user.wordays,
      partner_type: (user.shareholder) ? PartnerPositions.positions.shareholder : PartnerPositions.positions.owner
    }
    return this.httpClient.post<NewOrganisation>(this.organisationRegistrationURL, partnerRequestModel);
  }

  updatePartner(userUpdateRequestModel: UserUpdateRequestModel) {
    return this.httpClient.put<NewPartner>(this.partnerUpdateURL, userUpdateRequestModel);
  }

  updateOrganisation(partnerRequestModel: PartnerRegistrationRequestModel, organization: string) {
    const params = '/' + organization;
    return this.httpClient.put<NewOrganisation>(this.organisationRegistrationURL + params, partnerRequestModel);
  }

  activatePartner() {
    let activateAccountRequestModel : ActivateAccountRequestModel = {
      is_active: true
    }
    return this.httpClient.put<NewPartner>(this.partnerUpdateURL, activateAccountRequestModel);
  }

  confirmRegistration(hash: string) {
    const params = '/' + hash;
    return this.httpClient.get(this.partnerCompleteRegistrationURL + params);
  }

  resetPassword(passwordResetModel: ResetPasswordRequestModel, token: string) {
    const params = '/' + token;
    return this.httpClient.patch(this.organisationResetPasswordURL + params, passwordResetModel);
  }

  initiateResetPassword(initiatedPasswordResetModel: PasswordResetRequestModel) {
    return this.httpClient.post(this.organisationInitiateResetPasswordURL, initiatedPasswordResetModel);
  }

  changePassword(changePasswordModel: ChangePasswordRequestModel) {
    return this.httpClient.patch(this.partnerChangePasswordURL, changePasswordModel);
  }

  initiateRegistration(initiateRegistrationModel: InitialPartnerModelRequestModel) {
    return this.httpClient.post<PartnerVerifyState>(this.partnerBeginRegistrationURL, initiateRegistrationModel);
  }

  veifyRegistration(verifyPartnerModelRequestModel: VerifyPartnerModelRequestModel) {
    return this.httpClient.patch(this.partnerVerifyHashTagURL, verifyPartnerModelRequestModel);
  }
}

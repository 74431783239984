import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Option } from 'src/models/DTO/V2/options/Option';


@Injectable({
  providedIn: 'root'
})
export class OptionService {

  private optionURL : string = environment.api + "option";

  constructor(private httpClient: HttpClient) { }

  getOptionsForSubCategory(subCategoryUUID: string, areVariations: boolean) {
    const areVariationSuffix = (areVariations) ? 1 : 0;
    const urlParams = '?filter%5Bcategory%5D=' + subCategoryUUID + '&include=values&perPage=130&filter%5Bvariations_only%5D=' + areVariationSuffix;
    return this.httpClient.get<Option>(this.optionURL + urlParams);
  }
}

<button style="font-size: 15px;" class="close" mat-button (click)="onNoClick()">X</button>
<h3 style="text-align: center; font-size: 20px;">{{this.dashboardLables(this.getPageName()).variationHeader}}</h3>
<h5 style="text-align: center; margin: auto; font-size: 16px; color: #75809C; width: 80%; margin-bottom: 20px;">{{this.dashboardLables(this.getPageName()).variationSubHeader}}</h5>
<ngx-file-drop style="height: 320px; width: 90%;" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div cdkDropList cdkDropListOrientation="horizontal" class="example-list" (cdkDropListDropped)="drop($event)">
        <div cdkDrag style="width: 80px; margin: 5px;" *ngFor="let image of this.images; index as i">
          <h6  style="color: #0A69EB; height: 19.2px;">{{i == 0 ? 'НАЧАЛНА' : ' '}}</h6>
          <img (click)="this.showCarousel = true" style="margin-bottom: 5px; width: 80px; height: 100px; cursor: pointer;" [src]="(image && image.original) ? image.original.url : ''" alt="">
          <button class="btn image-delete-btn" (click)="this.deleteImage(image.uuid)"></button>
        </div>
      </div>
      <span class="fileUpload"></span>
      <span class="uploadFileLabel" style="color: #99A3BF;">{{this.dashboardLables(this.getPageName()).uploadFileLabel}}</span>
      <span class="uploadFileLabel" style="font-weight: bold; color: #75809C;">JPEG, PNG, TIFF</span>
      <button  type="button" class="btn btn-primary uploadFile" (click)="openFileSelector()">{{this.dashboardLables(this.getPageName()).uploadFileBtn}}</button>
    </ng-template>
</ngx-file-drop>

<div id="carouselWindow" (click)="this.closeCarouselOnOutsideClick($event)" *ngIf="this.showCarousel">
    <button style="top: 10px; right: 10px; position: absolute; width: 250px; z-index: 2;" type="button" class="btn btn-primary uploadFile" (click)="this.showCarousel = false">Затвори</button>
    <ngb-carousel>
        <ng-template *ngFor="let image of this.images;" ngbSlide>
          <div class="picsum-img-wrapper">
            <img [src]="(image && image.original) ? image.original.url : ''" alt="Random first slide">
          </div>
        </ng-template>
    </ngb-carousel>
</div>
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">{{this.labels.title}}</h4>
                <p>{{this.labels.description}}</p>
                <hr>
                <p class="mb-0">
                    {{this.labels.help}}
                    <button class="btn btn-danger" (click)="this.goToHomePage();">Go to homepage</button>
                </p>
            </div>
        </div>
    </div>
</div>
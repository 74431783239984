import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Labels } from '../../../config/labels/labels';
import { Errors } from '../../../config/labels/errors';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private toastService: ToastrService,
    private localStorageService : LocalstorageserviceService,
    private router: Router) 
    { };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error) {
          var language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
          const status =  error.status;
          if (Errors.statuses[status]) {
            Errors.statuses[status](error, this.toastService, this.router, language);
          }
          else {
            Errors.statuses.default(error, this.toastService, this.router, language);

          }
        }
        return throwError(error);
      })
    )}
  }
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';
import { CountriesService } from 'src/services/requestServices/countries/countries.service';
import { Country } from 'src/models/DTO/countries/country';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';


@Component({
  selector: 'app-personaladdress',
  templateUrl: './personaladdress.component.html',
  styleUrls: ['./personaladdress.component.css']
})
export class PersonaladdressComponent extends BaseComponent implements OnInit {

  public personalAddress : FormGroup;
  private _errorMsg = "";
  public selectedIndex = 0;
  public countries: Country[] = [{name: "България"}] as any;
  filteredOptions: Observable<Country[]>;

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private countryService: CountriesService,
    private fb: FormBuilder,
    protected router: Router) {
      super(userinfostoreService, localStorageService, router);
      this.pageName = "personalAddress";
      this.backPage = 'registration/details';
      this.nextPage = 'registration/company';
      this.personalAddress = fb.group({
        'country': [{value: '', disabled: true}, [Validators.required]],
        'city': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
        'address': ['', [Validators.required]],
        'postcode': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.alpha_dash)]],
      })
      // this.countryService.getCountries().subscribe(countries => {
      //   this.countries = countries;
      //   this.personalAddress.controls.country.setValue("България");
      // });
      this.personalAddress.controls.country.setValue("България");

    }

  ngOnInit(): void {
    this.filteredOptions = this.personalAddress.get('country').valueChanges
          .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

  }

  onChangedCountry(event) {
    const selectedCountry = event.source.value;
    this.countries.forEach(country => {
      if (country.name == selectedCountry) {
        this.user.userLocation.countryId = country.id;
      }
    })
  }

  errorMessage() {
    return this._errorMsg;
  }

  areFieldsValid() {
    let fieldName = ValidationHelper.formGroup.validate(this.personalAddress, this.lables(this.pageName));
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.lables(this.pageName).invalidField;
      return false;
    }
    return true;
  }



  private _filter(value: string): Country[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this.countries.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;

  }

  private getCountryIdFromName(name: string) {
    let country = this.countries.find((country) => country.name == name);
    return country.id;
  }

  moveForward() {
    if (this.areFieldsValid()) {
      this.user.userLocation.countryId = this.getCountryIdFromName(this.personalAddress.get('country').value);
      this.user.userLocation.postalCode = this.personalAddress.get('postcode').value;
      this.user.userLocation.cityName = this.personalAddress.get('city').value;
      this.user.userLocation.address1 = this.personalAddress.get('address').value;

      // May need to remove them later
      this.user.address = this.personalAddress.get('address').value;
      this.user.city = this.personalAddress.get('city').value;
      this.user.country = this.personalAddress.get('country').value;
      this.user.postcode = this.personalAddress.get('postcode').value;
      super.moveForward();
    }
  }
}

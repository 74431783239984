import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrderCollection } from 'src/models/DTO/V2/orders/OrderCollection';
import { OrderResponse } from 'src/models/DTO/V2/orders/OrderResponse';
import { ConfirmOrder } from 'src/models/requestModels/V2/orders/confirmOrder';
import { CancelOrder } from 'src/models/requestModels/V2/orders/cancelOrder';
import { RefundOrder } from 'src/models/requestModels/V2/orders/refundOrder';
import { CancelOrderLine } from 'src/models/requestModels/V2/orders/cancelOrderLine';





@Injectable({
  providedIn: 'root'
})
export class OrdersService {


  private orderURL : string = environment.api + "order";
  private cancelOrderURL : string = environment.api + "order/cancel";
  private cancelOrderLineURL : string = environment.api + "order/line/cancel";
  private confirmOrderURL : string = environment.api + "order/confirm";
  private searchOrderURL : string = environment.api + "order/search";
  private refundOrderURL : string = environment.api + "order/refund";





  constructor(private httpClient: HttpClient) { }

  getOrdersForPartner(perPage: number, page: number, state: string = 'all') {
    let params = '?include=user,delivery_address,invoice_address,group,lines,shipment,paymentMethod&perPage=' + perPage + '&page=' + page;
    if (state != 'all') {
      params += '&filter%5Bstate%5D=' + state;
    }
    return this.httpClient.get<OrderCollection>(this.searchOrderURL + params);
  }

  search(perPage: number, page: number, state: string = 'all', query: string) {
    let params = '?sort=newest&include=organisationFees,user,delivery_address,invoice_address,group,lines,shipment,lines.sku,paymentMethod&perPage=' + perPage + '&page=' + page + '&q=' + query;
    if (state != 'all') {
      params += '&filter%5Bstate%5D=' + state;
    }
    return this.httpClient.get<OrderCollection>(this.searchOrderURL + params);
  }

  getOrder(orderUUID: string) {
    const params = '/' + orderUUID + '?include=organisation_fees,lines.sku.options.option,lines.sku.options.option.values,payment_method,user,delivery_address,invoice_address,group,lines,shipment,lines.sku,lines.sku.product,lines.sku.product.organisation,lines.sku.product.pricing';
    return this.httpClient.get<OrderResponse>(this.orderURL + params);
  }

  cancelOrder(orderUUID: string, cancelOrderModel: CancelOrder) {
    const params = '/' + orderUUID;
    return this.httpClient.post(this.cancelOrderURL + params, cancelOrderModel);
  }

  confirmOrder(orderUUID: string, confirmOrderRequestModel: ConfirmOrder) {
    const params = '/' + orderUUID;
    return this.httpClient.post(this.confirmOrderURL + params, confirmOrderRequestModel);
  }

  cancelOrderLine(orderLineUUID: string, cancelOrderLine: CancelOrderLine) {
    const params = '/' + orderLineUUID;
    return this.httpClient.post(this.cancelOrderLineURL + params, cancelOrderLine);
  }

  downloadOrderDocument(orderUUID: string) {
    const params = '/' + orderUUID + '/download-document';
    return this.httpClient.get(this.orderURL + params, {headers: {'content-type': 'application/download'}, responseType: 'blob'});
  }

  refundOrder(orderUUID: string, refundOrder: RefundOrder) {
    const param = '/' + orderUUID;
    return this.httpClient.post(this.refundOrderURL + param, refundOrder);
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';
import { CompanyTypes } from '../../../config/labels/companyTypes';
import { Labels } from 'src/config/labels/labels';
import { CheckerTypes } from 'src/config/labels/checkerTypes';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { validateIBAN } from 'ngx-iban-validator';
import { CheckerService } from 'src/services/requestServices/V2/checkers/checker.service';
import { Errors } from 'src/config/labels/errors';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-companyaddress',
  templateUrl: './companyaddress.component.html',
  styleUrls: ['./companyaddress.component.css']
})
export class CompanyaddressComponent extends BaseComponent implements OnInit {

  public companyDetails : FormGroup;
  private _compTypes;
  private errorMsg: string;
  private _duplicateOrganisationErrors = [];
  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private fb: FormBuilder,
    protected router: Router, 
    private checkerService: CheckerService, 
    private toastr: ToastrService) {
    super(userinfostoreService, localStorageService, router);
    this.pageName = "companyAddress";
    this.backPage = 'registration/address/details';
    this.nextPage = 'registration/company/details';
    this._compTypes = CompanyTypes.compTypes;
    this.companyDetails = fb.group({
      'legalName': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      'tradingName': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.latinFull)]],
      // 'nameInEnglish': ['', [Validators.pattern(ValidationConfig.regexes.latin)]],
      'type': [this._compTypes[this.localStorageService.getLocalStorageItem(Labels.lsKey)][0].value, [Validators.required]],
      'bulstat': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.alpha_dash), this.validateBulstat]],
      'vat': ['No', [Validators.required]],
      'vatNumber': ['', [Validators.pattern(ValidationConfig.regexes.alpha_dash), Validators.maxLength(255)]],
      'iban': ['', [Validators.required, this.validateIban]],
      'bank': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      'bic': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.bic)]],
      'description': ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylicOnly)]],

    })
    this._duplicateOrganisationErrors.push(
      Errors.duplicateOrganisation.bulstat[this.language],
      Errors.duplicateOrganisation.vat[this.language]
    )
  }

  ngOnInit(): void {
  }

  compTypes() {
    return this._compTypes[this.localStorageService.getLocalStorageItem(Labels.lsKey)];
  }

  private  validateIban(control: AbstractControl): ValidationErrors | null {
    if (control.value && validateIBAN( { value: control.value } ).ibanInvalid) {
      return {ibanInvalid: {value: control.value}}
    }
    return null;
  }


  vat() {
    return this.companyDetails.get('vat').value;
  }

  errorMessage() {
    return this.errorMsg;
  }

  validateBulstat (control: AbstractControl): {[key : string]: boolean} | null {
    const value = control.value.toString();
    if (value.length >= 9 && value.length <= 255){
      return null;
    }
    return {'validateDate': false};
  };

  arePersonalDetailsValid() {
    let fieldName = ValidationHelper.formGroup.validate(this.companyDetails, this.lables(this.pageName));
    if (this.vat() == "Yes" && 
        !this.companyDetails.get('vatNumber').value) {
          fieldName = this.lables(this.pageName).vatNumber;
    }
    let cyrilycRE = new RegExp(ValidationConfig.regexes.cyrilycWithSymbols, 'i');
    if (this.companyDetails.get('description').value.search(cyrilycRE) > -1) {
      fieldName = this.lables(this.pageName).description;

    }
    if (fieldName != '') {
      this.error = true;
      this.errorMsg = fieldName + this.lables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  duplicateValidation() {
    let duplicateCheckPromisesArray = [
      this.checkerService.duplicatePartnerCheck({
        number: this.companyDetails.get('bulstat').value,
        check_type: CheckerTypes.duplicateOrganisationCkeck.checks.bulstat
      }).toPromise()
    ]
    if (this.companyDetails.get('vatNumber').value) {
      duplicateCheckPromisesArray.push(
        this.checkerService.duplicatePartnerCheck({
          vat_number: this.companyDetails.get('vatNumber').value,
          check_type: CheckerTypes.duplicateOrganisationCkeck.checks.vat
        }).toPromise()
      )
    }
    return duplicateCheckPromisesArray;
  }


  moveForward() {
    if (!this.arePersonalDetailsValid()) {
      return;
    }
    Promise.all(this.duplicateValidation()).then(responses => {
      for (let i = 0; i < responses.length; i++) {
        if (!responses[i].data.valid) {
          this.toastr.error(this._duplicateOrganisationErrors[i])
          return false;
        }
      }
      this.user.legalName = this.companyDetails.get('legalName').value;
      this.user.tradingName = this.companyDetails.get('tradingName').value;
      this.user.type = this.companyDetails.get('type').value;
      this.user.bulstat = this.companyDetails.get('bulstat').value;
      this.user.compDescription = this.companyDetails.get('description').value;
      this.user.compIban = this.companyDetails.get('iban').value;
      this.user.compBic = this.companyDetails.get('bic').value;
      this.user.compBank = this.companyDetails.get('bank').value;
      this.user.compNameInEnglish = this.companyDetails.get('tradingName').value;
      if (this.vat() == "Yes") {
        this.user.vatNumber = this.companyDetails.get('vatNumber').value;
      }
      super.moveForward();
    })
  }

}

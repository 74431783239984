import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StoreformComponent } from 'src/app/shared/storeform/storeform.component';
import { Successes } from 'src/config/labels/successes';
import { Store } from 'src/models/DTO/stores/store';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { WorkingtimeService } from 'src/services/requestServices/workingtime/workingtime.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';

@Component({
  selector: 'app-editstore',
  templateUrl: './editstore.component.html',
  styleUrls: ['./editstore.component.css']
})
export class EditstoreComponent extends BaseDashboardComponent implements OnInit {

  @ViewChild(StoreformComponent)
  private _storeformComponent: StoreformComponent;
  private _currentStore: Store = {} as any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    private locationService: LocationsService,
    private workingTimeService:  WorkingtimeService,
    private toastr: ToastrService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService

  ) { 
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.getCurrentStore();
    this.pageName = "editStore";
  }

  ngOnInit(): void {
  }

  async getCurrentStore() {
    if (!this.router.navigated) {
      await this.getUserInfo();
    }
    this.getUrlParams().subscribe((params) => {
      this._params = params.params;
      this.locationService.getStoreById(this._params.id).subscribe((store) => {
        this._currentStore = store;
        this.workingTimeService.getWorkingTimeByLocationId(this._currentStore.id).subscribe((workingTime) => {
          this._storeformComponent.setStore(this._currentStore, workingTime[0]);
        });
      });
    });
  }

  save() {
    if (this._storeformComponent.validation()) {
      this._storeformComponent.loggedUser = this.loggedUser;
      this.locationService.updateStore(this._storeformComponent.prepareRequestModel()).subscribe(() => {
        let workingTime = this._storeformComponent.returnWorkingTime();
        workingTime.partnerId = this.loggedUser.partner.id;
        workingTime.partnerLocationId = this._currentStore.id;
        this.workingTimeService.updateWorrkingTime(workingTime).subscribe(() => {
          this.toastr.success(Successes.storeCreation[this._language]);
          this.router.navigate(['dashboard/stores'])
        })
      })
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcenotfoundComponent } from './resourcenotfound/resourcenotfound.component';



@NgModule({
  declarations: [ResourcenotfoundComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ResourcenotfoundComponent
  ]
})
export class ErrorsModule { }

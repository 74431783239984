import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { City } from 'src/models/DTO/cities/city';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  private getAllCitiesByCountryURL : string = environment.api + "api/partner-cities";
  private getAllCitiesURL : string = environment.api + "api/cities";


  constructor(private httpClient: HttpClient) { }

  getCities(countryId: number, ln: string = 'bg') {
    const params = RequestHelper.addMultipleParams([
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      },
      {
        key: 'partnerId.equals',
        value: countryId
      }
    ]);
    return this.httpClient.get<City[]>(this.getAllCitiesByCountryURL + params);
  }

  getAllCities(string = 'bg') {
    const params = RequestHelper.addMultipleParams([
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      },
    ]);
    return this.httpClient.get<City[]>(this.getAllCitiesURL + params);
  }
}

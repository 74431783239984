import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { Contact } from 'src/models/DTO/V2/contact/Contact';
import { CreateContactRequestModel } from 'src/models/requestModels/V2/contact/CreateContactRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private contactRegistrationURL : string = environment.api + "contact";

  constructor(private httpClient: HttpClient,
    protected localStorageService : LocalstorageserviceService) {

  }

  registerPartnerContact(userInfo: UserRegistration) {
    const createContactRequestModel : CreateContactRequestModel = {
      contactable_type:  'PARTNER',
      contactable_uuid:  userInfo.id,
      country_uuid:  'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
      type:  'PARTNER_PERSONAL_TYPE_CONTACT',
      city:  userInfo.city,
      county:  userInfo.country,
      postcode:  userInfo.postcode,
      street_name:  userInfo.address,
      street_number: 0,
      mobile_phone_1_country_code:  'BG',
      mobile_phone_1:  userInfo.telephone,
      landline_phone_country_code:  'BG',
      contact_person_first_name: userInfo.firstName,
      contact_person_last_name:  userInfo.lastName
    }
    return this.httpClient.post<any>(this.contactRegistrationURL, createContactRequestModel);
  }

  registerOrganisationContact(userInfo: UserRegistration, organisationId: string) {
    const createContactRequestModel : CreateContactRequestModel = {
      contactable_type:  'ORGANISATION',
      contactable_uuid:  organisationId,
      country_uuid:  'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
      type:  'ORGANISATION_TYPE_CONTACT',
      city:  userInfo.comCity,
      county:  userInfo.compCountry,
      postcode:  userInfo.compPostcode,
      street_name:  userInfo.compAddress,
      street_number: 0,
      mobile_phone_1_country_code:  'BG',
      mobile_phone_1:  userInfo.compTelephone,
      landline_phone_country_code:  'BG',
      contact_person_first_name: userInfo.compNameInEnglish,
      contact_person_last_name:  userInfo.compNameInEnglish
    }
    return this.httpClient.post<any>(this.contactRegistrationURL, createContactRequestModel);
  }

 updatePartnerContact(createContactRequestModel: CreateContactRequestModel, currentContactUIID: string) {
    const params = '/' + currentContactUIID;
    return this.httpClient.put<any>(this.contactRegistrationURL + params, createContactRequestModel);
  }

  createOrganisationContact(createContactRequestModel: CreateContactRequestModel) {
    return this.httpClient.post<any>(this.contactRegistrationURL, createContactRequestModel);
  }

  updateOrganisationContact(createContactRequestModel: CreateContactRequestModel, organisationId: string, currentContactUIID: string) {
    const params = '/' + currentContactUIID;
    return this.httpClient.put<any>(this.contactRegistrationURL + params, createContactRequestModel);
  }

  getContactInfoForEntity(contactUIID: string) {
    const params = '/' + contactUIID + '?include=country&fields%5Bcontacts%5D=id%2Cuuid%2Ccountry_id%2Ctype%2Ccity%2Cstreet_name%2Cstreet_number%2Cpost_code%2Cmobile_phone_1_e164&fields%5Bcountry%5D=id%2Cuuid%2Cname'
    return this.httpClient.get<Contact>(this.contactRegistrationURL + params);
  }
}

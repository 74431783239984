import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CarrierAddressComponent } from 'src/app/dialogs/carrier-address/carrier-address.component';
import { PasswordConfirmComponent } from 'src/app/dialogs/confirmPassword/password-confirm/password-confirm.component';
import { CompanyTypes } from 'src/config/labels/companyTypes';
import { ReturnPeriods } from 'src/config/labels/returnPeriods';
import { Successes } from 'src/config/labels/successes';
import { environment } from 'src/environments/environment';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { Carrier } from 'src/models/DTO/V2/carrier/Carrier';
import { CarrierPredefinedAddresses } from 'src/models/DTO/V2/carrier/CarrierPredefinedAddresses';
import { CarrierAccount } from 'src/models/DTO/V2/carrier/CarrierAccount';
import { CarrierAddressItem } from 'src/models/DTO/V2/carrier/CarrierAddressItem';
import { CreateCarrierRequestModel } from 'src/models/requestModels/V2/carrier/CreateCarrierRequestModel';
import { UpdateDeliveryRequestModel } from 'src/models/requestModels/V2/delivery/UpdateDeliveryRequestModel';
import { WorkHours as WorkHoursRequestModel } from 'src/models/requestModels/V2/workHours/WorkHours';
import { WorkHours } from 'src/models/requestModels/V2/workHours/WorkHours';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ContractService } from 'src/services/requestServices/contracts/contract.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DeliveryService } from 'src/services/requestServices/V2/delivery/delivery.service';
import { LogisticsService } from 'src/services/requestServices/V2/logistics/logistics.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { StripeService } from 'src/services/requestServices/V2/stripe/stripe.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { SetNewDefaultCarrierAddressRquestModel } from 'src/models/requestModels/V2/carrier/SetNewDefaultCarrierAddressRquestModel';
import { DispatchTypes } from 'src/config/labels/dispatchTypes';
import { PackageSizes } from 'src/config/labels/packageSizes';
import { DeliveryExtras } from 'src/config/labels/deliveryExtras';
import { PaymentTypes } from 'src/config/labels/paymentTypes';
import { CreateLogisticsRequestModel } from 'src/models/requestModels/V2/logistics/CreateLogisticsRequestModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedLabels } from 'src/config/labels/sharedLabels'
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { UpdateCarrierPasswordRequestModel } from 'src/models/requestModels/V2/carrier/UpdateCarrierPasswordRequestModel';



@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css']
})
export class CarriersComponent extends BaseDashboardComponent implements OnInit {

  displayedColumnsForAddressesTable = [];

  @ViewChild("deliverymodel") _modal: ElementRef;
  @ViewChild("changeSpeedyUsername") _speedyModal: ElementRef;

  private _selectedAddress: any;
  private _errorMsg: string;
  private _password: string;
  private _carriers: Carrier;
  private _currentCarrier: CarrierAccount = { data: []};
  private _initialValuesToValidate = ['deliveryToAddressFee', 'deliveryToCourierOfficeFee', 'freeShippingOverAmount', 'speedyUsername', 'speedyPassword'];
  private _secondaryValuesToValidate = ['deliveryToAddressFee', 'deliveryToCourierOfficeFee', 
                                        'freeShippingOverAmount', 'dispatch', 
                                        'size', 
                                        'payment', 'pickupHour'];


  private _predefinedCarrierAddress: CarrierPredefinedAddresses = { data: [] };

  private _requirements = [
    {
      req: 'logistics',
      tab: 'delivery'
    },
    {
      req: 'payments',
      tab: 'payments'
    }
  ]

  public delivery : FormGroup;
  public return : FormGroup;
  public carrierForm : FormGroup;
  public selectedAddres = null;
  public showBody: boolean = false;
  public pickUPHourAlert = '';

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    private FB: FormBuilder,
    private toastr: ToastrService,
    private contractService: ContractService,
    private documentService: DocumentsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    private deliveryService: DeliveryService,
    private matDialog: MatDialog,
    protected stripeService: StripeService,
    protected carrierService: CarrierService,
    private logisticsService: LogisticsService,
    private modalService: NgbModal,
    private loadingState: LoadingstateService
    
  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentService);
    this.pageName = "carriers";
    this.loadData();
    this.loadingState.loading$.subscribe(result => {
      console.log(result);
      this.isLoading = result;
      if (this.isLoading) {
        setTimeout( () => { this.showBody = true }, 1500);
      }
      else {
        this.showBody = false;
      }
    });
  }

  ngOnInit(): void {
  }

  private async loadData() {
    await this.getUserInfo();
    const currentOrganization = this.loggedPartner.data.organisations[0];
    this._carriers = await this.carrierService.getCarriers().toPromise();
    this._currentCarrier = await this.carrierService.getCarriearAccount().toPromise();
    if (this._currentCarrier?.data[0]?.uuid) {
      this._predefinedCarrierAddress = await this.carrierService.getCarrierAddresses(this._currentCarrier.data[0].uuid).toPromise();
    }
    else {
      this.modalService.open(this._modal, { centered: true } );
    }
    this.selectedAddres = this._predefinedCarrierAddress?.data.find(address => address.id == this._currentCarrier?.data[0]?.settings.client_id)
    this.delivery = this.FB.group({
      deliveryToAddressFee: [currentOrganization.delivery_to_address_price, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      deliveryToCourierOfficeFee: [currentOrganization.delivery_to_courier_office_price, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      freeShippingOverAmount: [currentOrganization.free_delivery_after_order_amount, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
      speedyUsername: ['', [Validators.required]],
      speedyPassword: ['', [Validators.required]],
      dispatch: [this._currentCarrier?.data[0]?.settings.dispatch_parcel_from || DispatchTypes.organisation, [Validators.required]],
      size: [this._currentCarrier?.data[0]?.settings.label_size || PackageSizes.a4, [Validators.required]],
      // extras: [this._currentCarrier?.data[0]?.settings.delivery_extras, [Validators.required]],
      payment: [this._currentCarrier?.data[0]?.settings.payment_type || PaymentTypes.cash, [Validators.required]],
      pickupHour: [this._currentCarrier?.data[0]?.settings.pickup_hour]

    })
    this.displayedColumnsForAddressesTable = ['Selection', 'Address', 'Company', 'Contact', 'Telephone', 'ClientID'];
  }

  private checkIfValidationIsReallyNeeded(formGroup: FormGroup) {
    let fieldsThatNeedValidation = this._initialValuesToValidate;
    if (this._currentCarrier?.data[0]?.uuid) {
      fieldsThatNeedValidation = this._secondaryValuesToValidate;
    }
    for (let index = 0; index < fieldsThatNeedValidation.length; index++) {
      const fieldToValidate = fieldsThatNeedValidation[index];
      if (!this.delivery.get(fieldToValidate).valid) {
        return false;
      }
      
    }
    return true;
  }

  private validate(formGroup: FormGroup) {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(formGroup, this.dashboardLables(this.getPageName()));
    if (fieldName != '' && !this.checkIfValidationIsReallyNeeded(formGroup)) {
      this.error = true;
      this._errorMsg = fieldName + this.dashboardLables(this.getPageName()).invalidField;
      return false;
    }
    if (this.delivery.get('dispatch').value == this.dispatchTypes.organisation) {
      if (!this.delivery.get('pickupHour').value) {
        this.error = true;
        this._errorMsg = "Въведете час на вземане.";
        return false;
      }
    }
    return true;
  }

  private prepareWorkHours(workHours: WorkHours) : WorkHoursRequestModel  {
    let response : WorkHoursRequestModel = {} as any;
    Object.keys(workHours).forEach(key => {
      if (Array.isArray(workHours[key]) && workHours[key].length) {
        response[key] = {};
        response[key].hours = workHours[key][0].split('-');
      }
    })
    return response;
  }

  private getCarrierByName(name: string) {
    return this._carriers.data.find(carrier => carrier.name = name);
  }

  private createCarrier() {
    const createCarrierModel: CreateCarrierRequestModel = {
      accountable: {
        uuid: this.loggedPartner.data.organisations[0].uuid,
        type: 'organisation'
      },
      carrier_uuid: this.getCarrierByName(environment.carriers.speedy)?.uuid,
      username:  this.delivery.get('speedyUsername').value,
      password:  this.delivery.get('speedyPassword').value
    }
    return this.carrierService.createCarrierAccount(createCarrierModel).toPromise();
  }


  private updateCarrierDetails() {
    const createLogisticsRequestModel: CreateLogisticsRequestModel = {
      password: this._currentCarrier.data[0].settings.password,
      dispatch_parcel_from: this.delivery.get('dispatch').value,
      pickup_hour: this.delivery.get('pickupHour').value,
      label_size: this.delivery.get('size').value,
      delivery_extras: this._currentCarrier?.data[0]?.settings.delivery_extras.split(','),
      payment_type: this.delivery.get('payment').value,
    }
    return this.logisticsService.createLogistics(this._currentCarrier.data[0].uuid, createLogisticsRequestModel).toPromise();
  }

  get errorMessage() : string {
    return this._errorMsg;
  }

  get returnPeriods() {
    return ReturnPeriods.periods[this._language];
  }

  get currentCarrier() {
    return this._currentCarrier.data[0];
  }

  get currentCarrierAddresses() {
    return this._predefinedCarrierAddress;
  }

  get dispatchTypes() {
    return DispatchTypes;
  }

  get packageSizes() {
    return PackageSizes;
  }

  get deliveryExtras() {
    return DeliveryExtras;
  }

  get paymentTypes() {
    return PaymentTypes;
  }

  get sharedLabels() {
    return SharedLabels[this._language]
  }

  clearError() {
    this.error = false;
    this._errorMsg = '';

  }

  public updateCarrierLogin(modal) {
    const updateCarrierPassword: UpdateCarrierPasswordRequestModel = {
      password:  this.delivery.get('speedyPassword').value
    }
    modal.close()
    return this.carrierService.updateCarrierPassword(this.getCarrierByName(environment.carriers.speedy)?.uuid, updateCarrierPassword).toPromise();
  }

  onPickupChange(event) {
    if (event.target.value < '09' || event.target.value > '18') {
      this.pickUPHourAlert = 'Моля въведи час между 09:00 и 18:00';
    }
    else {
      this.pickUPHourAlert = '';
    }
  }

  saveSelectedAddress(element) {
    this.selectedAddres = element;
    this.delivery.markAsDirty();
  }

  setAsDefaultAddress() {
    let element = this.selectedAddres;
    const carrierAddressModel: SetNewDefaultCarrierAddressRquestModel = {
      password: this._currentCarrier.data[0].settings.password,
      client_id: element.id,
      dispatch_parcel_from: this.delivery.get('dispatch').value,
      pickup_hour: this.delivery.get('pickupHour').value,
      label_size: this.delivery.get('size').value,
      delivery_extras: this._currentCarrier?.data[0]?.settings.delivery_extras?.split(','),
      payment_type: this.delivery.get('payment').value,
    }
    this.carrierService.updateCarrierAddresses(this._currentCarrier.data[0].uuid, carrierAddressModel).subscribe(() => {
      this.toastr.success(Successes.updateCarrierAddressEntry[this._language]);
    })
  }

  saveCarrier(formGroup: FormGroup) {
    if (this._currentCarrier?.data[0]?.uuid) {
      this.updateCarrierDetails().then(() => {
        this.loadData();
        this.toastr.success(Successes.updatePartner[this._language]);
      })
    }
    else {
      this.createCarrier().then(() => {
        this.loadData();
        this.getUserInfo();
        this.toastr.success(Successes.updatePartner[this._language]);
      })
    }
  }

  onSpeedyClick() {
    this.modalService.open(this._speedyModal, { centered: true } );
  }

  save(formGroup: FormGroup) {
    if (!this.validate(formGroup)) {
      return false;
    }
    this.clearError();
    const dialogRef  = this.matDialog.open(PasswordConfirmComponent, {
      width: '400px',
      height: '350px',
      disableClose: true,
      data: {password: this._password}
    });
    const currentOrganization = this.loggedPartner.data.organisations[0];
    dialogRef.afterClosed().subscribe(result => {
      this._password = result;
      const updateDeliveryModel: UpdateDeliveryRequestModel = {
        delivery_to_address_price: this.delivery.get('deliveryToAddressFee').value || 0,
        delivery_to_courier_office_price: this.delivery.get('deliveryToCourierOfficeFee').value || 0,
        free_delivery_after_order_amount: this.delivery.get('freeShippingOverAmount').value || 0,
        product_max_return_period_in_days: currentOrganization.product_max_return_period_in_days || '14',
        password: this._password,
        partner_uuid: this.loggedPartner.data.uuid,
        native_name: currentOrganization.native_name,
        name_in_latin: currentOrganization.name_in_latin,
        legal_form: CompanyTypes.compTypes[this._language].find((type) => type.type == currentOrganization.legal_form.label).value,
        number: currentOrganization.number,
        vat_registered: (currentOrganization.vat_number) ? true : false,
        vat_number: currentOrganization.vat_number,
        description: { bg: currentOrganization.description } ,
        bank_name: currentOrganization.bank_name,
        iban: currentOrganization.iban,
        bic_swift_code: 'BUINBGSF',
        opening_hours: this.prepareWorkHours(currentOrganization.opening_hours),
        partner_type: currentOrganization.partner_type
      }
      this.deliveryService.updateDelivery(updateDeliveryModel, currentOrganization.uuid).subscribe(() => {
          this.loadData();
          this.toastr.success(Successes.updatePartner[this._language]);
      }, (error) => {
        this.loadData();
      })
    });
  }

  addAddress() {
    const dialogRef  = this.matDialog.open(CarrierAddressComponent, {
      width: '400px',
      height: '350px',
      disableClose: true,
    });
  }
}

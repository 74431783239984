import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Labels } from 'src/config/labels/labels';
import { environment } from 'src/environments/environment';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { UserRegistrationRequestModel } from 'src/models/requestModels/user/UserRegistrationRequestModel';
import { UserLoginRequestModel } from 'src/models/requestModels/user/UserLoginRequestModel';
import { LocalstorageserviceService } from '../../infrastructureServices/localstorageservice/localstorageservice.service';
import { ChangePasswordRequestModel } from 'src/models/requestModels/passwords/changePasswordRequestModel';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ResetPasswordRequestModel } from 'src/models/requestModels/passwords/resetPasswordRequestModel';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private userRegistrationURL : string = environment.api + "api/account";
  private userInfoURL : string = environment.api + "api/account";
  private userLoginURL : string = environment.api + "api/authenticate";
  private resetPassowordURL : string = environment.api + "api/account/reset-password/init";
  private changePasswordURL : string = environment.api + "api/account/change-password";
  private resetNewPasswordURL: string = environment.api + 'api/account/reset-password/finish'


  
  constructor(private httpClient: HttpClient,
    protected localStorageService : LocalstorageserviceService) {

  }

  private prepareUserForRegistration(user: UserRegistration) {
    let userRequestModel : UserRegistrationRequestModel = {
      email: user.email,
      userName: user.email,
      password: user.password,
      dob: user.birthday,
      phone: user.telephone,
      langKey: this.localStorageService.getLocalStorageItem(Labels.lsKey),
      firstName: user.firstName,
      lastName: user.lastName,
      login: user.email,
      id: 1,
      registerAsManager: user.shareholder,
      registerAsOwner: user.owner,
      activated: true
      
    }
    return userRequestModel;
  }

  private prepareUserForLogin(user: UserRegistration) {
    let userRequestModel = {};
    userRequestModel = AutoMapper.map(user, userRequestModel, [{from: "userName", to: "username"}, {from: "email", to: "username"}], [{key: "rememberMe", value: (user.rememberMe) ? true : false}]);
    return <UserLoginRequestModel> userRequestModel;
  }

  registerUser(user: UserRegistration) {
    const userRequestModel = this.prepareUserForRegistration(user);
    return this.httpClient.put<any>(this.userRegistrationURL, userRequestModel);
  }

  loginUser(user: UserRegistration) {
    const userRequestModel = this.prepareUserForLogin(user);
    return this.httpClient.post<any>(this.userLoginURL, userRequestModel);
  }

  resetPassword(email: string) {
    return this.httpClient.post<any>(this.resetPassowordURL, email);
  }

  getUserInfo() {
    return this.httpClient.get<any>(this.userInfoURL);
  }

  isUserLoggedIn() : boolean {
    return (this.localStorageService.getLocalStorageItem(Labels.lsTokenKey)) ? true : false;
  }

  logout(): void {
    this.localStorageService.removeLocalStorageItem(Labels.lsTokenKey)
  }

  changePassword(passwordRequestModel: ChangePasswordRequestModel) {
    return this.httpClient.post(this.changePasswordURL, passwordRequestModel);
  }

  resetNewPassword(resetPasswordModel: ResetPasswordRequestModel) {
    return this.httpClient.post(this.resetNewPasswordURL, resetPasswordModel);
  }
}

export const ReservationIntevals = {
    intevals: {
        bg: [
            { inteval: "10 мин", value: "MINUTES_10"},
            { inteval: "15 мин", value: "MINUTES_15"},
            { inteval: "30 мин", value: "MINUTES_30"},
            { inteval: "45 мин", value: "MINUTES_45"},
            { inteval: "60 мин", value: "MINUTES_60"},
            { inteval: "90 мин", value: "MINUTES_90"},
            { inteval: "120 мин", value: "MINUTES_120"},
            { inteval: "1 ден", value: "DAY_1"}
        ],
        en: [
            { inteval: "10 min", value: "MINUTES_10"},
            { inteval: "15 min", value: "MINUTES_15"},
            { inteval: "30 min", value: "MINUTES_30"},
            { inteval: "45 min", value: "MINUTES_45"},
            { inteval: "60 min", value: "MINUTES_60"},
            { inteval: "90 min", value: "MINUTES_90"},
            { inteval: "120 min", value: "MINUTES_120"},
            { inteval: "1 day", value: "DAY_1"}
        ],
    },

    values: {
        MINUTES_10: 10,
        MINUTES_15: 15,
        MINUTES_30: 30,
        MINUTES_45: 45,
        MINUTES_60: 60,
        MINUTES_90: 90,
        MINUTES_120: 120,
        DAY_1: 1440
    }
}

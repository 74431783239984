import { Component, OnInit } from '@angular/core';
import { UserinfostoreService } from '../../../services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LocalstorageserviceService } from '../../../services/infrastructureServices/localstorageservice/localstorageservice.service';
import { BaseComponent } from '../../base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent extends BaseComponent implements OnInit {

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router: Router) { 
      super(userinfostoreService, localStorageService, router);
      this.nextPage = 'registration/email';
      this.pageName = "positionPage";
    }


  ngOnInit(): void {
    this.user.shareholder = false;
    this.user.owner = false;

  }

  onUserTypeChange(event, position) {
    this.user[position] = !this.user[position];
    this.userinfostoreService.addNewUser(this.user);
    console.log(this.user);
    //this.updateCheckedElementView();
  }

  updateCheckedElementView() {
    let borderedElements = document.getElementsByClassName("bordered");
    const classNameAppend = " active";
    for (let i = 0; i < borderedElements.length; i++) {
      if (borderedElements[i].querySelectorAll('.form-check-input:checked').length) {
        if (borderedElements[i].className.indexOf(classNameAppend) < 0) {
          borderedElements[i].className += classNameAppend; 
        }
      }
      else {
        borderedElements[i].className = borderedElements[i].className.replace(classNameAppend, "");
      }
    }
  }

  moveForward() {
    if (this.user.owner || this.user.shareholder) {
      super.moveForward();
    }
    else {
      this.error = true;
    }
  }
}

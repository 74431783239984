import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { Item } from 'src/models/DTO/base/item';
import { NewItem } from 'src/models/requestModels/base/newItem';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  private updateItemURL : string = environment.api + 'api/partners';
  private deleteItemURL : string = environment.api + 'api/partners';
  private getItemURL: string = environment.api + 'api/partners';


  constructor(private httpClient: HttpClient) { }

  updateItem(item: NewItem) {
    const params = RequestHelper.addRESTParams(
      [item.partnerId.toString(), 'products', item.id.toString()]
    );
    return this.httpClient.put(this.updateItemURL + params, item);
  }

  deleteItem(item: NewItem) {
    const params = RequestHelper.addRESTParams(
      [item.partnerId.toString(), 'products', item.id.toString()]
    );
    return this.httpClient.delete(this.deleteItemURL + params);
  }

  getItem(partnerid: number, productId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerid.toString(), 'products', productId.toString()]
    );
    return this.httpClient.get<Item>(this.getItemURL + params);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SkuCollection } from 'src/models/DTO/V2/sku/SkuCollection';
import { Sku } from 'src/models/DTO/V2/sku/Sku';
import { CreateSkuRequestModel } from 'src/models/requestModels/V2/sku/CreateSkuRequestModel';

@Injectable({
  providedIn: 'root'
})
export class SkuService {

  private skuURL : string = environment.api + "sku";
  private getSkuUrl : string = this.skuURL + '/product/';



  constructor(private httpClient: HttpClient) { }

  createSku(skuRequestModel: CreateSkuRequestModel) {
    return this.httpClient.post<Sku>(this.skuURL, skuRequestModel);
  }

  updateSku(skuUUID: string, sku: string, quantity: number) {
    const params = '/' + skuUUID;
    const payload = {
      sku: sku,
      quantity: quantity
    }
    return this.httpClient.put<any>(this.skuURL + params, payload);
  }

  getSkus(productUUID: string) {
    return this.httpClient.get<SkuCollection>(this.getSkuUrl + productUUID);
  }

  deleteSku(skuUUID: string) {
    return this.httpClient.delete<any>(this.skuURL + '/' + skuUUID);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LayoutsModule } from './layouts/layouts.module';
import { RegistrationModule } from './registration/registration.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CoreModule } from './../services/core/core.module';
import { ImageselectionComponent } from './dialogs/ImageSelect/imageselection/imageselection.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { SharedModule } from './shared/shared.module';
import { ErrorsModule } from './errors/errors.module';
import { StripeModule } from './stripe/stripe.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { PasswordConfirmComponent } from './dialogs/confirmPassword/password-confirm/password-confirm.component';
import { MatInputModule } from '@angular/material/input';
import { CarriersComponent } from './dashboard/carriers/carriers.component';
import { CarrierAddressComponent } from './dialogs/carrier-address/carrier-address.component';





@NgModule({
  declarations: [
    AppComponent,
    ImageselectionComponent,
    PasswordConfirmComponent,
    CarrierAddressComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
	  BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        preventDuplicates: true,
        maxOpened: 1
      }
    ),
    LayoutsModule,
    RegistrationModule,
    DashboardModule,
    MatDialogModule,
    SharedModule,
    CoreModule,
    ErrorsModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    StripeModule,
    FormsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReservationIntevals } from 'src/config/labels/reservationInterval';
import { Successes } from 'src/config/labels/successes';
import { environment } from 'src/environments/environment';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { Category } from 'src/models/DTO/categories/category';
import { Service } from 'src/models/DTO/services/Service';
import { NewService } from 'src/models/requestModels/services/createNewServiceRequestModel';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { CategoriesService } from 'src/services/requestServices/categories/categories.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { ServiceService } from 'src/services/requestServices/services/service.service';
import { ImageuploadComponent } from 'src/app/shared/images/imageupload.component';
import { StorestabsComponent } from 'src/app/shared/storestabs/storestabs.component';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseItemComponent } from '../base-item/base-item.component';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { Store } from 'src/models/DTO/stores/store';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';

@Component({
  selector: 'app-addservice',
  templateUrl: './addservice.component.html',
  styleUrls: ['./addservice.component.css']
})
export class AddserviceComponent extends BaseItemComponent implements OnInit {


  public serviceInfo: FormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public base64Files: Base64Image[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  @ViewChild(ImageuploadComponent)
  private _imageUploadComponent: ImageuploadComponent;
  @ViewChild(StorestabsComponent)
  private _storeTabsComponent: StorestabsComponent;

  private _categories : Category[];
  private _subCategories: Category[];
  private _errorMsg: string;
  private _createdService: Service;
  


  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected usersService: UsersService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected locationService: LocationsService,
    private FB: FormBuilder,
    private categoryService: CategoriesService,
    private itemService: ItemService,
    private toastrService: ToastrService,
    private serviceService: ServiceService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService

  ) { 
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, locationService, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.pageName = "addService";
    this.serviceInfo = FB.group({
      category: ['', [Validators.required]],
      subcategory: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description2: ['', [Validators.required]],
      description: ['', [Validators.required]],
      terms: ['', [Validators.required]],
      pricing: ['', [Validators.required]],
      resInterval: [this.intevals[0].value, [Validators.required]],
      stores: ['', [Validators.required]],
    });

    this.getAllRootCategories(environment.serviceKey).then(
      (rootCategories) => {
        this._categories = rootCategories;
        this.serviceInfo.get('category').setValue(this.categories[0].id);
        this.executePromisesOnCategoryChange(this.categories[0].id);
    });

    this.loggeduserinfoservice.userInfo$.subscribe(user => {
      if (Object.keys(user).length) {
        this.createNewService().then(service => {
          this._storeTabsComponent.setProductId(service.id);
          this._createdService = service;
          this._imageUploadComponent.setCreated(this._createdService);
        })
      }
    })

}

async getStores() {
  await this.onDirectNavigation();
  await this.checkForAddedStores();
  this._storeTabsComponent.setStores(this.stores);
  if (this.showMissingStoreAlert()) {
    this.toastrService.warning('You will not be able to add product without stores', 'Adding a product', { timeOut: 9500 })
  }
}

  get categories() : Category[] {
    return this._categories;
  }

  get subcategories() : Category[] {
    return this._subCategories;
  }

  get errorMessage() : string {
    return this._errorMsg;
  }

  get intevals() {
    return ReservationIntevals.intevals[this._language];
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.base64Files = this._imageUploadComponent.base64Files;
    this._storeTabsComponent.setFormGroup(this.serviceInfo);
    this.getStores();
  }

  getStoreByName(name: string) : Store {
    let matchedStore : Store;
    this.stores.forEach((store) => {
      if (store.name == name) {
        matchedStore = store;
      }
    })
    return matchedStore;
  }

  getAllRootCategories(type: string) : Promise<Category[]> {
    return this.categoryService.getAllRootCategories(type).toPromise();
  }

  getAllSubCategories(id) {
    return this.categoryService.getAllSubcategories(id).toPromise();
  }

  executePromisesOnCategoryChange(id) {
    this.getAllSubCategories(id).then(
      subCategories => {
        this._subCategories = subCategories;
        this.serviceInfo.get('subcategory').setValue(this.subcategories[0].id);
      }
    )
  }

  categoryChange(event) {
    this.executePromisesOnCategoryChange(event.target.value);
  }

  prepareServiceForUpload() {
    this._createdService.category01Id = this.serviceInfo.get('category').value;
    this._createdService.category02Id = this.serviceInfo.get('subcategory').value;
    this._createdService.unitPriceAmt = this.serviceInfo.get('pricing').value;
    this._createdService.description2 = this.serviceInfo.get('description2').value;
    this._createdService.description = this.serviceInfo.get('description').value;
    this._createdService.name = this.serviceInfo.get('name').value;
    this._createdService.reservationInterval = 'MINUTES_120';
    this._createdService.type = environment.service;
  }

  upload() {
    if (this.validateBeforeUpload()) {
      this.prepareServiceForUpload();
      this.itemService.updateItem(this._createdService)
        .subscribe(() => {
          this.toastrService.success(
            Successes.serviceCreation[this._language]
          )
          this.goToDashboard();
        });
    }
  }

  validateBeforeUpload() {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this.serviceInfo, this.dashboardLables(this.pageName))
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.dashboardLables(this.pageName).invalidField;
      return false;
    }
    return true;
  }


  createNewService() {
    let newService : NewService = {} as any;
    newService.code = Core.getRandomString(24);
    newService.partnerId = this.loggedUser.partner.id;
    return this.serviceService.createService(newService).toPromise();
  }

  handleCancelRedirect() {
    if (this._createdService && Object.keys(this._createdService).length) {
      this.serviceService.deleteService(this._createdService).subscribe(() => {
        this.router.navigate(['dashboard/products']);
      })
    }
    else {
      this.router.navigate(['dashboard/products']);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Labels } from 'src/config/labels/labels';
import { PartnerPositions } from 'src/config/labels/partnerPositions';
import { Successes } from 'src/config/labels/successes';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { VerifyPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/VerifyPartnerModelRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent extends BaseComponent implements OnInit {


  public passwordGroup : FormGroup
  private errorMsg: string;

  constructor(
    protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private fb: FormBuilder,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private toastr: ToastrService) {
      super(userinfostoreService, localStorageService, router);
      this.nextPage = "registration/details";
      this.pageName = 'passwordConfirmation';
      this.backPage = "registration/positionPage";
      this.passwordGroup = fb.group({
        'password': ['', [Validators.required, 
                          Validators.minLength(8),
                          this.passwordValidator]],
        'confirmPassword': ['', [Validators.required, 
                                 Validators.minLength(8),
                                 this.passwordValidator]],
      })

     }

  ngOnInit(): void {
    const language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const shareorowner = params['shareorowner'];
      const hash = params['hash'];
      const verifyPartnerModelRequestModel : VerifyPartnerModelRequestModel = {
        hash: hash
      };
      this.registrationService.veifyRegistration(verifyPartnerModelRequestModel).subscribe(() => {
          this.user.email = email;
          this.user.shareholder = PartnerPositions.positions.shareholder == shareorowner;
          this.userinfostoreService.addNewUser(this.user);
          const language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
          this.toastr.success(Successes.confirmedAccount[language]);
      });
    })
  }

  checkForSamePasswords(password, confirmationPassword) {
    if (password != confirmationPassword) {
      return false;
    }
    return true;
  }

  passwordValidator (control: AbstractControl): {[key : string]: boolean} | null {
    const value = <string> control.value;
    if ( value.search(/\d/) < 0 || 
         value.search(/[a-zA-Zа-яА-Я]/) < 0 || 
         value.search(/\W/) < 0){
      return {'passwordValidator': false};
    }
    return null;
  };

  arePasswordsValid() {
    if (!this.passwordGroup.get('password').valid ||
        !this.passwordGroup.get('confirmPassword').valid) {
      this.errorMsg = this._labels.passwordConfirmation.invalidPassword;
      return false;
    }
    else if (!this.checkForSamePasswords(this.passwordGroup.get('password').value,
                                         this.passwordGroup.get('confirmPassword').value)) {
      this.errorMsg = this._labels.passwordConfirmation.notSamePassword;
      return false;
    }
    return true;
  }

  errorMessage() {
    return this.errorMsg;
  }

  moveForward() {
    if (!this.arePasswordsValid()) {
      this.error = true;
      return;
    }
    this.user.password = this.passwordGroup.get('password').value;
    super.moveForward();
  }
}

<div style="text-align: center;">
    <button style="margin: 5px; float: left; width: auto;" class="btn backTab" (click)="this.backToStores()">{{this.dashboardLables.back}}</button>
    <h4 style="color: #0A69EB;">{{this.dashboardLables.pageTitle}}</h4>
</div>

<form [formGroup]="this.store" class="form-group">
    <br>
    <label >{{this.dashboardLables.name}}</label>
    <input formControlName="name" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.name">
    <small id="emailHelp" class="text-muted">{{this.dashboardLables.nameInfo}}</small>

    <!-- <label >{{this.dashboardLables.code}}</label>
    <input formControlName="code" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.code">
    <small id="emailHelp" class="text-muted">{{this.dashboardLables.codeInfo}}</small> -->

    <label >{{this.dashboardLables.country}}</label>
    <mat-form-field class="country-full-width" >
        <input type="text"
            matInput
            formControlName="country"
            [matAutocomplete]="auto"
            id="inputGroupSelect01"/>
        <!-- <mat-autocomplete (optionSelected)="this.onCountryChange($event)" #auto="matAutocomplete"> -->
        <mat-autocomplete  #auto="matAutocomplete">
            <mat-option *ngFor="let c of this.filteredOptions | async;"  [value]="c.name">
                {{c.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <label >{{this.dashboardLables.city}}</label>
    <mat-form-field class="country-full-width" >
        <input type="text"
            matInput
            formControlName="city"
            [matAutocomplete]="auto1"
            id="inputGroupSelect02"/>
        <mat-autocomplete #auto1="matAutocomplete">
            <mat-option *ngFor="let c of this.filteredCityOptions | async;"  [value]="c.name">
                {{c.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <label >{{this.dashboardLables.address}}</label>
    <input formControlName="address" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.address">

    <label >{{this.dashboardLables.post}}</label>
    <input formControlName="post" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.post">

    <label >{{this.dashboardLables.phone}}</label>
    <input formControlName="phone" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.phone">

    <label >{{this.dashboardLables.pickUp}}</label>
    <select formControlName="pickUp"  class="form-control" id="pickUp">
        <option *ngFor="let yesNo of this.yesAndNo;" [value]="yesNo.value">{{yesNo.option}}</option>
    </select>    
    <small id="emailHelp" class="text-muted">{{this.dashboardLables.pickUpInfo}}</small>

    <!-- <label >{{this.dashboardLables.title}}</label>
    <input formControlName="title" type="email" class="form-control mx-sm-4" id="exampleInputEmail1" aria-describedby="emailHelp" [placeholder]="this.dashboardLables.title"> -->
    <app-workingtimes></app-workingtimes>
</form>
<div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
    {{this.errorMessage('price')}}
</div>

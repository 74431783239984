import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { Service } from 'src/models/DTO/services/Service';
import { TableService } from 'src/models/DTO/services/tableService';
import { NewService } from 'src/models/requestModels/services/createNewServiceRequestModel';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  
  private createServiceURL : string = environment.api + "api/partners";
  private deleteServiceURL : string = environment.api + "api/partners"
  private getPartnerServiceURL: string = environment.api + "api/partners";
  private updateServiceURL : string = environment.api + 'api/partners';

  constructor(private httpClient: HttpClient) { }

  createService(service: NewService) {
    const params = RequestHelper.addRESTParams(
      [service.partnerId.toString(), 'products']
    );
    return this.httpClient.post<Service>(this.createServiceURL + params, service);
  }

  
  deleteService(service: NewService) {
    const params = RequestHelper.addRESTParams(
      [service.partnerId.toString(), 'products', service.id.toString()]
    );
    return this.httpClient.delete(this.deleteServiceURL + params);
  }

  getActiveServicesForPartner(partnerId: number, pageNumber: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.service
      },
      {
        key: 'active.equals',
        value: true
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      }
    ]);
    return this.httpClient.get<TableService[]>(this.getPartnerServiceURL + params);
  } 

  getArchivedServicesForPartner(partnerId: number, pageNumber: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'products']
    ) + RequestHelper.addMultipleParams([
      {
        key: 'type.equals',
        value: environment.service
      },
      {
        key: 'active.equals',
        value: false
      },
      {
        key: environment.pageParam,
        value: pageNumber
      },
      {
        key: environment.sizeRequestParam,
        value: environment.productPerPage
      }
    ]);
    return this.httpClient.get<TableService[]>(this.getPartnerServiceURL + params);
  } 
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { CreateImageModel } from 'src/models/requestModels/images/CreateImageRequestModel';
import { Image } from 'src/models/DTO/images/image';
import { CreateVariantImageRequestModel } from 'src/models/requestModels/images/CreateVariantImageRequestModel';
import { VariantImage } from 'src/models/DTO/images/variantImage';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';


@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private createProductImageURL : string = environment.api + "api/products";
  private createPartnerImageURL : string = environment.api + "api/partners"
  private updatePartnerImageURL : string = environment.api + "api/partners"
  private getPartnerImageURL : string = environment.api + "api/partners"
  private deleteProductImageURL : string = environment.api + "api/images";
  private getImageForItemURL : string = environment.api + "api/products";
  private createVariantImageURL : string = environment.api + "api/product-variants";
  private getVariantImageURL : string = environment.api + "api/product-variants"
  private updateVariantImageURL : string = environment.api + "api/product-variants"

  constructor(private httpClient: HttpClient) { }

  prepareImageForCreation(image: CreateImageModel, data : object) {
    AutoMapper.map(data, image);
  }

  createProductImage(image: CreateImageModel) {
    const params = RequestHelper.addRESTParams(
      [image.productId.toString(), 'images']
    );
    return this.httpClient.post<Image>(this.createProductImageURL + params, image);
  }

  createPartnerImage(image: CreateImageModel) {
    const params = RequestHelper.addRESTParams(
      [image.partnerId.toString(), 'images']
    );
    return this.httpClient.post<Image>(this.createPartnerImageURL + params, image);
  }

  updatePartnerImage(image: CreateImageModel) {
    const params = RequestHelper.addRESTParams(
      [image.partnerId.toString(), 'images', image.id.toString()]
    );
    return this.httpClient.put<Image>(this.updatePartnerImageURL + params, image);
  }

  deleteImage(imageID: number) {
    const params = RequestHelper.addRESTParams(
      [imageID.toString()]
    );
    return this.httpClient.delete(this.deleteProductImageURL + params);
  }

  getImagesForItem(productId: number) {
    const params = RequestHelper.addRESTParams(
      [productId.toString(), 'images']
    );
    return this.httpClient.get<Image[]>(this.getImageForItemURL + params);
  }

  getImagesForPartner(partnerId: number) {
    const params = RequestHelper.addRESTParams(
      [partnerId.toString(), 'images']
    );
    return this.httpClient.get<Image[]>(this.getPartnerImageURL + params);
  }

  getVariantImagesForProduct(variantId: number) {
    const params = RequestHelper.addRESTParams(
      [variantId.toString(), 'images']
    );
    return this.httpClient.get<VariantImage[]>(this.getVariantImageURL + params);
  }

  createVariantImagesForProduct(variantImage: CreateVariantImageRequestModel) {
    const params = RequestHelper.addRESTParams(
      [variantImage.productVariantId.toString(), 'images']
    );
    return this.httpClient.post<VariantImage>(this.createVariantImageURL + params, variantImage);
  }

  updateVariantImagesForProduct(variantImage: CreateVariantImageRequestModel) {
    const params = RequestHelper.addRESTParams(
      [variantImage.productVariantId.toString(), 'images', variantImage.id.toString()]
    );
    return this.httpClient.put<VariantImage>(this.updateVariantImageURL + params, variantImage);
  }
}

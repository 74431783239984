import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { ErrorTranslations } from "src/config/labels/errorTranslations";


export const Errors = {
    statuses: {
        '422': function(error: HttpErrorResponse, toastr: ToastrService, router: Router, language: string = 'bg') {
            const errorDetected = error?.error?.data?.errors;
            toastr.error(ErrorTranslations.translate(errorDetected[Object.keys(errorDetected)[0]][0], language));
        },
        '409': function(error: HttpErrorResponse, toastr: ToastrService, router: Router, language: string = 'bg') {
            toastr.error(Errors.responseCatchedErrors[409][language]);
        },
        '401': function(error: HttpErrorResponse, toastr: ToastrService, router: Router, language: string = 'bg') {
            const url = error.url;
            if (Errors.responseCatchedErrors[401][url]) {
                toastr.error(Errors.responseCatchedErrors[401][url][language]);
            }
            else {
                toastr.error(Errors.responseCatchedErrors[401].default[language]);
            }
            router.navigate(['/registration/login'])
        },
        default: function(error: HttpErrorResponse, toastr: ToastrService, router: Router, language: string = 'bg') {
            return Errors.default[language];
        },
    },
    responseCatchedErrors: {
        '401': {
            'https://test-api.shareme.co/v1/partner/auth/login': {
                bg: "Невалидни потребител или парола",
                en: "Invalid credentials"
            },
            default: {
                bg: "Изтекла сесия",
                en: "Registration timed out",
            }
        },
        '409': {
            bg: 'Въвели сте грешна стара парола'
        }
    },
    userexists : {
        bg: "Такъв потребител вече съществува",
        en: "Such user already exists"
    },
    imageUpload: {
        bg: "Качването на снимката е неуспешно",
        en: "The image was uploaded unsuccessful"
    },
    imageType: {
        bg: "Типът на снимката не се поддържа",
        en: "The image extension is invalid"
    },
    imageMaxAmount: {
        bg: "Можете да добавите до 8 снимки",
        en: "You can add upp to 8 pictures only"
    },
    imageMaxSize: {
        bg: " надхвърля 8 мегабайта",
        en: " exceeds 8 megabytes"
    },
    default: {
        en: "There is a problem with the system. Contact the support.",
        bg: "Има грешка със системата. Свържете се с екипа за поддръжка."
    },

    errrorPages: {
        resourceNotFound:
        {
            en: {
                title: 'Item not found',
                description: 'Cannot find your item. Your link is invalid.',
                help: 'Call the support for more information.'
            },
            bg: {
                title: 'Артикулът не е намерен',
                description: 'Не можем да намерим вашият артикул. Може би линкът не е валиден.',
                help: 'Обърнете се към екипа за поддръжка за повече информация.'
            }
        }
    },

    pricing: {
        from: {
            min: {
                en: "The minimal amount is " + environment.initialNumberOfPriceRanges,
                bg: "Количеството трябва да е по-голямо от " + environment.initialNumberOfPriceRanges 
            },
            biggerThen: {
                en: "The amount must be bigger than the one from the next pricing",
                bg: "Количеството трябва да е по-голямо от това на следващия ценови диапазон"  
            },
            smallerThen: {
                en: "The amount must be smaller than the one from the previous pricing",
                bg: "Количеството трябва да е по-малко от това на предишния ценови диапазон"  
            },
            smallerThenTo: {
                en: "The amount must be smaller than the one from the previous pricing",
                bg: "Количеството трябва да е по-голямо от количеството на предходния диапазон."  
            },
            max: {
                en: "The amount must be smaller than the one from the final amount",
                bg: "Количеството трябва да е по-голямо от това на крайното количество" 
            }
        },
        to: {
            biggerThen: {
                en: "The amount must be bigger than the one from the initial amount",
                bg: "Количеството трябва да е по-голямо от това на началното количество"  
            },
        },
        price: {
            biggerThen: {
                en: "The price must be bigger than the one from the prevois pricing",
                bg: "Цената трябва да е по-голямa от тази на следващия ценови диапазон"  
            },
            smallerThen: {
                en: "The price must be smaller than the one from the next pricing",
                bg: "Цената трябва да е по-ниска от предходния диапазон"  
            }
        }

    },

    passwordMatch: {
        en: "Your password confirmation is invalid",
        bg: "Новите пароли не съвпадат"
    },

    duplicateOrganisation: {
        bulstat: {
            bg: "Фирма с това ЕИК/Булстат е вече регистрирана"
        },
        vat: {
            bg: "Фирма с такъв ДДС номер е вече регистрирана"
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { MatTableModule } from '@angular/material/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailsComponent } from './details/details.component';



@NgModule({
  declarations: [OverviewComponent, DetailsComponent],
  imports: [
    CommonModule,
    MatTableModule,
    NgbModule

  ]
})
export class StripeModule { }

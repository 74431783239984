<h1 mat-dialog-title>{{ dialogs.saveChangesLabel }}</h1>
<div mat-dialog-content>
  <p class="modal-title">{{ dialogs.saveAddress }}</p>
  <hr>
  <div>
    <label for="typeahead-prevent-manual-entry">{{ dialogs.country }}</label>
    <input id="typeahead-prevent-manual-entry"  type="text" class="form-control"
        [(ngModel)]="addressDataToAdd.country"
        [ngbTypeahead]="searchCountry"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]='false'
        />
  </div>
  <div *ngIf="this.addressDataToAdd.country >= 0y">
    <label for="typeahead-prevent-manual-entry">{{ dialogs.city }}</label>
    <input id="typeahead-prevent-manual-entry"  type="text" class="form-control"
        [(ngModel)]="addressDataToAdd.city"
        [ngbTypeahead]="searchCity"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]='false' />
  </div>
  <div *ngIf="this.addressDataToAdd.city >= 0">
    <label for="typeahead-prevent-manual-entry">{{ dialogs.street }}</label>
    <input id="typeahead-prevent-manual-entry"  type="text" class="form-control"
        [(ngModel)]="addressDataToAdd.street"
        [ngbTypeahead]="searchStreet"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]='false' />
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-transparent margin" mat-button (click)="onNoClick()">{{ dialogs.cancel }}</button>
  <button class="btn btn-primary margin" style="float: right;"  mat-button [mat-dialog-close]="addressDataToAdd" cdkFocusInitial>{{ dialogs.OK }}</button>
</div>
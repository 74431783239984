import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ConfirmEmailRequestModel } from '../../../models/requestModels/emailConfirmationModels/ConfirmEmailRequestModel';


@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationService {

  private emailConfirmationURL : string = environment.api + "api/register";


  constructor(private httpClient: HttpClient) { }

  confirmEmail(email)  {
    let confirmUserEmailModel : ConfirmEmailRequestModel = {
      email: email,
      langKey: 'bg',
      login: email,
      password: email
    }
    return this.httpClient.post<any>(this.emailConfirmationURL, confirmUserEmailModel);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { CreatePricingRequestModel } from 'src/models/requestModels/pricings/createPricingRequestModel';
import { Pricing } from 'src/models/DTO/pricing/pricing';


@Injectable({
  providedIn: 'root'
})
export class PricingService {

  private createPricingForProductURL = environment.api + "api/products";
  private getPricingForProductsURL = environment.api + "api/products";
  private updatePricingForProductURL = environment.api + "api/products";
  private deletePricingForProductURL = environment.api + "api/price-amendments"

  constructor(private httpClient: HttpClient) { }

  createPricings(pricing: FormGroup, productId: number, priceRangeCount: number) : Promise<any>[] {
    let postRequestsToPromises : Promise<any>[] = [];
    let pricings = this.preparePricings(pricing, productId, priceRangeCount);
    const params = RequestHelper.addRESTParams(
      [productId.toString(), 'price-amendments']
    ); 
    pricings.forEach(pricing => {
      postRequestsToPromises.push(
        this.httpClient.post(this.createPricingForProductURL + params, pricing).toPromise() 
      )
    });
    return postRequestsToPromises;
  } 

  updatePricings(pricing: FormGroup, productId: number, priceRangeCount: number) : Promise<any>[] {
    let postRequestsToPromises : Promise<any>[] = [];
    let pricings = this.preparePricings(pricing, productId, priceRangeCount);
    pricings.forEach(pricing => {
      if (pricing.id) {
        const params = RequestHelper.addRESTParams(
          [productId.toString(), 'price-amendments', pricing.id.toString()]
        ); 
        postRequestsToPromises.push(
          this.httpClient.put(this.updatePricingForProductURL + params, pricing).toPromise() 
        )
      }
      else {
        const params = RequestHelper.addRESTParams(
          [productId.toString(), 'price-amendments']
        ); 
        postRequestsToPromises.push(
          this.httpClient.post(this.createPricingForProductURL + params, pricing).toPromise() 
        )
      }
    });
    return postRequestsToPromises;
  } 

  private preparePricings(pricing: FormGroup, productId: number, priceRangeCount: number): CreatePricingRequestModel[] {
    let newPricingsModels: CreatePricingRequestModel[] = [ ] as any;
    for(let i = 0; i < priceRangeCount; i++) {
      let indexForUnitsTo = i;
      if (i < environment.initialNumberOfPriceRanges) {
        indexForUnitsTo = environment.initialNumberOfPriceRanges - 1;
      }
      let newPricing: CreatePricingRequestModel = {} as any;
      newPricing.qtyFrom = pricing.get('unitsFrom' + i).value;
      newPricing.qtyTo = pricing.get('unitsTo' + indexForUnitsTo).value;
      newPricing.unitPriceAmt = pricing.get('pricing' + i).value;
      newPricing.productId = productId;
      if (pricing.get('id' + i) && pricing.get('code' + i)) {
        newPricing.id = pricing.get('id' + i).value;
        newPricing.code = pricing.get('code' + i).value;
      }
      else {
        newPricing.code = Core.getRandomString(24);
      }
      newPricingsModels.push(newPricing); 
    }
    return newPricingsModels;
  }

  getPricingsForProduct(productId: number)  {
    const params = RequestHelper.addRESTParams([
      productId.toString(), 'price-amendments'
    ])
    return this.httpClient.get<Pricing[]>(this.getPricingForProductsURL + params);
  }

  deletePricings(pricingIds: number[]) {
    return this.httpClient.request('DELETE', this.deletePricingForProductURL, { body: { ids: pricingIds } })
  }
}

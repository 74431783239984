import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageserviceService {

  constructor() { }

  setLocalStorageItem(key : string, value : any) {
    localStorage.setItem(key, value);
  }

  removeLocalStorageItem(key : string) {
    localStorage.removeItem(key);
  }

  getLocalStorageItem(key : string) {
    return localStorage.getItem(key)
  }
}

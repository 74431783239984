import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';
import { Calendar } from 'src/models/DTO/calendar/calendar';
import { CreateCalendarRequestModel } from 'src/models/requestModels/calendar/createCalendarRequestModel';
import { CalendarEntry } from 'src/models/DTO/calendar/calendarEntry';
import { CreateCalendarEntryRequestModel } from 'src/models/requestModels/calendar/createCalendarEntryRequestModel';


@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  
  private createCalendarUrl: string = environment.api + 'api/products';
  private getCalendarUrl: string = environment.api + 'api/products';
  private changeCalendarUrl: string = environment.api + 'api/products';

  private createCalendarEntryUrl: string = environment.api + 'api/reservation-calendars';
  private getCalendarEntryUrl: string = environment.api + 'api/reservation-calendars';
  private changeCalendarEntryUrl: string = environment.api + 'api/reservation-calendars';

  constructor(private httpClient: HttpClient) { }

  // Cancelation
  createCalendar(calendar: CreateCalendarRequestModel) {
    const params = RequestHelper.addRESTParams([
      calendar.productId.toString(), 'reservation-calendars'
    ])
    return this.httpClient.post<Calendar>(this.createCalendarUrl + params, calendar);
  }

  getCalendarByProductAndLocation(productId: number, locationId: number) {
    let params = RequestHelper.addRESTParams([
      productId.toString(), 'reservation-calendars'
    ]);
    params += RequestHelper.addMultipleParams([
      {
        key: 'productId.equals',
        value: productId.toString()
      },
      {
        key: 'partnerLocationId.equals',
        value: locationId.toString()
      },
    ])
    return this.httpClient.get<Calendar[]>(this.getCalendarUrl + params);
  }

  updateCalendar(calendar: CreateCalendarRequestModel) {
    const params = RequestHelper.addRESTParams([
      calendar.productId.toString(), 'reservation-calendars'
    ])
    return this.httpClient.put(this.changeCalendarUrl + params, calendar);
  }

  createCalendarEntry(calendarEntry: CreateCalendarEntryRequestModel) {
    let params = RequestHelper.addRESTParams([
      calendarEntry.reservationCalendarId.toString(), 'reservation-calendar-entries'
    ]);
    return this.httpClient.post<CalendarEntry>(this.createCalendarEntryUrl + params, calendarEntry);

  }

  getCalendarEntries(calendarId: number) {
    let params = RequestHelper.addRESTParams([
      calendarId.toString(), 'reservation-calendar-entries'
    ]);
    params += RequestHelper.addMultipleParams([
      {
        key: 'reservationCalendarId.equals',
        value: calendarId.toString()
      }
    ])
    return this.httpClient.get<CalendarEntry[]>(this.getCalendarEntryUrl + params);
  }

  updateCalendarEntry(calendarEntry: CreateCalendarEntryRequestModel) {
    const params = RequestHelper.addRESTParams([
      calendarEntry.reservationCalendarId.toString(), 'reservation-calendar-entries', calendarEntry.id.toString()
    ])
    return this.httpClient.put(this.changeCalendarEntryUrl + params, calendarEntry);
  }
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DASHBOARD_LABLES } from 'src/config/labels/dashboard';
import { Labels } from 'src/config/labels/labels';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { ProductLocation } from 'src/models/DTO/productLocation/productLocation';
import { Store } from 'src/models/DTO/stores/store';
import { CreateProductLocationRequestModel } from 'src/models/requestModels/productLocation/createProductLocationRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ProductlocationService } from 'src/services/requestServices/productlocation/productlocation.service';

@Component({
  selector: 'app-storestabs',
  templateUrl: './storestabs.component.html',
  styleUrls: ['./storestabs.component.css']
})
export class StorestabsComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public stores: Store[] = [];
  public storesAsStrings: string[] = [];
  public filteredStores: Observable<string[]>;
  public selectedStores: string[] = [];
  public selectedStoresForm: Store[] = [];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public loadComponent = false;
  public infoFormGroup: FormGroup;

  private _dashboardLables;
  private _pageName = "";
  private _language;
  private _productLocations: ProductLocation[] = [];
  private _productId: number = 0;

  @ViewChild('storeInput') storeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  constructor(
    private localStorageService: LocalstorageserviceService,
    private productLocationService: ProductlocationService
  ) {
    this._pageName = 'stores';
    this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
    this._dashboardLables = DASHBOARD_LABLES[this._language];
  }

  ngOnInit(): void {
  }

  setFormGroup(fg: FormGroup) {
    this.infoFormGroup = fg;
    this.loadComponent = true;
  }

  setStores(stores: Store[]) {
    this.stores = stores;
    this.stores.forEach((store) => {
      this.storesAsStrings.push(store.name);
    })
    this.filteredStores = this.infoFormGroup.get('stores').valueChanges.pipe(
      startWith(null),
      map((store: string | null) => store ? this._filter(store) : this.storesAsStrings.slice()))
  } 

  dashboardLables(pagename: string) {
    return this._dashboardLables[pagename];
  } 

  getPageName() {
    return this._pageName;
  }

  setProductId(id) { 
    this._productId = id;
    this.productLocationService.getProductLocationByProductAndLocation(this._productId).subscribe((locations) => {
      this._productLocations = locations;
      this._productLocations.forEach(store => {
        this.selectedStores.push(this.getStoreNameById(store.partnerLocationId))
      })
    })
  }
  
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.storesAsStrings.indexOf(value.trim()) < 0) {
        return;
      }
      this.selectedStores.push(value.trim());
      this.addStore(value);
      this.infoFormGroup.get('stores').setValue(this.selectedStores)
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  private addStore(storeName) {
    if (this._productId) {
      let productLocation: CreateProductLocationRequestModel = {} as any;
      productLocation.code = Core.getRandomString(24);
      productLocation.partnerLocationId = this.getStoreIdByName(storeName.trim());
      productLocation.productId = this._productId;
      this.productLocationService.createProductLocation(productLocation).subscribe((productLocation) => {
        this._productLocations.push(productLocation);
      })
    }
  }

  private removeStore(storeName) {
    if (this._productId) {
      const locationToDelete = this._productLocations.find(location => location.partnerLocationId == this.getStoreIdByName(storeName.trim()));
      this.productLocationService.deleteProductLocation(this._productId, locationToDelete.id).subscribe(() => {
        return;
      });
    }
  }

  private getStoreIdByName(storeName: string) : number{
    return this.stores.find(store => store.name == storeName).id;
  }

  private getStoreNameById(storeId: number) : string{
    return this.stores.find(store => store.id == storeId).name;
  }

  remove(removedStore: string): void {
    const index = this.selectedStores.findIndex((store) => store == removedStore);

    if (index >= 0) {
      this.selectedStores.splice(index, 1);
      this.infoFormGroup.get('stores').setValue(this.selectedStores)
    }
    this.removeStore(removedStore);
  }

  private _filter(value: string): string[] {
    let store = value;
    if (Array.isArray(value)) {
      store = value[0];
    }

    const filterValue = store.toLowerCase();

    return this.storesAsStrings.filter(store => store.toLowerCase().indexOf(filterValue) === 0);
  }
  
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedStores.push(event.option.viewValue);
    this.addStore(event.option.viewValue);
    this.storeInput.nativeElement.value = '';
  }
}

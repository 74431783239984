import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { ImageselectionComponent } from 'src/app/dialogs/ImageSelect/imageselection/imageselection.component';
import { ImageuploadComponent } from 'src/app/shared/images/imageupload.component';
import { StorestabsComponent } from 'src/app/shared/storestabs/storestabs.component';
import { Conditions } from 'src/config/labels/conditions';
import { Errors } from 'src/config/labels/errors';
import { Genders } from 'src/config/labels/genders';
import { Successes } from 'src/config/labels/successes';
import { YesAndNo } from 'src/config/labels/yesNoOptions';
import { environment } from 'src/environments/environment';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { CategoryAttribute } from 'src/models/DTO/attributes/attribute';
import { Brand } from 'src/models/DTO/brands/brand';
import { Category } from 'src/models/DTO/categories/category';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { Pricing } from 'src/models/DTO/pricing/pricing';
import { Product } from 'src/models/DTO/products/product';
import { TableVariation } from 'src/models/DTO/variations/tableVariation';
import { CreateBrandModel } from 'src/models/requestModels/brands/cereateBrandRequestModel';
import { CreateVariantImageRequestModel } from 'src/models/requestModels/images/CreateVariantImageRequestModel';
import { NewProduct } from 'src/models/requestModels/product/newProductRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { BrandsService } from 'src/services/requestServices/brands/brands.service';
import { CategoriesService } from 'src/services/requestServices/categories/categories.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { PricingService } from 'src/services/requestServices/pricing/pricing.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { VariationsService } from 'src/services/requestServices/variations/variations.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { BaseItemComponent } from '../base-item/base-item.component';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';

declare var bootstrap: any;


@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css']
})
export class EditproductComponent extends BaseItemComponent implements OnInit {

  productInfo:  FormGroup;
  specs: FormGroup;
  pricing: FormGroup;
  ship: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  variationDataSource: MatTableDataSource<TableVariation>;
  displayedColumnsForProductTable: string[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public base64Files: Base64Image[] = [];
  public pricings: Pricing[] = [];
  public deletedPricings: Pricing[] = [];
  public deletedVariations: TableVariation[] = [];




  private _tabidToFormgroup : object;
  private _categories: Category[];
  private _subCategories: Category[];
  private _brands: Brand[];
  private _categoryAttributes: CategoryAttribute[];
  private _priceRangeCount: number;
  private _errorMsgs = {
    info: "",
    specs: "",
    price: "",
    inventory: "",
    ship: ""
  };
  private _currentItem: Product;
  private _hasInstanceAttribute: boolean = false;
  private ELEMENT_DATA : TableVariation[] = [];
  private _customBrandId: number;

  @ViewChild(ImageuploadComponent) set image(content: ImageuploadComponent) {
    if(content) { // initially setter gets called with undefined
        this._imageUploadComponent = content;
        this.base64Files = this._imageUploadComponent.base64Files;
        this._imageUploadComponent.setCreated(this._currentItem);
        this._imageUploadComponent.deleteImageCallback = (imageId) => {
        this.variationDataSource.data.forEach(element => {
          if (element.file.id == imageId) {
            element.file = null;
          }
        })
      } 
    }
  }
  private _imageUploadComponent: ImageuploadComponent;
  @ViewChild(StorestabsComponent) set store(content: StorestabsComponent) {
    if(content) { // initially setter gets called with undefined
        this._storeTabsComponent = content;
        this._storeTabsComponent.setFormGroup(this.ship);
        this._storeTabsComponent.setProductId(this._currentItem.id);
        this.getStores()
    }
  }
  private _storeTabsComponent: StorestabsComponent;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    protected locationsService: LocationsService,
    private categoryService: CategoriesService,
    private productService: ProductService,
    private itemService: ItemService,
    private toastrService: ToastrService,
    private brandsService: BrandsService,
    private variationService: VariationsService,
    private pricingService: PricingService,
    private dialogService: MatDialog,
    private fb: FormBuilder,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService

  ) { 
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, locationsService, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.onDirectNavigation();
    this.pageName = "editProduct";

    this.displayedColumnsForProductTable = ['image', 'size', 'color', 'SKU', 'quantity', 'action'];
    this.variationDataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.getCurrentProduct();

    this._tabidToFormgroup = {
      info: this.productInfo,
      specs: this.specs,
      price: this.pricing,
      inventory: null,
      ship: this.ship
    }

  }

  get condtions() {
    return Conditions.conditions[this._language];
  }

  get genders() {
    return Genders.genders[this._language];
  }

  get yesAndNo() {
    return YesAndNo.options[this._language];
  }
  
  get categories() : Category[] {
    return this._categories;
  }

  get subCategories() : Category[] {
    return this._subCategories;
  }

  get brands() : Brand[] {
    return this._brands;
  }

  get attributes() : CategoryAttribute[] {
    return this._categoryAttributes;
  }

  get priceRangeCount() {
    return this._priceRangeCount;
  }

  get customBrandId() {
    return this._customBrandId;
  }

  get defaultAttr():  string[] {
    return ['gender', 'condition', 'description'];
  }

  get disabledAttr():  string[] {
    return ['color', 'size'];
  }

  get isPricingFormValid() {
    return Object.keys(this.pricing.controls).length;
  }

  ngOnInit(): void {
  }

  async getStores() {
    await this.onDirectNavigation();
    await this.checkForAddedStores();
    this._storeTabsComponent.setStores(this.stores);
    if (this.showMissingStoreAlert()) {
      this.toastrService.warning('You will not be able to add product without stores', 'Adding a product', { timeOut: 9500 })
    }
  }

  async getCurrentProduct() {
    if (!this.router.navigated) {
      await this.getUserInfo();
    }
    this.getUrlParams().subscribe((params) => {
      this._params = params.params;
      this.itemService.getItem(this.loggedUser.partner.id, this._params.id).subscribe((product) => {
        if (product.type != environment.item) {
          this.router.navigate(['error/resource/notfound']);
          return;
        }
        this._currentItem = product;
        this.initForm();

      })
    });
  }

  private initForm() {
    this.productInfo = this.fb.group({
      category: [this._currentItem.category01Id, [Validators.required]],
      subcategory: [this._currentItem.category02Id, [Validators.required]],
      name: [this._currentItem.name, [Validators.required]],
      brand: [this._currentItem.category03Id, [Validators.required]],
      manufacturer: [this._currentItem.manufacturer, [Validators.required]],
      condition: [this.condtions[0].value, [Validators.required]],
      barcode: [this._currentItem.ean, [Validators.required]],
      SKU: [this._currentItem.sku, [Validators.required]],
      VAT: [this._currentItem.vatCategory, [Validators.required]],
      warranty: [this._currentItem.guaranteeDurationInMonths, [Validators.required]],
      customBrand: ['']
    });
    

    this.specs = this.fb.group({
      quantity: [this._currentItem.qtyAvailableUnit, [Validators.required]],
      gender: [this._currentItem.gender, [Validators.required]],
      description: [this._currentItem.description, [Validators.required]]
    });
    this.pricing = this.fb.group({
    });

    this.ship = this.fb.group({
      pickUp: [this._currentItem.dlvPickUp, [Validators.required]],
      stores: [[], [Validators.required]],
      shippWithin: ['', [Validators.required]],
      height: [this._currentItem.heightValue, [Validators.required]],
      length: [this._currentItem.lengthValue, [Validators.required]],
      depth: [this._currentItem.widthValue, [Validators.required]],
      weight: [this._currentItem.weightValue, [Validators.required]],
    });

    if (this._storeTabsComponent) {
      this._storeTabsComponent.setFormGroup(this.ship);
      this.getStores()
    }
    
    this.getAllRootCategories(environment.productKey).then(
      (rootCategories) => {
        this._categories = rootCategories;
        this.executePromisesOnCategoryChange(this._currentItem.category01Id);
    });
    this.getImagesForItem();
    this.getPricings();
    this.finalizeForms();
  }

  private getVariations() {
    this.variationService.getVariation(this._currentItem.id).subscribe((variations) => {
      variations.forEach((variation) => {
        this.ELEMENT_DATA = [];
        let tableVariation: TableVariation = {} as any;
        AutoMapper.map(variation, tableVariation, [
          {
            from: 'sku',
            to: 'SKU'
          },
          {
            from: 'qtyAvailableValue',
            to: 'quantity'
          }
        ], [
          {
            key: 'file',
            value: {
              imageContent: '',
              file: {},
              id: 0,
              sanitizedContent: ''
            } 
          }
        ])
        this.imagesService.getVariantImagesForProduct(variation.id).subscribe((images) => {
          if (images.length) {
            tableVariation.file.file = images[0];
            tableVariation.file.id = images[0].id;
            tableVariation.file.imageContent = images[0].content;
            tableVariation.file.sanitizedContent = this.sanitizer.bypassSecurityTrustResourceUrl(Convertor.convertImages.plainToFullBase64(images[0].content, images[0].contentContentType));
          }
          this.ELEMENT_DATA.push(tableVariation);
          this.variationDataSource = new MatTableDataSource(this.ELEMENT_DATA);
        })
      })
    })
  }


  attachOnPriceEvent(formControl: AbstractControl, id: number, callback: (id, prev, next, scope) => void) {
    formControl.valueChanges
    .pipe(
      debounceTime(1500),
      startWith(null), 
      pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        callback(id, prev, next, this)
      });
  }

  attachChangeEventToMultplePricings(id: number) {
    this.attachOnPriceEvent(this.pricing.get('unitsFrom' + id), 4, this.onPriceFromAmountChange);
    this.attachOnPriceEvent(this.pricing.get('unitsTo' + id), 4, this.onPriceToAmountChange)
    this.attachOnPriceEvent(this.pricing.get('pricing' + id), 0, this.onPriceChange)
  }

  onPriceFromAmountChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('unitsTo' + id)) {
      const toValue = parseInt(scope.pricing.get('unitsTo' + id).value);
      if (toValue <= nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.max[scope._language])
      }
    }
    if (nextValue < environment.initialNumberOfPriceRanges) {
      scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
      scope.toastrService.error(Errors.pricing.from.min[scope._language]);
    }
    if (this.pricing.get('unitsFrom' + (id-1))) {
      const prvoiousFromValue = parseInt(scope.pricing.get('unitsFrom' + (id+1)).value);
      if (prvoiousFromValue > nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.biggerThen[scope._language])
      }
    }
    if (this.pricing.get('unitsFrom' + (id+1))) {
      const nextFromValue = parseInt(scope.pricing.get('unitsFrom' + (id+1)).value);
      if (nextFromValue <= nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.smallerThen[scope._language])
      }
    }
  }

  onPriceToAmountChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('unitsFrom' + id)) {
      const fromValue = parseInt(scope.pricing.get('unitsFrom' + id).value);
      if (fromValue > nextValue) {
        scope.pricing.get('unitsTo' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.to.biggerThen[scope._language])
      }
    }
  }

  onPriceChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('pricing' + (id-1))) {
      const previousPricing = parseInt(scope.pricing.get('pricing' + (id-1)).value);
      if (previousPricing < nextValue) {
        scope.pricing.get('pricing' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.price.smallerThen[scope._language])
      }
    }
    if (scope.pricing.get('pricing' + (id+1))) {
      const nextPricing = parseInt(scope.pricing.get('pricing' + (id+1)).value);
      if (nextPricing > nextValue) {
        scope.pricing.get('pricing' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.price.biggerThen[scope._language])
      }
    }
  }


  private getPricings() {
    this.pricingService.getPricingsForProduct(this._currentItem.id).subscribe((pricings) => {
      this.pricings = pricings;
      this._priceRangeCount = this.pricings.length;
      let id = -1;
      pricings.forEach(pricing => {
        id++;
        const controlNameFrom = 'unitsFrom' + (id).toString();
        const controlNameTo = 'unitsTo' + (id).toString();
        const controlNamePricing = 'pricing' + (id).toString();
        const controlNameID = 'id' + (id).toString();
        const controlNameCode = 'code' + (id).toString();


        if (id < environment.initialNumberOfPriceRanges - 1) {
          this.pricing.addControl(controlNameFrom, new FormControl({value: pricing.qtyFrom, disabled: true}, [Validators.required]));
        }
        else {
          this.pricing.addControl(controlNameFrom, new FormControl(pricing.qtyFrom, [Validators.required]));
          this.pricing.addControl(controlNameTo, new FormControl(pricing.unitPriceAmt, [Validators.required]));
          this.attachOnPriceEvent(this.pricing.controls[controlNameTo], id, this.onPriceToAmountChange);
        }
        this.pricing.addControl(controlNameCode, new FormControl(pricing.code, [Validators.required]));  
        this.pricing.addControl(controlNameID, new FormControl(pricing.id, [Validators.required]));  
        this.pricing.addControl(controlNamePricing, new FormControl(pricing.unitPriceAmt, [Validators.required]));  
        this.attachOnPriceEvent(this.pricing.controls[controlNameFrom], id, this.onPriceFromAmountChange);
        this.attachOnPriceEvent(this.pricing.controls[controlNamePricing], id, this.onPriceChange);

      });

    })
  }

  private finalizeForms() {
    this._tabidToFormgroup = {
      info: this.productInfo,
      specs: this.specs,
      price: this.pricing,
      inventory: null,
      ship: this.ship
    }
  } 

  private getImagesForItem() {
    this.imagesService.getImagesForItem(this._currentItem.id).subscribe((images) => {
      images.forEach(image => {
        let base64Image: Base64Image = {} as any;
        let imageContent = Convertor.convertImages.plainToFullBase64(image.content, image.contentContentType);
        let sanitizedImageContent = this.sanitizer.bypassSecurityTrustResourceUrl(imageContent)

        AutoMapper.map(image, base64Image, [],
          [
            {
              key: 'file',
              value: image
            },
            {
              key: 'sanitizedContent',
              value: sanitizedImageContent
            }
          ]);
        this.base64Files.push(base64Image);
      });
    })
  }

  createNewTableRow() {
    let tableRowObject : TableVariation = {
      file: null,
      id: this.ELEMENT_DATA.length,
      size: "YxY",
      SKU: "",
      name: "",
      quantity: 0,
      color: ""
    } as any;
    this.ELEMENT_DATA.push(tableRowObject);
    this.variationDataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  removeVariation(id) {
    if (this.ELEMENT_DATA.length == 1) {
      return;
    }
    this.ELEMENT_DATA = this.ELEMENT_DATA.filter(element => element.id != id);
    this.variationDataSource.data = this.variationDataSource.data.filter(element => {
      if (element.id == id) {
        this.deletedVariations.push(element);
        return false
      }
      return true;
    });
  }

  openImageSelection(id) {
    let self = this;

    const dialogRef = this.dialogService.open(ImageselectionComponent, {
      width: '100%',
      data: self.base64Files
    });

    dialogRef.afterClosed().subscribe(result => {
      self.variationDataSource.data.find(element => element.id == id).file = result;
    });
  }

  increaseRange() {
    const id = this.priceRangeCount;
    let newPricing: Pricing = {
      qtyFrom: id + 1,
      unitPriceAmt: 0,
      qtyTo: id + 2

    } as any;
    this.pricings.push(newPricing)
    this._priceRangeCount++;
    this.pricing.addControl('unitsFrom'+id, new FormControl('', [Validators.required]));
    this.pricing.addControl('unitsTo'+id, new FormControl('', [Validators.required]));
    this.pricing.addControl('pricing'+id, new FormControl('', [Validators.required]));
    this.attachChangeEventToMultplePricings(id);
  }

  decreaseRange() {
    if (this._priceRangeCount == environment.initialNumberOfPriceRanges) {
      return;
    }
    this.deletedPricings.push(this.pricings.pop());
    this._priceRangeCount--;
    const id = this.priceRangeCount;
    this.pricing.removeControl('unitsFrom'+id);
    this.pricing.removeControl('unitsTo'+id);
    this.pricing.removeControl('pricing'+id);
    if (this.pricing.controls['id'+id]) {
      this.pricing.removeControl('id'+id);
    }
    if (this.pricing.controls['code'+id]) {
      this.pricing.removeControl('code'+id);
    }
  }

  getAllRootCategories(type: string) {
    return this.categoryService.getAllRootCategories(type).toPromise();
  }

  getAllBrandsForSubCategory(subCategoryId: number) {
    return this.brandsService.getAllBrandsForSubcategory(subCategoryId).toPromise();
  }

  getAllSubCategories(id) {
    return this.categoryService.getAllSubcategories(id).toPromise();
  }

  getAllAttributesForCategory(id) {
    return this.categoryService.getAllAttributesForCategory(id).toPromise();
  }

  executePromisesOnCategoryChange(id) {
    this.getAllSubCategories(id).then(
      subCategories => {
        this._subCategories = subCategories;
        this.getAllAttributesForCategory(this.subCategories[0].id).then(
          attributes => {
            this._categoryAttributes = attributes;
            this.addAttrName();
            this.addAttributesFormControl();
          }
        )
        this.getAllBrandsForSubCategory(this.subCategories[0].id).then(brands => {
          this._brands = brands;
          brands.forEach(brand => {
            if (brand.name == this.getCustomBrandTag()) {
              this._customBrandId = brand.id;
            }
          })
          this.toggleCustomBrand(brands[0].id);
        })
      }
    )
  }

  subCategoryChange(event) {
    this.getAllAttributesForCategory(event.target.value).then(
      attributes => {
        this._categoryAttributes = attributes;
        this.addAttrName();
        this.addAttributesFormControl();
      }
    )
    this.getAllBrandsForSubCategory(event.target.value).then(brands => {
      brands.forEach(brand => {
        if (brand.name == this.getCustomBrandTag()) {
          this._customBrandId = brand.id;
        }
      })
      this._brands = brands;
      this.toggleCustomBrand(brands[0].id);
    })
  }

  getCustomBrandTag() {
    return this.dashboardLables(this.getPageName()).custom;
  }

  changeBrand(event) {
    this.toggleCustomBrand(event.target.value);
  }

  toggleCustomBrand(currentBrandId: number) {
    if (currentBrandId == this.customBrandId) {
      document.getElementById('brand').style.width = "40%"
    }
    else {
      document.getElementById('brand').style.width = "100%"
    }
  }

  addAttrName() {
    this.attributes.map( 
      attribute => {
        attribute.name = Convertor.convertAttributeName.convertFromAPI(attribute.attributeCode);
      })
  }

  addAttributesFormControl() {
    this._hasInstanceAttribute = false;
    Object.keys(this.specs.controls).forEach(control => {
      if (control != 'description') {
        this.specs.removeControl(control);
      }
    });
    this.attributes.forEach(
      attribute => {
        if (attribute.instanceAttribute) {
          this._hasInstanceAttribute = true;
        }
        this.specs.addControl(attribute.name, new FormControl(this._currentItem[attribute.attributeCode], [Validators.required]))
      })
    if (!this._hasInstanceAttribute) {
      this.specs.addControl('quantity', new FormControl(this._currentItem.qtyAvailableUnit, [Validators.required]));
    }
    else {
      this.getVariations();
      this._categoryAttributes = this.attributes.filter(attribute => {
        if ((this.disabledAttr.indexOf(attribute.attributeCode) > -1)) {
          this.specs.removeControl(attribute.name);
          return false;
        }
        return true;
      });
      this.specs.addControl('gender', new FormControl(this._currentItem.gender, [Validators.required]));
    }

  }

  hasInstanceAttribute() : boolean {
    return this._hasInstanceAttribute;
  }

  categoryChange(event) {
    this.executePromisesOnCategoryChange(event.target.value);
  }

  errorMessage(tabId) {
    return this._errorMsgs[tabId];
  }

  validateTabBeforeMoving(tabId) {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this._tabidToFormgroup[tabId], this.dashboardLables(this.pageName))
    if (fieldName != '') {
      this.error = true;
      this._errorMsgs[tabId] = fieldName + this.dashboardLables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  nextTab(tabId, currentTab = null) {
    if (currentTab == 'specs') {
      let shouldCreateVariations = this.createVariations();
      if (shouldCreateVariations) {
        shouldCreateVariations.then((variations) => {
          this.ELEMENT_DATA.forEach(tableVariation => {
            const newVariation = variations.find(variation => variation.sku == tableVariation.SKU);
            tableVariation.id = newVariation.id;
            this.variationService.updateImageVariations(tableVariation);
          })
        });
      }
    }
    if (currentTab && !this.validateTabBeforeMoving(currentTab)) {
      return;
    }
    this.error = false;
    Object.keys(this._errorMsgs).forEach(key => {
      this._errorMsgs[key] = "";
    })
    var tabEl = document.querySelector('a[href="#' + tabId + '"]');
    var tab = new bootstrap.Tab(tabEl);
    if (tabId == 'specs' && !this._currentItem) {
      this.moveToSPecs(tab);
    }
    else {
      tab.show();
    }
  }

  moveToSPecs(tab) {
    this.createNewroduct().then(createdProduct => {
      this._currentItem = createdProduct;
      this._imageUploadComponent.setCreated(this._currentItem);
      let shouldCreateBrand = this.shouldCreateBrand();
      if (shouldCreateBrand) {
        if (!this.productInfo.get('customBrand').value) {
          this.error = true;
          this._errorMsgs.info = this.dashboardLables(this.pageName).brand + this.dashboardLables(this.pageName).invalidField;
          return false;
        }
        else {
          shouldCreateBrand.then(newBrand => {
            this._currentItem.category03Id = newBrand.id;
            tab.show();
          });
        }
      }
      else {
        tab.show();
      }
    })
  }

  shouldCreateBrand() : Promise<Brand> {
    if (this.productInfo.get('brand').value == this.getCustomBrandTag()) {
      const newBrand : CreateBrandModel = {
        parentCategoryId: this.productInfo.get('subcategory').value,
        name: this.productInfo.get('customBrand').value,
        code: this.productInfo.get('customBrand').value
      } as any;
      return this.brandsService.createBrand(newBrand).toPromise();
    }
    return null;
  }

  handleTabRedirect(event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    return false;
  }

  handleCancelRedirect() {
    this.router.navigate(['dashboard/products']);
  }

  createNewroduct() {
    let newProduct : NewProduct = {} as any;
    newProduct.code = Core.getRandomString(24);
    newProduct.name = this.productInfo.get("name").value;
    newProduct.partnerId = this.loggedUser.partner.id;
    return this.productService.createProduct(newProduct).toPromise();
  }

  private createVariations() : Promise<any> {
    if (this.hasInstanceAttribute() && this.ELEMENT_DATA.find(element => element.SKU)) {
      let variationsToUpdate = this.ELEMENT_DATA.filter(element => element.code);
      let variationsToCreate = this.ELEMENT_DATA.filter(element => !element.code);
      let updateVariationPromises = this.variationService.updateVariations(variationsToUpdate, this._currentItem);
      let createVariationPromises = this.variationService.createVariations(variationsToCreate, this._currentItem);
      return Promise.all(updateVariationPromises.concat(createVariationPromises))
    }
    return null;
  }
  

  private prepareProductForUpdate() {
    this._currentItem.createdBy = this.loggedUser.firstName + " " + this.loggedUser.lastName;
    //this._currentItem.createdDate = new Date().toLocaleString();
    this._currentItem.guaranteeDurationInMonths =	this.productInfo.get('warranty').value;
    this._currentItem.heightValue =	this.ship.get('height').value;
    this._currentItem.ean =	this.productInfo.get('barcode').value;
    this._currentItem.weightValue =	this.ship.get('weight').value;
    this._currentItem.lengthValue =	this.ship.get('length').value;
    this._currentItem.manufacturer =	this.productInfo.get('manufacturer').value;
    this._currentItem.name =	this.productInfo.get('name').value;
    this._currentItem.partnerCode =	this.loggedUser.partner.name;
    this._currentItem.partnerId =	this.loggedUser.partner.id;
    this._currentItem.sku =	this.productInfo.get('SKU').value;
    this._currentItem.vatCategory =	this.productInfo.get('VAT').value;
    this._currentItem.volumeValue =	this.ship.get('depth').value * this.ship.get('length').value * this.ship.get('height').value;
    this._currentItem.widthValue =	this.ship.get('depth').value;
    this._currentItem.category01Id = this.productInfo.get('category').value;
    this._currentItem.category02Id = this.productInfo.get('subcategory').value;
    this._currentItem.category03Id = this.productInfo.get('brand').value;
    this._currentItem.description = this.specs.get('description').value;
    this._currentItem.type = environment.item;
    this._currentItem.condition = this.productInfo.get('condition').value;
    this._currentItem.dlvPickUp = this.ship.get('pickUp').value;
    this._currentItem.qtyAvailableUnit = (this.specs.get('quantity')) ? (this.specs.get('quantity')).value : undefined;
    this._currentItem.gender = (this.productInfo.get('gender')) ? (this.productInfo.get('gender')).value : undefined;
    this.addProductAttrToNewProduct();
  }

  private addProductAttrToNewProduct() {
    Object.keys(this.specs.controls).forEach(control => {
      if (this.defaultAttr.indexOf(control) < 0) {
        const attrName = control.toLowerCase() + "Attr";
        this._currentItem[attrName] = this.specs.get(control).value;
      }
    })
  }

  updateProduct() : Promise<any>{
      this.prepareProductForUpdate();
      return this.itemService.updateItem(this._currentItem).toPromise();
  }

  deletePricings() {
    let idsToDelete = [];
    this.deletedPricings.forEach(deleteId => {
      if (deleteId.id) {
        idsToDelete.push(deleteId.id)
      }
    })
    return this.pricingService.deletePricings(idsToDelete).toPromise();
  }

  deleteVariations() {
    let idsToDelete = [];
    this.deletedVariations.forEach(deleteId => {
      if (deleteId.code) {
        idsToDelete.push(deleteId.id)
      }
    })
    return this.variationService.deletePricings(idsToDelete).toPromise();
  }

  upload(currentTab) {
    if (this.validateTabBeforeMoving(currentTab)) {
      //let shouldCreateVariations = this.createVariations();
      let createProductPromises : Promise<any>[] = 
        [
          this.updateProduct(),
        ];
      createProductPromises.concat(this.pricingService.updatePricings(this.pricing, this._currentItem.id, this._priceRangeCount));
      createProductPromises.concat(this.deletePricings());
      createProductPromises.concat(this.deleteVariations());  
      // if (shouldCreateVariations) {
      //   shouldCreateVariations.then((variations) => {
      //     this.ELEMENT_DATA.forEach(tableVariation => {
      //       const newVariationId = variations.find(variation => variation.sku == tableVariation.SKU).id;
      //       tableVariation.id = newVariationId;
      //       this.variationService.updateImageVariations(tableVariation);
      //     })
      //   });
      // }
      Promise.all(createProductPromises)
        .then(() => {
          this.toastrService.success(
            Successes.productCreation[this._language]
          )
          this.goToDashboard();
        });
    }
  }

}

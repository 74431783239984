export const Colors = [
    {
        color: '#E5D3B4',
        name: 'Бежов'
    },
    {
        color: '#B19969',
        name: 'Бронзов'
    },
    {
        color: '#8C1C39',
        name: 'Бордо'
    },
    {
        color: '',
        name: 'Бял цвят'
    },
    {
        color: '#F6E019',
        name: 'Жълт'
    },
    {
        color: '#77C940',
        name: 'Зелен'
    },
    {
        color: '#EAC42F',
        name: 'Златисто'
    },
    {
        color: '#CB9B74',
        name: 'Кафяв'
    },
    {
        color: '#CB7BE7',
        name: 'Лилав'
    },
    {
        color: '',
        name: 'Многоцветен'
    },
    {
        color: '#F78A32',
        name: 'Оранжев'
    },
    {
        color: '#F3F6FB',
        name: 'Прозрачно'
    },
    {
        color: '#F3ACC9',
        name: 'Розов'
    },
    {
        color: '#8D9096',
        name: 'Сив'
    },
    {
        color: '#84C4FC',
        name: 'Син'
    },
    {
        color: '#E3E1DD',
        name: 'Сребърно'
    },
    {
        color: '#3F5080',
        name: 'Тъмносин'
    },
    {
        color: '#FB3232',
        name: 'Червен'
    },
    {
        color: '#000000',
        name: 'Черен'
    }
]
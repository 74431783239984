export const Primaries = {
    noImages: {
        bg: "Нямате качени снимки !",
        en: "No uploaded images !"
    },
    OK: {
        bg: "ОК",
        en: "OK"
    },
    Cancel: {
        bg: "Затвори",
        en: "Cancel"
    }, 
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AttachSkuOptionRequestModel } from 'src/models/requestModels/V2/option/AttachSkuOptionRequestModel';
import { UpdateSkuOptionRequestModel } from 'src/models/requestModels/V2/option/UpdateSkuOptionRequestModel';
import { DeleteSkuOptionRequestModel } from 'src/models/requestModels/V2/option/DeleteSkuOptionRequestModel'
import { SkuOption } from 'src/models/DTO/V2/skuOptions/SkuOption';

@Injectable({
  providedIn: 'root'
})
export class SkuoptionService {

  private optionBaseURL : string = environment.api + "sku/option/";
  private optionAttachURL : string = this.optionBaseURL + 'attach';
  private optionDetachURL : string = this.optionBaseURL + 'detach';



  constructor(private httpClient: HttpClient) { }

  attachOptionToSku(attachSkuOptionRequestModel: AttachSkuOptionRequestModel) {
    return this.httpClient.post(this.optionAttachURL, attachSkuOptionRequestModel);
  }

  updateSkuOption(updatedSkuOption: UpdateSkuOptionRequestModel, skuOptionUIID: string) {
    return this.httpClient.put<SkuOption>(this.optionBaseURL + skuOptionUIID, updatedSkuOption);
  }

  deleteSkuOption(deleteSkuOption: DeleteSkuOptionRequestModel) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: deleteSkuOption
    }
    return this.httpClient.delete<any>(this.optionDetachURL, options)
  }

  getOptionsForSku(skuUUID: string) {
    return this.httpClient.get<SkuOption>(this.optionBaseURL + skuUUID);
  }
}

<div class="container-xxl">
    <a href="/overview/b305d6b4-9017-40fe-8f7e-c7223f9b7d02" type="button" class="btn btn-outline-primary">Back</a>
    <div class="row">
        <div class="col col-lg-12" style="text-align: center;">
            <img style="width: 90px; height: 70px;" [src]="this.image" alt="Logo">
            <h5>{{ this.organisation?.native_name }}</h5>
            <p>{{ this.organisation?.description }}</p>
        </div>
    </div>
    <div class="row" >
        <h5>{{ this.lables.firmDetailsLabel }}</h5>
        <div class="col col-md-3 offset-md-2">
            <p><span class="label">{{ this.lables.firmName }}</span>: <span>{{ this.organisation?.native_name }}</span></p>
            <p><span class="label">{{ this.lables.tradingName }}</span>: <span>{{ this.organisation?.name_in_latin }}</span></p>
            <p><span class="label">{{ this.lables.legalOrgType }}</span>: <span>{{ this.organisation?.legal_form.label }}</span></p>
            <p><span class="label">{{ this.lables.address }}</span>: <span>{{ this.contact?.data.address_line_1 }}</span></p>
        </div>
        <div class="col col-md-3 offset-md-2">
            <p><span class="label">{{ this.lables.bulstat }}</span>: <span>{{ this.organisation?.number }}</span></p>
            <p><span class="label">{{ this.lables.vatRegistered }}</span>: <span>{{ this.organisation?.vat_number ? 'Yes' : 'No' }}</span></p>
            <p *ngIf="this.organisation?.vat_number"><span class="label">{{ this.lables.vatNumber }}</span>: <span>{{ this.organisation?.vat_number }}</span></p>
            <p><span class="label">{{ this.lables.iban }}</span>: <span>{{ this.organisation?.iban }}</span></p>
            <p><span class="label">{{ this.lables.bankName }}</span>: <span>{{ this.organisation?.bank_name }}</span></p>
            <p><span class="label">{{ this.lables.bankSortCode }}</span>: <span>{{ this.organisation?.bic_swift_code }}</span></p>
        </div>
    </div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionComponent } from './../../app/registration/position/position.component';
import { PersonaldetailsComponent } from './../../app/registration/personaldetails/personaldetails.component';
import { PersonaladdressComponent } from './../../app/registration/personaladdress/personaladdress.component';
import { LoginComponent } from './../../app/registration/login/login.component';
import { ForgotpasswordComponent } from './../../app/registration/forgotpassword/forgotpassword.component';
import { FinishComponent } from './../../app/registration/finish/finish.component';
import { EmailpageComponent } from './../../app/registration/emailpage/emailpage.component';
import { EmailconfirmationComponent } from './../../app/registration/emailconfirmation/emailconfirmation.component';
import { CompanydetailedComponent } from './../../app/registration/companydetailed/companydetailed.component';
import { CompanyaddressComponent } from './../../app/registration/companyaddress/companyaddress.component';
import { ChangepasswordComponent } from './../../app/registration/changepassword/changepassword.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessfulactivationComponent } from './successfulactivation/successfulactivation.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../shared/shared.module';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ResetpassconfirmComponent } from './resetpassconfirm/resetpassconfirm.component';






@NgModule({
  declarations: [
    PersonaldetailsComponent,
    PositionComponent,
    ChangepasswordComponent,
    CompanyaddressComponent,
    CompanydetailedComponent,
    FinishComponent,
    EmailpageComponent,
    EmailconfirmationComponent,
    ChangepasswordComponent,
    LoginComponent,
    ForgotpasswordComponent,
    PersonaladdressComponent,
    SuccessfulactivationComponent,
    ResetpasswordComponent,
    ResetpassconfirmComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    SharedModule,
    NgbTooltipModule
  ],
  exports: [
    PersonaldetailsComponent,
    PositionComponent,
    ChangepasswordComponent,
    CompanyaddressComponent,
    CompanydetailedComponent,
    FinishComponent,
    EmailpageComponent,
    EmailconfirmationComponent,
    ChangepasswordComponent,
    LoginComponent,
    ForgotpasswordComponent,
    PersonaladdressComponent,
    SuccessfulactivationComponent
  ]
})
export class RegistrationModule { }

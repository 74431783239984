import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Brand } from 'src/models/DTO/V2/brands/Brand';
import { NewBrand } from 'src/models/DTO/V2/brands/NewBrand';
import { CreateNewBrandRequestModel } from 'src/models/requestModels/V2/brand/CreateNewBrandRequestModel';


@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  private brandsURL : string = environment.api + "brand";

  constructor(private httpClient: HttpClient) { }

  getBrands(categoryUIID: string) {
    const urlQuery = "?filter%5Bcategory%5D=" + categoryUIID + "&fields%5Bbrands%5D=uuid%2Cname&sort=name&perPage=199"
    return this.httpClient.get<Brand>(this.brandsURL + urlQuery);
  }

  createNewBrand(newBrand: CreateNewBrandRequestModel) {
    return this.httpClient.post<NewBrand>(this.brandsURL, newBrand);
  }
}

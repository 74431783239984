import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DASHBOARD_LABLES } from 'src/config/labels/dashboard';
import { Labels } from 'src/config/labels/labels';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { CreateWorkingTimeRequestModel } from 'src/models/requestModels/workingTime/workingTime';
import { WorkingTime } from 'src/models/DTO/workingTime/workingTime';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { WorkHours } from 'src/models/requestModels/V2/workHours/WorkHours';

@Component({
  selector: 'app-workingtimes',
  templateUrl: './workingtimes.component.html',
  styleUrls: ['./workingtimes.component.css']
})
export class WorkingtimesComponent implements OnInit {

  public customLabelStyle = '';
  public pagename: string = "workingTimes";
  private _language: string;
  public customWorkTimeView : boolean = false;
  public currentWorkingTime: WorkHours = {} as any;
  public workDays: FormGroup;
  private _workdayMapping = {
    monday: {
      start: {
        control: 'monFrom'
      },
      end: {
        control: 'monTo'
      },
      rest: {
        control: 'monRest'
      }
    },
    tuesday: {
      start: {
        control: 'tueFrom'
      },
      end: {
        control: 'tueTo'
      },
      rest: {
        control: 'tueRest'
      }
    },
    wednesday: {
      start: {
        control: 'wedFrom'
      },
      end: {
        control: 'wedTo'
      },
      rest: {
        control: 'wedRest'
      }
    },
    thursday: {
      start: {
        control: 'thuFrom'
      },
      end: {
        control: 'thuTo'
      },
      rest: {
        control: 'thuRest'
      }
    },
    friday: {
      start: {
        control: 'friFrom'
      },
      end: {
        control: 'friTo'
      },
      rest: {
        control: 'friRest'
      }
    },
    saturday: {
      start: {
        control: 'satFrom'
      },
      end: {
        control: 'satTo'
      },
      rest: {
        control: 'satRest'
      }
    },
    sunday: {
      start: {
        control: 'sunFrom'
      },
      end: {
        control: 'sunTo'
      },
      rest: {
        control: 'sunRest'
      }
    }
  }

  constructor(
    private FB: FormBuilder,
    private localStorageService: LocalstorageserviceService,
  ) { 
    this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);  
    this.workDays = FB.group({
      monFrom: ['', [Validators.required]],
      monTo: ['', [Validators.required]],
      monRest: [false],
      tueFrom: ['', [Validators.required]],
      tueTo: ['', [Validators.required]],
      tueRest: [false],
      wedFrom: ['', [Validators.required]],
      wedTo: ['', [Validators.required]],
      wedRest: [false],
      thuFrom: ['', [Validators.required]],
      thuTo: ['', [Validators.required]],
      thuRest: [false],
      friFrom: ['', [Validators.required]],
      friTo: ['', [Validators.required]],
      friRest: [false],
      satFrom: ['', [Validators.required]],
      satTo: ['', [Validators.required]],
      satRest: [false],
      sunFrom: ['', [Validators.required]],
      sunTo: ['', [Validators.required]],
      sunRest: [false],
      everyDayFrom : [''],
      everyDayTo : ['']
    });
  }
  ngOnInit(): void {
  }

  get dashboardLables() {
    return DASHBOARD_LABLES[this._language][this.pagename];
  }

  
  applyToAllDays() {
    Object.keys(this.workDays.controls).forEach(control => {
      if (control.match(/From/) && control !== 'everyDayFrom') {
        var restControlName = control.replace('From', 'Rest');
        if (!this.workDays.get(restControlName).value) {
          this.workDays.get(control).setValue(this.workDays.get('everyDayFrom').value)
        }
      }
      else if (control.match(/To/) && control !== 'everyDayTo') {
        var restControlName = control.replace('To', 'Rest');
        if (!this.workDays.get(restControlName).value) {
          this.workDays.get(control).setValue(this.workDays.get('everyDayTo').value)
        }      
      }
    });
  }

  toggleCustomView(event) {
    if (event.target.checked){
      this.customWorkTimeView = true;
    }
    else {
      this.customWorkTimeView = false;
    }
  }

  prepareWorkingTime() {
    Object.keys(this._workdayMapping).forEach((mapping) => {
      const startMapping = this._workdayMapping[mapping].start;
      const endMapping = this._workdayMapping[mapping].end;
      const restMapping = this._workdayMapping[mapping].rest;
      this.currentWorkingTime[mapping] = { hours: [] };
      if (!this.workDays.controls[restMapping.control].value) {
        this.currentWorkingTime[mapping].hours = [this.workDays.controls[startMapping.control].value, this.workDays.controls[endMapping.control].value];
      }
      else {
        this.currentWorkingTime[mapping].hours = [];
      }
    });
    return this.currentWorkingTime;
  }

  setValues(workingTime: WorkHours) {
    Object.keys(this._workdayMapping).forEach((mapping) => {
      const startMapping = this._workdayMapping[mapping].start;
      const endMapping = this._workdayMapping[mapping].end;
      const restMapping = this._workdayMapping[mapping].rest;
      const workTime = workingTime[mapping][0].split('-');
      if (workTime.length) {
        this.workDays.controls[startMapping.control].setValue(workTime[0]);
        this.workDays.controls[endMapping.control].setValue(workTime[1]);
        this.workDays.controls[restMapping.control].setValue(false);
      }
      else {
        this.workDays.controls[restMapping.control].setValue(true);
      }
    })
    this.currentWorkingTime = workingTime;
  }

  validate() {
    return ValidationHelper.workingTimesFormGroup.validate(this.workDays, this.dashboardLables);
  }

  setCustomLabelStyle(style: string) {
    this.customLabelStyle = style;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/models/DTO/V2/categories/Category';
import { CategoryAttribute } from 'src/models/DTO/attributes/attribute';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { CategoriesService } from 'src/services/requestServices/V2/categories/categories.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import {COMMA, ENTER, P} from '@angular/cdk/keycodes';
import { ProductsService } from 'src/services/requestServices/V2/products/products.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { environment } from 'src/environments/environment';
import { Product } from 'src/models/DTO/products/product';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { Successes } from 'src/config/labels/successes';
import { PackagingCorurierTypes } from 'src/config/labels/packagingCorurierTypes';
import { INFO_STATUSES } from 'src/config/labels/infoStatuses';
import { Conditions } from 'src/config/labels/conditions';
import { Genders } from 'src/config/labels/genders';
import { YesAndNo } from 'src/config/labels/yesNoOptions';
import { Colors } from 'src/config/labels/colors';
import { Sizes } from 'src/config/labels/sizes';
import { PackagingOptions } from 'src/config/labels/packagingOptions';
import { BrandsService } from 'src/services/requestServices/V2/brands/brands.service';
import { Brand } from 'src/models/DTO/V2/brands/Brand';
import { NewBrand } from 'src/models/DTO/V2/brands/NewBrand';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { TableVariation } from 'src/models/DTO/variations/tableVariation';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ImageselectionComponent } from 'src/app/dialogs/ImageSelect/imageselection/imageselection.component';
import { VariationsService } from 'src/services/requestServices/variations/variations.service';
import { PricingService } from 'src/services/requestServices/pricing/pricing.service';
import { ImageuploadComponent } from 'src/app/shared/images/imageupload.component';
import { StorestabsComponent } from 'src/app/shared/storestabs/storestabs.component';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { Errors } from 'src/config/labels/errors';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseItemComponent } from '../base-item/base-item.component';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { Store } from 'src/models/DTO/stores/store';
import { Variation } from 'src/models/DTO/variations/variation';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { CreateNewBrandRequestModel } from 'src/models/requestModels/V2/brand/CreateNewBrandRequestModel';
import { CreateNewProductRequestModel } from 'src/models/requestModels/V2/product/CreateNewProductRequestModel';
import { ManifacturerService } from 'src/services/requestServices/V2/manifacturers/manifacturer.service';
import { CreateNewManufacturerRequestModel } from 'src/models/requestModels/V2/manufacturer/CreateNewManufacturerRequestModel';
import { Product as CreatedProduct } from 'src/models/DTO/V2/product/Product';
import { OptionService } from 'src/services/requestServices/V2/options/option.service';
import { Option } from 'src/models/DTO/V2/options/Option';
import { ImageCollection } from 'src/models/DTO/V2/image/ImageCollection';
import { ProductoptionService } from 'src/services/requestServices/V2/productOption/productoption.service';
import { AttachOptionRequestModel } from 'src/models/requestModels/V2/option/AttachOptionRequestModel';
import { PricingService as PricingsService } from 'src/services/requestServices/V2/pricing/pricing.service';
import { CreateBasePricingRequestModel } from 'src/models/requestModels/V2/pricing/CreateBasePricingRequestModel';
import { CreatePricingRequestModel } from 'src/models/requestModels/V2/pricing/CreatePricingRequestModel';
import { TableBaseVariation } from 'src/models/DTO/V2/variation/TableBaseVariation';
import { SkuService } from 'src/services/requestServices/V2/sku/sku.service';
import { CreateSkuRequestModel } from 'src/models/requestModels/V2/sku/CreateSkuRequestModel';
import { SkuoptionService } from 'src/services/requestServices/V2/skuOption/skuoption.service';
import { AttachSkuOptionRequestModel } from 'src/models/requestModels/V2/option/AttachSkuOptionRequestModel';
import { VariationimagesComponent } from 'src/app/shared/variationimage/variationimages/variationimages.component';
import { PackagingService } from 'src/services/requestServices/V2/packaging/packaging.service';
import { CreatePackagingRequestModel } from 'src/models/requestModels/V2/packaging/CreatePackagingRequestModel';
import { Manufacturer } from 'src/models/DTO/V2/manufacturer/Manufacturer';
import { UpdateProductRequestModel } from 'src/models/requestModels/V2/product/UpdateProductRequestModel';
import { UpdateOptionRequestModel } from 'src/models/requestModels/V2/option/UpdateOptionRequestModel';
import { ProductOption } from 'src/models/DTO/V2/productOptions/ProductOption';
import { SkuCollection } from 'src/models/DTO/V2/sku/SkuCollection';
import { UpdateSkuOptionRequestModel } from 'src/models/requestModels/V2/option/UpdateSkuOptionRequestModel';
import { ProductPricing } from 'src/models/DTO/V2/pricing/ProductPricing';
import { ProductPackaging } from 'src/models/DTO/V2/packaging/ProductPackaging';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { TagsService } from 'src/services/requestServices/V2/tags/tags.service';
import { Tags } from 'src/models/DTO/V2/tag/Tags';
import { AttachDetachTagRequestModel } from 'src/models/requestModels/V2/tag/AttachDetachTagRequestModel';
import { CurrentProduct } from 'src/models/DTO/V2/product/CurrentProduct';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoryPlain } from 'src/models/DTO/V2/categories/CategoryPlain';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';




declare var bootstrap: any;

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent extends BaseItemComponent implements OnInit {
  testExtrasValue = 'check';
  navValue =  33.2;
  filteredVariations = [];
  variationSlide = 'initial';
  readonly variationSlides = ['initial', 'variations']
  productInfo:  FormGroup;
  specs: FormGroup;
  pricing: FormGroup;
  ship: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  variationsDataSource: MatTableDataSource<TableBaseVariation>;
  displayedColumnsForVariations: string[] = ['image', 'sku', 'quantity', 'action'];
  completedTabs =  {
    info: {
      completed: false,
      tab: '#info',
      passed: false
    },
    specs: {
      completed: false,
      tab: '#specs',
      passed: false
    },
    variations: {
      completed: false,
      tab: '#variations',
      passed: false
    },
    price: {
      completed: false,
      tab: '#price',
      passed: false
    },
    delivery: {
      completed: false,
      tab: '#ship',
      passed: false

    },
  }
  public imageCollectionPerVariation: ImageCollection[] = [];


  tags: string[] = [];
  vcolors = [];
  vsizes = [];
  variationType = 'all';
  variationDataSource: MatTableDataSource<TableVariation>;
  displayedColumnsForProductTable: string[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public base64Files: Base64Image[] = [];
  private _imagesPerProduct: ImageCollection;
  private _color = ""
  private _currentProduct: CurrentProduct;
  private _tags: Tags;
  private _productPricing: ProductPricing;
  private _productSkus: SkuCollection;
  private defaultColumnsForVariations: string[] = ['image', 'sku', 'quantity', 'action'];
  private VARIATIONS_DATA: TableBaseVariation[] = [];
  private _tabidToFormgroup : object;
  private _categories: Category;
  private _subCategories: Category;
  private _subSubCategories: CategoryPlain[] = [];
  private _brands: Brand;
  private _options: Option;
  private _productOptions: ProductOption;
  private _productPackaging: ProductPackaging;
  private _variations: Option;
  private _categoryAttributes: CategoryAttribute[] = [];
  private _priceRangeCount: number;
  private _errorMsgs = {
    info: "",
    specs: "",
    price: "",
    inventory: "",
    ship: ""
  };
  private _createdProduct: Product;
  private _newCreatedProduct: CreatedProduct;
  private _hasInstanceAttribute: boolean = false;
  private ELEMENT_DATA : TableVariation[] = [];
  private _customBrandId: number;
  private _userSelectedWhetherToShowVariations: boolean = false;

  private _technicCategoryUUID: string = 'b51838c0-1561-417c-8b65-ce404618b625';


  @ViewChild(ImageuploadComponent)
  private _imageUploadComponent: ImageuploadComponent;
  @ViewChild(StorestabsComponent) set store(content: StorestabsComponent) {
    if(content) { // initially setter gets called with undefined
      this._storeTabsComponent = content;
      this._storeTabsComponent.setFormGroup(this.ship);
      this._storeTabsComponent.setProductId(this._createdProduct.id);
      this.getStores();
      this._imageUploadComponent.deleteImageCallback = (imageId) => {
        this.variationDataSource.data.forEach(element => {
          if (element.file.id == imageId) {
            element.file = null;
          }
        })
      }
    }
  }
  private _storeTabsComponent: StorestabsComponent;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    protected locationService: LocationsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    private categoryService: CategoriesService,
    private productService: ProductsService,
    private itemService: ItemService,
    private toastrService: ToastrService,
    private brandsService: BrandsService,
    private variationService: VariationsService,
    private pricingService: PricingService,
    private dialogService: MatDialog,
    private fb: FormBuilder,
    private manufacturerService: ManifacturerService,
    private optionService: OptionService,
    private productOptionService: ProductoptionService,
    private skuOptionService: SkuoptionService,
    private pricingsService: PricingsService,
    private skuService: SkuService,
    private packagingService: PackagingService,
    protected imageService: ImageService,
    private tagsService: TagsService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,
    private loadingState: LoadingstateService,
    private modalService: NgbModal

  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, locationService, loggedInfoService, infoService, imageService, carrierService, documentsService)
    this.pageName = "addProduct";
    this.loadData();
    this.loadCategories();
    this.completedTabs.info.completed = true;

    this.variationsDataSource = new MatTableDataSource(this.VARIATIONS_DATA);

    this.displayedColumnsForProductTable = ['image', 'size', 'color', 'sku', 'quantity', 'action'];
    this.variationDataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.createNewTableRow();

    this.productInfo = fb.group({
        category: ['', [Validators.required]],
        subcategory: ['', [Validators.required]],
        name: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylicFull)]],
        brand: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylicFull)]],
        manufacturer: ['', [Validators.pattern(ValidationConfig.regexes.cyrylicFull)]],
        condition: [this.condtions[0].value, [Validators.required]],
        quantity: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.numberOnly), Validators.min(1)]],
        barcode: [''],
        SKU: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.alpha_dash)]],
        VAT: ['0', [Validators.required, Validators.pattern(ValidationConfig.regexes.numberOnly)]],
        warranty: [0, [Validators.required, Validators.pattern(ValidationConfig.regexes.numberOnly)]],
        customBrand: ['', Validators.pattern(ValidationConfig.regexes.cyrylicFull)],
        subSubCategory: ['']
      });

      this.specs = fb.group({
        quantity: [''],
        gender: [this.genders[0].value],
        description: [''],
        // tags: ['']
      });

      this.pricing = fb.group({
        unitsFrom0: [{value: 1, disabled: true}, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        unitsFrom1: [{value: 2, disabled: true}, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        unitsFrom2: [{value: 3, disabled: true}, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        unitsFrom3: [{value: 4, disabled: true}, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        unitsFrom4: [5, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        unitsTo4: [6, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        pricing0: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]],
        pricing1: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]],
        pricing2: ['',  [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]],
        pricing3: ['',  [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]],
        pricing4: ['',  [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]],
      });

      this.attachOnPriceEvent(this.pricing.controls.unitsFrom4, 4, this.onPriceFromAmountChange);
      this.attachOnPriceEvent(this.pricing.controls.unitsTo4, 4, this.onPriceToAmountChange)
      this.attachOnPriceEvent(this.pricing.controls.pricing0, 0, this.onPriceChange)
      this.attachOnPriceEvent(this.pricing.controls.pricing1, 1, this.onPriceChange)
      this.attachOnPriceEvent(this.pricing.controls.pricing2, 2, this.onPriceChange)
      this.attachOnPriceEvent(this.pricing.controls.pricing3, 3, this.onPriceChange)
      this.attachOnPriceEvent(this.pricing.controls.pricing4, 4, this.onPriceChange)


      this._priceRangeCount = environment.initialNumberOfPriceRanges;

      this.ship = fb.group({
        // packaging: [PackagingOptions.options[this._language][0].value, [Validators.required]],
        shippWithin: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        height: ['', [Validators.pattern(ValidationConfig.regexes.number)]],
        length: ['', [Validators.pattern(ValidationConfig.regexes.number)]],
        depth: ['', [Validators.pattern(ValidationConfig.regexes.number)]],
        weight: ['', [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]],
        fragile: [false],
        test_before_pay: [false, [Validators.required]],
        check_before_pay: [false, [Validators.required]],
        courierType: [505, [Validators.required]]
      });

      this._tabidToFormgroup = {
        info: this.productInfo,
        specs: this.specs,
        price: this.pricing,
        inventory: null,
        ship: this.ship
      }

      this.loadingState.loading$.subscribe(result => {
        this.isLoading = result;
      });
  }

  private async loadCategories() {
    this.categoryService.getRootCategories().subscribe(
      (rootCategories) => {
        this._categories = rootCategories;
        if (this._newCreatedProduct?.data?.uuid) {
          this.loadCurrentProduct(this._newCreatedProduct.data.uuid).then(() => {
            this.categoryService.getParentCategory(this._currentProduct.data.category.uuid).subscribe(parentCategoryInfo => {
              const category = parentCategoryInfo.data[parentCategoryInfo.data.length - 1].uuid;
              this.productInfo.get('category').setValue(category);
              this.executePromisesOnCategoryChange(category).then(() => {
                  this.productInfo.get('subcategory').setValue(parentCategoryInfo.data[parentCategoryInfo.data.length - 2].uuid);
                  this._subSubCategories = this._subCategories.data.find(subCategory => subCategory.uuid == parentCategoryInfo.data[parentCategoryInfo.data.length - 2].uuid).sub_categories;
                  this.productInfo.get('subSubCategory').setValue(parentCategoryInfo.data[parentCategoryInfo.data.length - 3]?.uuid || '');
                  if (parentCategoryInfo.data[parentCategoryInfo.data.length - 3]?.uuid) {
                    this.brandsService.getBrands(parentCategoryInfo.data[parentCategoryInfo.data.length - 3]?.uuid).subscribe(brands => {
                      this._brands = brands;
                    }); 
                  }
                  else {
                    this.productInfo.get('brand').setValue(this._currentProduct.data.brand.uuid);
                  }
              });
            })
          });
        }
        // else {
        //   const category = this.categories[0].uuid;
        //   //this.productInfo.get('category').setValue(category);
        //   //this.executePromisesOnCategoryChange(category);
        // }
    });
  }

  private createBrand(): Promise<NewBrand> {
    const newBrand : CreateNewBrandRequestModel = {
      category_uuid: this.productInfo.get('subcategory').value,
      name: this.productInfo.get('customBrand').value,
    };
    return this.brandsService.createNewBrand(newBrand).toPromise();
  }

  private loadFullOptions(subCategoryUUID: string) {
    this.optionService.getOptionsForSubCategory(subCategoryUUID, false).subscribe(options => {
      this._options = options;
      this.addAttributesFormControl();
    })
    this.optionService.getOptionsForSubCategory(subCategoryUUID, true).subscribe(variations => {
      this._variations = variations;
      if (!this._variations.data.length) return;
      if(variations.data.length > 1) {
        this.variationType = 'all'
      }
      else {
        this.variationType = this._variations.data[0].display_name
      }
      this.navValue = (this.variations.length) ? 28.4 : 33.2;
      this.displayedColumnsForVariations = this.defaultColumnsForVariations;
      this._variations.data.forEach(variation => {
        if (variation.values.length) {
          variation.values = variation.values.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
          
            // names must be equal
            return 0;
          })
        }
        if (variation.field_name == 'color') {
          this.vcolors = variation.values;
        }
        if (variation.field_name == 'size') {
          this.vsizes = variation.values;
        }
        this.displayedColumnsForVariations = this.displayedColumnsForVariations.filter(column => column != variation.display_name)
        this.displayedColumnsForVariations.splice(1, 0, variation.display_name);
      })
    })
  }

  private updateTags() {
    let arrayForDeletedTags: Promise<any>[] = [];
    let detachTagsModel: AttachDetachTagRequestModel = {
      subject_type: 'PRODUCT',
      subject_uuid: this._newCreatedProduct.data.uuid,
      tags: []
    }
    this._tags.data.forEach(tag => {
      detachTagsModel.tags.push(tag.slug);
    })
    if (detachTagsModel.tags.length) {
      this.tagsService.detachTags(detachTagsModel).subscribe(() => {
        this.createNewTags();
      })
    }
    else {
      this.createNewTags();
    }
  }

  private createNewTags() {
    if (!this.tags.length) {
      return;
    }
    let attachTagsModel: AttachDetachTagRequestModel = {
      subject_type: 'PRODUCT',
      subject_uuid: this._newCreatedProduct.data.uuid,
      tags: []
    }
    let tagsArray = this.tags;
    tagsArray.forEach(newTag => {
      attachTagsModel.tags.push(newTag);
    });
    this.tagsService.createTags(attachTagsModel).toPromise();
  }

  private saveOptions(tab) {
    this.updateCurrentProduct();
    this.updateTags();
    let attachPromisses: Promise<any>[] = [];
    this._productOptions.data.forEach(productOption => {
      const currentOption = this._options.data.find((option) => productOption.uuid == option.uuid);
      if(!currentOption) {
        attachPromisses.push(this.productOptionService.detachOptionToProduct({
          option_uuid: productOption.uuid,
          product_uuid: this._newCreatedProduct.data.uuid
        }).toPromise());
      }
    });
    for(let option of this._options.data) {
      if (!this.specs.get(option.display_name.replace(',', '')).value && this.specs.get(option.display_name.replace(',', '')).touched) {
        attachPromisses.push(this.productOptionService.detachOptionToProduct({
          option_uuid: option.uuid,
          product_uuid: this._newCreatedProduct.data.uuid
        }).toPromise());
        continue;
      }
      if (!this.specs.get(option.display_name.replace(',', '')).value && !this.specs.get(option.display_name.replace(',', '')).touched) {
        continue;
      }

      const currentProductOption = this._productOptions.data.find((productOption) => productOption.uuid == option.uuid);
      if(currentProductOption) {
        const updateProductOptionModel: UpdateOptionRequestModel = {
          product_uuid: this._newCreatedProduct.data.uuid,
          option_uuid: currentProductOption.uuid,
          value: (option.type.label == 'boolean') ? this.specs.get(option.display_name.replace(',', '')).value == "true" : this.specs.get(option.display_name.replace(',', '')).value
        }
        attachPromisses.push(this.productOptionService.updateProductOption(updateProductOptionModel).toPromise());
      }
      else {
        const attachOptionModel: AttachOptionRequestModel = {
          product_uuid: this._newCreatedProduct.data.uuid,
          option_uuid: option.uuid,
          value: (option.type.value == 'boolean') ? this.specs.get(option.display_name.replace(',', '')).value == "true" : this.specs.get(option.display_name.replace(',', '')).value
        }
        attachPromisses.push(this.productOptionService.attachOptionToProduct(attachOptionModel).toPromise());
      }
    }
    Promise.all(attachPromisses).then(() => {
      this.getProductOptions();
      tab.show();
    })
  }

  private async loadBrands() {
   this._brands.data.push({
    uuid: this.dashboardLables(this.getPageName()).custom,
    name: this.dashboardLables(this.getPageName()).custom
   })
  }

  private isThereUnsavedVariation() {
    let variation = this.VARIATIONS_DATA.find(variation => !variation.uuid);
    return (variation) ? true : false;
  }

  async loadData() {
    await this.onDirectNavigation()
  }

  async getStores() {
    await this.onDirectNavigation();
    await this.checkForAddedStores();
    this._storeTabsComponent.setStores(this.stores);
    if (this.showMissingStoreAlert()) {
      this.toastrService.warning('You will not be able to add product without stores', 'Adding a product', { timeOut: 9500 })
    }
  }

  get condtions() {
    return Conditions.conditions[this._language];
  }

  get genders() {
    return Genders.genders[this._language];
  }

  get yesAndNo() {
    return YesAndNo.options[this._language];
  }

  get packagingOptions() {
    return PackagingOptions.options[this._language];
  }

  get categories() : Array<any> {
    return this._categories?.data || [];
  }

  get subCategories() : Array<any> {
    return this._subCategories?.data || [];
  }

  get subSubCategories() : Array<any> {
    return this._subSubCategories || [];
  }

  get value() : number {
    return this.navValue;
  }


  get brands() : Array<any> {
    if (this._brands?.data.length && !this.productInfo.dirty && this._currentProduct?.data?.brand?.uuid) {
      this.productInfo.get('brand').setValue(this._currentProduct.data.brand.uuid);
    }
    return this._brands?.data || [];
  }

  get attributes() : CategoryAttribute[] {
    return this._categoryAttributes;
  }

  get options() : Array<any> {
    return this._options?.data || [];
  }

  get variations() : Array<any> {
    return this._variations?.data || [];
  }

  get priceRangeCount() {
    return this._priceRangeCount;
  }

  get customBrandId() {
    return this._customBrandId;
  }

  get defaultAttr():  string[] {
    return ['quantity', 'description', 'tags'];
  }

  get disabledAttr():  string[] {
    return ['color', 'size'];
  }

  get imagesPerProduct(): ImageCollection {
    return this._imagesPerProduct;
  }

  get userSelectedWhetherToShowVariations() {
    return this._userSelectedWhetherToShowVariations;
  }

  get colors() {
    return Colors;
  }

  get sizes() {
    return Sizes;
  }

  private set newCreatedProduct(createdProduct: CreatedProduct) {
    this._newCreatedProduct = createdProduct;
  }

  ngOnInit(): void {
    this.getUrlParams().subscribe((params) => {
      const productId = params?.params?.id;
      if (productId) {
        this.newCreatedProduct = {
          data: {
            uuid: productId
          }
        };
      }
    });
  }

  ngAfterViewInit() {
    this.base64Files = this._imageUploadComponent.base64Files;
    this._imagesPerProduct = this._imageUploadComponent.imageCollectionPerProduct;
    this.loadTooltips();
  }

  getColor(color) {
    return this.colors.find(colorEl => colorEl.name == color).color;
  }

  applyParentStyle(element, color) {
    let colorHex = this.colors.find(colorEl => colorEl.name == color).color;
    if (colorHex) {
      element._elementRef.nativeElement.style = 'border: 1px solid ' + colorHex + ' !important; border-radius: 5px !important';
    }
  }

  getCourrierTypes(typetoGet: string) {
    return PackagingCorurierTypes.options[this._language].filter(type => type.name == typetoGet)[0]
  }

  toggleCourrierType($event, typetoChange: string) {
    if (!$event.target.checked) {
      $event.target.checked = true;
      return;
    }
    this.ship.get('courierType').setValue(PackagingCorurierTypes.options[this._language].filter(type => type.name == typetoChange)[0].value);

  }

  loadVariations(key: string) { 
    this.variationType = key;
    this.variations.forEach(variation => {
      this.displayedColumnsForVariations = this.displayedColumnsForVariations.filter(displayedColumn => displayedColumn != variation.display_name);
    });
    if (key == 'all') {
      this._variations.data.forEach(variation => {
        this.displayedColumnsForVariations.splice(1, 0, variation.display_name);
      })
    }
    else {
      this.displayedColumnsForVariations.splice(1, 0, key);
    }
  }


  onlyPositiveNumber(formGroup) {
    let value = formGroup.value;
    if (value < 0) {
      value = 0;
    }
    formGroup.setValue(value.toString().replace(/\D/i, ''));
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.input!.value = '';
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  getStoreByName(name: string) : Store {
    let matchedStore : Store;
    this.stores.forEach((store) => {
      if (store.name == name) {
        matchedStore = store;
      }
    })
    return matchedStore;
  }

  attachOnPriceEvent(formControl: AbstractControl, id: number, callback: (id, prev, next, scope) => void) {
    formControl.valueChanges
      .pipe(
        debounceTime(1500),
        startWith(null), 
        pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        callback(id, prev, next, this)
      });
  }

  attachChangeEventToMultplePricings(id: number) {
    this.attachOnPriceEvent(this.pricing.get('unitsFrom' + id), id, this.onPriceFromAmountChange);
    this.attachOnPriceEvent(this.pricing.get('unitsTo' + id), id, this.onPriceToAmountChange)
    this.attachOnPriceEvent(this.pricing.get('pricing' + id), id, this.onPriceChange)
  }

  onPriceFromAmountChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('unitsTo' + id)) {
      const toValue = parseInt(scope.pricing.get('unitsTo' + id).value);
      if (toValue <= nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.max[scope._language])
      }
    }
    if (nextValue < environment.initialNumberOfPriceRanges) {
      scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
      scope.toastrService.error(Errors.pricing.from.min[scope._language]);
    }
    if (scope.pricing.get('unitsFrom' + (id-1))) {
      const prvoiousFromValue = parseInt(scope.pricing.get('unitsFrom' + (id-1)).value);
      if (prvoiousFromValue > nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.biggerThen[scope._language])
      }
    }
    if (scope.pricing.get('unitsTo' + (id-1))) {
      const prvoiousToValue = parseInt(scope.pricing.get('unitsTo' + (id-1)).value);
      if (prvoiousToValue >= nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.smallerThenTo[scope._language])
      }
    }
    if (scope.pricing.get('unitsFrom' + (id+1))) {
      const nextFromValue = parseInt(scope.pricing.get('unitsFrom' + (id+1)).value);
      if (nextFromValue <= nextValue) {
        scope.pricing.get('unitsFrom' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.from.smallerThen[scope._language])
      }
    }
  }

  onPriceToAmountChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('unitsFrom' + id)) {
      const fromValue = parseInt(scope.pricing.get('unitsFrom' + id).value);
      if (fromValue > nextValue) {
        scope.pricing.get('unitsTo' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.to.biggerThen[scope._language])
      }
    }
  }

  onInputChangeMakeOnlyPositive(formControl : AbstractControl) {
    let changedValue = formControl.value;
    if (changedValue < 0) {
      formControl.setValue('');
      return
    }
    formControl.setValue(this.convertNumberWithSuffix(changedValue));
  }

  onInputChangeMakeOnlyPositiveAndWholeNumbers(formControl : AbstractControl) {
    let changedValue = formControl.value;
    if (changedValue < 0) {
      formControl.setValue('');
      return
    }
    else if (!Number.isInteger(changedValue)) {
      formControl.setValue('');
      return
    }
    formControl.setValue(changedValue);
  }

  convertNumberWithSuffix(number, toSuffix = 2) {
    return Convertor.convertNumbers.addToFixedSuffix(number, toSuffix);
  }

  onPriceChange(id, previousValue, nextValue, scope) {
    if(!nextValue) {
      return;
    }
    if (scope.pricing.get('pricing' + (id-1))) {
      const previousPricing = parseFloat(scope.pricing.get('pricing' + (id-1)).value);
      if (previousPricing < nextValue) {
        scope.pricing.get('pricing' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.price.smallerThen[scope._language])
      }
    }
    if (scope.pricing.get('pricing' + (id+1))) {
      const nextPricing = parseFloat(scope.pricing.get('pricing' + (id+1)).value);
      if (nextPricing > nextValue) {
        scope.pricing.get('pricing' + id).patchValue(previousValue);
        scope.toastrService.error(Errors.pricing.price.biggerThen[scope._language])
      }
    }
  }

  deleteVariation(variationToDelete) {
    this.skuService.deleteSku(variationToDelete.uuid).subscribe(() => {
      this.VARIATIONS_DATA = this.VARIATIONS_DATA.filter(variation => {
        if (variation.uuid && variation.uuid == variationToDelete.uuid) {
          return false;
        }
        return true;
      })
      this.variationsDataSource = new MatTableDataSource(this.VARIATIONS_DATA);
      this.toastrService.success(Successes.variationCreation[this._language])
    })
  }

  selectColor(color: string) {
    this._color = color;
  }

  openModal(modal, event) {
    event.preventDefault();
    this.modalService.open(modal, { centered: true } );

  }

  async updateVariation(updatedVariation) {
    for (const key in updatedVariation) {
      if (!updatedVariation[key]) {
        this.toastrService.error("Не са попълнени всички полета за вариациата!")
        return;
      }
    }
    let emptyOption = '';
    let updateVariationsPromises: Promise<any>[] = [];
    let newSkuOptionModel: AttachSkuOptionRequestModel = {
      sku_uuid: updatedVariation.uuid,
      options: []
    };
    const currentSKU = this._productSkus.data.find((sku) => sku.uuid == updatedVariation.uuid);
    await this.skuService.updateSku(updatedVariation.uuid, updatedVariation.sku, updatedVariation.quantity).toPromise();
    this._variations.data.forEach(variation => {
      if (variation[variation.display_name] = '') {
        emptyOption = variation[variation.display_name];
      }
      let currentSkuOption = currentSKU.options.find((skuOptData) => skuOptData.option.uuid == variation.uuid);
      if (currentSkuOption) {
        const updateSkuOption: UpdateSkuOptionRequestModel = {
          value: currentSkuOption.option.display_name == 'Цвят'  && Array.isArray(updatedVariation[currentSkuOption.option.display_name]) ? updatedVariation[currentSkuOption.option.display_name][0] : updatedVariation[currentSkuOption.option.display_name]
        }
        updateVariationsPromises.push(this.skuOptionService.updateSkuOption(updateSkuOption, currentSkuOption.uuid).toPromise());
      }
      else {
        newSkuOptionModel.options.push({
          uuid: variation.uuid,
          value: variation.display_name == 'Цвят'   && Array.isArray(variation[variation.display_name]) ? variation[variation.display_name][0] : variation[variation.display_name]
        })
      }
    })
    if (newSkuOptionModel.options.length) {
      updateVariationsPromises.push(this.skuOptionService.attachOptionToSku(newSkuOptionModel).toPromise());
    }
    if (emptyOption) {
      this.toastrService.error('Въведете ' + emptyOption + ' на разновидността');
      return;
    }
    Promise.all(updateVariationsPromises).then(() => {
      updatedVariation.changed = false;
      this.toastrService.success(Successes.variationCreation[this._language])
    })
  }

  async saveVariation(newVariation) {
    for (const key in newVariation) {
      if (!newVariation[key]) {
        this.toastrService.error("Не са попълнени всички полета за вариациата!")
        return;
      }
    }
    let emptyOption = '';
    const newSkuRequestModel: CreateSkuRequestModel = {
      product_uuid: this._newCreatedProduct.data.uuid,
      sku: newVariation.sku,
      quantity: newVariation.quantity
    }
    const createdSku = await this.skuService.createSku(newSkuRequestModel).toPromise();
    newVariation.uuid = createdSku.data.uuid;
    const newSkuOptionModel: AttachSkuOptionRequestModel = {
      sku_uuid: createdSku.data.uuid,
      options: []
    }
    this._variations.data.forEach(variation => {
      if (newVariation[variation.display_name] == '') {
        emptyOption = newVariation[variation.display_name];
      }
      newSkuOptionModel.options.push({
        uuid: variation.uuid,
        value: variation.display_name == 'Цвят' && Array.isArray(newVariation[variation.display_name])  ? newVariation[variation.display_name][0] : newVariation[variation.display_name]
      })
    })
    if (emptyOption) {
      this.toastrService.error('Въведете ' + emptyOption + ' на разновидността');
      return;
    }
    this.skuOptionService.attachOptionToSku(newSkuOptionModel).subscribe((test) => {
      newVariation.changed = false;
      this.toastrService.success(Successes.variationCreation[this._language]);
      this.prepareVariations();
    })
  }

  openImageVariationUpload(element) {
    if (!element.uuid) {
      this.toastrService.info(INFO_STATUSES.saveSKUBeforrImageUpload[this._language]);
      return;
    }

    const dialogRef = this.dialogService.open(VariationimagesComponent, {
      width: '100%',
      panelClass: 'variation-images-dialog'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.prepareVariations();
    })

    dialogRef.componentInstance.setVariation(element.uuid)
    dialogRef.componentInstance.setDialogRef(dialogRef)

  }

  createNewVariation() {
    this.VARIATIONS_DATA = [...this.VARIATIONS_DATA, {
      sku: '',
      quantity: null
    }];
    this.variationsDataSource = new MatTableDataSource(this.VARIATIONS_DATA);
  }

  createNewTableRow() {
    let tableRowObject : TableVariation = {
      file: null,
      id: this.ELEMENT_DATA.length,
      size: "YxY",
      SKU: "",
      name: "",
      quantity: null,
      color: ""
    } as any;
    this.ELEMENT_DATA = [...this.ELEMENT_DATA, tableRowObject];
    this.variationDataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }

  removeVariation(id) {
    if (this.ELEMENT_DATA.length == 1) {
      return;
    }
    this.ELEMENT_DATA = this.ELEMENT_DATA.filter(element => element.id != id);
    this.variationDataSource.data = this.variationDataSource.data.filter(element => element.id != id);
  }

  openImageSelection(id) {
    let self = this;

    const dialogRef = this.dialogService.open(ImageselectionComponent, {
      width: '100%',
      data: self.base64Files
    });

    dialogRef.afterClosed().subscribe(result => {
      self.variationDataSource.data.find(element => element.id == id).file = result;
    });
  }

  increaseRange() {
    const id = this.priceRangeCount;
    let currentMaxTo = this.pricing.get('unitsTo'+(id-1)).value;
    this._priceRangeCount++;
    this.pricing.addControl('unitsFrom'+id, new FormControl((currentMaxTo) ? currentMaxTo + 1 : '', [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]));
    this.pricing.addControl('unitsTo'+id, new FormControl('', [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]));
    this.pricing.addControl('pricing'+id, new FormControl('', [Validators.required, Validators.pattern(ValidationConfig.regexes.number), Validators.min(0.01)]));
    this.attachChangeEventToMultplePricings(id);
  }

  decreaseRange() {
    if (this._priceRangeCount == environment.initialNumberOfPriceRanges) {
      return;
    }
    this._priceRangeCount--;
    const id = this.priceRangeCount;
    this.pricing.removeControl('unitsFrom'+id);
    this.pricing.removeControl('unitsTo'+id);
    this.pricing.removeControl('pricing'+id);
  }

  executePromisesOnCategoryChange(id) {
    if (!id) {
      this._subCategories = {data: []};
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.categoryService.getSubcategories(id).subscribe(
        subCategories => {
          subCategories.data.push(subCategories.data.splice(subCategories.data.indexOf(subCategories.data.find(sc => sc.name == "Други")), 1)[0]);
          this._subCategories = subCategories;
          const subcategory = this._currentProduct?.data?.category?.uuid || this.subCategories[0].uuid;
          //this.productInfo.get('subcategory').setValue(this.subCategories[0].uuid);
          this.loadFullOptions(subcategory);
          this.brandsService.getBrands(subcategory).subscribe(brands => {
            this._brands = brands;
            this._brands.data.push({
              uuid: this.dashboardLables(this.getPageName()).custom,
              name: this.dashboardLables(this.getPageName()).custom
            })
            //this.productInfo.get('brand').setValue(this._brands.data[0].uuid);
            resolve();
          })
        }
      )
    })
  }

  subCategoryChange(event) {
    // this.getAllAttributesForCategory(event.target.value).then(
    //   attributes => {
    //     this._categoryAttributes = attributes;
    //     this.addAttrName();
    //     this.addAttributesFormControl();
    //   }
    // )

    if (!event.target.value) {
      this._brands = {data: []};
      return;
    }
    const subCategoryUUID = event.target.value;
    this.loadFullOptions(subCategoryUUID);
    this.brandsService.getBrands(subCategoryUUID).subscribe(brands => {
      this._brands = brands;
      this.loadBrands();
    });
    this._subSubCategories = this._subCategories.data.find(subCategory => subCategory.uuid == event.target.value).sub_categories;

  }

  subSubCategoryChange(event) {
    if (!event.target.value) {
      this._brands = {data: []};
      return;
    }
    const subSubCategoryUUID = event.target.value;
    this.loadFullOptions(subSubCategoryUUID);
    this.brandsService.getBrands(subSubCategoryUUID).subscribe(brands => {
      this._brands = brands;
      this.loadBrands();
    });
  }
 
  getCustomBrandTag() {
    return this.dashboardLables(this.getPageName()).custom;
  }

  changeBrand(event) {
    this.toggleCustomBrand(event.target.value);
  }

  toggleCustomBrand(currentBrandId: number) {
    if (currentBrandId == this.customBrandId) {
      document.getElementById('brand').style.width = "40%"
    }
    else {
      document.getElementById('brand').style.width = "100%"
    }
  }

  addAttrName() {
    this.attributes.map( 
      attribute => {
        attribute.name = Convertor.convertAttributeName.convertFromAPI(attribute.attributeCode);
      })
  }

  addAttributesFormControl() {
    Object.keys(this.specs.controls).forEach(control => {
        if (!this.defaultAttr.includes(control)) {
          this.specs.removeControl(control);
        }
    });
    this.options.forEach(option => {
      let validators = [];
      if (option.type.label == 'boolean') {
        validators.push(Validators.required);
      }
      this.specs.addControl(option.display_name.replace('.' , ''), new FormControl('', validators))
    })

  }

  hasInstanceAttribute() : boolean {
    return this._hasInstanceAttribute;
  }

  categoryChange(event) {
    this.executePromisesOnCategoryChange(event.target.value);
  }

  errorMessage(tabId) {
    return this._errorMsgs[tabId];
  }

  validateTabBeforeMoving(tabId) {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(this._tabidToFormgroup[tabId], this.dashboardLables(this.pageName));
    if (fieldName == this.dashboardLables(this.pageName).stores && this.ship.controls.pickUp.value != 'true') {
      fieldName = "";
    }
    if (fieldName != '') {
      this.error = true;
      this._errorMsgs[tabId] = fieldName + this.dashboardLables(this.pageName).invalidField;
      return false;
    }
    return true;
  }

  userVariationOptionSelect(value) {
      this._userSelectedWhetherToShowVariations = value;
  }

  nextTab(tabId, currentTab = null) {
    let step = (this.variations.length) ? 14.2 : 16.6;
    if (currentTab == 'specs') {
      let shouldCreateVariations = this.createVariations();
      if (shouldCreateVariations) {
        shouldCreateVariations.then((variations) => {
          this.ELEMENT_DATA.forEach(tableVariation => {
            const newVariation = variations.find(variation => variation.sku == tableVariation.SKU);
            tableVariation.id = newVariation.id;
            this.variationService.updateImageVariations(tableVariation);
          })
        });
      }
    }

    this.error = false;
    Object.keys(this._errorMsgs).forEach(key => {
      this._errorMsgs[key] = "";
    })
    let tabEl = document.querySelector('a[href="#' + tabId + '"]');
    let tab = new bootstrap.Tab(tabEl);
    switch(currentTab) {
      case 'info': {
        if (this.productInfo.invalid) {
          this.productInfo.markAllAsTouched();
          return;
        }
        this.saveProduct(tab);
        if (this.navValue < 3 * step) {
          this.navValue = 3 * step;
        }
        this.completedTabs.info.passed = true;
        this.completedTabs.specs.completed = true;
        this.completedTabs.specs.passed = false;

        break;
      }
      case 'specs': {
        this.specs.markAsTouched();
        if (!this._imageUploadComponent.images.length) {
          this.toastrService.warning("Качете снимки на продукта");
          return;
        }
        if (!this.specs.valid) {
          return;
        }
        if (this.variations.length) {
          let tabEl = document.querySelector('a[href="#variations"]');
          tab = new bootstrap.Tab(tabEl);
          this.saveOptions(tab);
          this.completedTabs.specs.passed = true;
          this.completedTabs.variations.completed = true;
          this.completedTabs.variations.passed = false;
        }
        else {
          this.saveOptions(tab);
          this.completedTabs.specs.passed = true;
          this.completedTabs.price.completed = true;
          this.completedTabs.price.passed = false;
        }
        if (this.navValue < 4 * step) {
          this.navValue = 4 * step;
        }
        this.completedTabs.specs.completed = true;
        break;
      }
      case 'variations': {
        if (this._userSelectedWhetherToShowVariations && this.isThereUnsavedVariation()) {
          this.toastrService.warning("Имате незапазени вариации !");
          return;
        }
        if (!this._userSelectedWhetherToShowVariations) {
          this.deleteAllVariations();
        }
        if (this.navValue < 5 * step) {
          this.navValue = 5 * step;
        }
        this.completedTabs.variations.passed = true;
        this.completedTabs.variations.completed = true;
        this.completedTabs.price.completed = true;
        this.completedTabs.price.passed = false;

        tab.show();
        break;
      }
      case 'price': {
        Promise.all(this.deleteCurrentPricings()).then(() => {
          this.savePricing(tab, () => {
            if (this.variations.length) {
              if (this.navValue < 6 * step) {
                this.navValue = 6 * step;
              }
            }
            else {
              if (this.navValue < 5 * step) {
                this.navValue = 5 * step;
              }
            }
            this.completedTabs.price.passed = true;
            this.completedTabs.delivery.completed = true;
            this.completedTabs.price.completed = true;
            this.completedTabs.delivery.passed = false;
          });
        })
        break;
      }
      case 'ship' : {
        this.ship.markAllAsTouched();
        if (!this.ship.valid) {
          return;
        }
        this.saveShippingAndPackaging();
        if (this.variations.length) {
          if (this.navValue < 7 * step) {
            this.navValue = 7 * step;
          }
        }
        else {
          if (this.navValue < 6 * step) {
            this.navValue = 6 * step;
          }
        }
        this.completedTabs.delivery.passed = true;
        this.completedTabs.delivery.completed = true;
      }
      default: {
        tab.show();
      }
    }
  }

  backVariation() {
    if (this.variationSlide == this.variationSlides[1]) {
      this.variationSlide = this.variationSlides[0];
    }
    else {
      let tabEl = document.querySelector('a[href="#specs"]');
      let tab = new bootstrap.Tab(tabEl);
      tab.show();
    }
  }

  private loadCurrentProduct(productId: string) {
    return new Promise<void>((resolve, reject) => {
      this.productService.getProduct(productId).subscribe(productInfo => {
        this._currentProduct = productInfo;
        this.productInfo.get('name').setValue(productInfo.data.name);
        if (productInfo.data.manufacturer?.name) {
          this.productInfo.get('manufacturer').setValue(productInfo.data.manufacturer.name);
        }
        else {
          this.productInfo.get('manufacturer').reset();
        }
        this.productInfo.get('condition').setValue(productInfo.data.product_condition);
        this.productInfo.get('barcode').setValue(productInfo.data.ean);
        this.productInfo.get('SKU').setValue(productInfo.data.skus[0].sku);
        this.productInfo.get('quantity').setValue(productInfo.data.skus[0].available_quantity);
        this.productInfo.get('VAT').setValue(parseInt(productInfo.data.vat_percentage));
        this.productInfo.get('warranty').setValue(productInfo.data.guarantee_in_months);
        this.specs.get('description').setValue(productInfo.data.description);
        resolve();
      })
    })
  }

  private deleteAllVariations() {
    this._productSkus.data.forEach(sku => {
      if (sku.options.length) {
        this.skuService.deleteSku(sku.uuid).toPromise();
      }
    })
  }

  saveShippingAndPackaging() {
    const createPackagingRequestModel: CreatePackagingRequestModel = {
      uuid: this._currentProduct?.data?.packaging?.uuid,
      product_uuid: this._newCreatedProduct.data.uuid,
      package_type: PackagingOptions.options.bg[0].value,
      delivery_time_in_days: this.ship.get('shippWithin').value,
      dimensions: {
          weight: this.ship.get('weight').value,
          width: 0,
          height: this.ship.get('height').value,
          length: this.ship.get('length').value,
          depth: this.ship.get('depth').value
      },
      courier_services: {
        carrier_uuid: this.loggedPartner.data.carrier.data[0].uuid,
        service_id: this.loggedPartner.data.carrier.data[0].settings.client_id,
        other_options: {
          test_before_pay: this.ship.get('test_before_pay').value,
          check_before_pay: this.ship.get('check_before_pay').value,
          type: this.ship.get('courierType').value,
          fragile: this.ship.get('fragile').value,
        }
      }
    }
    if (this._currentProduct?.data?.packaging?.uuid) {
      this.packagingService.updatePackaging(createPackagingRequestModel).subscribe(() => {
        this.toastrService.success(Successes.productFinalization[this._language]);
        this.goToDashboard();
      })
    }
    else {
      this.packagingService.createPackaging(createPackagingRequestModel).subscribe(() => {
        this.toastrService.success(Successes.productFinalization[this._language]);
        this.goToDashboard();
      })
    }
  }

  private deleteCurrentPricings() : Promise<any>[] {
    let arrayOfPromisses: Promise<any>[] = [];
    this._productPricing.data.forEach(productPricing => {
      if (productPricing.quantity_from > 4) {
        arrayOfPromisses.push(this.pricingsService.deleteProductPricing(productPricing.uuid).toPromise());
      }
    })
    return arrayOfPromisses;
  }

  private async savePricing(tab, callback) {
    if (this.pricing.invalid) {
      return;
    }
    let createBasePricingRequestModel: CreateBasePricingRequestModel = {
      product_uuid: this._newCreatedProduct.data.uuid,
      prices: []
    };
    for(let i = 0; i < 4; i++) {
      createBasePricingRequestModel.prices.push({
        price: Number.parseFloat(this.pricing.get('pricing' + i).value),
        quantity: i + 1
      })
    }
    if (this._productPricing.data.length) {
      await this.pricingsService.patchBasePricing(createBasePricingRequestModel).toPromise();
    }
    else {
      await this.pricingsService.createBasePricing(createBasePricingRequestModel).toPromise();
    }
    let arrayOfPromisses : Promise<any>[] = [];
    for(let i = 4; i < this.priceRangeCount; i++) {
      const createCustomPricingRequestModel : CreatePricingRequestModel = {
        product_uuid: this._newCreatedProduct.data.uuid,
        price_per_piece: Number.parseFloat(this.pricing.get('pricing' + i).value),
        quantity_from: Number.parseFloat(this.pricing.get('unitsFrom' + i).value),
        quantity_to: Number.parseFloat(this.pricing.get('unitsTo' + i).value)
      }
      arrayOfPromisses.push(this.pricingsService.createCustomPricing(createCustomPricingRequestModel).toPromise());
    }
    Promise.all(arrayOfPromisses).then(() => {
      this.loadProductPricing();
      callback();
      tab.show();
    }).catch(() => {
      this.loadProductPricing();
    })
  }

  private preparePricing() {
    if (this._productPricing.data.length) {
      this._priceRangeCount--;
      const id = this.priceRangeCount;
      this.pricing.removeControl('unitsFrom4');
      this.pricing.removeControl('unitsTo4');
      this.pricing.removeControl('pricing4');
    }
    this._productPricing.data.forEach(productPrice => {
      if (productPrice.quantity_from < 5) {
        this.pricing.get('pricing' + (productPrice.quantity_from - 1)).setValue(Number(productPrice.price_per_piece).toFixed(2));
      }
      else {
        const id = this.priceRangeCount;
        this._priceRangeCount++;
        this.pricing.addControl('unitsFrom'+id, new FormControl(productPrice.quantity_from, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]));
        this.pricing.addControl('unitsTo'+id, new FormControl(productPrice.quantity_to, [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]));
        this.pricing.addControl('pricing'+id, new FormControl(Number(productPrice.price_per_piece).toFixed(2), [Validators.required, Validators.pattern(ValidationConfig.regexes.number)]));
      }
    });
  }

  private prepareVariations() {
    this.VARIATIONS_DATA = [];
    this.skuService.getSkus(this._newCreatedProduct.data.uuid).subscribe((skuCollection) => {
      skuCollection.data.forEach((sku) => {
        if (sku.options.length) {
          this.imageService.getImagesPerProduct('SKU', sku.uuid).subscribe(images => {
            this.imageCollectionPerVariation[sku.uuid] = images;
          })
          let variation = {
            sku: sku.sku,
            quantity: sku.available_quantity,
            uuid: sku.uuid
          }
          //this.VARIATIONS_DATA = [...this.VARIATIONS_DATA, variation];
  
          sku.options.forEach(option => {
            if(!this._variations.data.find((variation) => variation.uuid == option.option.uuid)) {
              this.skuOptionService.deleteSkuOption({
                                                sku_option_uuid: option.option.uuid
                                            });
            }
            else {
              if (option.option.display_name == 'Цвят') {

                if (this._variations.data[1].values.find(color => color.uuid == option.value)?.value) {
                  const colorName = this._variations.data[1].values.find(color => color.uuid == option.value).value;
                  variation[option.option.display_name] = [option.value, colorName]
                }
                else {
                  const colorName = option.value;
                  variation[option.option.display_name] = colorName;
                }
                //document.getElementById('Цвят').style.border = "1px solid " + this.getColor(colorName) +" !important;"
              }
              else {
                variation[option.option.display_name] = option.value;
              }
            }
          })
          this.VARIATIONS_DATA = [...this.VARIATIONS_DATA, variation];
        }
      })
      if (!this.VARIATIONS_DATA.length) {
        this.VARIATIONS_DATA = [...this.VARIATIONS_DATA , {
          sku: '',
          quantity: null
        }];
      }
      this.variationsDataSource = new MatTableDataSource(this.VARIATIONS_DATA);
      this._productSkus = skuCollection;
      this._userSelectedWhetherToShowVariations = this._productSkus.data.length > 1;
    })
  }

  private loadProductPricing() {
    this.pricingsService.getProductPricing(this._newCreatedProduct.data.uuid).subscribe(pricing => {
      this._productPricing = pricing;
      this.preparePricing();
    })
  }

  private workedWithProductUpdate(tab) {
    this.loadProductPricing();
    this.getProductTags();
    this.prepareVariations();
    this.getProductPackaging();
    // this.toastrService.success(Successes.productCreation[this._language]);
    this._imageUploadComponent.setProduct(this._newCreatedProduct);
    tab.show();
  }

  private async getProductTags() {
    this._tags = await this.tagsService.getTags(this._newCreatedProduct.data.uuid).toPromise();
    this.tags = this._tags.data.map(tagInfo => tagInfo.slug);
  }

  toggleFragile($event, isFragile: boolean) {
    if (!$event.target.checked) {
      $event.target.checked = true;
      return;
    }
    this.ship.get('fragile').setValue(isFragile);
  }

  private async getProductPackaging() {
    if (!this._currentProduct?.data?.packaging) return;
    this.ship.get('shippWithin').setValue(this._currentProduct.data.packaging?.delivery_time_in_days);
    this.ship.get('height').setValue(this._currentProduct.data.packaging?.dimensions.height);
    this.ship.get('length').setValue(this._currentProduct.data.packaging?.dimensions.length);
    this.ship.get('depth').setValue(this._currentProduct.data.packaging?.dimensions.depth);
    this.ship.get('weight').setValue(this._currentProduct.data.packaging?.dimensions.weight);
    this.ship.get('fragile').setValue(this._currentProduct.data.packaging?.courier_services.other_options.fragile);
    this.ship.get('check_before_pay').setValue(this._currentProduct.data.packaging?.courier_services.other_options.check_before_pay);
    this.ship.get('test_before_pay').setValue(this._currentProduct.data.packaging?.courier_services.other_options.test_before_pay);
    this.ship.get('courierType').setValue(this._currentProduct.data.packaging?.courier_services.other_options.type) 
    if (this._currentProduct.data.packaging?.courier_services.other_options.check_before_pay && 
      this._currentProduct.data.packaging?.courier_services.other_options.test_before_pay) {
        this.testExtrasValue = 'both';
    }
    else if (this._currentProduct.data.packaging?.courier_services.other_options.check_before_pay) {
      this.testExtrasValue = 'check';
    }
    else if (this._currentProduct.data.packaging?.courier_services.other_options.test_before_pay) {
      this.testExtrasValue = 'test';
    }
    else {
      this.testExtrasValue = 'none';
    }
  }

  private async getProductOptions() {
    this._productOptions = await this.productOptionService.getOptionsForProduct(this._newCreatedProduct.data.uuid).toPromise();
    this.options.forEach(option => {
      const currentOption = this._productOptions.data.find(pOption => pOption.display_name == option.display_name);
      let value: any = '';
      if (currentOption?.type.value == "boolean") {
        if (currentOption.value.value == '1' ||  currentOption.value.value == 'true') {
          value = true;
        }
        else {
          value = false;
        }
      }
      else {
        value = currentOption ? currentOption.value.uuid || currentOption.value.value : '';
      }
      this.specs.get(option.display_name.replace(',', '')).setValue(value);
    })
  }

  private saveProduct(tab) {
    if (this._newCreatedProduct?.data?.uuid) {
      this.updateCurrentProduct().then(updatedProduct => {
        this.skuService.updateSku(this._currentProduct.data.skus[0].uuid, this.productInfo.get('SKU').value,  this.productInfo.get('quantity').value).subscribe(() => {
          this.loadCurrentProduct(this._newCreatedProduct.data.uuid);
          this.workedWithProductUpdate(tab);
          this.getProductOptions();
        });
      })
    }
    else {
      this.createNewroduct().then(createdProduct => {
        const createSkuModel : CreateSkuRequestModel = {
          product_uuid: createdProduct.data.uuid,
          sku: this.productInfo.get('SKU').value,
          quantity: this.productInfo.get('quantity').value,
        }
        this.skuService.createSku(createSkuModel).subscribe((sku) => {
          this.newCreatedProduct = createdProduct;
          this.workedWithProductUpdate(tab);
          this.getProductOptions();
        })
      })
    }
  }

  selectExtras(event) {
    const value = event.target.value;
    if (!value) {
      return
    }
    switch (value) {
      case 'check':
        this.ship.get('check_before_pay').setValue(true);
        this.ship.get('test_before_pay').setValue(false);
        break;
      case 'test':
        this.ship.get('check_before_pay').setValue(false);
        this.ship.get('test_before_pay').setValue(true);
        break;
      case 'both':
        this.ship.get('check_before_pay').setValue(true);
        this.ship.get('test_before_pay').setValue(true);
        break;
      default:
        this.ship.get('check_before_pay').setValue(false);
        this.ship.get('test_before_pay').setValue(false);
        break;
    }
  }

  public shouldCreateBrand() : boolean {
    if (this.productInfo.get('brand').value == this.getCustomBrandTag()) {
     return true;
    }
    return false;
  }
  
  compareColors(o1: any, o2: any): boolean {
    return o1[0] === o2[0] && o1[1] === o2[1];
  }

  handleTabRedirect(event, tabElement, scope) {
    if (tabElement.completed) {
      if (tabElement.tab == "#info") {
        if (scope._newCreatedProduct?.data?.uuid && !scope._currentProduct) {
          scope.router.navigate(['dashboard/products/add/' + scope._newCreatedProduct?.data?.uuid]);
          return false;
        }
      }
      let index = 1;
      let tabIndex = 1;
      document.querySelectorAll('#myTab a').forEach(element => {
        index++;
        if (element.getAttribute('href') == tabElement.tab) {
          tabIndex = index;
        }
      })
      let step = (this.variations.length) ? 14.2 : 16.6;
      this.navValue = tabIndex * step;
      let tabEl = document.querySelector('a[href="' + tabElement.tab + '"]');
      let tab = new bootstrap.Tab(tabEl);
      tab.show();
    }
    event.stopImmediatePropagation();
    event.stopPropagation();
    return false;
  }

  handleCancelRedirect() {
      this.router.navigate(['dashboard/products']);
  }

  async updateCurrentProduct() {
    let customBrand = await this.createBrandIfNeeded();
    this.productInfo.get('brand').setValue((customBrand) ? customBrand.data.uuid : this.productInfo.get('brand').value);
    let updatedProduct : UpdateProductRequestModel = {
      description: this.specs.get('description').value || '',
      category_uuid: this.productInfo.get('subSubCategory').value || this.productInfo.get('subcategory').value,
      brand_uuid: (customBrand) ? customBrand.data.uuid : this.productInfo.get('brand').value,
      ean: this.productInfo.get('barcode').value,
      sku: this.productInfo.get('SKU').value,
      guarantee_in_months: this.productInfo.get('warranty').value,
      vat_percentage: this.productInfo.get('VAT').value,
      name: this.productInfo.get('name').value,
      product_condition: this.productInfo.get('condition').value
    }
    if (this.productInfo.get('manufacturer').dirty) {
      let manufacturer = await this.createManufacturer();
      updatedProduct.manufacturer_uuid = manufacturer.data.uuid;
    }
    return this.productService.updateProduct(updatedProduct, this._newCreatedProduct.data.uuid).toPromise();
  }

  async createBrandIfNeeded() : Promise<NewBrand>{
    let customBrand: NewBrand;
    if (this.shouldCreateBrand()) {
      customBrand = await this.createBrand();
    }
    return customBrand;
  }

  async createManufacturer() : Promise<Manufacturer> {
    if (!this.productInfo.get('manufacturer').value) return;
    const newManufacturer : CreateNewManufacturerRequestModel = {
      name: this.productInfo.get('manufacturer').value
    };
    const manufacturer = await this.manufacturerService.createNewManufacturer(newManufacturer).toPromise();
    return manufacturer;
  }

  async createNewroduct() {
    let customBrand = await this.createBrandIfNeeded();
    let manufacturer = await this.createManufacturer();
    let newProduct : CreateNewProductRequestModel = {
      organisation_uuid: this.loggedPartner.data.organisations[0].uuid,
      category_uuid: this.productInfo.get('subSubCategory').value || this.productInfo.get('subcategory').value,
      brand_uuid: (customBrand) ? customBrand.data.uuid : this.productInfo.get('brand').value,
      ean: this.productInfo.get('barcode').value,
      sku: this.productInfo.get('SKU').value,
      guarantee_in_months: this.productInfo.get('warranty').value,
      vat_percentage: this.productInfo.get('VAT').value,
      name: this.productInfo.get('name').value,
      product_condition: this.productInfo.get('condition').value
    };
    if (manufacturer) {
      newProduct.manufacturer_uuid = manufacturer.data.uuid
    }
    return this.productService.createNewProduct(newProduct).toPromise();
  }

  private createVariations() : Promise<Variation[]> {
    if (this.hasInstanceAttribute() && this.ELEMENT_DATA.find(element => element.SKU)) {
      return Promise.all(this.variationService.createVariations(this.ELEMENT_DATA, this._createdProduct))
    }
    return null;
  }

  private prepareProductForUpdate() {
    this._createdProduct.createdBy = this.loggedUser.firstName + " " + this.loggedUser.lastName;
    this._createdProduct.guaranteeDurationInMonths =	this.productInfo.get('warranty').value;
    this._createdProduct.heightValue =	this.ship.get('height').value;
    this._createdProduct.ean =	this.productInfo.get('barcode').value;
    this._createdProduct.lengthValue =	this.ship.get('length').value;
    this._createdProduct.weightValue =	this.ship.get('weight').value;
    this._createdProduct.manufacturer =	this.productInfo.get('manufacturer').value;
    this._createdProduct.name =	this.productInfo.get('name').value;
    this._createdProduct.partnerCode =	this.loggedUser.partner.name;
    this._createdProduct.partnerId =	this.loggedUser.partner.id;
    this._createdProduct.sku =	this.productInfo.get('SKU').value;
    this._createdProduct.vatCategory =	this.productInfo.get('VAT').value;
    this._createdProduct.volumeValue =	this.ship.get('depth').value * this.ship.get('length').value * this.ship.get('height').value;
    this._createdProduct.widthValue =	this.ship.get('depth').value;
    this._createdProduct.category01Id = this.productInfo.get('category').value;
    this._createdProduct.category02Id = this.productInfo.get('subcategory').value;
    this._createdProduct.category03Id = this.productInfo.get('brand').value;
    this._createdProduct.description = this.specs.get('description').value;
    this._createdProduct.type = environment.item;
    this._createdProduct.condition = this.productInfo.get('condition').value;
    this._createdProduct.dlvPickUp = this.ship.get('pickUp').value;
    this._createdProduct.qtyAvailableUnit = (this.specs.get('quantity')) ? (this.specs.get('quantity')).value : undefined;
    this._createdProduct.gender = (this.productInfo.get('gender')) ? (this.productInfo.get('gender')).value : undefined;
    this.addProductAttrToNewProduct();
  }

  private addProductAttrToNewProduct() {
    Object.keys(this.specs.controls).forEach(control => {
      if (this.defaultAttr.indexOf(control) < 0) {
        const attrName = control.toLowerCase() + "Attr";
        this._createdProduct[attrName] = this.specs.get(control).value;
      }
    })
  }

  updateProduct() : Promise<any>{
      this.prepareProductForUpdate();
      return this.itemService.updateItem(this._createdProduct).toPromise();
  }

  upload(currentTab) {
    if (this.validateTabBeforeMoving(currentTab)) {
      //let shouldCreateVariations = this.createVariations();
      let createProductPromises : Promise<any>[] = 
        [
          this.updateProduct(),
        ];
      createProductPromises.concat(this.pricingService.createPricings(this.pricing, this._createdProduct.id, this._priceRangeCount))  
      //if (shouldCreateVariations) {
      //  createProductPromises.push(shouldCreateVariations)
      //}
      Promise.all(createProductPromises)
        .then(() => {
          this.toastrService.success(
            Successes.productCreation[this._language]
          )
          this.goToDashboard();
        });
    }
  }
}

export const DocumentEnums = {
    names: {
        coefficient_policy: {
            bg: "Приложение за коефициентите"
        },
        commission_contract: {
            bg: "Търговско предложение - Kомисиона"
        },
        privacy_policy: {
            bg: "Приложение за личните данни"
        },
        terms_and_condition: {
            bg: "Общи условия"
        },
    }
}
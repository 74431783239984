
<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <h3 class="card-title">{{this.lables(this.getPageName()).lable}}</h3>
                <h5 class="card-sub-title">
                    {{this.lables(this.getPageName()).subLableFirstRow}} 
                    <br>
                    {{this.lables(this.getPageName()).subLableSecondRow}} 
                </h5>
                <div class="form-group">
                    <label for="exampleInputEmail1">{{this.lables(this.getPageName()).emailLabel}}</label>
                    <input (input)="this.emailChange($event.target.value)" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                </div>
                <div>{{this.lables(this.getPageName()).terms}}</div>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.lables(this.getPageName()).error}}
                </div>
                <button style="margin-top: 60px;" (click)="this.resetPassword()" type="button" class="btn btn-primary resetPassword">{{this.lables(this.getPageName()).resetPassword}}</button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
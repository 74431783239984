import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product } from 'src/models/DTO/V2/product/Product';
import { ProductCollection } from 'src/models/DTO/V2/product/ProductCollection';
import { CurrentProduct } from 'src/models/DTO/V2/product/CurrentProduct';
import { CreateNewProductRequestModel } from 'src/models/requestModels/V2/product/CreateNewProductRequestModel';
import { UpdateProductRequestModel } from 'src/models/requestModels/V2/product/UpdateProductRequestModel';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private productURL : string = environment.api + "product";

  constructor(private httpClient: HttpClient) { }

  createNewProduct(newProduct: CreateNewProductRequestModel) {
    return this.httpClient.post<Product>(this.productURL, newProduct);
  }

  updateProduct(updatedProduct: UpdateProductRequestModel, currentProductId: string) {
    const params = '/' + currentProductId;
    return this.httpClient.put<Product>(this.productURL + params, updatedProduct);
  }

  getProducts(perPage: number, page: number, state: string) {
    const params = '?fields%5Bproducts%5D=id%2Cuuid%2Cname%2Corganisation_id%2Ccreated_at%2Cupdated_at%2Ccategory_id%2Cbrand_id%2Cmanufacturer_id%2Cstate&fields%5Bskus%5D=id%2Cproduct_id%2Cuuid%2Csku%2Cavailable_quantity%2Creserved_quantity&include=skus%2Corganisation%2Cpricing%2Cbrand%2Ccategory%2Cmanufacturer%2Cskus.options.option%2Coptions.option&append=total_available_quantity%2Ctotal_reserved_quantity&fields%5Borganisation%5D=id%2Cuuid%2Cname_in_latin%2Cnative_name&filter%5Bstate%5D=' + state + '&fields%5Bpricing%5D=id%2Cproduct_id%2Cuuid%2Cquantity_from%2Cquantity_to%2Cprice_per_piece&fields%5Bcategory%5D=id%2Cuuid%2Cname&fields%5Bskus.options%5D=id%2Cuuid%2Csku_id%2Coption_variation_id%2Cdisplay_value&fields%5Bskus.options.option%5D=id%2Cuuid%2Cdisplay_name&fields%5Boptions.option%5D=id%2Cuuid%2Cdisplay_name&perPage=' + perPage + '&page=' + page;
    return this.httpClient.get<ProductCollection>(this.productURL + params);
  }

  getProduct(productUUID: string) {
    const params = '/' + productUUID + '?fields%5Bproducts%5D=id%2Cuuid%2Cname%2Corganisation_id%2Cproduct_condition%2Ccreated_at%2Cupdated_at%2Ccategory_id%2Cbrand_id%2Cmanufacturer_id%2Cdescription%2Cguarantee_in_months%2Cean%2Cvat_percentage&fields%5Bskus%5D=id%2Cproduct_id%2Cuuid%2Csku%2Cavailable_quantity%2Creserved_quantity&include=brand%2Ccategory%2Cmanufacturer%2Cskus%2Cpackaging&append=total_available_quantity%2Ctotal_reserved_quantity&fields%5Bcategory%5D=id%2Cuuid%2Cname&fields%manufacturer%5D=id%2Cuuid%2Cname';
    return this.httpClient.get<CurrentProduct>(this.productURL + params);
  }

  changeProductState(productUUID: string, state: string) {
    const params = '/' + productUUID + '/change-state';
    const payload = {
      state: state
    };
    return this.httpClient.post<any>(this.productURL + params, payload);
  }

  deleteProduct(productUUID: string) {
    const params = '/' + productUUID;
    return this.httpClient.delete(this.productURL + params);
  }
}

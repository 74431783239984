import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateLogisticsRequestModel } from 'src/models/requestModels/V2/logistics/CreateLogisticsRequestModel';
import { CarrierAccount } from 'src/models/DTO/V2/carrier/CarrierAccount';

@Injectable({
  providedIn: 'root'
})
export class LogisticsService {

  private logisticsURL : string = environment.api + "carrier/account";

  constructor(private httpClient: HttpClient) { }

  createLogistics(carrierAccountUUID: string, createLogisticsRequestModel: CreateLogisticsRequestModel) {
    const params = '/' + carrierAccountUUID;
    return this.httpClient.put<CarrierAccount>(this.logisticsURL + params, createLogisticsRequestModel);
  }
}

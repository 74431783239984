import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'src/models/DTO/V2/orders/Order';
import { OrderResponse } from 'src/models/DTO/V2/orders/OrderResponse';

import { OrderCollection } from 'src/models/DTO/V2/orders/OrderCollection';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { SearchService } from 'src/services/requestServices/search/search.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { OrdersService } from 'src/services/requestServices/V2/orders/orders.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { ToastrService } from 'ngx-toastr';
import { ShipmentService } from 'src/services/requestServices/V2/shipment/shipment.service';
import { CancelOrderReasons } from 'src/config/labels/cancelOrderReasons';
import { OrderRessourceLine } from 'src/models/DTO/V2/orders/OrderRessourceLine';

import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { OrderStatus } from 'src/config/labels/orderStatus';
import { Fees } from 'src/config/labels/fees';
import { ShippingMethod } from 'src/config/labels/shippingMethod';
import { OrderStatusColors } from 'src/config/labels/orderStatusColors';
import { environment } from 'src/environments/environment';
import { P } from '@angular/cdk/keycodes';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Successes } from 'src/config/labels/successes';
import { ErrorTranslations } from 'src/config/labels/errorTranslations';



@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent extends BaseDashboardComponent implements OnInit {

  @ViewChild("cancelWindow") _cancelWindowmodal: ElementRef;
  public displayedColumnsForProductTable: string[];
  public displayedColumnsForCancledProductTable: string[];

  public ordersDataSource: OrderRessourceLine[] = [];
  public returnedOrdersDataSource: OrderRessourceLine[] = [];
  public numberPerPageValue : number = -1;
  public page: number = 0;
  public status: string = 'active';
  public packegeNumber = 1;
  expandedElement: Order | null;


  private _pageNumber = 1;
  private _searchString = "";
  private _searchType = "name.contains";
  private _initialNumberOfLoadedPages : number = 3;
  private _maxPage : number = 100;
  private _scrolling = false;
  private _currentOpenWindow?: NgbModalRef = null;
  private _selectedOrderLine: OrderRessourceLine

  private _order: OrderResponse;
  

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected productService: ProductService,
    protected usersService: UsersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    private ordersService: OrdersService,
    protected imageService: ImageService,
    private searchingService: SearchService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,
    private toastrService: ToastrService,
    private shipmentService: ShipmentService,
    private loadingState: LoadingstateService,
    private modalService: NgbModal,
    private toatsrService: ToastrService

  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.pageName = "orders";
    const initialSelection = [];
    const allowMultiSelect = true;

    this.loadingState.loading$.subscribe(result => {
      this.isLoading = result;
    });
    this.displayedColumnsForProductTable = ['Product', 'Quantity', 'PricePerPiece', 'Net', 'Vat', 'Price', 'Total', 'action'];
    this.displayedColumnsForCancledProductTable = ['Product', 'Quantity', 'PricePerPiece', 'Net', 'Vat', 'Price', 'Total'];


  }

  private loadImageForLine(line: OrderRessourceLine) {
    if (line.sku) {
      this.imageService.getImagesPerProduct("SKU", line.sku.uuid).subscribe(images => {
        if (!images.data.length) {
          this.imageService.getImagesPerProduct("PRODUCT", line.sku.product.uuid).subscribe(images => {
            if (images.data.length) {
              line.imageSource = images.data[0]?.original.url;
            }
          });
        }
        else {
          line.imageSource = images.data[0]?.original.url;
        }
      })
    }
  }

  private loadImagesForOrdersCollection(order: OrderResponse) {
      order.data.lines.forEach(line => {
        this.loadImageForLine(line);
    })
  }

  private fixPaymentValues(order: OrderResponse) {
    order.data.amount_captured = Convertor.convertNumbers.addToFixedSuffix(order.data.amount_captured, 2);
    order.data.amount_refunded = Convertor.convertNumbers.addToFixedSuffix(order.data.amount_refunded, 2);
    order.data.amount_reserved = Convertor.convertNumbers.addToFixedSuffix(order.data.amount_reserved, 2);
    order.data.total_gross = Convertor.convertNumbers.addToFixedSuffix(order.data.total_gross, 2);
    order.data.total_net = Convertor.convertNumbers.addToFixedSuffix(order.data.total_net, 2);
    order.data.total_tax_amount = Convertor.convertNumbers.addToFixedSuffix(order.data.total_tax_amount, 2);
    order.data.shipment_cost = Convertor.convertNumbers.addToFixedSuffix(order.data.shipment_cost, 2);
    order.data.voucher_amount = Convertor.convertNumbers.addToFixedSuffix(order.data.voucher_amount, 2);
    order.data.lines.forEach(line => {
      line.price_per_piece = Convertor.convertNumbers.addToFixedSuffix(line.price_per_piece , 2);
      line.single_gross = Convertor.convertNumbers.addToFixedSuffix(line.single_gross , 2);
      line.single_net = Convertor.convertNumbers.addToFixedSuffix(line.single_net, 2);
      line.single_tax_amount = Convertor.convertNumbers.addToFixedSuffix(line.single_tax_amount, 2);
      line.total_gross = Convertor.convertNumbers.addToFixedSuffix(line.total_gross, 2);
      line.total_net = Convertor.convertNumbers.addToFixedSuffix(line.total_net, 2);
      line.total_tax_amount = Convertor.convertNumbers.addToFixedSuffix(line.total_tax_amount, 2);
    });
    
    return order;
  }

  private loadOrderData(orderId: string) {
    this.ordersService.getOrder(orderId).subscribe(order => {
      let canceledStatus = 'cancelled';
      order = this.fixPaymentValues(order)
      this._order = order;
      this.loadImagesForOrdersCollection(this._order);
      this.ordersDataSource = this._order.data.lines.filter(line => line.state != canceledStatus)
      this.returnedOrdersDataSource = this._order.data.lines.filter(line => line.state == canceledStatus)
      this.ordersDataSource.forEach(line => {
        if (line.quantity != line.initial_quantity) {
          let clonedLine = { ...line } ;
          this.loadImageForLine(clonedLine);
          clonedLine.quantity = clonedLine.initial_quantity - clonedLine.quantity;
          this.returnedOrdersDataSource.push(clonedLine)
        }
      })
      this.ordersDataSource.map(line => line.editable = true);
      this.returnedOrdersDataSource.map(line => {
        line.editable = true;
        if (line.quantity == 0) {
          line.quantity = line.initial_quantity - line.quantity;
        }
      });
      if (this.ordersDataSource.length) {
        this.ordersDataSource.push({
          editable: false,
          uuid: '',
          state: '',
          price_per_piece: '0.00',
          quantity: 0,
          initial_quantity: 0,
          total_gross: '0.00',
          total_tax_amount: '',
          total_net: this.getNetShipmentConst,
          single_gross: this._order.data.shipment_cost,
          single_tax_amount: '0.00',
          single_net: this.getNetShipmentConst,
          options: [],
          sku: {
            uuid: '',
            sku: '',
            options: [],
            product: {
              uuid: '',
              name: 'Доставка',
              state: '',
              description: {
                bg: ''
              },
              guarantee_in_months: 0,
              organisation: {
                uuid: '',
                native_name: '',
                name_in_latin: '',
                requirements: [],
                delivery_to_address_price: '',
                delivery_to_courier_office_price: '',
                free_delivery_after_order_amount: '',
                number: 0
              },
              category: {
                uuid: '',
                name: ''
              },
              brand: {
                uuid: '',
                name: ''
              },
              skus: [],
              options: [],
              total_available_quantity: 0,
              total_reserved_quantity: 0,
              created_at: '',
              updated_at: '',
              vat_percentage: this._order.data.lines[0].sku.product.vat_percentage
            }
          }
        })
      }
    });
  }

  ngOnInit(): void {
    this.getUrlParams().subscribe((params) => {
      const orderId = params?.params?.id;
      if (orderId) {
        this.loadOrderData(orderId);
      }
    });
  }

  ngAfterViewInit() {
    this.loadTooltips();
  }

  public goToOrders() {
    this.router.navigate(['/dashboard/orders'])
  }

  public getLineTotalAmount(line: OrderRessourceLine) {
    if (!line.editable) return this._order.data.shipment_cost;
    return Number.parseFloat(line.total_gross).toFixed(2);
  }

  public getReturnedTotalAmount(line: OrderRessourceLine) {
    return (line.quantity * Number.parseFloat(line.price_per_piece)).toFixed(2);
  }

  public getPriceForFirstRange(line: OrderRessourceLine) {
    return line.sku.product.pricing.find(pricing => pricing.quantity_from == 1 && pricing.quantity_to == 1).price_per_piece || 0;
  }

  public convertVatPercentage(line: OrderRessourceLine) {
    return Number.parseInt(line.sku.product.vat_percentage);
  }

  public buildAddressDetails(address: any) {
    if (address.street_name == '-') {
      return address.address_line_2
    }
    return address.street_name + ' ' + address.street_number + ((address.address_line_2) ? ', ' + address.address_line_2 : ''); 
  }

  public convertOrderStatus(orderStatus: string) {
    return OrderStatus[orderStatus];
  }

  public checkGroupType(isGroup: object | null) {
    if (!isGroup) {
      return 'Лична';
    }
    return 'Групова';
  }

  get shipment() {
    return this._order.data.shipment ? this._order.data.shipment[0].ref_1 : null
  }

  get getNetShipmentConst() {
    return Convertor.convertNumbers.addToFixedSuffix(Number.parseFloat(this._order?.data.shipment_cost) / (Number.parseInt(this._order?.data.lines[0].sku.product.vat_percentage) / 100 + 1), 2);
  }

  get orderStatusColor() {
    return OrderStatusColors[this.order?.data?.state]
  }

  get ShareMeReturn() {
    return (this.order.data?.total_net) ? Number.parseFloat(this.order.data?.total_net) * environment.ShareMeReturnTax : 0
  }

  get order() {
    return this._order;
  }

  get orderTotal() {
    return Convertor.convertNumbers.addToFixedSuffix(Number.parseFloat(this.order?.data?.shipment_cost) + Number.parseFloat(this.order?.data?.total_gross), 2);
  }

  get classForSensitiveInfo() {
    if (this._order.data.state == "waiting" || this._order.data.delivery_address.mobile_phone_1.includes("*")) return "col-sm-8 column blurry-text"
    return "col-sm-8 column"
  }

  get selectedOrderLine() {
    return this._selectedOrderLine;
  }

  get orderRightPanel() {
    if (this.order?.data?.state == 'waiting') {
      return 'to_confirm';
    }
    else if (this.order?.data?.state == 'cancelled') {
      return 'cancelled';
    }
    else if (this.order?.data?.state == 'completed') {
      return 'completed';
    }
    else if (!['waiting_payment', 'cancelled'].includes(this.order?.data?.state)&& this.order?.data?.shipment && this.order?.data?.shipment[0]?.uuid) {
      return 'with_shipment';
    }
    else {
      return 'normal';
    }
  }

  get cancellationFee() {
    if (!this._order) return "-";
    if (!this._order.data.organisation_fees.length) return "-";
    let fee = this._order.data.organisation_fees.find(feeTmp => feeTmp.type == Fees.ORDER_CANCELATION_TYPE_FEE)?.gross_fee_amount;
    if (!fee) return "-";
    fee = Number.parseFloat(fee).toFixed(2)
    return fee + ' лв.'
  }

  public priceConvertor(number: number) {
    if (!number) {
      return '0.00';
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  public orderVariationsIfAny(orderLine: OrderRessourceLine) {
    if (!orderLine.sku.options.length) return [];
    let orderLineOptions = [];
    orderLine.sku.options.forEach(orderOption => {
      let displayName = orderOption.option.display_name;
      orderOption.option.values.forEach(possibleOptionValue => {
        if (possibleOptionValue.uuid == orderOption.value) {
          orderLineOptions.push({ name: displayName, value: possibleOptionValue.value})
        }
      })
    });
    return orderLineOptions;
  }

  public convertDate(date: string) {
    return Convertor.convertDate.convertDateTimeWithDelimiter(date, '/');
  }

  public confirmOrder() {
    this.ordersService.confirmOrder(this.order.data.uuid, { number_of_parcels: 1 }).subscribe(() => {
      this.toatsrService.success(Successes.confirmedOrder[this.language]);
      this.loadOrderData(this.order.data.uuid);
    })
  }

  public onPackageChange(event: any) {
    if (event.target.value < 1) {
      event.target.value = 1;
    }
    if (event.target.value > 10) {
      this.packegeNumber = 10;
    }
    else {
      this.packegeNumber = Number.parseInt(event.target.value);
    }
  }

  public paymentType(orderPaymentCode: string) {
    if (orderPaymentCode == 'card') {
      return "С карта";
    }
    return "Наложен платеж";
  }

  public generateShipment() {
    this.shipmentService.updateParcels({ number_of_parcels: this.packegeNumber}, this.order.data.shipment[0].uuid).subscribe(() => {
      this.shipmentService.createShipment(this.order.data.shipment[0].uuid).subscribe(() => {
        this.loadOrderData(this.order.data.uuid);
      })
    })
  }

  public printWayBill() {
    this.shipmentService.printWayBill(this.order.data.shipment[0].uuid).subscribe((response) => {
      let a = document.createElement("a")
      a.href = URL.createObjectURL(response)
      a.download = "Waybill.pdf";
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      this.toastrService.success("Успешно генериран документ");
    })
  }

  public printStockReciept() {
    this.ordersService.downloadOrderDocument(this.order.data.uuid).subscribe((response) => {
      let a = document.createElement("a")
      a.href = URL.createObjectURL(response)
      a.download = "Stock.pdf";
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      this.toastrService.success("Успешно генериран документ");
    })
  }
  
  public cancel() {
    this.ordersService.cancelOrderLine(this._selectedOrderLine.uuid, { reason: CancelOrderReasons.abandoned, new_quantity: this._selectedOrderLine.quantity - 1  }).subscribe(() => {
      this._currentOpenWindow.close();
      this.loadOrderData(this.order.data.uuid);
      this.returnedOrdersDataSource.push(this._selectedOrderLine);
      this.ordersDataSource = [...this.ordersDataSource.filter((order) => order.uuid != this._selectedOrderLine.uuid)];
      this.toatsrService.success(Successes.cancledOrder[this.language]);
      this._selectedOrderLine = null;
    }, (error) => {
      this.toatsrService.error(ErrorTranslations.translate(error.error.exception.message, this.language));
      this._selectedOrderLine = null;
    });
  }

  public cancelOrderLine(orderLine: OrderRessourceLine) {
    this._currentOpenWindow = this.modalService.open(this._cancelWindowmodal, { centered: true } );
    this._selectedOrderLine = orderLine;
  }

  convertShipmentMethod(shipmentKey) {
    return ShippingMethod[this._language][shipmentKey];
  }

  refund() {
    // Not sure if route is correct
    this.ordersService.refundOrder(this.order.data.uuid, { reason: ''}).subscribe(() => {
      this.toastrService.success("Успешно заявихте поръчката за връщане")
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { DASHBOARD_LABLES } from 'src/config/labels/dashboard';
import { Errors } from 'src/config/labels/errors';
import { Labels } from 'src/config/labels/labels';
import { Successes } from 'src/config/labels/successes';
import { environment } from 'src/environments/environment';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { Item } from 'src/models/DTO/base/item';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { CreateNewBase64Image } from 'src/models/requestModels/V2/image/Image';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { Product } from 'src/models/DTO/V2/product/Product';
import { ImageCollection } from 'src/models/DTO/V2/image/ImageCollection';
import { DeleteImage } from 'src/models/requestModels/V2/image/DeleteImage';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Image } from 'src/models/DTO/images/image';
import { ImageReorderRequestModel } from 'src/models/requestModels/V2/image/ImageReorderRequestModel';


@Component({
  selector: 'app-imageupload',
  templateUrl: './imageupload.component.html',
  styleUrls: ['./imageupload.component.css']
})
export class ImageuploadComponent implements OnInit {


  public files: NgxFileDropEntry[] = [];
  public base64Files: Base64Image[] = [];
  public deleteImageCallback;
  public showCarousel = false;

  private _dashboardLables;
  private _pageName = "";
  private _language;
  private _createdProduct: Product;
  private _storedFilesPerProduct = 0;

  public imageCollectionPerProduct: ImageCollection;
  public createdItem: Item;


  constructor(
    private imageService: ImageService,
    private toastrService: ToastrService,
    private localStorageService: LocalstorageserviceService,
  ) {
    this._pageName = 'imageUpload';
    this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
    this._dashboardLables = DASHBOARD_LABLES[this._language];
   }

  ngOnInit(): void {
  }

  get images() : Array<any> {
    return (this.imageCollectionPerProduct) ? this.imageCollectionPerProduct.data : [];
  }

  dashboardLables(pagename: string) {
    return this._dashboardLables[pagename];
  }

  getPageName() {
    return this._pageName;
  }

  setCreated(created: Item) {
    this.createdItem = created;
  }

  setProduct(product: Product) {
    this._createdProduct = product;
    this.imageService.getImagesPerProduct('PRODUCT', this._createdProduct.data.uuid).subscribe(images => {
      this.imageCollectionPerProduct = images;
      this._storedFilesPerProduct = this.imageCollectionPerProduct.data.length;
    })
  }

  dropped(files: NgxFileDropEntry[]) {
    let self = this;
    this.files = this.files.concat(files);
    for (const droppedFile of files) {
      if (this._storedFilesPerProduct + files.length > environment.maxPicturesPerProduct) {
        this.toastrService.error(Errors.imageMaxAmount[this._language]);
        return;
      }
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!ValidationHelper.allowedImages.validate(file.name.split('.').pop())) {
            this.toastrService.error(Errors.imageType[this._language]);
            return;
          }
          if (!ValidationHelper.maxSizeOfImage.validate(file.size)) {
            this.toastrService.error(file.name + Errors.imageMaxSize[this._language]);
            return;
          }
          let fr = new FileReader();
          fr.addEventListener("load", function () {
            const content = fr.result.toString().substring(fr.result.toString().indexOf(',') + 1);
            const newImage : CreateNewBase64Image = {
              subject_uuid: self._createdProduct.data.uuid,
              subject_type: 'PRODUCT',
              collection: 'images',
              uploads: [ { base64: content } ]
            };
            self.imageService.createNewImage(newImage).subscribe((imageResponse) => {
              self._storedFilesPerProduct++;
              imageResponse.data.forEach(image => {
                const base64Image: Base64Image = {
                  subject_uuid: self._createdProduct.data.uuid,
                  subject_type: 'PRODUCT',
                  imageContent: fr.result.toString(),
                  sanitizedContent: image.file_name,
                  id: image.uuid
                };
                self.base64Files.push(base64Image);
              })
              self.toastrService.success(Successes.imageUpload[self._language]);
              self.imageService.getImagesPerProduct('PRODUCT', self._createdProduct.data.uuid).subscribe(images => {
                self.imageCollectionPerProduct = images;
              })
            })

          }, false);

          fr.readAsDataURL(file);

        });
      }
    }
  }

  deleteImage(imageId: string) {
    const imageToDelete: DeleteImage = {
      subject_type: 'PRODUCT',
      subject_uuid: this._createdProduct.data.uuid,
      uuid: imageId
    }
    this.imageService.deleteImage(imageToDelete).subscribe(() => {
      this._storedFilesPerProduct--;
      this.base64Files = this.base64Files.filter(image => image.id != imageId);
      this.imageCollectionPerProduct.data = this.imageCollectionPerProduct.data.filter(image => image.uuid != imageId);

      if (this.deleteImageCallback) {
        this.deleteImageCallback(imageId);
      }
      this.toastrService.success(Successes.imageDelete[this._language]);
    })
  }

  closeCarouselOnOutsideClick(event) {
    if (event.target.id == 'carouselWindow') {
      this.showCarousel = false;
    }
  }

  drop(event: CdkDragDrop<Image[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    let imageReorderModel : ImageReorderRequestModel = {
      subject_type: 'PRODUCT',
      subject_uuid: this._createdProduct.data.uuid,
      media: []
    }
    let order = 0;
    this.images.forEach(element => {
      imageReorderModel.media.push({
        uuid: element.uuid,
        sort_order: order
      })
      order++;
    });
    this.imageService.reorderImages(imageReorderModel).subscribe(() => {
      this.toastrService.success(Successes.imageReorder[this._language]);
    })
  }

}

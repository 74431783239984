export const ValidationConfig = {
    telephone: {
        BGN: /^\+359\d{9}$/
    },

    email: {
        regex: /^[^@\s]+@([^@\s]+\.)+[^@\s]+$/
    },

    allowedImageExtensions: [
        "jpeg", "jpg", "png", "tiff"
    ],
    maxSizeOfImage: 8388608,
    regexes: {
        numberOnly: '^[0-9]+$',
        number: '^[0-9,.]+$',
        password: "(?=^.{6,10}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$",
        alpha: '^[A-Za-z]+$',
        alpha_dash: '^[a-zA-Z0-9-_]+$',
        cyrylic: '^[A-Za-zА-Яа-я0-9 \\W]+$',
        cyrylicOnly: '^[А-Яа-я0-9 \\W]+$',
        cyrilycWithSymbols: '[^\u0400-\u04FF.!"£$%^&*?,~|()\[\]\\\/]',
        cyrylicFull: '^[A-Za-zА-Яа-я0-9 \\W]+$',
        latin: '^[A-Za-z0-9 .-]+$',
        latinFull: '^[A-Za-z0-9 \\W]+$',
        iban: '[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}',
        bic: '([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)'
    }

}

<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body">
    <div class="row">
        <h4 style="margin-bottom: 35px; margin-top: 10px;">Фактури</h4>
    </div>
    <table mat-table multiTemplateDataRows [dataSource]="this.invoices" matSort class="mat-elevation-z8 products">
        <ng-container matColumnDef="Period">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef >Период</th>
            <td mat-cell *matCellDef="let element" style="width: 50px !important"></td>
        </ng-container>

        <ng-container matColumnDef="FOrders">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>Завършени поръчки <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Включва стойността на Доставените поръчки в секция Завършени."></small></th>
            <td mat-cell *matCellDef="let element" class="product-name-field"> {{element.address}} </td>
        </ng-container>

        <ng-container matColumnDef="ShareMe">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>ShareMe <br> Комисион 11% <small id="emailHelp" style="position: relative; top: -7px; right: 20px;" class="text-muted" data-bs-toggle="tooltip" title="Начислената комисионна на Завършените (Доставените) поръчки. За поръчки с НП, не се начислява комисион върху стойността на доставката."></small></th>
            <td mat-cell *matCellDef="let element">
                {{element.clientName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Taxes">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef>Такси <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Включва такса отказване, такса възстановяване и банков превод."></small></th>
            <td mat-cell *matCellDef="let element"> {{element.contactName}} </td>
        </ng-container>

        <ng-container matColumnDef="Returned">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef>Върнати поръчки с <br> карта <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Възстановените суми, чрез ShareMe, за поръчки платени с карти."></small></th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="ToRecieve">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>За получаване <small style="position: relative; top: 2px;" id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Сумата на завършилите (доставени) поръчки, минус таксите и възстановените суми, чрез ShareMe, платени с карта."></small></th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        
        <ng-container matColumnDef="Invoice">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>Фактура</th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsForAddressesTable"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsForAddressesTable;"></tr>
    </table>

    <ngb-alert [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
        <div class="alertIcon" style="display: inline-block; position: relative; margin-right: 10px; top: -10px;"></div> <div style="display: inline-block;  width: 95%;"> Ако сумата “За получаване" е отрицателна, моля преведете дължимата сума до 4 дни на посочената във фактурата банкова сметка на "ШеърМи" АД, за да продължите да продавате и продуктите ви да са видими от клиентите.</div>
    </ngb-alert>

    <div *ngIf="this.invoices.length == 0" style="text-align: center; margin-bottom: 50px; z-index: 1001;" class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">
        <img src="/assets/images/dashboard/products/noinvoices.svg" style="margin: auto; margin-top: 40px; z-index: 1001; width: 400px; height: 250px;" width="300" height="300" alt="">
        <h3 style="color: black; z-index: 1001; font-size: 21px;">Все още нямате издадени фактури<br>
            <span style="color: #75809C; z-index: 100000; font-size: 16px;">На този екран ще виждате списък с издадените <br> фактури за съответния период</span> <br> <br>
        </h3>
    </div>

</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration/registration.component';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';



@NgModule({
  declarations: [RegistrationComponent, DashboardComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    ToastrModule
  ],
  exports: [
    RegistrationComponent,
    DashboardComponent
  ]
})
export class LayoutsModule { }

export const STRIPE = {
    firmDetails: "Show firm details",
    products: 'products',
    product: "Product",
    image: "Image",
    name: "Name",
    sku: "SKU",
    quantity: "Quantity",
    price: "Price",
    date: "Date",
    variations: "Variations",
    address: 'Address',
    firmDetailsLabel: "Firm Details",
    firmName: 'Name of the firm',
    tradingName: 'Name in latin',
    legalOrgType: 'Legal form',
    bulstat: 'BULSTAT',
    vatRegistered: 'VAT Registered',
    vatNumber: 'VAT Number',
    iban: 'IBAN',
    bankName: 'Bank',
    bankSortCode: 'BIC/SWIFT',
    accountId: "Account ID",
    status: "Status",
    reason: "Reason",
    portal: "Portal",
    statuses: {
        onBoarding: {
            status: 'Onboard',
            forLink: 'on_boarding'
        },
        dashboard: {
            status: 'Dashboard',
            forLink: 'dashboard'
        },
        active: {
            status: "active",
            label: "Active"
        },
        pending: {
            status: "pending",
            label: "Pending"
        },
        on_hold: {
            status: "on_hold",
            label: "On hold"
        },
        deleted: {
            status: "deleted",
            label: "Deleted"
        }
    }
}
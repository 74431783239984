import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Carrier } from '../../../../models/DTO/V2/carrier/Carrier';
import { CarrierAddressItem } from '../../../../models/DTO/V2/carrier/CarrierAddressItem';
import { CarrierAccount } from '../../../../models/DTO/V2/carrier/CarrierAccount';
import { CreateCarrierRequestModel } from './../../../../models/requestModels/V2/carrier/CreateCarrierRequestModel';
import { CreateCarrierAddressRequestModel } from './../../../../models/requestModels/V2/carrier/CreateCarrierAddressRequestModel';
import { SetNewDefaultCarrierAddressRquestModel } from './../../../../models/requestModels/V2/carrier/SetNewDefaultCarrierAddressRquestModel';
import { UpdateCarrierPasswordRequestModel } from './../../../../models/requestModels/V2/carrier/UpdateCarrierPasswordRequestModel';
import { CarrierPredefinedAddresses } from 'src/models/DTO/V2/carrier/CarrierPredefinedAddresses';




@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  
  private carrierAccountURL : string = environment.api + "carrier/account";
  private carrierURL : string = environment.api + "carrier";
  private carrierSearchURL : string = environment.api + "carrier/address/search";
  private carrierAddressURL : string = environment.api + "contact/carrier-attributes";
  private updateCarrierPasswordURL : string = environment.api + "contact/update-password/"



  constructor(private httpClient: HttpClient) { }

  createCarrierAccount(createCarrierRequestModel: CreateCarrierRequestModel) {
    return this.httpClient.post<any>(this.carrierAccountURL, createCarrierRequestModel);
  }

  getCarriearAccount() {
    return this.httpClient.get<CarrierAccount>(this.carrierAccountURL);
  }

  getCarriers() {
    return this.httpClient.get<Carrier>(this.carrierURL);
  }

  getCarrierAddresses(carrierUUID: string) {
    const params = '/available-objects/' + carrierUUID;
    return this.httpClient.get<CarrierPredefinedAddresses>(this.carrierAccountURL + params);
  }

  updateCarrierAddresses(carrierUUID: string, carrierAddressUpdateModel: SetNewDefaultCarrierAddressRquestModel) {
    return this.httpClient.put(this.carrierAccountURL + '/' + carrierUUID, carrierAddressUpdateModel);
  }

  searchCarrierCountries(seacrhString: string) {
    return this.httpClient.get<CarrierAddressItem>(this.carrierSearchURL + '/country/' + seacrhString);
  }

  searchCarrierCity(countryKey: number, seacrhString: string) {
    return this.httpClient.get<CarrierAddressItem>(this.carrierSearchURL + '/city/' + countryKey + '/' + seacrhString);
  }

  searchCarrierStreet(cityKey: number, seacrhString: string) {
    return this.httpClient.get<CarrierAddressItem>(this.carrierSearchURL + '/steet/' + cityKey + '/' + seacrhString);
  }

  saveContactCarrierAdderres(createCarrierAddressRequestModel: CreateCarrierAddressRequestModel) {
    return this.httpClient.post<any>(this.carrierAddressURL, createCarrierAddressRequestModel);
  }

  updateCarrierPassword(carrierUUID: string, updateCarrierPasswordRequestModel: UpdateCarrierPasswordRequestModel) {
    return this.httpClient.post<any>(this.updateCarrierPasswordURL + carrierUUID, updateCarrierPasswordRequestModel);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ProductsComponent } from './products/products.component';
import { MatSortModule } from '@angular/material/sort';
import { AddproductComponent } from './addproduct/addproduct.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseDashboardComponent } from './base-dashboard/base-dashboard.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddserviceComponent } from './addservice/addservice.component';
import { SharedModule } from '../shared/shared.module';
import { EditserviceComponent } from './editservice/editservice/editservice.component';
import { EditproductComponent } from './editproduct/editproduct.component';
import { StoresComponent } from './stores/stores.component';
import { CreateStoreComponent } from './create-store/create-store.component';
import { EditstoreComponent } from './editstore/editstore.component';
import { SettingsComponent } from './settings/settings.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DeliveryComponent } from './delivery/delivery.component';
import { BaseItemComponent } from './base-item/base-item.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { NgbAccordionModule, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrdersComponent } from './orders/orders.component';
import { CarriersComponent } from './carriers/carriers.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OrderComponent } from './order/order.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';





@NgModule({
  declarations: [ProductsComponent, AddproductComponent, BaseDashboardComponent, AddserviceComponent, EditserviceComponent, EditproductComponent, StoresComponent, CreateStoreComponent, EditstoreComponent, SettingsComponent, DeliveryComponent, BaseItemComponent, CalendarComponent, OrdersComponent, CarriersComponent, OrderComponent, InvoicesComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    NgxFileDropModule,
    SharedModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    MatSlideToggleModule,
    NgbModule,
    NgbAccordionModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatButtonToggleModule,
    FormsModule,
    FontAwesomeModule
  ]
})
export class DashboardModule { }

import { Routes } from "@angular/router";
import { PositionComponent } from './../../../app/registration/position/position.component';
import { PersonaldetailsComponent } from './../../../app/registration/personaldetails/personaldetails.component';
import { PersonaladdressComponent } from './../../../app/registration/personaladdress/personaladdress.component';
import { LoginComponent } from './../../../app/registration/login/login.component';
import { ForgotpasswordComponent } from './../../../app/registration/forgotpassword/forgotpassword.component';
import { FinishComponent } from './../../../app/registration/finish/finish.component';
import { EmailpageComponent } from './../../../app/registration/emailpage/emailpage.component';
import { EmailconfirmationComponent } from './../../../app/registration/emailconfirmation/emailconfirmation.component';
import { CompanydetailedComponent } from './../../../app/registration/companydetailed/companydetailed.component';
import { CompanyaddressComponent } from './../../../app/registration/companyaddress/companyaddress.component';
import { ChangepasswordComponent } from './../../../app/registration/changepassword/changepassword.component';
import { SuccessfulactivationComponent } from "src/app/registration/successfulactivation/successfulactivation.component";
import { ResetpasswordComponent } from "src/app/registration/resetpassword/resetpassword.component";
import { ResetpassconfirmComponent } from "src/app/registration/resetpassconfirm/resetpassconfirm.component";


export const REGISTRATION_ROUTES: Routes = [
      {
        path: '', redirectTo: 'position', pathMatch: 'full'
      },
      {  
        path: 'position', component: PositionComponent 
      },
      {
        path: 'email', component: EmailpageComponent 
      },
      {
        path: 'email/confirmation', component: EmailconfirmationComponent 
      },
      {
        path: 'reset/pass/confirmation', component: ResetpassconfirmComponent 
      },
      {
        path: 'password/change/:hash/:shareorowner/:email', component: ChangepasswordComponent 
      },
      {
        path: 'details', component: PersonaldetailsComponent 
      },
      {
        path: 'address/details', component: PersonaladdressComponent 
      },
      {
        path: 'company', component: CompanyaddressComponent 
      },
      {
        path: 'company/details', component: CompanydetailedComponent 
      },
      {
        path: 'finish', component: FinishComponent 
      },
      {
        path: 'login', component: LoginComponent 
      },
      {
        path: 'password/reset', component: ForgotpasswordComponent 
      },
      {
        path: 'email/activation/:hash/:shareorowner/:email', component: SuccessfulactivationComponent 
      },
      {
        path: 'password/reset/new/:hash', component: ResetpasswordComponent 
      }

];
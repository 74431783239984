import { Component, OnInit } from '@angular/core';
import { STRIPE } from 'src/config/labels/stripe';
import { Contact } from 'src/models/DTO/V2/contact/Contact';
import { OrganisationInfo } from 'src/models/DTO/V2/info/OrganisationInfo';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';
import { ContactsService } from 'src/services/requestServices/V2/contacts/contacts.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  private _partnerInfo: PartnerInfo;
  private _contact: Contact;

  constructor(
    private infoService: InfoService,
    private contactsService: ContactsService,
    private imageService: ImageService
  ) {
    this.infoService.info().subscribe(info => {
      this._partnerInfo = info;
      this.imageService.getImagesPerProduct("ORGANISATION", this._partnerInfo.data.organisations[0].uuid, 'logo').subscribe((images) => {
        this._partnerInfo.data.images = images;
        this.contactsService.getContactInfoForEntity(this.organisation.contact.uuid).subscribe(contact => {
          this._contact = contact;
        });
      });
    });
  }

  ngOnInit(): void {
  }

  get organisation() : OrganisationInfo | null {
    return this._partnerInfo?.data?.organisations[0];
  }

  get contact() : Contact | null {
    return this._contact;
  }

  get image() : string | null {
    return this._partnerInfo.data.images.data[0].original.url
  }

  get lables() {
    return STRIPE;
  }
}

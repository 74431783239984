
<div class="col">
    <div class="card" style="width: 100%">
        <div class="card-body">
            <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
            <div class="wrapper">
                <form class="form-group" [formGroup]="this.companyDetails">
                    <div class="container">
                        <h3 class="card-title">{{this.lables(this.getPageName()).title}}</h3>
                        <p style="color:red; font-size: small;" class="card-title">{{this.lables(this.getPageName()).fillAllFields}}</p>
                        <div class="row">
                            <div class="col-6 column">  
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).legalName}}</label>
                                <input formControlName="legalName" type="text" [class]="(this.companyDetails.get('legalName').invalid && this.companyDetails.get('legalName').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).tradingName}}</label> <small style="position: relative; top: 2px;left: 3px;" id="exampleInputEmail11" class="text-muted" placement="top" [ngbTooltip]="this.lables(this.getPageName()).nameInEnglish"></small>
                                <input formControlName="tradingName" type="text" [class]="(this.companyDetails.get('tradingName').invalid && this.companyDetails.get('tradingName').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <!-- <label for="exampleInputEmail1">{{this.lables(this.getPageName()).inEnglish}}</label>
                                <input formControlName="nameInEnglish" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                                <div class="form-group">
                                    <label for="inputGroupSelect01">{{this.lables(this.getPageName()).type}}</label>
                                    <select class="form-control" formControlName="type"  id="inputGroupSelect01">
                                        <option *ngFor="let ct of this.compTypes(); index as i;" [selected]="i == 0" [ngValue]="ct.value">{{ct.type}}</option>
                                    </select>
                                </div>
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).bulstat}}</label>
                                <input formControlName="bulstat" type="text" [class]="(this.companyDetails.get('bulstat').invalid && this.companyDetails.get('bulstat').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <div class="form-group">
                                    <label for="inputGroupSelect01">{{this.lables(this.getPageName()).vat}}</label>
                                    <select [class]="(this.companyDetails.get('vat').invalid && this.companyDetails.get('vat').touched) ? 'form-control is-invalid' : 'form-control'" formControlName="vat"  id="inputGroupSelect01">
                                        <option selected value="No">Не</option>
                                        <option selected value="Yes">Да</option>
                                    </select>
                                </div>
                                <div *ngIf="this.vat() == 'Yes'">
                                    <label for="exampleInputEmail1">{{this.lables(this.getPageName()).vatNumber}}</label>
                                    <input formControlName="vatNumber" type="text" [class]="(this.companyDetails.get('vatNumber').invalid && this.companyDetails.get('vatNumber').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                </div>
                            </div>
                            <div class="col-6 column">  
                                <label for="exampleInputEmail11">{{this.lables(this.getPageName()).iban}}</label> <small style="position: relative; top: 2px;left: 3px;" id="exampleInputEmail11" class="text-muted" placement="top" [ngbTooltip]="this.lables(this.getPageName()).ibanInfo"></small>
                                <input formControlName="iban" type="text" [class]="(this.companyDetails.get('iban').invalid && this.companyDetails.get('iban').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).bank}}</label>
                                <input formControlName="bank" type="text" [class]="(this.companyDetails.get('bank').invalid && this.companyDetails.get('bank').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).bic}}</label>
                                <input formControlName="bic" type="text" [class]="(this.companyDetails.get('bic').invalid && this.companyDetails.get('bic').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp">
                                <label for="exampleInputEmail1">{{this.lables(this.getPageName()).description}}</label>
                                <textarea cdkTextareaAutosize formControlName="description" type="text" [class]="(this.companyDetails.get('description').invalid && this.companyDetails.get('description').touched) ? 'form-control is-invalid' : 'form-control'" id="exampleInputEmail1" aria-describedby="emailHelp"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                    {{this.errorMessage()}}
                </div>
                <button (click)="this.moveForward()" type="button" class="btn btn-primary nextBtn">{{this.lables(this.getPageName()).nextBtn}}</button>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
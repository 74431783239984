import { Component, OnInit } from '@angular/core';
import { UserinfostoreService } from '../../services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LocalstorageserviceService } from '../../services/infrastructureServices/localstorageservice/localstorageservice.service';
import { Labels } from './../../config/labels/labels';
import { DASHBOARD_LABLES } from './../../config/labels/dashboard';
import { UserRegistration } from '../../models/DTO/user/UserRegistrationModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {

  public user : UserRegistration;
  protected _labels;
  protected _dashboardlabels;
  protected error = false;
  protected pageName = "";
  protected backPage = "";
  protected nextPage = "";
  protected language;
  public hide = false;
  public hideConfirm = false;

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router : Router) {
      this.userinfostoreService.userInfo$.subscribe(user => {
          this.user = user;
        }
      )

      var language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
      this.language = language;
      this._labels = Labels[language];
      this._dashboardlabels = DASHBOARD_LABLES[language];
 }

  ngOnInit(): void {
  }

  shouldShowError() {
    return this.error;
  }
  
  lables(pagename: string) {
    return this._labels[pagename];
  }

  dashboardLables(pagename: string) {
    return this._dashboardlabels[pagename];
  } 

  getPageName() {
    return this.pageName;
  }

  moveBack() {
    this.router.navigate([this.backPage]);
  }

  moveForward() {
    this.router.navigate([this.nextPage]);
  }
}

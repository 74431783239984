export const Warnings = {
    delivery: {
        bg: "Не сте въвели данните за връщане",
    },

    documents: {
        bg: "Не сте потвърдили всички документи",
    },

    speedy: {
        bg: "Не сте въвели контактите за Спийди",
    }
}
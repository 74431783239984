import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


Sentry.init({
  dsn: "https://4806e3549b1b415cbf8818c20996e634@o969372.ingest.sentry.io/5934967",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "http://www.the-qpay.com"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // beforeSend: ((event, hint) => {
  //   if (hint.originalException.toString().includes('500')) {
  //     Sentry.showReportDialog({eventId: event.event_id});
  //   }
  //   return event;
  // }),
  // tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardComponent } from 'src/app/layouts/dashboard/dashboard.component';
import { Currencies } from 'src/config/labels/currencies';
import { ShippingMethod } from 'src/config/labels/shippingMethod';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { Order } from 'src/models/DTO/V2/orders/Order';
import { OrderCollection } from 'src/models/DTO/V2/orders/OrderCollection';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { ItemService } from 'src/services/requestServices/item/item.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { PricingService } from 'src/services/requestServices/pricing/pricing.service';
import { ProductService } from 'src/services/requestServices/products/product.service';
import { SearchService } from 'src/services/requestServices/search/search.service';
import { ServiceService } from 'src/services/requestServices/services/service.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { OrdersService } from 'src/services/requestServices/V2/orders/orders.service';
import { ShipmentService } from 'src/services/requestServices/V2/shipment/shipment.service';
import { VariationsService } from 'src/services/requestServices/variations/variations.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { OrderStatus } from 'src/config/labels/orderStatus'
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { OrderStatusColors } from 'src/config/labels/orderStatusColors';
import { Labels } from 'src/config/labels/labels';
import { ErrorTranslations } from 'src/config/labels/errorTranslations';
import { Successes } from 'src/config/labels/successes';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DispatchTypes } from 'src/config/labels/dispatchTypes';
import { Fees } from 'src/config/labels/fees';


declare var bootstrap: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrdersComponent extends BaseDashboardComponent implements OnInit {

  @ViewChild("changeSpeedyUsername") _modal: ElementRef;
  @ViewChild("cancelWindow") _cancelWindowmodal: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumnsForOrdersTable: string[];
  public ordersDataSource: OrderCollection = { data: [] };
  public numberPerPageValue : number = -1;
  public page: number = 0;
  public status: string = 'waiting';
  public selectedOrder: Order;
  expandedElement: Order | null;
  faSearch = faSearch;

  private _intervalToRefreshRemainingTime = 60000;
  private _numberOfParcels = 1;
  private _pageNumber = 1;
  private _searchString = "";
  private _searchType = "name.contains";
  private _initialNumberOfLoadedPages : number = 3;
  private _maxPage : number = 100;
  private _scrolling = false;
  private displayedColumnsForNewOrdersTable = ['ID', 'Date', 'Type', 'Client', 'Method', 'Delivery', 'Status', 'Sum', 'Confirm', 'Cancel'];
  private displayedColumnsForProcessingOrdersTable = ['ID', 'Date', 'Type', 'Client', 'Method', 'Delivery', 'Status',  'Sum', 'PickUp', 'Generate', 'Print', 'Cancel'];
  private displayedColumnsForCompletedOrdersTable = ['ID', 'Date', 'Type', 'Client', 'Method', 'Delivery', 'Status', 'Sum'];
  private displayedColumnsForCanceledOrdersTable = ['ID', 'Date', 'Type', 'Client', 'Method', 'Delivery', 'Sum', 'Status', 'Tax'];
  private displayedColumnsForReturnedOrdersTable = ['ID', 'Date', 'Client', 'EndDate', 'Status', 'Sum', 'ReturnedSum', 'Return', 'CancelReturn'];
  private displayedColumnsForAllOrdersTable = ['ID', 'Date', 'Type', 'Client', 'Status', 'Price', 'Tax', 'ShareMe', 'Net'];
  private _currentOpenWindow?: NgbModalRef = null;
  private _orderFees = {
    return: "ORDER_REFUND_TYPE_FEE",
    cancel: "ORDER_CANCELATION_TYPE_FEE",
    shareMe: "ORDER_DELIVERY_TYPE_FEE"
  };


  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected productService: ProductService,
    protected usersService: UsersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    private ordersService: OrdersService,
    protected imageService: ImageService,
    private loadingState: LoadingstateService,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,
    private toatsrService: ToastrService,
    private shipmentService: ShipmentService,
    private modalService: NgbModal
  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
    this.pageName = "orders";
    const initialSelection = [];
    const allowMultiSelect = true;

    this.loadingState.loading$.subscribe(result => {
      this.isLoading = result;
    });

    this.displayedColumnsForOrdersTable = this.displayedColumnsForNewOrdersTable;

  }

  async ngOnInit() {
    this.numberPerPageValue = Number.parseInt(this.localStorageService.getLocalStorageItem(Labels.ordersPerPage)) || 10;
    this.status = this.localStorageService.getLocalStorageItem(environment.lsOrderTab) || 'waiting';
    this.changeStateFilter(this.status);
    await this.loadData();
  }

  

  ngAfterViewInit() {
    setInterval(() => {
      this.recalculateRemainingTime()
    }, this._intervalToRefreshRemainingTime)
    
  }

  set numberOfParcels(numberOfParcels: number) {
    if (numberOfParcels < 1) {
      this._numberOfParcels = 1;
    }
    else if (numberOfParcels > 10) {
      this._numberOfParcels = 10;
    }
    else {
      this._numberOfParcels = numberOfParcels;
    }
  }

  get organisation() {
    return this.loggedPartner.data.organisations[0];
  }

  get tableDataSource() {
    let tableDatasource =new MatTableDataSource(this.ordersDataSource.data);
    tableDatasource.sort = this.sort;
    return tableDatasource;
  }

  get currentPageLength() {
    let pageLength = this.page.toString().length;
    let width = 20 + pageLength * 10;
    return width.toString();
  }

  get lastPage() {
    return this._maxPage;
  }

  // V2
  get numberOfParcels() {
    return this._numberOfParcels;
  }

  get numberPerPage() {
    return this.numberPerPageValue + " " + this.dashboardLables(this.getPageName()).productsLabel;
  }

  get hiddenPlaceholder() {
    return environment.hiddenValuesReplacement;
  }

  get hiddenBigPlaceholder() {
    return environment.hiddenValuesBigReplacement;
  }

  get pageNumber() : number{
    return +this._pageNumber;
  }

  get priceConvertor() {
    return Convertor.convertPrice.splitPriceToFloat
  }

  get searchString() {
    return this._searchString;
  }

  get isLoggedPartener() {
    return Object.keys(this.loggedPartner).length
  }

  get cancelRequestText() {
    if (this.selectedOrder && this.selectedOrder.state == "confirmed") {
      return "Ще бъде начислена такса отказване на поръчка 9.17% (без ДДС) от сумата на поръчката, ако я откажете.";
    }
    return "При отказването на поръчката, няма да бъде начислена такса “Отказване на поръчка”";
  }


  private async loadData() {
    await this.onDirectNavigation();
  }

  private getOrders() {
    if (this._searchString) {
      this.loadSearchedOrdersForTable();
    }
    else {
      this.loadOrdersForPartner();
    }
  }

  private initialLoad() {
    this.ordersDataSource = { data: []};
    this.page = 1;
    this.getOrders();
  }

  private loadImagesForOrdersCollection(orders: OrderCollection) {
    orders.data.forEach(order => {
      order.lines.forEach(line => {
        if (line.sku) {
          this.imageService.getImagesPerProduct("SKU", line.sku.uuid).subscribe(images => {
            line.imageSource = images.data[0]?.original.url;
          })
        }
      })
    })
  }

  private fixPaymentValues(orders: OrderCollection) {
    orders.data.forEach(order => {
      order.amount_captured = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.amount_captured, 2));
      order.amount_refunded = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.amount_refunded, 2));
      order.amount_reserved = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.amount_reserved, 2));
      order.total_gross = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.total_gross, 2));
      order.total_net = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.total_net, 2));
      order.total_tax_amount = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.total_tax_amount, 2));
      order.voucher_amount = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.voucher_amount, 2));
      order.shipment_cost = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.shipment_cost, 2));
      order.lines[0].single_gross = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.lines[0].single_gross , 2));
      order.lines[0].single_net = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.lines[0].single_net, 2));
      order.lines[0].single_tax_amount = Number.parseFloat(Convertor.convertNumbers.addToFixedSuffix(order.lines[0].single_tax_amount, 2));
    });
    return orders;
  }

  private hideTelephones(orders: OrderCollection) {
    orders.data.forEach(order => {
      if (order.invoice_address?.mobile_phone_1_e164) {
        order.invoice_address.mobile_phone_1_e164 = Core.replaceLastCharactersOfString(order.delivery_address.mobile_phone_1_e164, '*', 4);
      }
      if (order.delivery_address?.mobile_phone_1_e164) {
        order.delivery_address.mobile_phone_1_e164 = Core.replaceLastCharactersOfString(order.delivery_address.mobile_phone_1_e164, '*', 4);
      }
    });
    return orders;
  }

  private recalculateRemainingTime() {
    if (this.ordersDataSource.data.length) {
      this.ordersDataSource.data.forEach(order => this.attachRemainingDate(order))
    }
  }

  private compareTwoDates(dateStart: Date, dateEnd: Date) {
    // get total seconds between the times
    var delta = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = delta % 60;

    return {
      days: days,
      hours: hours
    }
  }

  private attachRemainingDate(order: Order) {
    let currentDate = new Date();
    let dateAfterWeek = new Date(order.created_at);
    if (order.group) {
      dateAfterWeek.setDate(dateAfterWeek.getDate() + 7);
    }
    else {
      dateAfterWeek.setDate(dateAfterWeek.getDate() + 7);
    }
    if (dateAfterWeek.getTime() > currentDate.getTime()) {
      order.timeToConfirm = this.compareTwoDates(currentDate, dateAfterWeek);
    }
    else {
      order.timeToConfirm = {
        hours: 0,
        days: 0
      }
    }
  }

  private loadOrdersForPartner() {
    this.ordersService.search(this.numberPerPageValue, this.page, this.status, '').subscribe((orders) => {
      //orders = this.hideTelephones(orders);
      orders = this.fixPaymentValues(orders);
      orders.data.forEach(order => {
        this.attachRemainingDate(order);
      })
      this.ordersDataSource = orders;
      this.ordersDataSource.data = this.ordersDataSource.data.sort((a, b ) => {  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime() });
      this._maxPage = orders.meta.last_page;
      this.loadImagesForOrdersCollection(this.ordersDataSource);
    });
  }

  private loadSearchedOrdersForTable() {
    this.ordersService.search(this.numberPerPageValue, this.page, this.status,  this._searchString).subscribe(orders => {
     // orders = this.hideTelephones(orders);
      orders = this.fixPaymentValues(orders);
      orders.data.forEach(order => {
        this.attachRemainingDate(order);
      })
      this.ordersDataSource = orders;
      this.ordersDataSource.data = this.ordersDataSource.data.sort((a, b ) => {  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime() });
      this._maxPage = orders.meta.last_page;
      this.loadImagesForOrdersCollection(this.ordersDataSource);
    })
  }

  public getCancellationFee(order: Order) {
    if (!order) return "-";
    if (!order.organisation_fees.length) return "-";
    let fee = order.organisation_fees.find(feeTmp => feeTmp.type == Fees.ORDER_CANCELATION_TYPE_FEE)?.gross_fee_amount;
    if (!fee) return "-";
    fee = Number.parseFloat(fee).toFixed(2)
    return fee + ' лв.'
  }

  public getRefundFee(order: Order) {
    if (!order) return "-";
    if (!order.organisation_fees.length) return "-";
    let fee = order.organisation_fees.find(feeTmp => feeTmp.type == Fees.ORDER_REFUND_TYPE_FEE)?.gross_fee_amount;
    if (!fee) return "-";
    fee = Number.parseFloat(fee).toFixed(2)
    return fee + ' лв.'
  }

  public getShareMeFee(order: Order) {
    if (!order) return "-";
    if (!order.organisation_fees.length) return "-";
    let fee = order.organisation_fees.find(feeTmp => feeTmp.type == Fees.ORGANISATION_WIRE_TRANSFER_FEE)?.gross_fee_amount;
    if (!fee) return "-";
    fee = Number.parseFloat(fee).toFixed(2)
    return fee + ' лв.'
  }

  public validateParcels(numberOfParcels: number, event: any) {
    numberOfParcels = Number.parseInt(numberOfParcels.toString()) || 0;
    if (numberOfParcels < 1) {
      this.numberOfParcels = 1
      event.preventDefault();
      event.stopPropagation()
    }
    this.numberOfParcels = numberOfParcels;
    event.target.value = this.numberOfParcels = 1
  }

  public setSearchedText(event) {
    if (!event.target.value.length ) {
      this.clearSearchString()
    }
    else {
      const searchQuery = event.target.value;
      this._searchString = searchQuery;
      if (this._searchString.length < 3) {
        this.loadOrdersForPartner();
        return;
      };
      this.search();
    }
  }

  public shouldShowPickUpBtn(order: Order) {
    return this.loggedPartner.data.organisations[0] &&
           this.loggedPartner.data.organisations[0].carrier_accounts.data[0]?.settings.dispatch_parcel_from == DispatchTypes.organisation &&
           order.shipment &&
           order.shipment[0].number_of_parcels;
  }

  public classEndTab(tabStatus: string) {
    return this.status == tabStatus ?  "nav-link active" : "nav-link";
  }

  public classTab(tabStatus: string) {
    return this.status == tabStatus ?  "nav-link no-radius-tab active" : "nav-link no-radius-tab";
  }

  public shareMeReturn(order: Order) {
    return Number.parseFloat(((order.total_gross) ? order.total_gross * environment.ShareMeReturnTax : 0).toFixed(2));
  }

  public totalTaxReturn(order: Order) {
    let cancelationFee = this.getCancellationFee(order) == '-' ? 0 : Number.parseFloat(this.getCancellationFee(order));
    let refundFee = this.getRefundFee(order) == '-' ? 0 : Number.parseFloat(this.getRefundFee(order));
    let wholeFee = cancelationFee + refundFee;
    return wholeFee.toFixed(2)

  }

  public paymentType(order: Order) {
    if (order.paymentMethod.code == 'card') {
      return "С карта";
    }
    return "Наложен платеж";
  }

  public hideLastTelephoneDigits(telephone: string, numberOfHiddenDiits: number) {
    return Core.replaceLastCharactersOfString(telephone, '*', numberOfHiddenDiits);

  }

  public deliveryAddress(order: Order) {
    return order.delivery_address?.county + ', ' + order.delivery_address?.city + ', ' + order.delivery_address?.address_line_1;
  }

  public deliveryMethod(order: Order) {
    return ShippingMethod[this._language][order.delivery_method];
  }

  public convertDate(date: string) {
    return Convertor.convertDate.convertDateTimeWithDelimiter(date, '/');
  }

  /** Announce the change in sort state for assistive technology. */
  public announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction == 'desc') {
      this.ordersDataSource.data = this.ordersDataSource.data.sort((a, b ) => {  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()  } )
    } else {
      this.ordersDataSource.data = this.ordersDataSource.data.sort((a, b ) => {  return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime() } )
    }
  }

  public onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit && !this._scrolling && this.numberPerPageValue == -1 && this.page < this._maxPage) {
      this._scrolling = true;
      this.page++;
      this.getOrders();
    }
  }

  public chooseNumberPerPage(event) {
    const value = event.value;
    this.numberPerPageValue = value;
    this.localStorageService.setLocalStorageItem(Labels.ordersPerPage, value);
    this.page = 1;
    this.ordersDataSource = { data: []};
    if (this.numberPerPageValue == -1) {
      for(let i = 0; i < this._initialNumberOfLoadedPages; i++) {
        this.page++;
        this.getOrders();
      }
    }
    else {
      this.getOrders();
    }
  }


  public loadSpecificPage(pageNumber) {
    if (pageNumber < 0 || pageNumber > this._maxPage) {
      return;
    }
    this.ordersDataSource = { data: []};
    this.page = pageNumber;
    this.getOrders();
  }


  public search() {
    this.ordersDataSource = { data: []};;
    this.initialLoad();
  }

  public clearSearchString() {
    this._searchString = '';
    this.ordersDataSource = { data: []};
    this.initialLoad();
  }

  public changeStateFilter(status: string) {
    this.localStorageService.setLocalStorageItem(environment.lsOrderTab, status)
    switch (status) {
      case 'waiting':
        this.displayedColumnsForOrdersTable = this.displayedColumnsForNewOrdersTable;
        break;
      case 'confirmed':
        this.displayedColumnsForOrdersTable = this.displayedColumnsForProcessingOrdersTable;
        break;
      case 'completed':
        this.displayedColumnsForOrdersTable = this.displayedColumnsForCompletedOrdersTable;
        break;
      case 'cancelled':
        this.displayedColumnsForOrdersTable = this.displayedColumnsForCanceledOrdersTable;
        break;
      case 'returned':
        this.displayedColumnsForOrdersTable = this.displayedColumnsForReturnedOrdersTable;
        break;
      default:
        this.displayedColumnsForOrdersTable = this.displayedColumnsForAllOrdersTable;
        break;
    }
    this.status = status;
    this.initialLoad();
    setTimeout(
      () => this.loadTooltips(),
      1000 
    )
  }


  public goToPreviousPage() {
    if (this.page == 1) {
      return;
    }
    this.page--;
    this.ordersDataSource = { data: []};
    this.getOrders();
  }

  public goToNextPage() {
    if (this.page == this._maxPage) {
      return;
    }
    this.page++;
    this.ordersDataSource = { data: []};
    this.getOrders();

  }

  public resetPage() {
    this.page = 1;
    this.getOrders();
  }

  public convertPrice(price: number) {
    let priceAsFloat = Convertor.convertPrice.splitPriceToFloat(price);
    return priceAsFloat.lev + '.' + priceAsFloat.coins + ' ' + Currencies.currencies[this._language]
  }

  public convertOrderStatus(orderStatus: string) {
    return OrderStatus[orderStatus];
  }

  public checkGroupType(isGroup: boolean | null) {
    if (!isGroup) {
      return 'Лична';
    }
    return 'Групова';
  }

  public confirmOrder(order: Order, event) {
    this.ordersService.confirmOrder(order.uuid, { number_of_parcels: 1}).subscribe(() => {
      this.toatsrService.success(Successes.confirmedOrder[this.language]);
      this.initialLoad();
    }, (error) => this.toatsrService.error(ErrorTranslations.translate(error.error.exception.message, this.language)));
    event.stopPropagation()
  }

  goToDetails(order: Order, event) {
    this.router.navigate(['dashboard/order/' + order.uuid])
  }

  cancel(order: Order) {
    this.ordersService.cancelOrder(order.uuid, { reason: environment.cancelation_reason}).subscribe(() => {
      this._currentOpenWindow.close();
      this.router.navigate(['/dashboard/orders'])
      this.toatsrService.success(Successes.cancledOrder[this.language]);
    }, (error) => this.toatsrService.error(ErrorTranslations.translate(error.error.exception.message, this.language)));
  }

  pickup(order: Order) {
    this.shipmentService.createShipment(order.shipment[0].uuid).subscribe(() => {
      this.toatsrService.success("Успешо заявяване от куриер");
    })
  }

  public shouldClientBeHidden(order: Order) {
    if (['waiting', 'cancelled'].includes(order.state)) {
      return true;
    }
    return false;
  }

  public createShipmentGeneration(order: Order) {
    this.selectedOrder = order;
    this.generateShipment()
  }

  public openModal(modal: any) {
    this.modalService.open(modal, { centered: true } );
  }

  public openCancelOrderDialog(order: Order, event) {
    this.selectedOrder = order;
    this.router.navigate(['dashboard/order/' + order.uuid])
    this._currentOpenWindow = this.modalService.open(this._cancelWindowmodal, { centered: true } );
    event.stopPropagation()
  }

  public generateShipment() {
    this.shipmentService.updateParcels({ number_of_parcels: this.numberOfParcels}, this.selectedOrder.shipment[0].uuid).subscribe(() => {
      this.toatsrService.success("Успешно генерирана товарителница");
    })
  }

  public printWayBill(order: Order, event: any) {
    event.stopPropagation();
    this.shipmentService.printWayBill(order.shipment[0].uuid).subscribe((response) => {
      let a = document.createElement("a")
      a.href = URL.createObjectURL(response)
      a.download = "Waybill.pdf";
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      this.toatsrService.success("Успешно генериран документ");
    })
  }

  public printStockReciept(order: Order, event: any) {
    event.stopPropagation();
    this.ordersService.downloadOrderDocument(order.uuid).subscribe((response) => {
      let a = document.createElement("a")
      a.href = URL.createObjectURL(response)
      a.download = "Stock.pdf";
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      this.toatsrService.success("Успешно генериран документ");
    })
  }

  cancelReturn(order: Order) {
    this.ordersService.cancelOrder(order.uuid, { reason: environment.cancelation_reason}).subscribe(() => {
      this.toatsrService.success("Успешо отказахте поръчката");
    })
  }

  getOrderStatusColor(order: Order) {
    return OrderStatusColors[order.state]
  }

}

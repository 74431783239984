import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/base/base.component';
import { environment } from 'src/environments/environment';
import { Warnings } from 'src/config/labels/warnings';
import { LoggedUser } from 'src/models/DTO/user/LoggedUser';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoginService } from 'src/services/requestServices/V2/login/login.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { Labels } from 'src/config/labels/labels';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit {

  private _tabToPage = {
    products: '/dashboard/products',
    stores: '/dashboard/logistics',
    settings: '/dashboard/settings',
    delivery: '/dashboard/delivery',
    orders: '/dashboard/orders/1',
    invoices: '/dashboard/invoices',
    logistics: '/dashboard/logistics'
  }

  private _mainRoutes = [
    '/dashboard/products',
    '/dashboard/logistics',
    '/dashboard/settings',
    '/dashboard/delivery',
    '/dashboard/orders/1',
    '/dashboard/logistics',
    '/dashboard/invoices'
  ];

  private _currentRoute : string = '';


  public loggedUser: LoggedUser;
  public loggedPartner : PartnerInfo;
  private _hideMenu : boolean = false;

  
  constructor(
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected router: Router,
    private loggeduserinfoservice: LoggeduserinfoService,
    private loggedInfoService: LoggedinfoService,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) { 
    super(userStateService, localStorageService, router);
    this.pageName = "layout";
    this.loggeduserinfoservice.userInfo$.subscribe(user => {
      if (user && Object.keys(user).length) {
        this.loggedUser = user;
      }
    });
    if (!this.loggedPartner || !Object.keys(this.loggedPartner).length) {
      this.loggedInfoService.userInfo$.subscribe(partner => {
        this.loggedPartner = partner;
      })
    }
  }

  private highlightMenu() {
    if (this._mainRoutes.includes(this.router.url)) {
      document.querySelectorAll('.navigation .active').forEach(
        el => {
          el.className = el.className.replace(' active', '');
      });
      let urlSplit = this.router.url.split('/');
      let HTMLclass = urlSplit.pop();
      if (!isNaN(+HTMLclass)) {
         HTMLclass = urlSplit.pop();
      }
      document.querySelector('span.' + HTMLclass).parentElement.className += ' active';
    }
  }

  ngOnInit(): void {
    this.switchLayout();
    this.router.events.subscribe(() => {
      this.switchLayout();
      setTimeout( () => {
        this.highlightMenu();
      }, 1000);
    });
  }

  ngAfterViewInit() {
    this.highlightMenu();
  }

   get hideMenu() : boolean {
     return this._hideMenu;
   }


  goToSettings() {
    var language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
    if (!this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days) {
      this.toastr.warning(Warnings.delivery[language]);
      return;
    }
    if (!this.loggedPartner?.data?.documents && this.areDocumentsFilled()) {
      this.toastr.warning(Warnings.documents[language]);
      return;
    }
    this.router.navigate(['/dashboard/settings'], {queryParams: {logo: 'upload'}})
  }


  onMenuClick(event, tab = '') {
    document.querySelectorAll('.navigation .active').forEach(
      el => {
        el.className = el.className.replace(' active', '');
      });
      if (event.target.tagName == "A") {
        event.target.className += ' active';
      }
      else if (event.target.tagName == "SPAN") {
        event.target.parentNode.className += ' active';
      }
      this.router.navigate([this._tabToPage[tab]])
  }

  logout() {
    this.loginService.logOut();
    this.router.navigate(['registration/login']);
  }


  isUserActive() : boolean {
    return this.loggedPartner?.data?.organisations[0]?.state != environment.onboarding;
  }

  areDocumentsFilled() : boolean {
    if (!this.loggedPartner.data.documents){
      return false;
    }
    let allFilled =true;
    this.loggedPartner.data.documents?.data.forEach(document => {
      if (environment.documentTemplates.includes(document.uuid)) {
        if (!document.download?.is_accepted){
          allFilled = false;
        } 
      }
    });
    return allFilled;
  }

  areReturnFieldsNotRight() {
    return !this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days || (this.loggedPartner?.data?.documents && !this.areDocumentsFilled());
  }

  private switchLayout() {
    const routesWithoutMenu = ['/dashboard/products/add'];
    for(let i = 0; i < routesWithoutMenu.length; i++) {
      if(this.router.url.indexOf(routesWithoutMenu[i]) > -1) {
        this._hideMenu = true;
        return;
      }
    }
    this._hideMenu = false;
  }

  get linkClasses() {
    return {
      normal: 'list-group-item list-group-item-action',
      active: 'list-group-item list-group-item-action active',
      disabled: 'list-group-item list-group-item-action disabled',
    }
  }
}

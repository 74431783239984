import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/base/base.component';
import { PartnerPositions } from 'src/config/labels/partnerPositions';
import { InitialPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/InitialPartnerModelRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';

@Component({
  selector: 'app-resetpassconfirm',
  templateUrl: './resetpassconfirm.component.html',
  styleUrls: ['./resetpassconfirm.component.css']
})
export class ResetpassconfirmComponent extends BaseComponent implements OnInit {

  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    protected router: Router) {
      super(userinfostoreService, localStorageService, router)
      this.pageName = 'resetpassconfirm';
     }

  ngOnInit(): void {
  }

  goToLogin(){
    this.router.navigate(['registration/login']);
  }
}

<app-loading *ngIf="this.isLoading"></app-loading>
<div *ngIf="this.showBody" class="container dashboard-body">
    <div class="row">
        <h4 style="margin-bottom: 35px; margin-top: 10px;">{{this.dashboardLables(this.getPageName()).logistics}}</h4>
        <mat-button-toggle-group *ngIf="this.delivery && this.currentCarrier?.uuid" name="fontStyle" aria-label="Font Style" style="top: 5px; right: 10px; position: absolute; border: unset;">
            <mat-button-toggle disabled="true" value="bold" ><img src="/assets/images/dashboard/icons/speedy.png" alt=""></mat-button-toggle>
            <mat-button-toggle value="italic" (click)="this.onSpeedyClick()">{{this.currentCarrierAddresses?.data[0]?.clientName}} &nbsp; <img  src="/assets/images/dashboard/icons/pencil.png" alt=""></mat-button-toggle>
          </mat-button-toggle-group>
        <div class="tab-pane fade show active" id="delivery" role="tabpanel" aria-labelledby="pills-home-tab">
            <ngb-alert *ngIf="this.delivery && !this.currentCarrier?.uuid" [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
                <small class="alertIcon"></small> {{this.dashboardLables(this.getPageName()).carrierAlert}}
            </ngb-alert>
            <form *ngIf="this.delivery && !this.currentCarrier?.uuid" [formGroup]="this.delivery" class="form-group">
                <div *ngIf="!this.currentCarrier?.uuid">
                    <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).speedyProfile}}</h4>
                    <div style="display: grid; grid-auto-columns: minmax(100px, auto); column-gap: 70px;">
                        <label style="grid-row: 1;">{{this.dashboardLables(this.getPageName()).speedyUsername}}<span class="delivery-req-fields">*</span></label>
                        <input style="grid-row: 2; grid-column: 1 / 2; width: 300px !important;" formControlName="speedyUsername" type="text" class="form-control mx-sm-4" id="speedyUsername" aria-describedby="emailHelp" [placeholder]="this.dashboardLables(this.getPageName()).insert">
                        <small style="grid-row: 2; grid-column: 2 / 2; font-size: 14px;" id="emailHelp" class="speedy-username text-muted" [innerHTML]="this.dashboardLables(this.getPageName()).speedyUsernameRegData"></small>
                    </div>
                    <div style="display: grid; grid-auto-columns: minmax(100px, auto);">
                        <label style="grid-row: 1;">{{this.dashboardLables(this.getPageName()).speedyPassword}}<span class="delivery-req-fields">*</span></label>
                        <input style="grid-row: 2; grid-column: 1 / 2; width: 300px  !important;" formControlName="speedyPassword" type="password" class="form-control mx-sm-4" id="speedyPassword" aria-describedby="speedylogo" [placeholder]="this.dashboardLables(this.getPageName()).insert">
                        <img  style="grid-row: 2; grid-column: 2 / 2;" id="speedylogo"  src="/assets/images/speedy.jpg" alt="" width="200" height="100">
                    </div>
                    <div *ngIf="this.delivery && !this.currentCarrier?.uuid"  style="text-align: center; width: 300px  !important;">
                        <button style="margin: 5px; width: 148px; height: 46px;"  [disabled]="this.delivery?.dirty ? false : true" class="btn btn-primary" (click)="this.saveCarrier(this.delivery)">{{(!this.currentCarrier?.uuid) ? this.dashboardLables(this.getPageName()).signIn : this.dashboardLables(this.getPageName()).upload}}</button>
                    </div>
                </div>
            </form>
            <form *ngIf="this.delivery && this.currentCarrier?.uuid" [formGroup]="this.delivery" class="form-group">

                <div class="courrier_section" style="font-size: 18px;">
                    <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).courier}}</h4>

                    <label style="font-size: 1rem;">{{this.dashboardLables(this.getPageName()).deliveryToAddressFee}}<span class="delivery-req-fields">*</span></label>
                    <input formControlName="deliveryToAddressFee" type="number" class="form-control mx-sm-4" id="deliveryToAddressFee" aria-describedby="emailHelp" [placeholder]="this.dashboardLables(this.getPageName()).insertPrice"><span class="currency">{{this.dashboardLables(this.getPageName()).currency}}</span>
                    <small style="font-size: 14px;" id="emailHelp" class="text-muted">{{this.dashboardLables(this.getPageName()).addressTaxInfo}} <span style="font-weight: 600;">0.00лв.</span></small>

                    <label style="font-size: 1rem;">{{this.dashboardLables(this.getPageName()).deliveryToCourierOfficeFee}}<span class="delivery-req-fields">*</span></label>
                    <input formControlName="deliveryToCourierOfficeFee" type="number" class="form-control mx-sm-4" id="deliveryToCourierOfficeFee" aria-describedby="emailHelp" [placeholder]="this.dashboardLables(this.getPageName()).insertPrice"><span class="currency">{{this.dashboardLables(this.getPageName()).currency}}</span>
                    <small style="font-size: 14px;" id="emailHelp" class="text-muted">{{this.dashboardLables(this.getPageName()).officeTaxInfo}} <span style="font-weight: 600;">0.00лв.</span></small>
                    
                    <label style="font-size: 1rem;">{{this.dashboardLables(this.getPageName()).freeShippingOverAmount}}<span class="delivery-req-fields">*</span></label>
                    <input formControlName="freeShippingOverAmount" type="number"class="form-control mx-sm-4" id="freeShippingOverAmount" aria-describedby="emailHelp" [placeholder]="this.dashboardLables(this.getPageName()).insertPrice"><span class="currency">{{this.dashboardLables(this.getPageName()).currency}}</span>
                    <small style="font-size: 14px;" id="emailHelp" class="text-muted">{{this.dashboardLables(this.getPageName()).freeOverInfo}}</small>
                </div>
                <div *ngIf="this.delivery && this.currentCarrier?.uuid"  style="text-align: center;">
                    <button style="margin: 5px; width: 148px; height: 46px;" class="btn btn-primary" [disabled]="this.delivery.dirty ? false : true" (click)="this.save(this.delivery)">{{(!this.currentCarrier?.uuid) ? this.dashboardLables(this.getPageName()).signIn : this.dashboardLables(this.getPageName()).upload}}</button>
                </div>
            </form>
            <form *ngIf="this.delivery && this.currentCarrier?.uuid" [formGroup]="this.delivery" class="form-group">

                <div class="courrier_section">
                    <div *ngIf="this.currentCarrier?.uuid" >
                        <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).dispatch}}</h4>

                        <div class="form-check delivery-rb">
                            <input formControlName="dispatch" class="form-check-input" type="radio" name="dispatch" id="dispatch1" [value]="dispatchTypes.organisation" checked>
                            <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="dispatch1">
                                {{this.dashboardLables(this.getPageName()).fromAddress}}
                            </label>
                        </div>
                        <div class="form-check delivery-rb">
                            <input formControlName="dispatch" class="form-check-input" type="radio" name="dispatch" id="dispatch2" [value]="dispatchTypes.courier">
                            <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="dispatch2">
                                {{this.dashboardLables(this.getPageName()).fromOffice}}
                            </label>
                        </div>
                        <hr style=" margin: auto; color: #99A3BF; margin-top: 10px; margin-bottom: 20px;">
                        <div *ngIf="this.delivery.get('dispatch').value == dispatchTypes.courier">
                            <table  class="mat-elevation-z8 products">
                            </table>
                        </div>
                        <div *ngIf="this.delivery.get('dispatch').value == dispatchTypes.organisation">
                            <h4 class="delivery-header">Избран адрес</h4>
                            <table *ngIf="this.delivery.get('dispatch').value == dispatchTypes.organisation" mat-table multiTemplateDataRows [dataSource]="this.currentCarrierAddresses?.data" matSort class="mat-elevation-z8 products">
                                <ng-container matColumnDef="Selection">
                                    <th style="width: 50px !important"  mat-header-cell *matHeaderCellDef ></th>
                                    <td mat-cell *matCellDef="let element" style="width: 50px !important">  <mat-checkbox [checked]="this.selectedAddres == element" (change)="this.saveSelectedAddress(element)" class="example-margin"></mat-checkbox></td>
                                </ng-container>
            
                                <ng-container matColumnDef="Address">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).address}}</th>
                                    <td mat-cell *matCellDef="let element" class="product-name-field"> {{element.address}} </td>
                                </ng-container>
            
                                <ng-container matColumnDef="Company">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).company}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.clientName}}
                                    </td>
                                </ng-container>
            
                                <ng-container matColumnDef="Contact">
                                    <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).contact}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.contactName}} </td>
                                </ng-container>
            
                                <ng-container matColumnDef="Telephone">
                                    <th mat-header-cell *matHeaderCellDef>{{this.dashboardLables(this.getPageName()).telephone}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                                </ng-container>
            
                                <ng-container matColumnDef="ClientID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).client}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsForAddressesTable"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsForAddressesTable;"></tr>
                            </table>
                            <h4 style="margin-top: 20px;" class="delivery-header">Час за вземане на пратки</h4>
                            <span style="font-size: 14px; color: #99A3BF; margin-left: 12px; font-weight: 600;" class="delivery-header">Въведи час</span><span style="color: red;"> * </span><br>
                            <input (change)="this.onPickupChange($event)" *ngIf="this.delivery.get('dispatch').value == dispatchTypes.organisation" formControlName="pickupHour" class="time" type="time" id="appt" name="appt" min="09:00" max="18:00"> <small style="font-size: 14px;" id="emailHelp" class="text-muted" [innerHTML]="this.dashboardLables(this.getPageName()).pickupHourInfo"></small>
                            <ngb-alert *ngIf="this.pickUPHourAlert" [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
                                <small class="alertIcon"></small> {{this.pickUPHourAlert}}
                            </ngb-alert>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="this.delivery && this.currentCarrier?.uuid" [formGroup]="this.delivery" class="form-group">
                <div class="courrier_section">
                    <h4 class="delivery-header" style="width: fit-content;">{{this.dashboardLables(this.getPageName()).size}}</h4>
                    <div class="form-check delivery-rb">
                        <input formControlName="size" class="form-check-input" type="radio" name="size" id="size1" [value]="packageSizes.a4">
                        <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="size1">
                            {{this.dashboardLables(this.getPageName()).a4}}
                        </label>
                    </div>
                    <div class="form-check delivery-rb">
                        <input formControlName="size" class="form-check-input" type="radio" name="size" id="size2" [value]="packageSizes.a6" checked>
                        <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="size2">
                            {{this.dashboardLables(this.getPageName()).a6}}
                        </label>
                    </div>
                    <hr style=" margin: auto; color: #99A3BF; margin-top: 10px; margin-bottom: 20px;">

                    <!-- <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).extras}}</h4>

                    <div class="form-check delivery-rb">
                        <input formControlName="extras" class="form-check-input" type="radio" name="extras" id="extras1" [value]="[]">
                        <label style="padding: 0; font-size: 16px;" class="form-check-label" for="extras1">
                            {{this.dashboardLables(this.getPageName()).noCheckAndTest}}
                        </label>
                    </div>
                    <div class="form-check delivery-rb">
                        <input formControlName="extras" class="form-check-input" type="radio" name="extras" id="extras2" checked [value]="[deliveryExtras.check, deliveryExtras.test]" checked>
                        <label style="padding: 0; font-size: 16px;" class="form-check-label" for="extras2">
                            {{this.dashboardLables(this.getPageName()).checkAndTest}}
                        </label>
                    </div>
                    <div class="form-check delivery-rb">
                        <input formControlName="extras" class="form-check-input" type="radio" name="extras" id="extras3" [value]="[deliveryExtras.check]">
                        <label style="padding: 0; font-size: 16px;" class="form-check-label" for="extras3">
                            {{this.dashboardLables(this.getPageName()).check}}
                        </label>
                    </div>
                    <div class="form-check delivery-rb">
                        <input formControlName="extras" class="form-check-input" type="radio" name="extras" id="extras4" [value]="[deliveryExtras.test]">
                        <label style="padding: 0; font-size: 16px;" class="form-check-label" for="extras4">
                            {{this.dashboardLables(this.getPageName()).test}}
                        </label>
                    </div>
                    <hr> -->

                    <h4 class="delivery-header">{{this.dashboardLables(this.getPageName()).payment}}</h4>

                    <div class="form-check delivery-rb">
                        <input formControlName="payment" class="form-check-input" type="radio" name="payment" id="payment1" [value]="paymentTypes.cash">
                        <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="payment1">
                            {{this.dashboardLables(this.getPageName()).cash}}
                        </label>
                    </div>
                    <div class="form-check delivery-rb">
                        <input formControlName="payment" class="form-check-input" type="radio" name="payment" id="payment2" [value]="paymentTypes.postCash" checked>
                        <label style="padding: 0; font-size: 16px; color: black;" class="form-check-label" for="payment2">
                            {{this.dashboardLables(this.getPageName()).postCash}}
                        </label>
                    </div>
                </div>
            </form>
            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                {{this.errorMessage}}
            </div>
            <div *ngIf="this.delivery && this.currentCarrier?.uuid"  style="text-align: center;">
                <button style="margin: 5px; width: 148px; height: 46px;" class="btn btn-primary" [disabled]="this.delivery.dirty ? false : true" (click)="this.setAsDefaultAddress()">{{(!this.currentCarrier?.uuid) ? this.dashboardLables(this.getPageName()).signIn : this.dashboardLables(this.getPageName()).upload}}</button>
            </div>
        </div>
    </div>
</div>

<ng-template #deliverymodel let-modal>
    <div style="text-align: center; padding: 20px;">
        <h5>{{this.dashboardLables(this.getPageName()).modalHeader}}</h5>
        <div class="modal-body" style="text-align: center; font-weight: 400;">
            {{this.dashboardLables(this.getPageName()).modalBody}}
        </div>
        <button style="width: 150px;" type="button" class="btn btn-primary" (click)="modal.close()">{{this.sharedLabels.close}}</button>
    </div>
</ng-template>

<ng-template #changeSpeedyUsername let-modal>
    <div style="text-align: center; padding: 20px;">
        <h4>Профил в Speedy</h4>
        <h5 style="color: #99A3BF;">Променили сте паролата в Speedy?</h5>
        <div class="modal-body" style="text-align: center; font-weight: 400;">
            <form [formGroup]="this.delivery" class="form-group" style="box-shadow: unset;">
                <label style="grid-row: 1;">{{this.dashboardLables(this.getPageName()).speedyUsername}}<span class="delivery-req-fields">*</span></label>
                <input style="grid-row: 2; grid-column: 1 / 2; width: 300px !important;" formControlName="speedyUsername" type="text" class="form-control mx-sm-4" id="speedyUsername" aria-describedby="emailHelp" [placeholder]="this.dashboardLables(this.getPageName()).insert">
                <label style="grid-row: 1;">{{this.dashboardLables(this.getPageName()).speedyPassword}}<span class="delivery-req-fields">*</span></label>
                <input style="grid-row: 2; grid-column: 1 / 2; width: 300px  !important;" formControlName="speedyPassword" type="password" class="form-control mx-sm-4" id="speedyPassword" aria-describedby="speedylogo" [placeholder]="this.dashboardLables(this.getPageName()).insert">
            </form>
        </div>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="background-color: white; border: 1px solid #B6BFD6; color: black; float: left;" mat-button (click)="modal.close()">Затвори</button>
            <button class="btn btn-primary" style="float: right;" mat-button (click)="this.updateCarrierLogin(modal)" cdkFocusInitial>Обнови парола</button>
          </div>
    </div>
</ng-template>

<app-loading *ngIf="this.isLoading"></app-loading>
<div class="row" style="overflow-x: hidden;">
    <div class="" style="height: 100vh; position: fixed; width: 15%;">
        <div style="height: 100%; width: 100%; float: left; background: #F1F3F6; margin-left: -15px; padding-top: 45px;" class="nav flex-column nav-pills me-20" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a style="margin-bottom: 5px;" class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#profile" role="tab" aria-controls="v-pills-home" aria-selected="true">{{this.dashboardLables(this.getPageName()).profile}}</a>
            <a style="margin-bottom: 5px;" class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" href="#bus-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{this.dashboardLables(this.getPageName()).bussines}}</a>
            <a style="margin-bottom: 5px;" class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#change-pass" role="tab" aria-controls="v-pills-messages" aria-selected="false">{{this.dashboardLables(this.getPageName()).password}}</a>
            <a style="margin-bottom: 5px;" class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" href="#contacts" role="tab" aria-controls="v-pills-settings" aria-selected="false">{{this.dashboardLables(this.getPageName()).contacts}}</a>
            <a style="margin-bottom: 5px;" class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" href="#partners" role="tab" aria-controls="v-pills-settings" aria-selected="false">{{this.dashboardLables(this.getPageName()).parntners}}</a>
        </div>
    </div>
    <div class="test" style="height: 100vh; position: relative; left: 20%; width: 100%; overflow: overlay;">
        <div class="container dashboard-body settings">
            <div class="row">
                <div class="col-sm-12">
                    <div class="d-flex align-items-start">
                        
                        <div class="tab-content" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="container dashboard-body" style="width: 100%;">
                                    <div class="row">
                                        <h4 class="profile-header" style="width: 100%;">{{this.dashboardLables(this.getPageName()).profile}}</h4>
                                        <div class="col-sm-6">
                                            <form *ngIf="this.profile" [formGroup]="this.profile">
                                                <div [className]="this.profile.get('role').value == 'manager' || this.profile.get('role').value == 'both' ? 'bordered active' : 'bordered'">
                                                    <div class="form-check form-check-inline">
                                                        <input name="role" (change)="this.onUserTypeChange($event)" formControlName="role" class="form-check-input" type="checkbox"  id="inlineRadio1" [checked]="this.profile.get('role').value == 'manager' || this.profile.get('role').value == 'both'">
                                                        <label [class]="this.profile.get('role').value == 'manager' || this.profile.get('role').value == 'both' ? 'form-check-label active' : 'form-check-label'" for="inlineRadio1">
                                                            {{this.dashboardLables(this.getPageName()).shareholder}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-sm-6">
                                            <form *ngIf="this.profile" [formGroup]="this.profile">
                                                <div [className]="this.profile.get('role').value == 'owner' || this.profile.get('role').value == 'both' ? 'bordered active' : 'bordered'">
                                                    <div class="form-check form-check-inline">
                                                        <input name="role" (change)="this.onUserTypeChange($event)" formControlName="role" class="form-check-input" type="checkbox" id="inlineRadio2"  [checked]="this.profile.get('role').value == 'owner' || this.profile.get('role').value == 'both'">
                                                        <label [class]="this.profile.get('role').value == 'manager' || this.profile.get('role').value == 'both' ? 'form-check-label active' : 'form-check-label'" for="inlineRadio1">{{this.dashboardLables(this.getPageName()).owner}}</label>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <form *ngIf="this.profile" [formGroup]="this.profile">
                                                <label for="name">{{this.dashboardLables(this.getPageName()).firstName}}</label>
                                                <input formControlName="firstName" type="text" class="form-control" id="name" [placeholder]="'Име'">

                                                <label for="name">{{this.dashboardLables(this.getPageName()).dob}}</label>
                                                <input formControlName="dob" type="date" class="form-control" id="name" placeholder="Дата на раждане">

                                                <label style="margin-bottom: 13px;">{{this.dashboardLables(this.getPageName()).country}}</label>
                                                <mat-form-field class="country-full-width" style="height: unset">
                                                    <input type="text"
                                                        disabled="true"
                                                        matInput
                                                        formControlName="country"
                                                        [matAutocomplete]="auto"
                                                        id="inputGroupSelect01"/>
                                                    <!-- <mat-autocomplete (optionSelected)="this.onCountryChange($event)" #auto="matAutocomplete"> -->
                                                    <mat-autocomplete  #auto="matAutocomplete">
                                                        <mat-option *ngFor="let c of this.filteredOptions | async;"  [value]="c.name">
                                                            {{c.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                                
                                                <label for="name">{{this.dashboardLables(this.getPageName()).address1}}</label>
                                                <input formControlName="address1" type="text" class="form-control" id="name">
                                            </form>                    
                                        </div>
                                        <div class="col-sm-6">
                                            <form *ngIf="this.profile" [formGroup]="this.profile">

                                                <label for="name">{{this.dashboardLables(this.getPageName()).lastName}}</label>
                                                <input formControlName="lastName" type="text" class="form-control" id="name">

                                                <label for="name">{{this.dashboardLables(this.getPageName()).phone}}</label>
                                                <input formControlName="phone" style="color: black;" type="text" [class]="(this.profile.get('phone').invalid && this.profile.get('phone').touched) ? 'form-control is-invalid' : 'form-control'" id="name" >
                                                
                                                <label style="margin-bottom: 10px;">{{this.dashboardLables(this.getPageName()).cityName}}</label>
                                                <mat-form-field class="country-full-width" style="height: unset">
                                                    <input type="text"
                                                        matInput
                                                        formControlName="cityName"
                                                        [matAutocomplete]="auto1"
                                                        id="inputGroupSelect02"/>
                                                    <mat-autocomplete #auto1="matAutocomplete">
                                                        <mat-option *ngFor="let c of this.filteredCityOptions | async;"  [value]="c.name">
                                                            {{c.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>

                                                <label for="name">{{this.dashboardLables(this.getPageName()).postalCode}}</label>
                                                <input formControlName="postalCode" type="text" class="form-control" id="name">
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                                {{this.errorMessage}}
                                            </div>
                                            <div style="text-align: center;">
                                                <button style="margin: 15px; width: 148px; height: 46px;" [disabled]="!this.profile?.dirty" class="btn btn-primary" (click)="this.savePartnerContact()">{{this.dashboardLables(this.getPageName()).save}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="bus-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"> <div class="tab-pane fade show active" style="overflow: hidden;" id="profile" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="container dashboard-body" style="width: 100%; ">
                                    <div class="row" style="margin-bottom: 20px;">
                                        <h4 class="profile-header" style="width: 100%;">{{this.dashboardLables(this.getPageName()).bussines}}</h4>
                                        <div class="col-sm-12">
                                            <label for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).logo}}</label>
                                            <input type="file" class="file-input upload_logo" (change)="avatarUpload($event.target.files)" #fileUpload  id="inputGroupFile">
                                            <div class="file-upload">
                                                <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">{{this.dashboardLables(this.getPageName()).choose}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <form *ngIf="this.bussines" [formGroup]="this.bussines">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).name}}</label>
                                                    <input formControlName="name" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('name').invalid && this.bussines.get('name').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01"> 

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).tradingName}}</label>
                                                    <input formControlName="tradingName" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('tradingName').invalid && this.bussines.get('tradingName').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).legalOrgType}}</label>
                                                    <select [style]="this.bussines.dirty ? 'color: black;' : ''" formControlName="legalOrgType"  class="form-control" id="inputGroupFile01">
                                                        <option *ngFor="let ct of this.compTypes(); index as i;" [selected]="i == 0" [ngValue]="ct.value">{{ct.type}}</option>
                                                    </select>  

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).country}}</label>
                                                    <input formControlName="country" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('country').invalid && this.bussines.get('country').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).cityName}}</label>
                                                    <input formControlName="cityName" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('cityName').invalid && this.bussines.get('cityName').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).address1}}</label>
                                                    <input formControlName="address1" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('address1').invalid && this.bussines.get('address1').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).postalCode}}</label>
                                                    <input formControlName="postalCode" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('postalCode').invalid && this.bussines.get('postalCode').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01">
                                                </form>
                                            </div>
                                            <div class="col-sm-6">
                                                <form *ngIf="this.bussines" [formGroup]="this.bussines">
                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).bulstat}}</label>
                                                    <input formControlName="bulstat" style="color: #99A3BF;" type="text" class="form-control" id="inputGroupFile01"> 

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).vatRegistered}}</label>
                                                    <select [style]="this.bussines.dirty ? 'color: black;' : ''" formControlName="vatRegistered" class='form-control' id="pickUp">
                                                        <option *ngFor="let yesNo of this.yesAndNo;" [value]="yesNo.value">{{yesNo.option}}</option>
                                                    </select>  
                                                    <label *ngIf="this.bussines.get('vatRegistered').value && this.bussines.get('vatRegistered').value != 'false'" for="inputGroupFile01" style="display: inline-block;">{{this.dashboardLables(this.getPageName()).vatNumber}}</label><span *ngIf="this.bussines.get('vatRegistered').value && this.bussines.get('vatRegistered').value != 'false'" style="color: red;"> * </span>
                                                    <input *ngIf="this.bussines.get('vatRegistered').value && this.bussines.get('vatRegistered').value != 'false'" formControlName="vatNumber" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('vatNumber').invalid) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).insert">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).iban}}</label>
                                                    <input formControlName="iban" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('iban').invalid && this.bussines.get('iban').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).iban">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).bankName}}</label>
                                                    <input formControlName="bankName" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text" [class]="(this.bussines.get('bankName').invalid && this.bussines.get('bankName').touched) ? 'form-control is-invalid' : 'form-control'" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).bankName">

                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).bankSortCode}}</label>
                                                    <input formControlName="bankSortCode" [style]="this.bussines.dirty ? 'color: black;' : ''" type="text"  [class]="(this.bussines.get('bankSortCode').invalid && this.bussines.get('bankSortCode').touched) ? 'form-control is-invalid' : 'form-control'"  id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).bankSortCode">
                                                </form>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <form *ngIf="this.bussines" [formGroup]="this.bussines" style="margin-bottom: 20px;">
                                                    <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).businessDescription}}</label>
                                                    <textarea cdkTextareaAutosize [style]="this.bussines.dirty ? 'color: black; ' : ''" formControlName="businessDescription"  type="text"  [class]="(this.bussines.get('businessDescription').invalid && this.bussines.get('businessDescription').touched) ? 'form-control is-invalid' : 'form-control'"  id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).businessDescription"></textarea>
                                                </form>
                                                <app-workingtimes></app-workingtimes>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 60px;">
                                            <div class="col-sm-12">
                                                <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                                    {{this.errorMessage}}
                                                </div>
                                                <div style="text-align: center;">
                                                    <button style="margin: 15px; width: 148px; height: 46px;" [disabled]="!this.bussines?.dirty && !this._workingtimesComponent?.workDays?.dirty" class="btn btn-primary" (click)="this.saveBussinesProfile()">{{this.dashboardLables(this.getPageName()).save}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="change-pass" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <div class="container dashboard-body" style="width: 100%;">

                                <div class="row">
                                    <h4 class="profile-header" style="margin-bottom: 10px; width: 100%;">{{this.dashboardLables(this.getPageName()).password}}</h4>
                                    <h5 style="margin-bottom: 10px; width: 100%;" class="card-sub-title">{{this.dashboardLables(this.getPageName()).passHint}}</h5>
                                    <div class="col-sm-6" style="margin-top: 20px;">
                                        <form *ngIf="this.bussines" [formGroup]="this.password">
                                            <div >
                                                <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).currentPassword}}<span class="req-fields">*</span></label>
                                                <input [type]="hide ? 'text': 'password'" formControlName="password" [style]="this.password.dirty ? 'color: black;' : ''" formControlName="currentPassword" type="password" class="form-control" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).passwordPlaceholder">
                                                <i class="material-icons" style="bottom: 40px; position: relative; left: 98%;" matSuffix (click) = "hide = !hide">{{hide ? 'visibility':'visibility_off'}}</i>
                                            </div>

                                            <div >
                                                <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).newPassword}}<span class="req-fields">*</span></label>
                                                <input [type]="hide ? 'text': 'password'" formControlName="password" [style]="this.password.touched ? 'color: black;' : ''" formControlName="newPassword" type="password" class="form-control" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).passwordPlaceholder">
                                                <i class="material-icons" style="bottom: 40px; position: relative; left: 98%;" matSuffix (click) = "hide = !hide">{{hide ? 'visibility':'visibility_off'}}</i>
                                            </div>

                                            <div >
                                                <label  for="inputGroupFile01">{{this.dashboardLables(this.getPageName()).confirmPassword}}<span class="req-fields">*</span></label>
                                                <input [type]="hide ? 'text': 'password'" formControlName="password" [style]="this.password.touched ? 'color: black;' : ''" formControlName="confirmPassword" type="password" class="form-control" id="inputGroupFile01" [placeholder]="this.dashboardLables(this.getPageName()).passwordPlaceholder">
                                                <i class="material-icons" style="bottom: 40px; position: relative; left: 98%;" matSuffix (click) = "hide = !hide">{{hide ? 'visibility':'visibility_off'}}</i>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                                            {{this.errorMessage}}
                                        </div>
                                        <div style="text-align: center; padding-top: 40px;">
                                            <button style="margin: 15px; width: 148px; height: 46px;" [disabled]="!this.password?.dirty" class="btn btn-primary" (click)="this.changePassword()">{{this.dashboardLables(this.getPageName()).change}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                            <div class="container dashboard-body" style="width: 100%;">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 style="color: #20213D; font-size: 20px; font-weight: 600;" class="card-title">{{this.dashboardLables(this.getPageName()).contacts}}</h4>
                                                <p class="card-text">{{this.dashboardLables(this.getPageName()).contactsHelp}}</p>
                                                <a href="mailto:office@shareme.co" class="btn" style="width: 100%">
                                                    <div class="card mb-12" style="width: 350px;">
                                                        <div class="row g-0">
                                                            <div class="col-md-2" style="padding: 0">
                                                                <div class="mail"></div>
                                                            </div>
                                                            <div class="col-md-10" style="padding: 0; text-align: left;">
                                                                <p class="email-label">{{this.dashboardLables(this.getPageName()).email}}</p>
                                                                <span class="email-span">office@shareme.co</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="tel:+359898983228" class="btn" style="width: 100%">
                                                    <div class="card mb-12" style="width: 350px;">
                                                        <div class="row g-0">
                                                            <div class="col-md-2" style="padding: 0">
                                                                <div class="tel"></div>
                                                            </div>
                                                            <div class="col-md-10" style="padding: 0; text-align: left;">
                                                                <p class="tel-label">{{this.dashboardLables(this.getPageName()).tel}}</p>
                                                                <span class="tel-span">+359 898-983-228</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="partners" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                            <div class="container dashboard-body" style="width: 100%;">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title" style="color: #20213D; font-size: 20px; font-weight: 600;">{{this.dashboardLables(this.getPageName()).partner}}</h4>
                                                <p class="card-text">{{this.dashboardLables(this.getPageName()).partnerHelp}}</p>
                                                <a href="mailto:georgi.e.stoyanov@gmail.com" class="btn" style="width: 100%">
                                                    <div class="card mb-12" style="width: 350px;">
                                                        <div class="row g-0">
                                                            <div class="col-md-2" style="padding: 0">
                                                                <div class="mail"></div>
                                                            </div>
                                                            <div class="col-md-10" style="padding: 0; text-align: left;">
                                                                <p class="email-label">{{this.dashboardLables(this.getPageName()).email}}</p>
                                                                <span class="email-span">georgi.e.stoyanov@gmail.com</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="tel:+359898983228" class="btn" style="width: 100%">
                                                    <div class="card mb-12" style="width: 350px;">
                                                        <div class="row g-0">
                                                            <div class="col-md-2" style="padding: 0">
                                                                <div class="tel"></div>
                                                            </div>
                                                            <div class="col-md-10" style="padding: 0; text-align: left;">
                                                                <p class="tel-label">{{this.dashboardLables(this.getPageName()).tel}}</p>
                                                                <span class="tel-span">+359 898-983-228</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base.component';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { ResetPasswordRequestModel } from 'src/models/requestModels/V2/password/ResetPasswordRequestModel';
import { ToastrService } from 'ngx-toastr';
import { Successes } from 'src/config/labels/successes';
import { Labels } from 'src/config/labels/labels';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent extends BaseComponent implements OnInit {

  
  public passwordGroup : FormGroup
  private errorMsg: string;
  private key: string;
  constructor(protected userinfostoreService : UserinfostoreService,
    protected localStorageService : LocalstorageserviceService,
    private registrationService: RegistrationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    protected router: Router) {
      super(userinfostoreService, localStorageService, router);
      this.nextPage = "registration/details";
      this.pageName = 'resetPassword';
      this.backPage = "registration/positionPage";
      this.passwordGroup = fb.group({
        'password': ['', [Validators.required, 
                          Validators.minLength(8),
                          this.passwordValidator]],
        'confirmPassword': ['', [Validators.required, 
                                 Validators.minLength(8),
                                 this.passwordValidator]],
      })
      this.language = this.localStorageService.getLocalStorageItem(Labels.lsKey);

    }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.key =  params['hash'];
    })
  } 

  checkForSamePasswords(password, confirmationPassword) {
    if (password != confirmationPassword) {
      return false;
    }
    return true;
  }

  passwordValidator (control: AbstractControl): {[key : string]: boolean} | null {
    const value = <string> control.value;
    if ( value.search(/\d/) < 0 || 
         value.search(/[a-zA-Zа-яА-Я]/) < 0 || 
         value.search(/\W/) < 0){
      return {'passwordValidator': false};
    }
    return null;
  };

  arePasswordsValid() {
    if (!this.passwordGroup.get('password').valid ||
        !this.passwordGroup.get('confirmPassword').valid) {
      this.errorMsg = this._labels.passwordConfirmation.invalidPassword;
      return false;
    }
    else if (!this.checkForSamePasswords(this.passwordGroup.get('password').value,
                                         this.passwordGroup.get('confirmPassword').value)) {
      this.errorMsg = this._labels.passwordConfirmation.notSamePassword;
      return false;
    }
    return true;
  }

  errorMessage() {
    return this.errorMsg;
  }

  changePassword() {
    if (!this.arePasswordsValid()) {
      this.error = true;
      return;
    }
    const resetPasswordModel: ResetPasswordRequestModel = {
      password: this.passwordGroup.get('password').value,
      password_confirmation: this.passwordGroup.get('password').value
      
    }
    this.registrationService.resetPassword(resetPasswordModel, this.key).subscribe(() => {
      this.toastr.success(Successes.changePassword[this.language]);
      this.router.navigate(['registration/login']);
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Brand } from 'src/models/DTO/brands/brand';
import { CreateBrandModel } from 'src/models/requestModels/brands/cereateBrandRequestModel';
import { RequestHelper } from 'src/infrastructure/requestHelpers/requestHelpers';



@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  private getBrandsForCategoryURL =  environment.api + "api/product-categories";
  private createBrandForCategoryURL = environment.api + "api/product-categories"

  constructor(private httpClient: HttpClient) { }

  getAllBrandsForSubcategory(subCategoryId: number) {
    const params = RequestHelper.addMultipleParams([
      {
        key: 'parentCategoryId.equals',
        value: subCategoryId
      },
      {
        key: environment.sizeRequestParam,
        value: environment.sizeOfRequests
      }
    ]);
    return this.httpClient.get<Brand[]>(this.getBrandsForCategoryURL + params);
  }

  createBrand(newBrand : CreateBrandModel) {
    return this.httpClient.post<Brand>(this.createBrandForCategoryURL, newBrand);
  } 
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Documents } from 'src/models/DTO/V2/documents/Documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {


  private documentsURL : string = environment.api + "document/partner";


  constructor(private httpClient: HttpClient) {
  }

  generateDocuments() {
    let observables: Observable<any>[] = [];
    environment.documentTemplates.forEach((templateUUID) => {
      const params = '/' + templateUUID + '/generate';
      observables.push(
        this.httpClient.post(this.documentsURL + params, null)
      )
    })
    return observables;
  }

  getDocuments() {
    return this.httpClient.get<Documents>(this.documentsURL);
  }

  downloadDocument(templateUUID: string) {
    const params = '/' + templateUUID + '/download';
    return this.httpClient.get<string>(this.documentsURL + params, { responseType: 'blob' as 'json' });
  }

  acceptDocument(templateUUID: string) {
    const params = '/' + templateUUID + '/accept';
    return this.httpClient.put(this.documentsURL + params, null);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Labels } from 'src/config/labels/labels';
import { PartnerPositions } from 'src/config/labels/partnerPositions';
import { Successes } from 'src/config/labels/successes';
import { UserRegistration } from 'src/models/DTO/user/UserRegistrationModel';
import { VerifyPartnerModelRequestModel } from 'src/models/requestModels/V2/partner/VerifyPartnerModelRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';

@Component({
  selector: 'app-successfulactivation',
  templateUrl: './successfulactivation.component.html',
  styleUrls: ['./successfulactivation.component.css']
})
export class SuccessfulactivationComponent implements OnInit {

  public lable : string;
  protected user : UserRegistration;


  constructor(private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private toastr: ToastrService,
    private localStorageService : LocalstorageserviceService,
    private userinfostoreService : UserinfostoreService,
    private router: Router
    ) {
      this.userinfostoreService.userInfo$.subscribe((user) => {
        this.user = user;
      })
     }

  ngOnInit(): void {
    const language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
    this.lable = Labels[language].emailActivation.lable;
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const shareorowner = params['shareorowner'];
      const hash = params['hash'];
      const verifyPartnerModelRequestModel : VerifyPartnerModelRequestModel = {
        hash: hash
      };
      this.registrationService.veifyRegistration(verifyPartnerModelRequestModel).subscribe(() => {
          this.user.email = email;
          this.user.shareholder = PartnerPositions.positions.shareholder == shareorowner;
          this.userinfostoreService.addNewUser(this.user);
          const language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
          this.toastr.success(Successes.confirmedAccount[language]);
          setTimeout(() => {
            this.router.navigate(['registration/password/change'])
          }, 2000)
      });
    })
  }

}

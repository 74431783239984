export const DASHBOARD_LABLES = {
    en: {
        layout: {
            dashboard: "Dashboard",
            products: "Products",
            objects: "Objects",
            stores: "Stores",
            tools: "Tools (Delivery)",
            invoices: "Invoices",
            invite: "Invite",
            settings: "Settings",
            logout: "Log out"
        },
        dashboard: {

        },
        products: {
            revenue: "Revenue",
            balance: "Balance",
            bankSave: "Save to bank",
            products: "Products",
            services: "Services",
            inventory: "Inventory",
            archive: "Archive",
            search: "Search",
            add: "+ Add",
            image: "Image",
            name: "Name",
            SKU: "SKU",
            quantity: "Availability",
            ordered: "Ordered",
            resQuantity: "Reserved quantity",
            price: "Price",
            date: "Date",
            status: "Status",
            available: "Available in",
            resTime: "Reservation time"
        },
        addProduct: {
            infoLabel: "Product info",
            specs: "Specifications",
            price: "Pricing",
            inventory: "Inventory",
            ship: "Shipping",
            addLabel: "Add new product",
            category: "Category",
            subcategory: "Subcategory",
            name: "Name",
            brand: "Brand",
            manufacturer: "Manufacturer",
            condition: "Condition",
            barcode: "EAN/Barcode",
            SKU: "SKU",
            VAT: "VAT",
            warranty: "Warranty",
            months: "Months",
            specsLabel: "Specifications",
            pricing: "Pricing range",
            cancel: "Cancel",
            uFrom: "Units from",
            uTo: "Units to",
            priceLabel: "Price",
            add: "Add",
            removeLast: "Remove last",
            next: "Next",
            back: "Back"

        }
    },
    bg: {
        layout: {
            dashboard: "Поръчки",
            products: "Продукти",
            objects: "Обекти",
            stores: "Магазини",
            tools: "Настройки за поръчки",
            logistics: "Логистика",
            orders: "Поръчки",
            invoices: "Фактури",
            invite: "Покани",
            settings: "Настройки",
            logout: "Изход"
        },
        dashboard: {
            
        },
        products: {
            firstProduct: "Добавете първия си продукт",
            addProduct: 'Все още нямате качени продукти. Добавете първия си продукт от бутона "Добави продукт"',
            close: "Затвори",
            revenue: "Нови поръчки",
            balance: "Приходи за месеца ",
            bankSave: "Приходи за годината",
            productAlertInfo: "Моля, попълнете данните в меню Логистика, за да станат видими продуктите ви за клиентите.",
            products: "Продукти",
            services: "Услуги",
            inventory: "Наличност",
            archive: "Архивирани",
            draft: "Чернови",
            search: "Търсене",
            add: " + Добави продукт",
            image: "Продукт",
            name: "Име",
            SKU: "SKU",
            quantity: "Наличност",
            ordered: "Поръчани",
            resQuantity: "Запазено количество",
            price: "Цена",
            date: "Дата на промяна",
            status: "Статус",
            available: "Адрес",
            resTime: "Резервация",
            edit: 'Редактирай',
            toArchive: 'Архивирай',
            restore: 'Активирай',
            delete: 'Изтрий',
            calendar: 'Виж Календар',
            variations: 'Виж вариации',
            color: "Цвят",
            size: "Големина",
            previousPage: "Предишна",
            nextPage: "Следваща",
            productsLabel: "продукта",
            show: "Покажи:",
            all: "Всички",
            variationsLabel: "Вариации"
        },
        addProduct: {
            infoLabel: "Информация за продукта",
            specs: "Характеристики",
            placeholders: {
                choose: 'Избери',
                name: 'Пример: Телевизор Samsung ETF52EF48',
                manufacturer: 'Пример: Samsung, Kenvelo, Swarovski ',
                ean: 'Пример: 2313122249094',
                sku: 'Пример: 42452242'
            },
            imageInfo: {
                heading: 'Главна снимка',
                li1: 'Главната снимка на продукта трябва да е на бял фон и да е центриранa',
                li2: 'Снимката трябва да съдържа само продукта, който се продава',
                li3: 'Не трябва да съдържа други добавени елементи: вкарани снимки, лого, текст',
                li4: 'Препоръчителен формат е JPEG, PNG, TIF',
                li5: 'Максималният размер на снимка е 20МВ',
                li6: 'Използвайте снимка, която е ясна, атрактивна и разбираема',
                li7: 'Hе трябва да съдържат водни знаци',
                li8: 'Продуктът трябва да заема основна част от цялата снимка',
                li9: 'Минималният приет размер за изображения е 640 х 640 пиксела',
                heading2: 'Допълнителни снимки',
                button: 'Виж',
                btnLabel: 'Пример',
                info: 'За фотограф на липсващи Ви снимки, може да се свържете:',

            },
            variationImageUpload: "+ Качи снимки",
            choose: 'Въведи',
            action: 'Действие',
            variations: "Вариации",
            addVariations: "Добави разновидности",
            noVariations: "Продуктът няма различни вариации",
            yesVariations: "Продуктът има различни вариации",
            price: "Цена",
            inventory: "Склад",
            ship: "Доставка",
            addLabel: "Добавяне на нов продукт",
            category: "Категория",
            subcategory: "Подкатегория",
            subSubCategory: "Подкатегория",
            name: "Наименование на продукта",
            brand: "Марка на продукта",
            manufacturer: "Производител",
            condition: "Състояние на продукта",
            barcode: "EAN/Баркод №",
            SKU: "SKU(Код на продукта)",
            variationsSKU: "SKU",
            tags: "Ключови думи при търсене",
            VAT: "ДДС",
            warranty: "Гаранция на продукта",
            months: "Месеца",
            specsLabel: "Характеристика",
            pricing: "Ценови диапазон",
            cancel: "Изход",
            units: "Количество",
            uFrom: "От количество",
            uInitFrom: "Количество",
            uTo: "До количество",
            priceLabel: "Цена",
            add: "Добави диапазон",
            customBrandPlaceholder: 'Добави своя марка',
            removeLast: "Премахни последното",
            next: "Продължи",
            back: "Назад",
            photos: "Снимки",
            packaging: 'Опаковка на продукта',
            pickUp: "Доставяне",
            stores: "Магазини",
            shippWithin: "Дни за доставка на продукта",
            height: "Височина",
            weight: "Тегло",
            depth: "Дълбочина",
            length: "Широчина",
            upload: "Качи продукт",
            uploadVariation: "Запази",
            cm: "см",
            kg: "кг",
            lv: "лв",
            newStore: "Нов Магазин",
            invalidField: ' е невалиден/невалидна/невалидно',
            days: "Дена",
            quantity: "Количество",
            variationsQuantity: "к-во",
            description: "Описание на продукта",
            uploadFileLabel: "Привлачи или натисни за добавяне до 8 снимки",
            uploadFileBtn: "Избери снимки...",
            color: "Цвят",
            delete: "Изтрии",
            addVariation: "Добави разновидност",
            size: "Големина",
            sizeLabel: "Размери на продукта",
            fragile: "Чупливо",
            courrierType: "Вид куриерска услуга",
            yes: "Да",
            no: "Не",
            pallete: "Палет",
            standart: "Стандарт 24",
            tyres: "Гуми",
            extras: 'Преглед и тест',
            payOptions: 'Опции преди плащане',
            noCheckAndTest: 'Без преглед и тест',
            check: 'Преглед',
            test: 'Тест',
            select: "Избери",
            image: "Лого",
            customBrand: "Добави",
            custom: "Добави своя",
            gender: "Пол",
            manufacturerInfo: ' Име на производителя на продукта.',
            conditionInfo: ' Само Нови продукти могат да се предлагат.',
            barcodeInfo: ' Това е 13-цифрения код в баркода на продукта.',
            SKUInfo: ' Това е вътрешен номер на продукта,  чрез който търговецът идентифицира стоките.',
            VATInfo: ' Приложимата ДДС ставка за продукта.',
            warratyInfo: 'Гаранцията приложима за продукта, издадена от производителя или търговеца.',
            pricingInfo: ' * Определете цената, която ще струва продукта при поръчка на съответно количество. От 1 до 4 броя цената трябва да е различна за всяка бройка, след това се задава диапазон и съответната цена. Задайте атрактивна цена, за повече продажби.',
            uploadImageInfo: 'Главната снимка на продукта трябва да е на бял фон и да е центриранa. Снимката трябва да съдържа само продукта, който се продава. Не трябва да съдържа други добавени елементи: вкарани снимки, лого, текст. Задължителен формат е JPEG, PNG, TIF. Максималният размер на снимка е 7МВ. Използвайте снимка, която е ясна, атрактивна и разбираема.'

        },
        editProduct: {
            infoLabel: "Продуктови данни",
            specs: "Спецификации",
            price: "Ценообразуване",
            inventory: "Склад",
            ship: "Доставка",
            addLabel: "Промени продукт",
            category: "Категория",
            subcategory: "Подкатегория",
            name: "Име",
            brand: "Марка на продукта",
            manufacturer: "Производител",
            condition: "Състояние",
            barcode: "Баркод",
            SKU: "СКУ",
            VAT: "ДДС",
            warranty: "Гаранция",
            months: "Месеца",
            specsLabel: "Спецификации",
            pricing: "Ценообразуване",
            cancel: "Спри",
            uFrom: "Количество от",
            uTo: "Количество до",
            priceLabel: "Цена",
            add: "Добави",
            removeLast: "Премахни последното",
            next: "Напред",
            back: "Назад",
            pickUp: "Доставяне",
            stores: "Магазини",
            shippWithin: "Доставка до",
            height: "Височина",
            weight: "Тегло",
            depth: "Дълбочина",
            length: "Дължина",
            upload: "Запиши",
            cm: "СМ",
            kg: "КГ",
            newStore: "Нов Магазин",
            invalidField: ' е невалиден/невалидна/невалидно',
            days: "Дена",
            quantity: "Количество",
            description: "Описание",
            uploadFileLabel: "Провлачете снимки, за да ги добавите *",
            uploadFileBtn: "Избери файл",
            color: "Цвят",
            delete: "Изтрии",
            addVariation: "Добави Вариация",
            size: "Големина",
            select: "Избери",
            image: "Лого",
            customBrand: "Добави",
            custom: "Друго",
            gender: "Пол",
            manufacturerInfo: ' Име на производителя на продукта.',
            conditionInfo: ' Само Нови продукти могат да се предлагат.',
            barcodeInfo: ' Това е 13-цифрения код в баркода на продукта.',
            SKUInfo: ' Това е вътрешен номер на продукта,  чрез който търговецът идентифицира стоките.',
            VATInfo: ' Приложимата ДДС ставка за продукта.',
            warratyInfo: 'Гаранцията приложима за продукта, издадена от производителя или търговеца.',
            pricingInfo: ' *Опредете цената, която ще струва продукта при поръчка на съответно количество. От 1 до 4 броя цената трябва да е различна за всяка бройка, след това се задава диапазон и съответната цена. Задайте атрактивна цена, за повече продажби.',
            uploadImageInfo: 'Главната снимка на продукта трябва да е на бял фон и да е центриранa. Снимката трябва да съдържа само продукта, който се продава. Не трябва да съдържа други добавени елементи: вкарани снимки, лого, текст. Задължителен формат е JPEG, PNG, TIF. Максималният размер на снимка е 7МВ. Използвайте снимка, която е ясна, атрактивна и разбираема.'

        },
        addService: {
            addServiceLabel: "Добави услуга",
            serviceInfoLabel: "Информация за услугата",
            category: "Категория",
            subcategory: "Подкатегория",
            name: "Име",
            upload: "Запиши",
            description: "Описание",
            description2: "Допълнително",
            terms: "Условия",
            pricing: "Цена",
            reservation: "Резервация",
            resInterval: "Резервация отпреди",
            stores: "Магазини"
        },
        editService: {
            addServiceLabel: "Промени услуга",
            serviceInfoLabel: "Информация за услугата",
            category: "Категория",
            subcategory: "Подкатегория",
            name: "Име",
            upload: "Запиши",
            description: "Описание",
            description2: "Допълнително",
            terms: "Условия",
            pricing: "Цена",
            reservation: "Резервация",
            resInterval: "Резервация отпреди",
            stores: "Магазини"
        },
        imageUpload: {
            uploadFileLabel: "Привлачи или натисни за добавяне до 8 снимки",
            uploadFileBtn: "Избери снимки...",
            variationHeader: "Качи снимки за разновидността",
            variationSubHeader: "Първата снимка е заглавната снимка на продукта. Можете да я промените като приплъзнете друга снимка на нейното място.",
        },
        stores: {
            stores: "Магазини",
            newStore: "Нов Магазин"
        },
        store: {
            title: "Магазини",
            search: "Търси",
            add: "Добави",
            noStores: "Все още нямате добавени магазини",
            name: "Име",
            address: "Адрес",
            phone: "Телефон",
            pickUp: "От място",
            edit: "Промени",
            delete: "Изтрии"
        }, 
        storeForm: {
            pageTitle: "Добави/Промени магазин",
            back: "Обратно",
            name: "Име",
            code: "Код на магазина",
            country: "Държава" ,
            city: "Град",
            address: "Адрес",
            post: "Пощенски код",
            phone: "Телефон",
            pickUp: "Вземане от място",
            title: "Заглавие",
            nameInfo: "Уникално име на магазина",
            codeInfo: "Уникален код за идентификация на магазина",
            pickUpInfo: "Информация дали има опция за взимане от място",
            invalidField: ' е невалиден/невалидна/невалидно'
        },
        createStore: {
            save: "Запази",
        },
        editStore: {
            save: "Запази",
        },
        workingTimes: {
            workDays: 'Работно време',
            everyDay: "Задайте за всеки ден",
            apply: "Задай",
            customWorkDayViewLabel: "Специфично",
            rest: "Почивка",
            mon: 'Понеделник',
            tue: 'Вторник',
            wed: 'Сряда',
            thu: 'Четвъртък',
            fri: 'Петък',
            sat: 'Събота',
            sun: 'Неделя'
        },
        settings: {
            profile: 'Личен профил',
            bussines: 'Бизнес профил',
            password: 'Смени парола',
            contacts: 'Контакти',
            parntners: 'Стани партньор',
            owner: 'Аз съм собственик/един от собствениците',
            shareholder: 'Аз съм управител/един от управителите',
            firstName: 'Име',
            dob: 'Дата на раждане',
            country: 'Държава',
            address1: 'Адрес',
            lastName: 'Фамилия',
            phone: 'Телефон',
            cityName: 'Град',
            postalCode: 'Пощенски код',
            logo: 'Качи лого',
            name: 'Име на фирмата',
            tradingName: 'Име на латиница',
            legalOrgType: 'Правна форма',
            bulstat: 'ЕИК/Булстат',
            vatRegistered: 'ДДС регистрация',
            vatNumber: 'ДДС номер',
            iban: 'IBAN',
            bankName: 'Банка',
            bankSortCode: 'BIC/SWIFT',
            businessDescription: 'Бизнес описание',
            save: 'Запази',
            insert: 'Въведи',
            passwordPlaceholder: 'Въведи',
            passHint: 'Паролата трябва да е минимум 8 символа (поне по една буква, една цифра и един знак)',
            currentPassword: 'Стара парола',
            newPassword: 'Нова парола',
            confirmPassword: 'Потвърди нова парола',
            passwordInfo: 'Паролата трябва да е минимум 8 символа (поне по една буква, една цифра и един знак)',
            change: 'Промени',
            contactsHelp: 'Ще се радваме да отговорим на въпросите Ви.',
            email: 'Имейл',
            tel: 'Телефон',
            partner: 'Стани партньор',
            partnerHelp: 'Ако искате да си партнираме за безплатна Giveaway кампания на ваши продукти, за стимулиране на продажбите Ви, свържете се с нас:',
            invalidField: ' е невалиден/невалидна/невалидно',
            choose: 'Избери'
        },
        delivery: {
            delivery: 'Доставка',
            returnInstructions: {
                header: 'Как да обработваме заявка за връщане на продукт',
                listHeader: 'След като клиент пусне заявка за връщане на продукт и изпрати обратно продукта, имате 14 дни да го разгледате и да възстановите сумата по върнатите продукти.',
                p: 'Процес на връщане:',
                li1: 'Клиентът пуска заявка през мобилното приложение',
                li2: 'Клиентът изпраща продуктите до посочения от Вас адрес',
                li3: 'Одобрявате връщането:',
                lili1: 'Ако е платено с наложен платеж, преведете сумата на посочената от клиента сметка.',
                lili2: 'Ако е платено с карта, ние ще възстановим сумата и ще я удържим от следващото изплащане.',
            },
            deliveryAlert: ' Изберете срок за възможно връщане на продукт от клиент, за да продължите.',
            documentsAlert: ' Потвърдете следните документи преди да започнете да качвате продукти.',
            return: 'Връщане',
            documents: 'Правни документи',
            courier: 'Доставка с куриер',
            deliveryToAddressFee: 'Такса за доставка до Адрес',
            deliveryToCourierOfficeFee: 'Такса за доставка до офис на куриер',
            freeShippingOverAmount: 'Безплатна доставка над сума',
            addressTaxInfo: 'Такса за доставка до адрес на клиента. 0.00лв за Безплатна доставка на всички продукти.',
            officeTaxInfo: 'Такса за доставка до офис. 0.00лв за Безплатна доставка на всички продукти.',
            freeOverInfo: 'Безплатна доставка над тази сума за поръчка от един клиент.',
            own: 'Доставка със собствен транспорт',
            yes: 'Да',
            no: 'Не',
            ownOverInfo: 'При доставка със собствен транспорт, търговецът трябва да се свърже с клиента, след потвърждаване на поръчката за уговаряне на дата и час на доставка. При този тип доставка , Търговецът трябва да въведе “Код за доставка” даден от клиента при доставката на продуктите.',
            deliveryViaOwnTransportFee: 'Такса за доставка*',
            cities: 'Градове за доставка *',
            freeReturnPeriodLabel: 'Срок за безплатно отказване на услуга *',
            freeReturnPeriodInfo: 'В този срок, преди датата на услугата, клиентът може да откаже услугата “Безплатно”, след него се удържа процент от стойността на поръчката.',
            freeReturnPeriod: 'Дни преди услугата',
            returnPeriodInfo: 'В този срок, клиетът има право да върне продукта, без причина и в съответното състояние. Срокът за връщане започва от датата на доставката на продукта до клиента.',
            returnPeriod: 'Срок за връщане на продукт',
            feendays: '14-дни',
            tyndays: '30-дни',
            upload: 'Запази',
            invalidField: ' е невалиден/невалидна/невалидно',
            document: 'Документ',
            file: 'Файл',
            date: 'Дата на издаване',
            status: 'Статус',
            action: 'Действие',
            sign: 'Потвърди',
            save: 'Потвърди',
            terms: 'Приемам условията',
            declaration: 'Декларирам, че съм официален представител на ',
            confirmed: 'Потвърден',
            notconfirmed: 'Непотвърден',
            termsnotconfirmed: 'Не сте приели условията!',
            speedyProfile: 'Профил в Спиди',
            speedyUsername: 'Потребителско име в Speedy',
            speedyUsernameRegData: 'Въведете данните за регистрацията си в Спиди, за да може да извършвате продажби.',
            speedyPassword: 'Парола в Speedy',
            insertPrice: 'Въведи цена',
            insert: 'Въведи',
            documentFooter: '* Всички продукти са нови и с опаковка на производителя',
            currency: 'лв',
            stripeCardInfo: "Активирайте акаунта си в Stripe, за да приемате плащания с карта.",
            toStripe: "Към Stripe",
            payments: "Плащания",
            stripeHelp: "*При нужда от асистенция с работата и активирането на акаунта в Stripe, моля свържете се с нас на +359 898 983 228",
            stripeActivate: "Активирай",
            stripePortal: "Kъм портал",
            modalBody: 'Моля, въведете данните в секция "Настройки за поръчки" преди да започнете да качвате продукти'
        },
        carriers: {
            logistics: "Логистика",
            delivery: 'Доставка',
            carrierAlert: ' За да започнете да доставяте продукти е нужно да влезете в профила си в Speedy. За целта, моля, въведете потребителско си име и парола в Speedy.',
            return: 'Връщане',
            documents: 'Правни документи',
            courier: 'Доставка с куриер',
            deliveryToAddressFee: 'Такса за доставка до aдрес',
            deliveryToCourierOfficeFee: 'Такса за доставка до офис на куриер',
            freeShippingOverAmount: 'Безплатна доставка над сума',
            addressTaxInfo: 'Такса за доставка до адрес на клиента. За Безплатна доставка до адрес на всички продукти, въведете ',
            officeTaxInfo: 'Такса за доставка до офис. За Безплатна доставка до офис на всички продукти, въведете ',
            freeOverInfo: 'Безплатна доставка над тази сума за поръчка от един клиент.',
            own: 'Доставка със собствен транспорт',
            yes: 'Да',
            no: 'Не',
            ownOverInfo: 'При доставка със собствен транспорт, търговецът трябва да се свърже с клиента, след потвърждаване на поръчката за уговаряне на дата и час на доставка. При този тип доставка , Търговецът трябва да въведе “Код за доставка” даден от клиента при доставката на продуктите.',
            deliveryViaOwnTransportFee: 'Такса за доставка*',
            cities: 'Градове за доставка *',
            freeReturnPeriodLabel: 'Срок за безплатно отказване на услуга *',
            freeReturnPeriodInfo: 'В този срок, преди датата на услугата, клиентът може да откаже услугата “Безплатно”, след него се удържа процент от стойността на поръчката.',
            freeReturnPeriod: 'Дни преди услугата',
            returnPeriodInfo: 'В този срок, клиетът има право да върне продукта, без причина и в съответното състояние. Срокът за връщане започва от датата на доставката на продукта до клиента.',
            returnPeriod: 'Срок за връщане на продукт',
            feendays: '14-дневен',
            tyndays: '30-дневен',
            upload: 'Запази',
            signIn: 'Влез',
            invalidField: ' е невалиден/невалидна/невалидно',
            document: 'Документ',
            file: 'Файл',
            date: 'Дата на издаване',
            status: 'Статус',
            action: 'Действие',
            sign: 'Потвърди',
            save: 'Потвърди',
            terms: 'Приемам условията',
            declaration: 'Декларирам, че съм официален представител на ',
            confirmed: 'Потвърден',
            notconfirmed: 'Непотвърден',
            termsnotconfirmed: 'Не сте приели условията!',
            speedyProfile: 'Профил в Спиди/Speedy',
            speedyUsername: 'Потребителско име в Speedy',
            speedyUsernameRegData: '<span>За да може да извършвате продажби е нужно да сключите договор с куриерска фирма Спиди (дори да имате вече сключен договор с тях, ще е необходимо да сключите нов за изплащане на сумите от продажби към сметката на "ШеърМи" АД). За повече информация, се свържете с нас на <a href="tel:+359898983228" target="_blank">+359 898 983 228</a> или директно със Спиди на  <a href="tel:+070017001" target="_blank"> 0 7001  7001</a></span>',
            speedyPassword: 'Парола в Speedy',
            insertPrice: 'Въведи цена',
            insert: 'Въведи',
            documentFooter: '* Всички продукти са нови и с опаковка на производителя',
            currency: 'лв',
            stripeCardInfo: "Активирайте акаунта си в Stripe, за да приемате плащания с карта.",
            toStripe: "Към Stripe",
            payments: "Плащания",
            stripeHelp: "*При нужда от асистенция с работата и активирането на акаунта в Stripe, моля свържете се с нас на +359 898 983 228",
            stripeActivate: "Активирай",
            stripePortal: "Kъм портал",
            set: "Задай",
            address: "Адрес",
            company: "Фирма",
            contact: "Лице за контакт",
            telephone: "Телефон",
            client: "Клиентски номер",
            dispatch: 'Метод за доставка',
            fromAddress: 'Пратките се вземат от адрес на подателя',
            fromOffice: 'Пратките се изпращат от офис на куриер',
            pickupHour: "Час за вземане",
            pickupHourInfo: 'Моля въведете удобен за Вас час <strong>(между 09:00 и 18:00)</strong>, в който куриерът да взема подготвените пратки.',
            size: 'Размер на товарителницата',
            a6: 'А6',
            a4: 'А4',
            extras: 'Преглед и тест',
            checkAndTest: 'С преглед и тест',
            noCheckAndTest: 'Без преглед и тест',
            check: 'Преглед',
            test: 'Тест',
            payment: 'Метод на плащане',
            cash: 'Наложен платеж',
            postCash: 'Пощенски паричен превод',
            modalBody: 'За да започнете да доставяте продукти е нужно да влезете в профила си в Speedy от меню "Логистика" и да въведете необходимите данни.',
            modalHeader: "Преди да започнете да доставяте"


        },
        calendar: {
            prev: "Предишен",
            next: "Следващ",
            toggle: "Задай",
            freeDay: "Маркирай цял ден като свободен",
            resDay: "Маркирай цял ден като зает",
            all: "Всички",
            free: "Сводободни",
            reserved: "Заети",
            res: "Зает",
            fr: "Свободен",
            reserve: "Резервирай"
        },
        orders: {
            id: "Поръчка №",
            revenue: "Нови поръчки",
            balance: "Приходи за месеца ",
            bankSave: "Приходи за годината",
            products: "Продукти",
            services: "Услуги",
            inventory: "Наличност",
            archive: "Архивирани",
            draft: "Чернови",
            search: "Търсене",
            add: " + Добави продукт",
            image: "Продукт",
            name: "Име",
            SKU: "СКУ",
            quantity: "Наличност",
            ordered: "Поръчани",
            resQuantity: "Запазено количество",
            price: "Сума",
            date: "Дата",
            type: "Тип поръчка",
            tax: "Такса",
            returnFee: "Такса връщане",
            client: "Клиент",
            status: "Статус",
            available: "Адрес",
            resTime: "Резервация",
            edit: 'Промени',
            toArchive: 'Архивирай',
            restore: 'Активирай',
            delete: 'Изтрий',
            calendar: 'Виж Календар',
            variations: 'Покажи вариации',
            color: "Цвят",
            size: "Големина",
            previousPage: "Предишна",
            nextPage: "Следваща",
            productsLabel: "Продукта",
            show: "Покажи",
            all: "Всички",
            variationsLabel: "Вариации",
            method: 'Платежен метод',
            enddate: 'Крайна дата',
            delivery: 'Метод на доставка',
            sum: 'Сума',
            returnedsum: 'Върната сума',
            confirm: 'Нето Продажба',
            pickup: 'Нето Продажба',
            generate: 'Нето Продажба',
            print: 'Нето Продажба',
            return: 'Нето Продажба',
            cancelreturn: 'Нето Продажба',
            cancel: 'Откажи',
            qty: 'К-во',
            qtypp: 'Цена (1 бр.)',
            pricenet: 'Цена (без ДДС)',
            vat: 'ДДС',
            pricegross: 'Цена с ДДС',
            ordersLabel: 'Поръчки',

        },
    }
};
  
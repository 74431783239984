import { FormGroup } from '@angular/forms';
import { ValidationConfig } from './../validationHelpers/validationConfig';

export const ValidationHelper = {
    telephone: {
        validate: function(telephone : string, country: string) {
            return (telephone.match(ValidationConfig.telephone[country])) ? true : false;
        }
    },

    email: {
        validate: function(email: string) {
            return (email.match(ValidationConfig.email.regex)) ? true : false;

        }
    },

    allowedImages: {
        validate: function(extension: string) {
            return ValidationConfig.allowedImageExtensions.indexOf(extension.toLowerCase()) > -1;
        }
    },

    maxSizeOfImage: {
        validate: function(imageSize: number) {
            if (imageSize > ValidationConfig.maxSizeOfImage) {
                return false;
            }
            return true;
        }
    },

    formGroup: {
        validate: function(formGroup: FormGroup, labels: any) : string{
            var invalidField = "";
            Object.keys(formGroup.controls).forEach(control => {
                if (!formGroup.get(control).valid && !formGroup.get(control).disabled) {
                    invalidField = labels[control];
                }
              });
              return invalidField;
        }
    },

    workingTimesFormGroup: {
        validate: function(formGroup: FormGroup, labels: any) : string{
            var invalidField = "";
            Object.keys(formGroup.controls).forEach(control => {
                if (!formGroup.get(control).valid && !formGroup.get(control).disabled) {
                  control = control.replace(/From|To/, '');
                  invalidField = labels[control];
                }
            });
            return invalidField;
        }
    }
}

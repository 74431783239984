import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PasswordConfirmComponent } from 'src/app/dialogs/confirmPassword/password-confirm/password-confirm.component';
import { WorkingtimesComponent } from 'src/app/shared/workingtimes/workingtimes.component';
import { CompanyTypes } from 'src/config/labels/companyTypes';
import { Errors } from 'src/config/labels/errors';
import { Labels } from 'src/config/labels/labels';
import { PartnerPositions } from 'src/config/labels/partnerPositions';
import { Successes } from 'src/config/labels/successes';
import { YesAndNo } from 'src/config/labels/yesNoOptions';
import { Convertor } from 'src/infrastructure/convertorHelpers/convertor';
import { Core } from 'src/infrastructure/coreFunctionHelpers/coreFunctions';
import { AutoMapper } from 'src/infrastructure/mappers/automapper';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { City } from 'src/models/DTO/cities/city';
import { Country } from 'src/models/DTO/countries/country';
import { Contact } from 'src/models/DTO/V2/contact/Contact';
import { Base64Image } from 'src/models/DTO/V2/image/BaseEncodedImage';
import { CreateImageModel } from 'src/models/requestModels/images/CreateImageRequestModel';
import { ChangePasswordRequestModel } from 'src/models/requestModels/V2/password/ChangePasswordRequestModel';
import { UpdateContactRequestModel } from 'src/models/requestModels/V2/contact/UpdateContactRequestModel';
import { DeleteImage } from 'src/models/requestModels/V2/image/DeleteImage';
import { CreateNewBase64Image } from 'src/models/requestModels/V2/image/Image';
import { UpdatePartnerRequestModel } from 'src/models/requestModels/V2/partner/UpdatePartnerRequestModel';
import { UserRegistrationRequestModel } from 'src/models/requestModels/V2/user/UserRegistrationRequestModel';
import { UserUpdateRequestModel } from 'src/models/requestModels/V2/user/UserUpdateRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { CitiesService } from 'src/services/requestServices/cities/cities.service';
import { CountriesService } from 'src/services/requestServices/countries/countries.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { LocationsService } from 'src/services/requestServices/locations/locations.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { ContactsService } from 'src/services/requestServices/V2/contacts/contacts.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { WorkingtimeService } from 'src/services/requestServices/workingtime/workingtime.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ValidationConfig } from 'src/infrastructure/validationHelpers/validationConfig';
import { validateIBAN } from 'ngx-iban-validator';
import { Warnings } from 'src/config/labels/warnings';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';

declare var bootstrap: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends BaseDashboardComponent implements OnInit {

  private _errorMsgs: string = "";
  private _errorMsg: string;
  public profile: FormGroup;
  public bussines: FormGroup;
  public password: FormGroup;
  private _countries: Country[] = [] as any;
  filteredOptions: Observable<Country[]>;
  private _cities: City[] = [] as any;
  filteredCityOptions: Observable<City[]>;
  private _currentPartnerContact: Contact
  private _currentOrginizationContact: Contact;
  private _password: string


  @ViewChild(WorkingtimesComponent)
  public _workingtimesComponent: WorkingtimesComponent;

  constructor(   
    protected activatedRoute: ActivatedRoute, 
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected usersService: UsersService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected partnersService: PartnersService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    private FB: FormBuilder,
    private countryService: CountriesService,
    private cityService: CitiesService,
    private locationService: LocationsService,
    private workingTimeService: WorkingtimeService,
    private toastrService: ToastrService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    private contactService: ContactsService,
    private registrationService: RegistrationService,
    protected imageService: ImageService,
    private matDialog: MatDialog,
    protected carrierService: CarrierService,
    protected documentsService: DocumentsService,
    private loadingState: LoadingstateService
    ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentsService);
      this.onDirectNavigation();
      this.getWholeUserInfo();
      this.pageName = "settings";
      this._language = this.localStorageService.getLocalStorageItem(Labels.lsKey);
      this.password = this.FB.group({
        currentPassword: ['', [Validators.required, Validators.minLength(8)]],
        newPassword: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]]
      })
      this.loadingState.loading$.subscribe(result => {
        this.isLoading = result;
      });
  }

  get errorMessage() : string {
    return this._errorMsg;
  }

  async getWholeUserInfo() {
    await this.getUserInfo();

    this._currentPartnerContact = await this.contactService.getContactInfoForEntity(this.loggedPartner.data.contact.uuid).toPromise();
    this._currentOrginizationContact = await this.contactService.getContactInfoForEntity(this.loggedPartner.data.organisations[0].contact.uuid).toPromise();

    this.initProfile();
    this.initBussiness();
    this.prepareFilters();
  }


  private initProfile() {
    let role = '';
    if (this.loggedPartner.data.organisations[0].partner_type == PartnerPositions.positions.both) {
      role = 'both';
    }
    else {
      role = (this.loggedPartner.data.organisations[0].partner_type == PartnerPositions.positions.shareholder) ? 'manager' : 'owner';
    }
    this.profile = this.FB.group({
      firstName: [{value: this.loggedPartner.data.first_name, disabled: true}, [Validators.required]],
      dob: [{value: this.loggedPartner.data.dob, disabled: true}, [Validators.required]],
      country: [{value: 'България', disabled: true}, [Validators.required]],
      address1: [{value: this._currentPartnerContact.data.street_name, disabled: true}, [Validators.required]],
      phone: [{value: this._currentPartnerContact.data.mobile_phone_1_e164, disabled: false}, [Validators.required, this.validateTell.bind(this)]],
      cityName: [{value: this._currentPartnerContact.data.city, disabled: true}, [Validators.required]],
      postalCode: [{value: this._currentPartnerContact.data.post_code, disabled: true}, [Validators.required]],
      lastName: [{value: this.loggedPartner.data.last_name, disabled: true}, [Validators.required]],
      role: [{value: role, disabled: true}, [Validators.required]],
    })
  }

  private initBussiness() {
    const currentOrganization = this.loggedPartner.data.organisations[0];
    this._workingtimesComponent.setValues(currentOrganization.opening_hours);
    this._workingtimesComponent.setCustomLabelStyle('color: #0A69EB');
    const compType = CompanyTypes.compTypes[this._language].find((type) => type.type == currentOrganization.legal_form.label).value;
    this.bussines = this.FB.group({
      name: [currentOrganization.native_name, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      tradingName: [currentOrganization.name_in_latin, [Validators.required, Validators.pattern(ValidationConfig.regexes.latinFull)]],
      country: [{value: 'България', disabled: true}, [Validators.required]],
      address1: [this._currentOrginizationContact.data.street_name, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      bulstat: [{value: currentOrganization.number, disabled: true}, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      cityName: [this._currentOrginizationContact.data.city, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      postalCode: [this._currentOrginizationContact.data.post_code, [Validators.required,Validators.pattern(ValidationConfig.regexes.alpha_dash)]],
      legalOrgType: [compType, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      vatRegistered: [(currentOrganization.vat_number) ? true : false, [Validators.required]],
      vatNumber: [currentOrganization.vat_number, [Validators.pattern(ValidationConfig.regexes.alpha_dash)]],
      bankName: [currentOrganization.bank_name, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylic)]],
      bankSortCode: [currentOrganization.bic_swift_code, [Validators.required, Validators.pattern(ValidationConfig.regexes.bic)]],
      businessDescription: [currentOrganization.description, [Validators.required, Validators.pattern(ValidationConfig.regexes.cyrylicOnly)]],
      iban: [currentOrganization.iban, [Validators.required, this.validateIban]],
    })
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(queryParam => {
      if (queryParam.has('logo')) {
        let tabEl = document.querySelector('a[href="#bus-profile"]');
        let tab = new bootstrap.Tab(tabEl);
        tab.show()
      }
    })
  }

  validateTell (control: AbstractControl): {[key : string]: boolean} | null {
    const value = control.value.toString();
    const phoneUtils = PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtils.parse(value, 'BG');
      if (phoneUtils.isValidNumberForRegion(number, 'BG')) {
        return null;
      }
      return {'validateTell': false};
    }
    catch(error) {
      return {'validateTell': false};
    }
  };

  private  validateIban(control: AbstractControl): ValidationErrors | null {
    if (control.value && validateIBAN( { value: control.value } ).ibanInvalid) {
      return {ibanInvalid: {value: control.value}}
    }
    return null;
  }

  private prepareFilters() {
    this.filteredOptions = this.profile.get('country').valueChanges
      .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.filteredCityOptions = this.profile.get('cityName').valueChanges
      .pipe(
      startWith(''),
      map(value => this._filterCity(value))
    );
  }

  private _filter(value: string): Country[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this._countries.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;
  }

  private _filterCity(value: string): City[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    let test = this._cities.filter(option => option.name.toLowerCase().includes(filterValue));
    return test;
  }

  get yesAndNo() {
    return YesAndNo.options[this._language];
  }

  private validation(formGroup: FormGroup) {
    let fieldName = "";
    fieldName = ValidationHelper.formGroup.validate(formGroup, this.dashboardLables(this.getPageName()));
    if (formGroup.controls['vatNumber'] && formGroup.get('vatRegistered').value &&
        formGroup.get('vatRegistered').value != 'false' && !formGroup.get('vatNumber').value) {
          formGroup.get('vatNumber').setErrors({ notEmpty: true});
          return false;
    }
    if (fieldName != '') {
      this.error = true;
      this._errorMsg = fieldName + this.dashboardLables(this.getPageName()).invalidField;
      return false;
    }
    return true;
  }

  private validateWorkingTime() {
    let fieldName = "";
    fieldName = this._workingtimesComponent.validate();
    if (fieldName != '') {
      this.error = true;
      this._errorMsgs = fieldName + this.dashboardLables(this.getPageName()).invalidField;
      return false;
    }
    return true;
  }

  onUserTypeChange(event) {
    this.profile.get('role').setValue(event.target.value);
    this.updateCheckedElementView();
  }

  updateCheckedElementView() {
    let borderedElements = document.getElementsByClassName("bordered");
    const classNameAppend = " active";
    for (let i = 0; i < borderedElements.length; i++) {
      if (borderedElements[i].querySelectorAll('.form-check-input:checked').length) {
        if (borderedElements[i].className.indexOf(classNameAppend) < 0) {
          borderedElements[i].className += classNameAppend; 
        }
      }
      else {
        borderedElements[i].className = borderedElements[i].className.replace(classNameAppend, "");
      }
    }
  }

  onCheckboxChange(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  savePartnerContact() {
    if (!this.validation(this.profile)){
      return false;
    }
    const updatePersonalContact: UpdateContactRequestModel = {
      contactable_type:  this.loggedPartner.data.organisations[0].partner_type,
      contactable_uuid:  this.loggedPartner.data.uuid,
      country_uuid:  'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
      type: 'PARTNER_PERSONAL_TYPE_CONTACT',
      city: this._currentPartnerContact.data.city,
      county: this._currentPartnerContact.data.country.uuid,
      postcode: this._currentPartnerContact.data.post_code,
      street_name:  this._currentPartnerContact.data.street_name,
      street_number: 0,
      mobile_phone_1_country_code: 'BG',
      mobile_phone_1: this.profile.get('phone').value,
      contact_person_first_name: this.loggedPartner.data.first_name,
      contact_person_last_name: this.loggedPartner.data.last_name,
    }
    this.contactService.updatePartnerContact(updatePersonalContact, this.loggedPartner.data.contact.uuid).subscribe(() => {
      this.toastrService.success(Successes.updatePartnerInfo[this._language]);
    })
  }


  saveBussinesProfile() {
    if (!this.validation(this.bussines)){
      return false;
    }
    if (!this.validateWorkingTime()){
      return false;
    }
    const dialogRef  = this.matDialog.open(PasswordConfirmComponent, {
      width: '400px',
      height: '350px',
      disableClose: true,
      data: {password: this._password}
    });
    dialogRef.afterClosed().subscribe(result => {
      this._password = result;
      const updateOrganisation: UpdatePartnerRequestModel = {
        password: this._password,
        partner_uuid: this.loggedPartner.data.uuid,
        native_name: this.bussines.get('name').value,
        name_in_latin: this.bussines.get('tradingName').value,
        legal_form: this.bussines.get('legalOrgType').value,
        number: this.loggedPartner.data.organisations[0].number,
        vat_registered: this.bussines.get('vatRegistered').value === 'true',
        vat_number: this.bussines.get('vatNumber').value,
        description: { bg: this.bussines.get('businessDescription').value },
        bank_name: this.bussines.get('bankName').value,
        iban: this.bussines.get('iban').value,
        bic_swift_code: this.bussines.get('bankSortCode').value,
        opening_hours: this._workingtimesComponent.prepareWorkingTime(),
        partner_type: (this.profile.get('role').value == "manager") ? PartnerPositions.positions.shareholder : PartnerPositions.positions.owner,
      };
      const updateOrganisationContact: UpdateContactRequestModel = {
        contactable_type:  'ORGANISATION',
        contactable_uuid:  this.loggedPartner.data.uuid,
        country_uuid:  'fee09419-17d4-4ec0-bf7d-a3b12b5a5cc9',
        type: 'ORGANISATION_TYPE_CONTACT',
        city: this.bussines.get('cityName').value,
        county: this.bussines.get('country').value,
        postcode: this.bussines.get('postalCode').value,
        street_name:  this.bussines.get('address1').value,
        street_number: 0,
        mobile_phone_1_country_code: 'BG',
        mobile_phone_1: this.loggedPartner.data.organisations[0].contact.mobile_phone_1_e164,
        contact_person_first_name: this.loggedPartner.data.first_name,
        contact_person_last_name: this.loggedPartner.data.last_name,
      }
      const arrayOfPromisses : Promise<any>[] = [
        this.registrationService.updateOrganisation(updateOrganisation, this.loggedPartner.data.organisations[0].uuid).toPromise(),
        this.contactService.updateOrganisationContact(updateOrganisationContact, this.loggedPartner.data.organisations[0].uuid,
                                                       this._currentOrginizationContact.data.uuid).toPromise()
      ]
      Promise.all(arrayOfPromisses).then(() => {
        this.toastrService.success(Successes.updatePartner[this._language]);
      })
    });
  }

  compTypes() {
    return CompanyTypes.compTypes[this.localStorageService.getLocalStorageItem(Labels.lsKey)];
  }

  avatarUpload(files) {
    if (!this.loggedPartner?.data?.organisations[0]?.product_max_return_period_in_days) {
      this.toastrService.warning(Warnings.delivery[this._language]);
      return;
    }
    if (!this.loggedPartner?.data?.documents && this.areDocumentsFilled()) {
      this.toastrService.warning(Warnings.documents[this._language]);
      return;
    }
    const file = files[0];
    let self = this;
    if (!ValidationHelper.allowedImages.validate(file.name.split('.').pop())) {
      this.toastrService.error(Errors.imageType[this._language]);
      return;
    }
    if (!ValidationHelper.maxSizeOfImage.validate(file.size)) {
      this.toastrService.error(file.name + Errors.imageMaxSize[this._language]);
      return;
    }
    
    let fr = new FileReader();
    fr.addEventListener("load", function () {
      const content = fr.result.toString().substring(fr.result.toString().indexOf(',') + 1);
      const imageToDelete : DeleteImage = {
        subject_type: 'ORGANISATION',
        subject_uuid: self.loggedPartner.data.organisations[0].uuid,
        uuid: self.loggedPartner.data.images?.data[0]?.uuid
      };
      if (self.loggedPartner.data.images?.data[0]?.uuid) {
        self.imageService.deleteImage(imageToDelete).toPromise().then(() => {
          self.createAvatar(content);
        })
      }
      else {
        self.createAvatar(content);
      }
    }, false);

    fr.readAsDataURL(file);

  }

  private createAvatar(content: string) {
    const createImage : CreateNewBase64Image = {
      subject_type: 'ORGANISATION',
      subject_uuid: this.loggedPartner.data.organisations[0].uuid,
      collection: 'logo',
      uploads: [
        {
          base64: content
        }
      ]
    }
    this.imageService.createNewImage(createImage).subscribe(() => {
      this.imageService.getImagesPerProduct("ORGANISATION", this.loggedPartner.data.organisations[0].uuid, 'logo').subscribe((images) => {
        this.loggedPartner.data.images = images;
        this.toastrService.success(Successes.imageUpload[this._language]);
      })
    })
  }

  changePassword() {
    if (!this.validation(this.password)) {
      return false;
    }
    if (this.password.controls.newPassword.value != this.password.controls.confirmPassword.value) {
      this._errorMsg = Errors.passwordMatch[this._language];
      this.error = true;
      return false;
    }
    let changePasswordRequestModel : ChangePasswordRequestModel = {
      old_password: this.password.get('currentPassword').value,
      password_confirmation: this.password.get('newPassword').value,
      password: this.password.get('newPassword').value
    }
    this.registrationService.changePassword(changePasswordRequestModel).subscribe(() => {
      this.toastrService.success(Successes.changePassword[this._language]);
    })

  }

  passwordValidator (control: AbstractControl): {[key : string]: boolean} | null {
    const value = <string> control.value;
    if ( value.search(/\d/) < 0 || 
         value.search(/[a-zA-Zа-яА-Я]/) < 0 || 
         value.search(/\W/) < 0){
      return {'passwordValidator': false};
    }
    return null;
  };
}

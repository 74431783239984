<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body">
    <div class="row">
        <div class="col-sm-3">
            <h3>{{this.serviceName}}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <button class="btn btn-primary" 
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate">
                {{this.dashboardLables(this.getPageName()).prev}}
            </button>
        </div>
        <div class="col-md-6">
            <h3 style="text-align: center;">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-primary" 
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate">
                {{this.dashboardLables(this.getPageName()).next}}
            </button>
        </div>
    </div>

    <div class="row"> 
        <div class="col-sm-12">
            <ngb-carousel [interval]=0 [showNavigationIndicators]="false" *ngIf="this.productLocations">
                <ng-template (slid)="this.changeLocation(location)" *ngFor="let location of this.productLocations" ngbSlide>
                  <div >
                    <p style="text-align: center;">{{location.partnerLocationName}}</p>
                  </div>
                </ng-template>
              </ngb-carousel>
        </div>
    </div>
            
    <div class="row">
        <div class="col-sm-8">
            <mwl-calendar-month-view
                [viewDate]="viewDate" 
                [events]="events" 
                (dayClicked)="dayClicked($event.day)" 
                [refresh]="this.refresh">
            </mwl-calendar-month-view>
        </div>
        <div class="col-sm-4" style="overflow-y: scroll; height: 90vh;">
            <h5 style="width: 60%; display: inline-block;">{{ viewDate | calendarDate:('dayViewTitle'):'en'}}</h5>
            <div ngbDropdown class="btn-group" style="width: 20%; display: inline-block; position: relative; top: -10px;">
                <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-calendar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{this.dashboardLables(this.getPageName()).toggle}}
                </button>
                <div ngbDropdownMenu class="dropdown-menu">
                  <a ngbDropdownItem style="font-size: xx-small;" [disabled]="!this.isAllDayNotFree" class="dropdown-item" (click)="this.markDayAsFree()">{{this.dashboardLables(this.getPageName()).freeDay}}</a>
                  <a ngbDropdownItem style="font-size: xx-small;" [disabled]="this.hasEmptySlot" class="dropdown-item" (click)="this.markDayAsTaken()">{{this.dashboardLables(this.getPageName()).resDay}}</a>
                </div>
            </div>
            <div style="margin-bottom: 5px;">
                <button (click)="this.filter('all')" style="margin-right: 2px; display:inline-block; width: 30%; font-size: xx-small;"  type="button" class="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{this.dashboardLables(this.getPageName()).all}}
                </button>
                <button (click)="this.filter('free')" style="margin-right: 2px; display:inline-block; width: 30%; font-size: xx-small;"  type="button" class="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{this.dashboardLables(this.getPageName()).free}}
                </button>
                <button (click)="this.filter('reserved')" style="margin-right: 2px; display:inline-block; width: 30%; font-size: xx-small;" type="button" class="btn btn-primary dropdown-calendar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{this.dashboardLables(this.getPageName()).reserved}}
                </button> 
            </div>
            <div class="list-group">
                <a *ngFor="let interval of this.timeIntervals" href="#" class="list-group-item list-group-item-action" aria-current="true">
                  <h5>{{interval.time}}</h5>
                  <p style="display: inline-block;">{{(interval.reserved) ? this.dashboardLables(this.getPageName()).res : this.dashboardLables(this.getPageName()).fr }}</p>
                  <mat-slide-toggle
                    style="float: right;"
                    class="example-margin"
                    (change)="this.onReserve(interval, $event)"
                    [color]="color"
                    [checked]="interval.reserved || interval.closed"
                    [disabled]="disabled">
                        {{this.dashboardLables(this.getPageName()).reserve}}
                    </mat-slide-toggle>
                </a>
              </div>
        </div>
    </div>
</div>
<div class="card" style="width: 100%">
    <div class="card-body">
        <button (click)="this.moveBack()" type="button" style="margin: 8% 0;" class="btn back"><img src="/assets/images/arrow.svg" alt="Back"></button>
        <div class="wrapper">
            <h5 class="card-title">{{this.lables(this.getPageName()).lable}}</h5>
            <div [class]="this.user.owner ? 'bordered active' : 'bordered'" (click)="this.onUserTypeChange($event, 'owner')">
                <div class="form-check">
                    <input name="owner" [checked]="this.user.owner"   class="form-check-input" type="checkbox"  id="exampleRadios1" value="option1">
                    <label class="form-check-label" for="exampleRadios1" (click)="this.onUserTypeChange($event, 'owner')">
                        {{this.lables(this.getPageName()).owner}}
                    </label>
                </div>
            </div>
            <div [class]="this.user.shareholder ? 'bordered active' : 'bordered'" (click)="this.onUserTypeChange($event, 'shareholder')">
                <div class="form-check">
                    <input name="shareholder" [checked]="this.user.shareholder" class="form-check-input" type="checkbox"  id="exampleRadios2" value="option1">
                    <label class="form-check-label" for="exampleRadios2" (click)="this.onUserTypeChange($event, 'shareholder')">
                        {{this.lables(this.getPageName()).shareholder}}
                    </label>
                </div>
            </div>
            <div *ngIf="this.shouldShowError()" class="alert alert-danger" role="alert">
                {{this.lables(this.getPageName()).error}}
            </div>
            <button (click)="this.moveForward()" type="button" class="btn btn-primary move-forward">{{this.lables(this.getPageName()).continueBtn}}</button>
        </div>
    </div>
</div>
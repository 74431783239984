import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/base/base.component';
import { ValidationHelper } from 'src/infrastructure/validationHelpers/validationHelper';
import { PasswordResetRequestModel } from 'src/models/requestModels/V2/password/PasswordResetRequestModel';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { RegistrationService } from 'src/services/requestServices/V2/registration/registration.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent extends BaseComponent implements OnInit {

  constructor(protected userInfoService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected router: Router,
    private registrationService: RegistrationService,
    private toast: ToastrService) {
      super(userInfoService, localStorageService, router);
      this.pageName = "forgottenPassword";
   }

  ngOnInit(): void {
  }

  resetPassword() {
    if (this.user.email && 
      ValidationHelper.email.validate(this.user.email)) {
        const initiatePasswordResetRequestModel : PasswordResetRequestModel = {
          email: this.user.email
        }
        this.registrationService.initiateResetPassword(initiatePasswordResetRequestModel).subscribe(() => {
          this.toast.success(this.lables(this.getPageName()).success);
          this.router.navigate(['registration/reset/pass/confirmation']);
        })
    }
    else {
      this.error = true;
    }
  }

  emailChange(value) {
    this.user.email = value;
    this.userinfostoreService.addNewUser(this.user);
  }

}

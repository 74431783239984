export const Dialogs = {
    bg: {
        saveChangesLabel: "Запазване на промените ?",
        givePassword: "Въведете паролата си, за да запазите промените",
        password: "Парола",
        saveAddress: "Въведете адрес за доставка",
        country: "Въведете държава",
        city: "Въведете врад",
        street: "Въведете улица",
        OK: "Запази",
        cancel: "Откажи"
    }
}
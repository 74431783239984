import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductCollection } from 'src/models/DTO/V2/product/ProductCollection';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private productURL : string = environment.api + "product/search";

  constructor(private httpClient: HttpClient) { }


  searchProducts(perPage: number, page: number, query: string, state: string) {
    const params = '?fields%5Bproducts%5D=id%2Cuuid%2Cname%2Corganisation_id%2Ccreated_at%2Cupdated_at%2Ccategory_id%2Cbrand_id%2Cmanufacturer_id%2Cstate&fields%5Bskus%5D=id%2Cproduct_id%2Cuuid%2Csku%2Cavailable_quantity%2Creserved_quantity&include=skus%2Corganisation%2Cpricing%2Cbrand%2Ccategory%2Cmanufacturer%2Cskus.options.option%2Coptions.option&append=total_available_quantity%2Ctotal_reserved_quantity&fields%5Borganisation%5D=id%2Cuuid%2Cname_in_latin%2Cnative_name&filter%5Bstate%5D=' + state + '&fields%5Bpricing%5D=id%2Cproduct_id%2Cuuid%2Cquantity_from%2Cquantity_to%2Cprice_per_piece&fields%5Bcategory%5D=id%2Cuuid%2Cname&fields%5Bskus.options%5D=id%2Cuuid%2Csku_id%2Coption_variation_id%2Cdisplay_value&fields%5Bskus.options.option%5D=id%2Cuuid%2Cdisplay_name&fields%5Boptions.option%5D=id%2Cuuid%2Cdisplay_name&perPage=' + perPage + '&page=' + page + '&q=' + query;
    return this.httpClient.get<ProductCollection>(this.productURL + params);
  }
}

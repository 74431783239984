import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalstorageserviceService } from './../infrastructureServices/localstorageservice/localstorageservice.service';
import { ErrorInterceptorService } from './../interceptorServices/errorinterceptor/errorinterceptor.service';
import { TokenInterceptorService } from './../interceptorServices/tokenInterceptor/token-interceptor.service';
import { EmailConfirmationService } from './../requestServices/emailConfirmationService/email-confirmation.service';
import { UsersService } from './../requestServices/users/users.service';
import { PartnersService } from './../requestServices/partners/partners.service';
import { CategoriesService as Catedories } from './../requestServices/categories/categories.service';
import { UserinfostoreService } from './../stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggeduserinfoService } from './../stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { ProductService } from './../requestServices/products/product.service';
import { CountriesService } from './../requestServices/countries/countries.service';
import { LocationsService } from './../requestServices/locations/locations.service';
import { ImagesService } from './../requestServices/images/images.service';
import { AuthGuardService } from './../guards/authentication/authguard.service';
import { BrandsService as Brands } from './../requestServices/brands/brands.service';
import { VariationsService } from './../requestServices/variations/variations.service';
import { PricingService as pricings } from './../requestServices/pricing/pricing.service';
import { CitiesService } from './../requestServices/cities/cities.service';
import { ServiceService } from './../requestServices/services/service.service';
import { WorkingtimeService } from './../requestServices/workingtime/workingtime.service';
import { ContractService } from '../requestServices/contracts/contract.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalendarService } from '../requestServices/calendar/calendar.service';
import { ProductlocationService } from '../requestServices/productlocation/productlocation.service';
import { SearchService as Search } from '../requestServices/search/search.service';
import { DocumentsService } from '../requestServices/documents/documents.service';
import { HttploadingstateService } from '../interceptorServices/httploadingstateService/httploadingstate.service';


// Request Services //
import { RegistrationService } from '../requestServices/V2/registration/registration.service';
import { ContactsService } from '../requestServices/V2/contacts/contacts.service';
import { LoginService } from '../requestServices/V2/login/login.service';
import { InfoService } from '../requestServices/V2/info/info.service';
import { BrandsService } from '../requestServices/V2/brands/brands.service';
import { CategoriesService } from '../requestServices/V2/categories/categories.service';
import { ProductsService } from '../requestServices/V2/products/products.service';
import { ManifacturerService } from '../requestServices/V2/manifacturers/manifacturer.service';
import { OptionService } from '../requestServices/V2/options/option.service';
import { ProductoptionService } from '../requestServices/V2/productOption/productoption.service';
import { PricingService } from '../requestServices/V2/pricing/pricing.service';
import { SkuoptionService } from '../requestServices/V2/skuOption/skuoption.service';
import { SkuService } from '../requestServices/V2/sku/sku.service';
import { PackagingService } from '../requestServices/V2/packaging/packaging.service';
import { TagsService } from '../requestServices/V2/tags/tags.service';
import { SearchService } from '../requestServices/V2/search/search.service';
import { DeliveryService } from '../requestServices/V2/delivery/delivery.service';
import { StripeService } from '../requestServices/V2/stripe/stripe.service';
import { OrdersService } from '../requestServices/V2/orders/orders.service';
import { CarrierService } from '../requestServices/V2/carrier/carrier.service';
import { CheckerService } from '../requestServices/V2/checkers/checker.service';
import { ShipmentService } from '../requestServices/V2/shipment/shipment.service';




// State Services //
import { LoggedinfoService } from '../stateServices/V2/loggedinfo/loggedinfo.service';
import { LoadingstateService } from '../stateServices/loading/loadingstate.service';








@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttploadingstateService,
      multi: true
    },
    pricings,
    EmailConfirmationService,
    UsersService,
    UserinfostoreService,
    LocalstorageserviceService,
    PartnersService,
    CategoriesService,
    ProductService,
    LoggeduserinfoService,
    LocationsService,
    CountriesService,
    ImagesService,
    AuthGuardService,
    BrandsService,
    VariationsService,
    PricingService,
    CitiesService,
    ServiceService,
    WorkingtimeService,
    ContractService,
    CalendarService,
    ProductlocationService,
    SearchService,
    DocumentsService,
    RegistrationService,
    ContactsService,
    LoginService,
    InfoService,
    LoggedinfoService,
    ProductsService,
    ManifacturerService,
    OptionService,
    ProductoptionService,
    PricingService,
    SkuoptionService,
    SkuService,
    PackagingService,
    TagsService,
    DeliveryService,
    StripeService,
    OrdersService,
    CarrierService,
    LoadingstateService,
    CheckerService,
    ShipmentService
  ]
})
export class CoreModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PartnerInfo } from 'src/models/DTO/V2/info/PartnerInfo';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  private infoURL : string = environment.api + "partner/me?include=organisations.carrierAccounts%2Corganisations.contact%2Ccontact%2Corganisations.return_address&fields%5Borganisations%5D=id%2Cpartner_id%2Cuuid%2Cnative_name%2Copening_hours%2Cname_in_latin%2Clegal_form%2Cpartner_type%2Cnumber%2Cvat_number%2Cbank_name%2Ciban%2Cdescription%2Cdelivery_to_address_price%2Cdelivery_to_courier_office_price%2Cfree_delivery_after_order_amount%2Cproduct_max_return_period_in_days%2Cbic_swift_code%2Cstate%2Crequirements&fields%5Bpartners%5D=id%2Cuuid%2Cfirst_name%2Clast_name%2Cemail%2Cdob%2Cis_active&fields%5Borganisations.contact%5D=contacts.id%2Ccontacts.contactable_id%2Ccontacts.contactable_type%2Ccontacts.mobile_phone_1_e164%2Ccontacts.uuid&fields%5Bcontact%5D=contacts.id%2Ccontacts.contactable_id%2Ccontacts.contactable_type%2Ccontacts.mobile_phone_1_e164%2Ccontacts.uuid";

  constructor(private httpClient: HttpClient) { }

  info() {
    return this.httpClient.get<PartnerInfo>(this.infoURL);
  }
}
